// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class ReferanceStandart {
  constructor() {}

  /**
   * Referans Standartı Ekleme
   * @param {string} product_id
   * @param {string} name
   * @param {string} name_en
   */
  Create = ({ product_id, name, name_en }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          product_id,
          name,
          name_en,
        };
        const response = await ApiService(
          "post",
          Endpoint.ReferanceStandart.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Referans Standartı  Düzenleme
   * @param {string} standard_id
   * @param {string} product_id
   * @param {string} name
   * @param {string} name_en
   */
  Update = ({ standard_id, product_id, name, name_en }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          standard_id,
          product_id,
          name,
          name_en,
        };
        const response = await ApiService(
          "put",
          Endpoint.ReferanceStandart.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Referans Standartı Silme İşlemi
   * @param {string} standard_id
   */
  Delete = (standard_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          standard_id,
        };
        const response = await ApiService(
          "delete",
          Endpoint.ReferanceStandart.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Referans Standartı  Listeleme
   * @param {number} limit
   * @param {number} page
 
   */
  SelectAll = (limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.ReferanceStandart.getAll}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Referans Standartı Detayı Alma
   * @param {string} standard_id
   */
  Select = (standard_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.ReferanceStandart.getOne}/${standard_id}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Referans Standartı Aktif/Pasif Durumu Değiştirme
   * @param {string} standard_id
   */
  StatusChange = (standard_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          standard_id
        };

        const response = await ApiService(
          "post",
          Endpoint.ReferanceStandart.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new ReferanceStandart();
