import React, { Component } from 'react';

import { NavLink } from "react-router-dom"

import Table from '../../Components/UI/Table';
import PageHeader from '../../Components/UI/PageHeader';

import { Modal, Button, Tooltip, Card, Form, Input, Select, Space } from 'antd';

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const tableColumns = [
    {
        title: 'Teklif No',
        dataIndex: 'offer_no',
        key: 'offer_no',
    },
    {
        title: 'Tarih',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Müşteri',
        dataIndex: 'customer',
        key: 'customer',
    },
    {
        title: 'Testler',
        dataIndex: 'tests',
        key: 'tests',
    },
    {
        title: 'Fiyat',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'Seçenekler',
        dataIndex: 'options',
        key: 'options',
        render: (text, record) => (
          <Space size="middle">
            <Button shape="round" type="primary" >İncele</Button>
            <Button shape="round" type="primary" >Düzenle</Button>
            <Button type="link" icon={<DeleteOutlined />} danger></Button>
          </Space>
        ),
    }
];

const tableData = [
    {
        key: '1',
        offer_no: '100001',
        date: '10.05.20 10:20',
        customer: 'Petrol Ofisi A.Ş.',
        tests: 'Test 1, Test 2, Test 3',
        price: '1000 TL',
    },
    {
        key: '2',
        offer_no: '100002',
        date: '10.05.20 10:20',
        customer: 'Petrol Ofisi A.Ş.',
        tests: 'Test 1, Test 2, Test 3',
        price: '1000 TL',
    },
    {
        key: '2',
        offer_no: '100003',
        date: '10.05.20 10:20',
        customer: 'Petrol Ofisi A.Ş.',
        tests: 'Test 1, Test 2, Test 3',
        price: '1000 TL',
    },
];

class index extends Component {

    state = { visible: false };
    showModal = () => {
        this.setState({
        visible: true,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };


    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    
                    <PageHeader title="Teklifler" />

                    <Tooltip title="Yeni Teklif Ekle" placement="left">
                        <NavLink activeClassName="nav-active" exact to="/offers/edit">
                            <Button shape="circle" icon={<PlusOutlined />} />
                        </NavLink>
                    </Tooltip>
                    
                    <Modal
                        title="Yeni Teklif Ekle"
                        visible={this.state.visible}
                        onCancel={this.handleCancel}
                        footer=""
                        >
                    </Modal>
                </div>


                <Card bordered={false} className="cardTable">
                    <Table columns={tableColumns} data={tableData} />
                </Card>
            </div>
        );
    }
}

export default index;