// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Logs {
  constructor() {}

  /**
   * Log Listeleme
   * @param {number} limit
   * @param {number} page
   * @param {Date} date - Date (month-Year)
   */
  Select = (date, limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
     
        const endpoints = `${Endpoint.Logs}/${limit}/${page}/${date}`;
        const response = await ApiService(
          "get",

          endpoints
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}
export default new Logs();
