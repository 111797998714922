import React, { useState, useEffect } from "react";
import _ from "lodash";
import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";

import Export from "../../../Components/Export/Export";
import { Card, Space, Spin } from "antd";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import RangeButton from "../../../Components/UI/RangeButton";
import moment from "moment";
const SampleTracking = () => {
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const [tableColumns, setTableColums] = useState([
    {
      title: "Rapor No",
      dataIndex: "report_no",
      key: "report_no",
      ...Helper.getColumnSearchProps(
        "report_no",
        handleSearch,
        handleReset,
        "Rapor No"
      ),
      sorter: (a, b) => String(a?.report_no).localeCompare(String(b.report_no)),
    },
    {
      title: "Kayıt No",
      dataIndex: "registry_no",
      key: "registry_no",
      ...Helper.getColumnSearchProps(
        "registry_no",
        handleSearch,
        handleReset,
        "Kayıt No"
      ),
      sorter: (a, b) =>
        String(a?.registry_no).localeCompare(String(b?.registry_no)),
    },
    {
      title: "Terminal",
      dataIndex: "get_location",
      key: "get_location",
      ...Helper.getColumnSearchProps(
        "get_location",
        handleSearch,
        handleReset,
        "Terminal"
      ),
      sorter: (a, b) =>
        String(a?.get_location).localeCompare(String(b?.get_location)),
    },
    {
      title: "Müşteri Adı",
      dataIndex: "customer_name",
      key: "customer_name",
      ...Helper.getColumnSearchProps(
        "customer_name",
        handleSearch,
        handleReset,
        "Müşteri Adı"
      ),
      sorter: (a, b) =>
        String(a?.customer_name).localeCompare(String(b?.customer_name)),
    },
    {
      title: "Tipi",
      dataIndex: "workOrderTypeName",
      key: "workOrderTypeName",
      filters: [
        { value: "Analiz", text: "Analiz" },
        { value: "Saklama", text: "Saklama" },
      ],
      onFilter: (value, record) => {
        return record.workOrderTypeName === value;
      },
      sorter: (a, b) =>
        String(a?.workOrderTypeName).localeCompare(
          String(b?.workOrderTypeName)
        ),
    },
    {
      title: "Ürün Cinsi",
      dataIndex: "productName",
      key: "productName",

      sorter: (a, b) =>
        String(a?.productName).localeCompare(String(b?.productName)),
    },
    {
      title: "Numunenin Alındığı Yer",
      dataIndex: "get_part",
      key: "get_part",
      ...Helper.getColumnSearchProps(
        "get_part",
        handleSearch,
        handleReset,
        "Numunenin Alındığı Yer"
      ),
      sorter: (a, b) => String(a?.get_part).localeCompare(String(b?.get_part)),
    },
    {
      title: "Sektör",
      dataIndex: "sectorName",
      key: "sectorName",
      ...Helper.getColumnSearchProps(
        "sectorName",
        handleSearch,
        handleReset,
        "Sektör"
      ),
      sorter: (a, b) =>
        String(a?.sectorName).localeCompare(String(b?.sectorName)),
    },
    {
      title: "Depo",
      dataIndex: "storeName",
      key: "storeName",
      ...Helper.getColumnSearchProps(
        "storeName",
        handleSearch,
        handleReset,
        "Depo"
      ),
      sorter: (a, b) =>
        String(a?.storeName).localeCompare(String(b?.storeName)),
    },
    {
      title: "Lab",
      dataIndex: "labName",
      key: "labName",
      ...Helper.getColumnSearchProps(
        "labName",
        handleSearch,
        handleReset,
        "Lab"
      ),
      sorter: (a, b) => String(a?.labName).localeCompare(String(b?.labName)),
    },
    {
      title: "Konteyner",
      dataIndex: "containerName",
      key: "containerName",
      ...Helper.getColumnSearchProps(
        "containerName",
        handleSearch,
        handleReset,
        "Konteyner"
      ),
      sorter: (a, b) =>
        String(a?.containerName).localeCompare(String(b?.containerName)),
    },
    {
      title: "Raf",
      dataIndex: "shelfName",
      key: "shelfName",
      ...Helper.getColumnSearchProps(
        "shelfName",
        handleSearch,
        handleReset,
        "Raf"
      ),
      sorter: (a, b) =>
        String(a?.shelfName).localeCompare(String(b?.shelfName)),
    },
    {
      title: "Şişe",
      dataIndex: "bottle",
      key: "bottle",
      ...Helper.getColumnSearchProps(
        "bottle",
        handleSearch,
        handleReset,
        "Şişe"
      ),
      sorter: (a, b) =>
        String(a?.bottle).localeCompare(String(b?.bottle)),
    },
    {
      title: "Başlangıç",
      dataIndex: "date_start",
      key: "date_start",
      ...Helper.getColumnSearchProps(
        "date_start",
        handleSearch,
        handleReset,
        "Başlangıç"
      ),
      sorter: (a, b) =>
        String(a?.date_start).localeCompare(String(b?.date_start)),
    },
    {
      title: "Bitiş",
      dataIndex: "date_finish",
      key: "date_finish",
      ...Helper.getColumnSearchProps(
        "date_finish",
        handleSearch,
        handleReset,
        "Bitiş"
      ),
      sorter: (a, b) =>
        String(a?.date_finish).localeCompare(String(b?.date_finish)),
    },
    {
      title: "Mühür",
      dataIndex: "signet_code",
      key: "signet_code",
      ...Helper.getColumnSearchProps(
        "signet_code",
        handleSearch,
        handleReset,
        "İmza"
      ),
      sorter: (a, b) =>
        String(a?.signet_code).localeCompare(String(b?.signet_code)),
    },
  ]);

  useEffect(() => {
    getData();
  }, []);
  const getData = (
    date = [moment().add(-2, "week"), moment().add(2, "week")]
  ) => {
    DataService.Sample.SampleTrackingList(
      0,
      0,
      moment(date[0]).unix(),
      moment(date[1]).unix()
    )
      .then((result) => {
        setLoading(false);
        if (result.status) {
          setData(result?.response?.data || []);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleTableChanged = () => {};
  return (
    <div>
      <div className="pageHeaderWrapper">
        <PageHeader title="Numune Takip" />

        <div className="pageHeaderOptions">
          <Space>
            <Export
              data={data}
              columns={tableColumns}
              tableName={"SampleTracking"}
              dates={["tarih"]}
            />
          </Space>
        </div>
      </div>
      <Spin spinning={loading}>
        <Card bordered={false} className="cardTable">
          <RangeButton onClick={getData} />
          <Tables
            columns={tableColumns}
            onChange={handleTableChanged}
            dataSource={data}
            pagination={{
              defaultPageSize: 1,
            }}
            rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
          />
        </Card>
      </Spin>
    </div>
  );
};

export default SampleTracking;
