import React, {Component,} from "react";

import {NavLink} from "react-router-dom";

import Table from "../../../../Components/UI/Table";
import PageHeader from "../../../../Components/UI/PageHeader";

import {Button, Card, Col, Descriptions, Form, Input, Modal, Progress, Row, Select, Space, Spin,} from "antd";

//import QrCode from "../../../../Assets/qrcode.svg";
import QRCode from "react-qr-code";
import {PlusOutlined, PrinterOutlined, SaveOutlined,} from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import Helper from "../../../../Helper";
import DataService from "../../../../Provider/Service/DataService";
import PrintComponent from "../Print/print";

const {Option} = Select;

class SampleTestEdit extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: true,
            showSaveAllProgress: false,
            saveAllProgressValue: 0,
            saveAllProgressMessage: '',
            saveAllProgressError: false,
            selectedWorkBranch: {},
            tableColumns: [
                {
                    title: "Test Adı",
                    dataIndex: "name",
                    key: "name",
                    render: (text, record) => `${text}/${record?.name_en}`,
                },
                {
                    title: "Tank Grubu",
                    dataIndex: "group_name",
                    key: "group_name",
                    render: (text, record) => text || "N/A",
                },
                {
                    title: "Method",
                    dataIndex: "method",
                    key: "method",
                },
                {
                    title: "Birim",
                    dataIndex: "unit",
                    key: "unit",
                },
                {
                    title: "Akredite",
                    dataIndex: "accredited",
                    key: "accredited",
                    render: (text, record) => (text == 0 ? "Hayır" : "Evet"),
                },
                {
                    title: "Ureal",
                    dataIndex: "ureal",
                    key: "ureal",
                },
                {
                    title: "Ref Min",
                    dataIndex: "ref_min",
                    key: "ref_min",
                    render: this._renderRefMin,
                },
                {
                    title: "Ref Max",
                    dataIndex: "ref_max",
                    key: "ref_max",
                    render: this._renderRefMax,
                },
                {
                    // tipe gore input veya select gelecek, select optionlarina numune secenekleri gelecek.
                    title: "Sonuç",
                    dataIndex: "result",
                    key: "result",
                    render: this._renderResult,
                },
                {
                    title: "Ö.B",
                    dataIndex: "m_u",
                    key: "m_u",
                    render: (text, record) => {
                        if (record.number == 1) {
                            const resultOB = (
                                parseFloat(record.ureal) * Number(record.result)
                            ).toFixed(record.numberCharacters);
                            if (isNaN(resultOB)) {
                                return "N/A";
                            } else {
                                return resultOB;
                            }
                        } else {
                            return "N/A";
                        }
                    },
                },
                {
                    title: () => {
                        return (<>
                            <Select
                                showSearch
                                style={{width: 150}}
                                placeholder="Kullanıcı Seçiniz"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                defaultValue={'Analizi Yapan'}
                                onChange={(value) => (this[`users-general`] = value)}
                            >
                                {this.state?.users?.map((item, index) => {
                                    return (
                                        <Option
                                            value={item.userId}
                                        >{`${item.name}  ${item.lastname}`}</Option>
                                    );
                                })}
                            </Select>
                        </>)
                    },
                    dataIndex: "testUserId",
                    key: "testUserId",
                    render: this._renderUsers,
                },
                {
                    title: "Seçenekler",
                    dataIndex: "options",
                    key: "options",
                    render: (text, record) => {
                        return (
                            <Space size="middle">
                                <Button
                                    shape="round"
                                    type="primary"
                                    onClick={() => this._resultSaved(record)}
                                    disabled={
                                        this.state.details.statusId == 11 && record.status == 3
                                    }
                                    className={
                                        record.result || record.testUserId
                                            ? "btn-success"
                                            : "ant-btn-primary"
                                    }
                                >
                                    {record.result || record.testUserId ? "Güncelle" : "Kaydet"}
                                </Button>
                            </Space>
                        );
                    },
                },
            ],
            data: [],
            details: {
                reportNo: "N/A",
                sectorName: "N/A",
                sectorNameEn: "N/A",
                getLocation: "N/A",
                getPartAdd: "N/A",
                getMethod: "N/A",
                productName: "N/A",
                productNameEn: "N/A",
                getAcceptanceDate: "N/A",
                getAcceptanceHour: "",
                statusId: "N/A",
                operationName: "N/A",
                sampleId: "",
                commentRevisionReq: "",

                customer_name: "N/A",
                dateDelivery: "N/A",
                getUser: "N/A",
                amount: "N/A",
                amount_type: "N/A",
                signetCode: "N/A",
                getSenderName: "N/A",
            },
            users: [{userId: "123", userName: "Orhan"}],
            sampleId: "",
            complated: 0,
            count: 0,
            progressBar: 0,
            btnLoading: false,
            tankbytankList: [],
            tankbytankId: null,
            tankbytankComment: "",
            tankbytankVisible: false,
            tankbytankEdit: false,
        };
    }

    forms = React.createRef();

    componentDidMount() {
        this.getData();
    }

    getValueOrSelf(input, sample, index = null) {
        let response = '';
        if (sample.number == 1) {
            response = input.state.value;
            response = parseFloat(response).toFixed(sample.numberCharacters || 0);
        } else {
            response = input;
        }

        return response != undefined ? response : false;
    }

    saveAllFormData() {
        this.setState({
            showSaveAllProgress: true
        })

        let errorStatus = false;
        let progressValue = 0;

        let saveAllProgressValue = 0;

        let recordsToSave = [];

        this.state.data.forEach((item, index) => {

            let singleResult = {
                refMinOperator: this[`refmin-operator-${Number(index)}`] ?? false,
                refMin: this.getValueOrSelf(this[`refmin-${Number(index)}`], item, index),
                refMaxOperator: this[`refmax-operator-${Number(index)}`] ?? false,
                refMax: this.getValueOrSelf(this[`refmax-${Number(index)}`], item),
                resultOperator: this[`result-operator-${Number(index)}`] ?? false,
                result: this.getValueOrSelf(this[`result-${Number(index)}`], item),
                user: this.hasOwnProperty(`users-${Number(index)}`) === undefined ? this[`users-${Number(index)}`] : this[`users-general`],
                self: item
            };


            if (this.state.data[index].testUserId !== this[`users-${Number(index)}`]) {
                singleResult.user = this[`users-${Number(index)}`];
            }


            if ((singleResult.refMin === '-' || singleResult.refMax === '-') && !singleResult.result) {

            } else {
                recordsToSave.push(singleResult);
            }
        });


        recordsToSave.forEach((singleResult, index) => {


            this._saveSingleResult({
                showAlerts: false,
                sample: singleResult.self,
                refMinValue: singleResult.refMin,
                refMinOperatorValue: singleResult.refMinOperator,
                refMaxValue: singleResult.refMax,
                refMaxOperatorValue: singleResult.refMaxOperator,
                resultValue: singleResult.result,
                resultOperatorValue: singleResult.resultOperator,
                testUserValue: singleResult.user
            }, (res) => {
                if (res.status) {

                    // If Done increase percentage
                    progressValue = ((index + 1) * 100) / recordsToSave.length;
                    if (res.increase) {
                        this.setState({
                            saveAllProgressValue: Math.round(progressValue),
                            //saveAllProgressValue: parseFloat(progressValue),
                            saveAllProgressMessage: res.message
                        })
                    }

                    // If Completed clear states
                    if (progressValue >= 100) {
                        // Refresh all data
                        setTimeout(() => {
                            this.setState({
                                showSaveAllProgress: false,
                                saveAllProgressValue: 0,
                                saveAllProgressMessage: '',
                                saveAllProgressError: false
                            });

                            window.location.reload();
                        }, 2000)
                    }
                } else {
                    this.setState({
                        saveAllProgressMessage: res.message,
                        saveAllProgressError: true
                    });
                    errorStatus = true;
                }
            });
        })


    }

    getData = async () => {
        try {
            if (this.props.match?.params?.sampleId) {
                const result = await DataService.Sample.getTests(
                    this.props.match?.params?.sampleId
                );

                console.log('result', result);
                result.data.forEach((test, index) => {
                    this[`refmin-${Number(index)}`] = test.ref_min;
                    this[`refmin-operator-${Number(index)}`] = test.ref_min_operator ?? "=";
                    this[`refmax-${Number(index)}`] = test.ref_max;
                    this[`refmax-operator-${Number(index)}`] = test.ref_max_operator ?? "=";
                    this[`result-${Number(index)}`] = test.result;
                    this[`result-operator-${Number(index)}`] = test.operator ?? "=";
                    this[`result-operator-${Number(index)}`] = test.operator ?? "=";
                })

                let data = [];
                if (result.status) {
                    let complated = 0,
                        count = result.data.length;

                    for (const key in result.data) {
                        if (result.data.hasOwnProperty(key)) {
                            const element = result.data[key];

                            data.push({
                                ...element,
                                key,
                            });
                            if (element.result != null) {
                                complated += 1;
                            }
                        } else {
                            console.log("resultdatanoobject", result.data[key]);
                        }
                    }
                    let progressBar = Math.round((100 / count) * complated);

                    const detailsData = {
                        ...result.details,
                        sampleId: this.props.match?.params?.sampleId,
                        getAcceptanceHour: result?.details?.getAcceptanceHour
                            ? moment(Number(result.details.getAcceptanceHour) * 1000).format(
                                "hh:mm"
                            )
                            : "",
                    };

                    data.forEach((test, index) => {
                        this[`users-${Number(index)}`] = test.testUserId;
                    });

                    this.setState({
                        data: data,
                        details: detailsData,
                        users: result.sampleUsers,
                        loading: false,
                        sampleId: this.props.match?.params?.sampleId,
                        complated,
                        count,
                        progressBar: parseFloat(Math.round(progressBar.toFixed(2))),
                        tankbytankList: result.tankbytankList
                    });

                    // Get all sectors
                    let sectorsRequest = await DataService.Sector.SelectAll();
                    const sectors = sectorsRequest.response.data;

                    const selectedWorkBranch = sectors.filter((item) => item.name === this.state.details.sectorName)[0]


                    this.setState({
                        selectedWorkBranch: selectedWorkBranch
                    });
                } else {
                    Helper.ErrorMessage("Test Bulunamadı");
                    this.props.history.push("/sample/sample-test");
                }
            } else {
                Helper.ErrorMessage("Test Bulunamadı");
                this.props.history.push("/sample/sample-test");
            }
        } catch (error) {
            Helper.ErrorMessage(error.toString());
            //  this.props.history.push("/sample/sample-test");
        }
    };
    _renderRefMin = (text, record) => {
        /*
         if (text == 0) {
             text = "";
         }
         */

        if (this.state.details.statusId == 11 && record.status == 3) {
            return text;
        }
        if (record.number == 1) {
            let numberCharacters = Number(record.numberCharacters);

            numberCharacters = numberCharacters > 0 ? numberCharacters : 0;

            const steps =
                numberCharacters == 0
                    ? 1
                    : parseFloat(`0.${Array(numberCharacters).join(numberCharacters)}1`);

            /*this[`refmin-operator-${Number(record.key)}`] =
                record?.ref_min_operator || "=";*/

            return (
                <>
                    <Select
                        style={{width: 50}}
                        placeholder="Seçiniz"
                        defaultValue={record?.ref_min_operator || "="}
                        onChange={(value) => {
                            this['refmin-operator-' + Number(record.key)] = value;

                            let label = `refmin-operator-${Number(record.key)}`;
                            this.setState({
                                [label]: value
                            });
                        }}
                    >
                        <Option value=">">{">"}</Option>
                        <Option value="=">{"="}</Option>
                        <Option value="<">{"<"}</Option>
                    </Select>
                    <Input
                        ref={(refs) => (this[`refmin-${Number(record.key)}`] = refs)}
                        style={{width: 100}}
                        className={this.state.data[record.key].statusMessage?.refMin?.type}
                        min={steps}
                        step={steps}
                        readOnly={record.confirm == 1}
                        defaultValue={text}
                        type="number"
                        placeholder="-"
                        onChange={(e) => {
                            this._inputChangeGetMessage(
                                e.target.value,
                                this[`refmax-${Number(record.key)}`].state.value,
                                this[`result-${Number(record.key)}`].state.value,
                                record
                            );
                        }}
                    />
                    <br/>
                    <small
                        className={this.state.data[record.key].statusMessage?.refMin?.type + '-text'}>{this.state.data[record.key].statusMessage?.refMin?.text}</small>
                </>
            );
        } else {
            this[`refmin-${Number(record.key)}`] = text;
            const newArray = [{name: "-"}, ...record.options];
            return (
                <Select
                    style={{width: 150}}
                    placeholder="Seçiniz"
                    optionFilterProp="children"
                    onChange={(value) => {
                        this[`refmin-${Number(record.key)}`] = value

                        this.state.data[record.key].ref_min = value
                        this.forceUpdate()

                        this._inputChangeGetMessageText(
                            value,
                            this[`result-${Number(record.key)}`],
                            record
                        )
                    }}
                    defaultValue={text}
                    disabled={record.confirm == 1}
                >
                    {newArray.map((item, index) => {
                        return <Option value={item.name}>{item.name}</Option>;
                    })}
                </Select>
            );
        }
    };
    _renderRefMax = (text, record) => {
        /*
        if (text == 0) {
            text = "";
        }
         */


        if (this.state.details.statusId == 11 && record.status == 3) {
            return text;
        }
        if (record.number == 1) {
            let numberCharacters = Number(record.numberCharacters);
            numberCharacters = numberCharacters > 0 ? numberCharacters : 0;
            const steps =
                numberCharacters == 0
                    ? 1
                    : parseFloat(`0.${Array(numberCharacters).join(numberCharacters)}1`);

            /* this[`refmax-operator-${Number(record.key)}`] =
                 record?.ref_max_operator || "=";*/

            return (
                <>
                    <Select
                        style={{width: 50}}
                        placeholder="Seçiniz"
                        defaultValue={record?.ref_max_operator || "="}
                        onChange={(value) => {
                            (this[`refmax-operator-${Number(record.key)}`] = value)

                            let label = `refmax-operator-${Number(record.key)}`;
                            this.setState({
                                [label]: value
                            });
                        }}
                    >
                        <Option value=">">{">"}</Option>
                        <Option value="=">{"="}</Option>
                        <Option value="<">{"<"}</Option>
                    </Select>
                    <Input
                        ref={(refs) => (this[`refmax-${Number(record.key)}`] = refs)}
                        style={{width: 100}}
                        className={this.state.data[record.key].statusMessage?.refMax?.type}
                        min={steps}
                        step={steps}
                        readOnly={record.confirm == 1}
                        defaultValue={text}
                        type="number"
                        placeholder="-"
                        onChange={(e) => {
                            this._inputChangeGetMessage(
                                this[`refmin-${Number(record.key)}`].state.value,
                                e.target.value,
                                this[`result-${Number(record.key)}`].state.value,
                                record
                            )
                        }}
                    />
                    <br/>
                    <small
                        className={this.state.data[record.key].statusMessage?.refMax?.type + '-text'}>{this.state.data[record.key].statusMessage?.refMax?.text}</small>
                </>
            );
        } else {
            return "";
        }
    };
    _renderResult = (text, record) => {
        if (this.state.details.statusId == 11 && record.status == 3) {
            return text;
        }
        if (record.number == 1) {
            let numberCharacters = Number(record.numberCharacters);
            numberCharacters = numberCharacters > 0 ? numberCharacters : 0;
            const steps =
                numberCharacters == 0
                    ? 1
                    : parseFloat(`0.${Array(numberCharacters).join(numberCharacters)}1`);

            /*this[`result-operator-${Number(record.key)}`] = record?.operator || "=";*/

            return (
                <>
                    <Select
                        style={{width: 50}}
                        placeholder="Seçiniz"
                        defaultValue={record?.operator || "="}
                        onChange={(value) => {
                            (this[`result-operator-${Number(record.key)}`] = value)

                            let label = `result-operator-${Number(record.key)}`;
                            this.setState({
                                [label]: value
                            });
                        }}
                    >
                        <Option value=">">{">"}</Option>
                        <Option value="=">{"="}</Option>
                        <Option value="<">{"<"}</Option>
                    </Select>
                    <Input
                        ref={(refs) => (this[`result-${Number(record.key)}`] = refs)}
                        style={{
                            width: 100,
                        }}
                        className={this.state.data[record.key].statusMessage?.result?.type}
                        min={steps}
                        step={steps}
                        status="warning"
                        readOnly={record.confirm == 1}
                        defaultValue={text}
                        type="number"
                        placeholder="-"
                        onChange={(e) => {
                            let label = `result-${Number(record.key)}`;
                            this.setState({
                                [label]: e.target.value
                            });

                            this._inputChangeGetMessage(
                                this[`refmin-${Number(record.key)}`].state.value,
                                this[`refmax-${Number(record.key)}`].state.value,
                                e.target.value,
                                record
                            )
                        }}
                    />
                    <br/>
                    <small
                        className={this.state.data[record.key].statusMessage?.result?.type + '-text'}>{this.state.data[record.key].statusMessage?.result?.text}</small>
                </>
            );
        } else {
            // this[`result-${Number(record.key)}`] = text;
            const newArray = [{name: "-"}, ...record?.options];
            return (
                <>
                    <Select
                        style={{width: 150}}
                        placeholder="Seçiniz"
                        optionFilterProp="children"
                        onChange={(value) => {
                            /*
                            let label = `result-${Number(record.key)}`;

                            this.setState({
                                [label]: value
                            });
                             */

                            this[`result-${Number(record.key)}`] = value;
                            this.state.data[record.key].result = value
                            this.forceUpdate();

                            this._inputChangeGetMessageText(
                                this[`refmin-${Number(record.key)}`],
                                value,
                                record
                            )

                        }}
                        defaultValue={text}
                        disabled={record.confirm == 1}
                    >
                        {newArray.map((item, index) => {
                            return <Option value={item.name}>{item.name}</Option>;
                        })}
                    </Select>
                    <br/>
                    <small
                        className={this.state.data[record.key].statusMessage?.result?.type + '-text'}>{this.state.data[record.key].statusMessage?.result?.text}</small>
                </>

            );
        }
    };
    // _renderUsers = (text, record) => {
    //   return (
    //     <Select
    //       showSearch
    //       style={{ width: 150 }}
    //       placeholder="Kullanıcı Seçiniz"
    //       optionFilterProp="children"
    //       filterOption={(input, option) =>
    //         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //       }
    //       defaultValue={text}
    //       onChange={(value) => (this[`users-${Number(record.key)}`] = value)}
    //     >
    //       {this.state?.users?.map((item, index) => {
    //         return (
    //           <Option
    //             value={item.userId}
    //           >{`${item.name}  ${item.lastname}`}</Option>
    //         );
    //       })}
    //     </Select>
    //   );
    // };
    _resultSaved = ({
                        key,
                        number,
                        test_id,
                        pack_id,
                        testUserId,
                        ureal,
                        numberCharacters,
                        sampleTestId,
                    }) => {
        let refMin =
                number == 1 ? this[`refmin-${key}`].state.value : this[`refmin-${key}`],
            refMax = number == 1 ? this[`refmax-${key}`].state.value : "-",
            result =
                number == 1 ? this[`result-${key}`].state.value : this[`result-${key}`],
            test_userId = this[`users-${key}`] ? this[`users-${key}`] : testUserId,
            operator = number == 1 ? this[`result-operator-${key}`] : "=",
            ref_min_operator = this[`refmin-operator-${key}`] || "=",
            ref_max_operator = this[`refmax-operator-${key}`] || "=";

        refMin = refMin == "" ? "-" : refMin;
        refMax = refMax == "" ? "-" : refMax;
        result = result ? result : "-";
        if ((refMin || refMax) && result && test_userId) {
            if (number == 1) {
                if (refMin && refMax) {
                    if (refMin != "-" && refMax != "-") {
                        if (parseFloat(refMin) > parseFloat(refMax)) {
                            Helper.warningMessage(
                                `Referans Minimum değeri Referans Maximum değerine eşit veya yüksek olamaz.`
                            );
                        }
                        if (parseFloat(refMax) < parseFloat(refMin)) {
                            Helper.warningMessage(
                                `Referans Maximum değeri Referans Minimum değerine eşit veya  düşük olamaz.`
                            );
                        }
                        if (
                            parseFloat(result) < parseFloat(refMin) ||
                            parseFloat(result) > parseFloat(refMax)
                        ) {
                            Helper.warningMessage(
                                `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı. `
                            );
                        }
                    } else {
                        if (refMin == "-" && refMax != "-") {
                            if (refMax && parseFloat(result) >= parseFloat(refMax)) {
                                Helper.warningMessage(
                                    `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı.`
                                );
                            }
                        }
                        if (refMin != "-" && refMax == "-") {
                            if (refMin && parseFloat(result) <= parseFloat(refMin)) {
                                Helper.warningMessage(
                                    `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı.`
                                );
                            }
                        }
                    }
                } else {
                    if (refMin && parseFloat(result) < parseFloat(refMin)) {
                        Helper.warningMessage(
                            `Sonuç değeri  ${refMin} değerine eşit veya küçük olamaz.`
                        );
                    }
                    if (refMax && parseFloat(result) > parseFloat(refMax)) {
                        Helper.warningMessage(
                            `Sonuç değeri  ${refMax}  değerine eşit veya büyük olamaz.`
                        );
                    }
                }
            } else {
                if (refMin != result) {
                    Helper.warningMessage(
                        `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı.`
                    );
                }
            }
            const numbers = Number(numberCharacters);

            const floatRefMin =
                number == 1 ? parseFloat(refMin).toFixed(numbers || 0) : refMin;
            const floatRefMax =
                number == 1 ? parseFloat(refMax).toFixed(numbers || 0) : refMax;
            let urealResult, floatResult;
            if (result != "-") {
                floatResult =
                    number == 1 ? parseFloat(result).toFixed(numbers || 0) : result;
                urealResult =
                    number == 1
                        ? (parseFloat(ureal) * Number(result)).toFixed(numbers || 0)
                        : "-";
            } else {
                Helper.warningMessage("Sonuç değeri girilmeden kayıt edildi.");
                urealResult = "-";
                floatResult = "-";
            }

            this.resultSaved({
                refMin: floatRefMin,
                refMax: floatRefMax,
                result: floatResult,
                testId: test_id,
                packId: pack_id,
                testUserId: test_userId,
                urealResult,
                operator,
                ref_min_operator,
                ref_max_operator,
                sampleTestId,
            });
        } else {
            if (!test_userId) {
                Helper.warningMessage("Lütfen kullanıcı seçimini yapınız.");
                return;
            }
            if (!result) {
                Helper.warningMessage(
                    "Lütfen sonuç değerini giriniz. Örnek değer: (1000,12)"
                );
            }
            if (!refMin || !refMax) {
                Helper.warningMessage(
                    "Lütfen refMin veya refMax değerlerinden en az bir tanesini  giriniz. Örnek değer: (1000,12)"
                );
            }
        }
    };


    _inputChangeGetMessageText(refmin, result, record) {

        let items = this.state.data,
            statusMessage = {};

        let refMin = items[Number(record.key)].ref_min;
        let resultData = items[Number(record.key)].result;

        if (refMin != '-' && resultData != '-') {
            if ((refMin && resultData) && (refMin != resultData)) {
                statusMessage.result = {
                    text: "Analiz sonucu limit dışı!",
                    type: "warning"
                }
            }
        }


        items[Number(record.key)] = {
            ...items[Number(record.key)],
            statusMessage
        };

        this.setState({
            data: items
        })


    }


    _inputChangeGetMessage(refMin, refMax, result, record) {
        let items = this.state.data,
            statusMessage = {};

        if (refMin !== "-" && refMax !== "-") {
            if (parseFloat(refMin) >= parseFloat(refMax)) {
                statusMessage.refMin = {
                    text: "Minimum değer Maximum değere eşit veya yüksek olamaz.",
                    type: "warning"
                }
            }
            if (parseFloat(refMax) <= parseFloat(refMin)) {
                statusMessage.refMax = {
                    text: "Maximum değer Minimum değere eşit veya  düşük olamaz.",
                    type: "warning"
                }
            }
            if (
                parseFloat(result) < parseFloat(refMin) ||
                parseFloat(result) > parseFloat(refMax)
            ) {
                statusMessage.result = {
                    text: "Analiz sonucu limit dışı!",
                    type: "warning"
                }
            }
        } else {
            if (refMin === "-" && refMax !== "-") {
                if (refMax && parseFloat(result) > parseFloat(refMax)) {
                    statusMessage.result = {
                        text: "Analiz sonucu limit dışı!",
                        type: "warning"
                    }
                }
            }
            if (refMin !== "-" && refMax === "-") {
                if (refMin && parseFloat(result) < parseFloat(refMin)) {
                    statusMessage.result = {
                        text: "Analiz sonucu limit dışı!",
                        type: "warning"
                    }
                }
            }
        }

        items[Number(record.key)] = {
            ...items[Number(record.key)],
            statusMessage
        };
        this.setState({
            data: items
        })
    }

    _saveSingleResult = ({
                             showAlerts,
                             sample,
                             refMinValue,
                             refMinOperatorValue,
                             refMaxValue,
                             refMaxOperatorValue,
                             resultValue,
                             resultOperatorValue,
                             testUserValue
                         }, callback) => {
        let refMin = refMinValue,
            refMax = refMaxValue,
            result = resultValue,
            numberCharacters = "N/A",
            test_userId = testUserValue ? testUserValue : sample.testUserId,
            operator = sample.number === 1 ? resultOperatorValue : "=",
            ref_min_operator = refMinOperatorValue || "=",
            ref_max_operator = refMaxOperatorValue || "=";

        console.log('sample',sample);
        // Get Number Characters
        if (sample.number === 1) {
            /*
            const resultOB = (parseFloat(sample.ureal) * Number(sample.result)).toFixed(sample.numberCharacters);
            if (isNaN(resultOB)) {
                numberCharacters = "N/A";
            } else {
                //numberCharacters = resultOB;
                numberCharacters = sample.numberCharacters;
            }
             */
            numberCharacters = sample.numberCharacters;
        } else {
            numberCharacters = "N/A";
        }
        refMin = refMin == "" ? "-" : refMin;
        refMax = refMax == "" ? "-" : refMax;
        result = result ? result : "-";

        if ((refMin || refMax) && result && test_userId) {
            if (sample.number === 1) {
                if (refMin && refMax) {
                    if (refMin !== "-" && refMax !== "-") {
                        if (parseFloat(refMin) >= parseFloat(refMax)) {
                            if (showAlerts) {
                                Helper.warningMessage(
                                    `Referans Minimum değeri Referans Maximum değerine eşit veya yüksek olamaz.`
                                );
                            }
                            callback({
                                status: false,
                                message: "Referans Minimum değeri Referans Maximum değerine eşit veya yüksek olamaz. İlgili Kayıt: " + sample.name
                            })
                        }
                        if (parseFloat(refMax) <= parseFloat(refMin)) {
                            if (showAlerts) {
                                Helper.warningMessage(
                                    `Referans Maximum değeri Referans Minimum değerine eşit veya  düşük olamaz.`
                                );
                            }
                            callback({
                                status: false,
                                message: "Referans Maximum değeri Referans Minimum değerine eşit veya  düşük olamaz. İlgili Kayıt: " + sample.name
                            })
                        }
                        if (
                            parseFloat(result) <= parseFloat(refMin) ||
                            parseFloat(result) >= parseFloat(refMax)
                        ) {
                            if (showAlerts) {
                                Helper.warningMessage(
                                    `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı. `
                                );
                            }

                            callback({
                                status: true,
                                message: "Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı. İlgili Kayıt: " + sample.name
                            })
                        }
                    } else {
                        if (refMin === "-" && refMax !== "-") {
                            if (refMax && parseFloat(result) >= parseFloat(refMax)) {
                                if (showAlerts) {
                                    Helper.warningMessage(
                                        `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı.`
                                    );
                                }
                                callback({
                                    status: true,
                                    message: "Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı. İlgili Kayıt: " + sample.name
                                })
                            }
                        }

                        if (refMin !== "-" && refMax === "-") {
                            if (refMin && parseFloat(result) <= parseFloat(refMin)) {
                                if (showAlerts) {
                                    Helper.warningMessage(
                                        `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı.`
                                    );
                                }
                                callback({
                                    status: true,
                                    message: "Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı. İlgili Kayıt: " + sample.name
                                })
                            }
                        }
                    }
                } else {
                    if (refMin && parseFloat(result) <= parseFloat(refMin)) {
                        if (showAlerts) {
                            Helper.warningMessage(
                                `Sonuç değeri  ${refMin} değerine eşit veya küçük olamaz.`
                            );
                        }
                        callback({
                            status: false,
                            message: "Sonuç değeri " + refMin + " değerine eşit veya küçük olamaz. İlgili Kayıt: " + sample.name
                        })
                    }
                    if (refMax && parseFloat(result) >= parseFloat(refMax)) {
                        if (showAlerts) {
                            Helper.warningMessage(
                                `Sonuç değeri  ${refMax}  değerine eşit veya büyük olamaz.`
                            );
                        }

                        callback({
                            status: false,
                            message: "Sonuç değeri " + refMax + " değerine eşit veya küçük olamaz. İlgili Kayıt: " + sample.name
                        })
                    }
                }
            } else {
                if (refMin !== result) {
                    if (showAlerts) {
                        Helper.warningMessage(
                            `Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı.`
                        );
                    }
                    callback({
                        status: true,
                        message: "Analiz sonucu limit dışı olacak şekilde kayıt işlemi tamamlandı. İlgili Kayıt: " + sample.name
                    })
                }
            }
            console.log('numberCharacters',numberCharacters);


            const numbers = Number(numberCharacters);

            const floatRefMin =
                sample.number === 1 ? parseFloat(refMin).toFixed(numbers || 0) : refMin;
            const floatRefMax =
                sample.number === 1 ? parseFloat(refMax).toFixed(numbers || 0) : refMax;
            let urealResult, floatResult;
            if (result !== "-") {
                floatResult =
                    sample.number === 1 ? parseFloat(result).toFixed(numbers || 0) : result;
                //(parseFloat(ureal) * Number(result)).toFixed(numbers || 0)
                urealResult =
                    sample.number === 1
                        ? (parseFloat(sample.ureal * Number(result)).toFixed(numbers || 0))
                        : "-";
            } else {
                if (showAlerts) {
                    Helper.warningMessage("Sonuç değeri girilmeden kayıt edildi.");
                }

                callback({
                    status: true,
                    message: "Sonuç değeri girilmeden kayıt edildi. İlgili Kayıt: " + sample.name
                })

                urealResult = "-";
                floatResult = "-";
            }

            this.resultSaved({
                refMin: floatRefMin,
                refMax: floatRefMax,
                result: floatResult,
                testId: sample.test_id,
                packId: sample.pack_id,
                testUserId: test_userId,
                urealResult,
                operator,
                ref_min_operator,
                ref_max_operator,
                sampleTestId: sample.sampleTestId,
            }, (response) => {
                callback({
                    status: true,
                    increase: true
                })
            });
        } else {
            if (!test_userId) {
                if (showAlerts) {
                    Helper.warningMessage("Lütfen kullanıcı seçimini yapınız.");
                }

                callback({
                    status: false,
                    message: "Lütfen kullanıcı seçimini yapınız. İlgili Kayıt: " + sample.name
                })

                return;
            }
            if (!result) {
                if (showAlerts) {
                    Helper.warningMessage(
                        "Lütfen sonuç değerini giriniz. Örnek değer: (1000,12)"
                    );
                }

                callback({
                    status: false,
                    message: "Lütfen sonuç değerini giriniz. Örnek değer: (1000,12). İlgili Kayıt: " + sample.name
                })
            }
            if (!refMin || !refMax) {
                if (showAlerts) {
                    Helper.warningMessage(
                        "Lütfen refMin veya refMax değerlerinden en az bir tanesini  giriniz. Örnek değer: (1000,12)"
                    );
                }

                callback({
                    status: false,
                    message: "Lütfen refMin veya refMax değerlerinden en az bir tanesini  giriniz. Örnek değer: (1000,12). İlgili Kayıt: " + sample.name
                })
            }

        }
    };

    _renderUsers = (text, record) => {
        return (
            <Select
                showSearch
                style={{width: 150}}
                placeholder="Kullanıcı Seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={text}
                onChange={(value) => (this[`users-${Number(record.key)}`] = value)}
                disabled={this.state.details.statusId == 11 && record.status == 3}
            >
                {this.state?.users?.map((item, index) => {
                    return (
                        <Option value={item.userId}
                        >{`${item.name}  ${item.lastname}`}</Option>
                    );
                })}
            </Select>
        );
    };

    savedData = (params) => {
        let data = [...this.state.data];
        const result = _.findIndex(data, {sampleTestId: params.sampleTestId});
        data[result] = {
            ...data[result],
            operator: params.operator,
            ref_max: params.refMax,
            ref_min: params.refMin,
            ref_max_operator: params.ref_max_operator,
            ref_min_operator: params.ref_min_operator,
            result: params.result,
            testUserId: params.testUserId,
            urealResult: params.urealResult,
        };
        return data;
    };

    savedProgressBar = (resultData) => {
        let complated = 0,
            count = resultData.length;

        for (const key in resultData) {
            if (resultData.hasOwnProperty(key)) {
                const element = resultData[key];


                if (element.result != null) {
                    complated += 1;
                }
            } else {
                // console.log("resultdatanoobject", resultData[key]);
            }
        }
        let progressBar = Math.round((100 / count) * complated);

        return progressBar;
    }
    resultSaved = async (params, callback = false) => {
        this.setState({loading: true});
        try {
            const requestParams = {
                sampleId: this.state.sampleId,
                ...params,
            };
            requestParams.refMin = requestParams.refMin == 'NaN' ? '-' : requestParams.refMin;
            requestParams.refMax = requestParams.refMax == 'NaN' ? '-' : requestParams.refMax;
            requestParams.result = requestParams.result == 'NaN' ? '-' : requestParams.result;
            //requestParams.ureal_result = requestParams.ureal_result == 'NaN' ? '-' : requestParams.ureal_result;

            let data = [...this.state.data];
            let progressBar = 0;
            const response = await DataService.Sample.setTests(requestParams);

            if (!response.status) {
                Helper.ErrorMessage(response.message);
            } else {
                data = this.savedData(params);
                progressBar = this.savedProgressBar(data);

            }
            this.setState({loading: false, data, progressBar});

            if (callback) {
                callback({
                    status: true,
                    message: "Tekil kayıt tamamlandı."
                });
            }

        } catch (error) {
            Helper.ErrorMessage("Sistemsel bir hata meydana geldi");
            this.setState({loading: false});
            this.props.history.go("/sample/sample-test");
        }
    };
    _startTest = () => {
        this.setState({btnLoading: true});
        const {sampleId} = this.state;
        DataService.Sample.changeStatusTest(sampleId, "start")
            .then((result) => {
                this.setState({btnLoading: false});
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    this.getData();
                } else {
                    Helper.ErrorMessage(result.message);
                }
            })
            .catch((error) => {
                this.setState({btnLoading: false});
                Helper.ErrorMessage(error.toString());
            });
    };
    _finishTest = () => {
        this.setState({btnLoading: true});
        const {sampleId} = this.state;
        DataService.Sample.changeStatusTest(sampleId, "finish")
            .then((result) => {
                this.setState({btnLoading: false});
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    this.props.history.push("/sample/sample-test");
                } else {
                    Helper.ErrorMessage(result.message);
                    this.props.history.push("/sample/sample-test");
                }
            })
            .catch((error) => {
                this.setState({btnLoading: false});
                Helper.ErrorMessage(error.toString());
                this.props.history.push("/sample/sample-test");
            });
    };
    renderFooter = () => {
        if (this?.state?.details?.commentRevisionReq)
            return `Revize Açıklama: ${
                this?.state?.details?.commentRevisionReq || ""
            }`;
    };
    onFormFinish = (props) => {
        let requestParams = [];
        Object.entries(props).forEach(([key, value]) => {
            requestParams.push({id: Number(key), group_content: value});
        });
        DataService.Sample.tankbytankUpdate(requestParams)
            .then((result) => {
                if (result.status) {
                    this.setState({
                        tankbytankVisible: false,
                        loading: true,
                    });
                    Helper.SuccessMessage("Başarılı bir şekilde güncellendi.");
                    this.getData();
                } else {
                    this.setState({
                        tankbytankVisible: false,
                    });
                    Helper.ErrorMessage(result.message);
                }
            })
            .catch((error) => {
                this.setState({
                    tankbytankVisible: false,
                });
                Helper.ErrorMessage("Sistemsel bir hata meydana geldi.");
            });
    };
    renderTable = () => {
        const {details, data, tableColumns, progressBar} = this.state;
        if (!this.state.loading) {
            return (
                <Table columns={tableColumns} data={data} footer={this.renderFooter}/>
            );
        } else {
            return (
                <Table
                    columns={tableColumns}
                    data={[]}
                    loading={true}
                    footer={this.renderFooter}
                />
            );
        }
    };

    render() {
        const {details, data, tableColumns, progressBar} = this.state;
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title={`Sonuç Gir : ${details.reportNo}`}/>

                    <div className="pageHeaderOptions">
                        <Space>
                            <NavLink
                                activeClassName="nav-active"
                                exact
                                to="/sample/sample-test"
                            >
                                <Button className="btn-list" shape="round">
                                    Numune Test Listesi
                                </Button>
                            </NavLink>
                        </Space>
                    </div>
                </div>
                <Spin spinning={this.state.loading}>
                    <Card bordered={false} className="cardTable">
                        <div
                            className="sample-information"
                            style={{marginBottom: "24px"}}
                        >
                            <h2>Numune Bilgileri</h2>
                            <Row gutter="16" style={{marginBottom: "16px"}}>
                                <Col span={24} md={21}>
                                    <Descriptions className="descriptionList" title="" bordered>
                                        <Descriptions.Item label="Operasyon">
                                            {details.operationName}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="İş Kolu">
                                            {details.sectorName}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Ürün">
                                            {details.productName}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Alma Metodu">
                                            {details.getMethod}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Kabul Tarih">
                                            {`${details.getAcceptanceDate} ${details.getAcceptanceHour}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Alındığı Yer">
                                            {details.getPart}
                                        </Descriptions.Item>
                                        {this.state.selectedWorkBranch.name !== 'LUBOIL (OCM)' &&
                                            <Descriptions.Item
                                                label={(this.state.selectedWorkBranch.hasOwnProperty('name') && this.state.selectedWorkBranch.name) === 'LUBOIL (OCM)' ? "Parça" : "Terminal"}>
                                                {details.getLocation}
                                            </Descriptions.Item>
                                        }
                                        <Descriptions.Item label="Numune Alma Tarihi">
                                            {details.dateDelivery}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numuneyi Alan">
                                            {`${details.getUser} / ${details.getUserCustomer}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Miktarı">
                                            {`${details.amount}${details.amount_type}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numune Adı ve Tarifi">
                                            {`${details.productName} / ${details.productNameEn}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Mühür Bilgileri">
                                            {details.signetCode}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Numuneyi Teslim Eden">
                                            {`${details.getSenderName} / ${details.getSenderCustomer}`}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Deney Tarihi">
                                            {details.dateFinish}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={24} md={3}>
                                    <div className="qr-print-img" style={{width: "100%"}}>
                                        <QRCode size={128}
                                                value={'https://verify.intertekturkey.com/' + this.state.sampleId}/>
                                    </div>
                                </Col>
                            </Row>

                            {/* asagidaki sonuclar girildikce progress artacak */}
                            {/* <Progress percent={0} />
              <Progress percent={30} /> */}
                            <Progress percent={this.state.progressBar}/>
                        </div>
                        {/* this.state.details.statusId != 3 ? (
              <Table
                columns={tableColumns}
                data={data}
                footer={this.renderFooter}
              />
            ) */}
                        {this.state.details.statusId != 3 ? (
                            this.renderTable()
                        ) : (
                            // this.renderTable()
                            <div className="formButton" style={{marginBottom: "26px"}}>
                                <Space size={15}>
                                    <Button
                                        type="primary"
                                        onClick={() => this.setState({printer: true})}
                                    >
                                        <PrinterOutlined/> Yazdır
                                    </Button>
                                </Space>
                                <Space size={15}>
                                    <Button
                                        type="primary"
                                        onClick={this._startTest}
                                        loading={this.state.btnLoading}
                                    >
                                        <PlusOutlined/> Testi Başlat
                                    </Button>
                                </Space>
                            </div>
                        )}

                        {progressBar >= 100 ? (
                            <div className="formButton" style={{marginBottom: "26px"}}>
                                {this.state?.tankbytankList?.length > 0 && (
                                    <Space size={15}>
                                        <Button
                                            type="primary"
                                            onClick={() => this.setState({tankbytankVisible: true})}
                                        >
                                            <PrinterOutlined/> Grup Açıklaması Ekle
                                        </Button>
                                    </Space>
                                )}

                                <Space size={15}>
                                    <Button
                                        type="primary"
                                        onClick={() => this.setState({printer: true})}
                                    >
                                        <PrinterOutlined/> Yazdır
                                    </Button>

                                    <Button
                                        type="primary"
                                        onClick={this._finishTest}
                                        loading={this.state.btnLoading}
                                    >
                                        <PlusOutlined/> Testi Bitir
                                    </Button>

                                    <Button
                                        type="primary"
                                        onClick={() => this.saveAllFormData()}
                                    >
                                        <SaveOutlined/> Tüm verileri güncelle
                                    </Button>
                                </Space>
                            </div>
                        ) : this.state.details.statusId != 3 ? (
                            <div className="formButton" style={{marginBottom: "26px"}}>
                                {this.state?.tankbytankList?.length > 0 && (
                                    <Space size={15}>
                                        <Button
                                            type="primary"
                                            onClick={() => this.setState({tankbytankVisible: true})}
                                        >
                                            <PrinterOutlined/> Grup Açıklaması Ekle
                                        </Button>
                                    </Space>
                                )}
                                <Space size={15}>
                                    <Button
                                        type="primary"
                                        onClick={() => this.setState({printer: true})}
                                    >
                                        <PrinterOutlined/> Yazdır
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => this.saveAllFormData()}
                                    >
                                        <SaveOutlined/> Tüm verileri kaydet
                                    </Button>
                                </Space>
                            </div>
                        ) : null}
                    </Card>
                </Spin>
                <Modal
                    title="Toplu Veri Kaydetme"
                    centered
                    footer={null}
                    onCancel={() => {
                        this.setState({showSaveAllProgress: false})
                    }}
                    visible={this.state.showSaveAllProgress}
                >
                    <div className="centered" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <p>Veriler kaydedilirken lütfen bekleyiniz...</p>
                        <Progress size={65} type="circle" percent={this.state.saveAllProgressValue} style={{margin: 15}}
                                  status={this.state.saveAllProgressError ? 'exception' : 'normal'}/>
                        <p>{this.state.saveAllProgressMessage}</p>


                    </div>
                </Modal>
                <Modal
                    title="Önizleme"
                    visible={this.state?.printer || false}
                    onOk={() => this.setState({printer: false})}
                    onCancel={() => this.setState({printer: false})}
                    width={1200}
                    footer=""
                >
                    <PrintComponent
                        ref={(refs) => (this.printerRef = refs)}
                        details={this.state.details}
                        tableData={this.state.data}
                    />
                </Modal>
                <Modal
                    title="Grup Açıklaması  Ekle"
                    visible={this.state.tankbytankVisible}
                    onCancel={() => this.setState({tankbytankVisible: false})}
                    footer={[]}
                >
                    {this.state?.tankbytankList?.length > 0 && (
                        <Form
                            hideRequiredMark
                            onFinish={this.onFormFinish}
                            ref={this.forms}
                        >
                            {this.state.tankbytankList.map((item, index) => {
                                {
                                    return (
                                        <Form.Item
                                            name={item.id}
                                            label={item.group_name}
                                            rules={[{required: false}]}
                                            initialValue={item.group_content}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    );
                                }
                            })}
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Kaydet
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Modal>
            </div>
        );
    }
}

export default SampleTestEdit;

/*


  <List
                  header={
                    <div
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <div> Açıklamalar</div>
                      <a
                        onClick={() =>
                          this.setState({
                            tankbytankVisible: true,
                          })
                        }
                        key="list-loadmore-edit"
                      >
                        Ekle
                      </a>
                    </div>
                  }
                  bordered
                  dataSource={this.state.tankbytankList}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <a
                          onClick={() =>
                            this.setState({
                              tankbytankId: item.id,
                              tankbytankComment: item.group_content,
                              tankbytankVisible: true,
                              tankbytankEdit: true,
                            })
                          }
                          key="list-loadmore-edit"
                        >
                          Düzenle
                        </a>,
                        <a
                          onClick={() => {
                            let newArray = [...this.state.tankbytankList];
                            newArray = _.reject(newArray, {
                              id: item.id,
                            });
                            this.setState({
                              tankbytankList: newArray,
                            });
                          }}
                          key="list-loadmore-more"
                        >
                          Sil
                        </a>,
                      ]}
                    >
                      <Typography.Text mark>
                        [{item.group_name}]
                      </Typography.Text>
                      {item.group_content}
                    </List.Item>
                  )}
                />
                <Modal
                  title="Açıklama Ekle"
                  visible={this.state.tankbytankVisible}
                  onCancel={() =>
                    this.setState({
                      tankbytankVisible: false,
                      tankbytankComment: "",
                    })
                  }
                  onOk={() => {
                    let newArray = [...this.state.tankbytankList];
                    newArray = _.reject(newArray, {
                      id: this.state?.tankbytankId,
                    });

                    this.setState({
                      tankbytankVisible: false,
                      tankbytankComment: "",
                      tankbytankList: newArray,
                      tankbytankId: null,
                    });
                  }}
                  okText={"Kaydet"}
                  cancelText="Vazgeç"
                >
                  <p>Açıklama</p>
                  <Input
                    value={this.state.commentText}
                    onChange={(e) =>
                      this.setState({
                        commentText: e.target.value,
                      })
                    }
                  />
                </Modal>

*/
