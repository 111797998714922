import React, { useState, useEffect } from "react";

import { Card, Button, Space, Spin, Result } from "antd";
import { NavLink } from "react-router-dom";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import Table from "../../../Components/UI/Table";
import moment from "moment";

const Samples = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const tableColumns = [
    {
      title: "Rapor No",
      dataIndex: "report_no",
      key: "report_no",
    },
    {
      title: " Numunenin Alındığı Yer",
      dataIndex: "get_part",
      key: "get_part",
    },

    {
      title: "Durumu",
      dataIndex: "statusName",
      key: "statusName",
    },
    {
      title: "Seçenekler",
      dataIndex: "options",
      key: "options",
      render: (text, record) => (
        <Space size="middle">
          <NavLink to={`/sample/work-orders/edit/${record.report_no}`}>
            <Button shape="round" type="primary">
              İncele
            </Button>
          </NavLink>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const dates = [moment().add(-15, "days"), moment().add(15, "days")];
    DataService.WorkOrder.SelectAll(
      0,
      0,
      moment(dates[0]).unix(),
      moment(dates[1]).unix()
    )
      .then((result) => {
        console.log("result", result);
        if (result.status) {
          setData(result.response.data);
          setLoading(false);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  }, []);

  return (
    <Spin spinning={loading}>
      <Card
        bordered={false}
        className="cardTable"
        title="Son İş Emirleri"
        extra={<a href="/sample/work-orders">Tümünü Gör</a>}
      >
        {data?.length > 0 ? (
          <Table columns={tableColumns} data={data} />
        ) : (
          <Result title="Kayıt bulunamadı." />
        )}
      </Card>
    </Spin>
  );
};

export default Samples;
