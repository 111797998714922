import React, { Component } from 'react';


import Table from '../../../../Components/UI/Table';
import PageHeader from '../../../../Components/UI/PageHeader';

import { Card, Modal, Button, Tooltip, Form, Input, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

const tableColumns = [
    {
        title: 'Numune Marka Adı',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Seçenekler',
        dataIndex: 'options',
        key: 'options',
        render: (text, record) => (
          <Space size="middle">
            <Button shape="round" type="primary" warning>Düzenle</Button>
            <Button type="link" danger>Sil</Button>
          </Space>
        ),
    }
];

const tableData = [
    {
        key: '1',
        name: 'Test Lab New York',
    },
    {
        key: '2',
        name: 'Jim Green',
    },
    {
        key: '3',
        name: 'Joe Black',
    }
];

class index extends Component {


    state = { visible: false };
    showModal = () => {
        this.setState({
        visible: true,
        });
    };
    closeModal = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div>
                

                <div className="pageHeaderWrapper">
                    
                    <PageHeader title="Numune Marka" subTitle="" />

                    <Tooltip title="Yeni Marka Ekle" placement="left">
                        <Button shape="circle" icon={<PlusOutlined />} onClick={this.showModal} />
                    </Tooltip>
                    
                    <Modal
                        title="Yeni Marka Ekle"
                        visible={this.state.visible}
                        onCancel={this.closeModal}
                        footer=""
                        >
                            
                        <Form layout="vertical" hideRequiredMark>
                            <Form.Item
                                name="name"
                                label="Marka Adı"
                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                >
                                <Input  />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType="submit"
                                >
                                <PlusOutlined /> Ekle
                            </Button>
                        </Form>
                    </Modal>
                </div>

                <Card bordered={false} className="cardTable">
                    <Table columns={tableColumns} data={tableData} />
                </Card>


            </div>
        );
    }
}

export default index;