import React from "react";

import { Card, Row, Col } from "antd";
import { Gozetim } from "../Components";

const permLabGozetim = () => {
  return (
    <div>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={24}>
          <Gozetim />
        </Col>
      </Row>
    </div>
  );
};

export default permLabGozetim;
