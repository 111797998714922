import React from "react";
import { Table, InputNumber, Checkbox, Select, Tag } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";

import _ from "lodash";
const { Option } = Select;

class SortableTable extends React.Component {
  state = {
    dataSource: [],
    columns: [
      {
        title: "İş Kolu",
        dataIndex: "sector_name",
        name: "sector_name",
        render: (text) => text || "Test Paketi",
      },
      {
        title: "Name TR",
        dataIndex: "name",
        name: "name",
        render: (text) => text || "N/A",
      },
      {
        title: "Name EN",
        dataIndex: "name_en",
        name: "name_en",
        render: (text) => text || "N/A",
      },
      {
        title: "Method Adı TR",
        dataIndex: "method_name",
        name: "method_name",
        render: (text) => text || "N/A",
      },
      {
        title: "Method Adı EN",
        dataIndex: "method_name_en",
        name: "method_name_en",
        render: (text) => text || "N/A",
      },
      {
        title: "Method Adı EN",
        dataIndex: "method_name_en",
        name: "method_name_en",
        render: (text) => text || "N/A",
      },
      {
        title: "Birimi",
        dataIndex: "unit_name",
        name: "unit_name",
        render: (text) => text || "N/A",
      },
      {
        title: "Hassasiyet",
        dataIndex: "number_characters",
        name: "number_characters",
        render: (text) => text || "N/A",
      },
      {
        title: "Paket İçeriği",
        dataIndex: "tests",
        render: (tags) => {
          if (typeof tags == "object") {
            const newData = [];
            for (const key in tags) {
              if (tags.hasOwnProperty.call(tags, key)) {
                const element = tags[key];
                newData.push(element);
              }
            }
            return newData.map((item, index) => {
              return (
                <Tag color="geekblue" key={item.test_id}>
                  {`${item.name.toUpperCase()}/${item.name_en.toUpperCase()}`}
                </Tag>
              );
            });
          } else {
            return "N/A";
          }
        },
      },
    ],
  };

  componentDidMount() {

    console.log('testlist',this.props.data);
    this.setData(this.props.data);
  }
  componentDidUpdate(prev, next) {
    if (prev.data !== this.props.data) {
      console.log('testlist',this.props.data);

      this.setData(this.props.data);
    }
  }
  setData = (dataSource) => {
    this.setState({ dataSource });
  };

  render() {
    const { dataSource, columns } = this.state;

    return (
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
      />
    );
  }
}

export default SortableTable;

/*

Custom CSS
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

*/
