// Servis Çağrıları
import {ApiService} from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";

class Customer {
    constructor() {
    }

    /**
     * Müşteri Ekleme
     * @param {string} customerTopId - Bağlı olduğu müşteri id 0 ise ana müşteri
     * @param {string} customerName - Şirket Adı
     * @param {string} custpmerTitle - Şirket Adı
     * @param {string} customerAdress - Şirket Adresi
     * @param {string} customerTaxNumber - Vergi Numarası
     * @param {string} customerTaxOffice - Vergi Dairesi
     * @param {string} customerIban - Banka Iban Numarası
     * @param {string} customerPhone - Sabit Telefon
     * @param {string} customerGsmPhone - Cep Telefonu
     * @param {string} customerCityId - İl Bilgisi
     * @param {string} customerDistrictId - İlçe Bilgisi
     */
    Create = ({
                  customerTopId,
                  customerName,
                  custpmerTitle,
                  customerAdress,
                  customerTaxNumber,
                  customerTaxOffice,
                  customerIban,
                  customerPhone,
                  customerGsmPhone,
                  customerCityId,
                  customerDistrictId,
                  email
              }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    top_customer_id: customerTopId,
                    name: customerName,
                    title: custpmerTitle,
                    address: customerAdress,
                    tax_no: customerTaxNumber,
                    tax_administration: customerTaxOffice,
                    iban: customerIban,
                    phone: customerPhone,
                    phone_gsm: customerGsmPhone,
                    city_id: customerCityId,
                    district_id: customerDistrictId,
                    emails: email
                };

                const response = await ApiService(
                    "post",
                    Endpoint.Customer.create,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Müşteri Düzenleme
     * @param {string} customerId - Şirket Id
     * @param {string} customerTopId - Bağlı olduğu müşteri id 0 ise ana müşteri
     * @param {string} customerName - Şirket Adı
     * @param {string} custpmerTitle - Şirket Adı
     * @param {string} customerAdress - Şirket Adresi
     * @param {string} customerTaxNumber - Vergi Numarası
     * @param {string} customerTaxOffice - Vergi Dairesi
     * @param {string} customerIban - Banka Iban Numarası
     * @param {string} customerPhone - Sabit Telefon
     * @param {string} customerGsmPhone - Cep Telefonu
     * @param {string} customerCityId - İl Bilgisi
     * @param {string} customerDistrictId - İlçe Bilgisi

     */
    Update = ({
                  customerId,
                  customerTopId,
                  customerName,
                  custpmerTitle,
                  customerAdress,
                  customerTaxNumber,
                  customerTaxOffice,
                  customerIban,
                  customerPhone,
                  customerGsmPhone,
                  customerCityId,
                  customerDistrictId,
                  email
              }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    customer_id: customerId,
                    top_customer_id: customerTopId,
                    name: customerName,
                    title: custpmerTitle,
                    address: customerAdress,
                    tax_no: customerTaxNumber,
                    tax_administration: customerTaxOffice,
                    iban: customerIban,
                    phone: customerPhone,
                    phone_gsm: customerGsmPhone,
                    city_id: customerCityId,
                    district_id: customerDistrictId,
                    emails: email
                };

                const response = await ApiService(
                    "put",
                    Endpoint.Customer.update,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Müşteri Mail Listesi
     * @param {string} customerId  - Şirket Id
     */
    mailList = (customerId) => {
        return new Promise(async (resolve, reject) => {
            try {

                const endpoints = `${Endpoint.Customer.mail}/${customerId}`;
                const response = await ApiService(
                    "get",
                    endpoints,
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Müşteri Silme İşlemi
     * @param {string} customerId - Şirket ID
     */
    Delete = (customerId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    customer_id: customerId,
                };
                const response = await ApiService(
                    "delete",
                    Endpoint.Customer.delete,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Müşterileri Listeleme
     * @param {number} limit
     * @param {number} page
     * @param {Date} startDate
     * @param {Date} endDate
     */
    SelectAll = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Customer.getAll}/${limit}/${page}/${startDate}/${endDate}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        response: {
                            details: response.reply.pagination,
                            data: response.reply.posts,
                        },
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
            }
        });
    };

    /**
     * Müşteri Detayı Alma
     * @param {string} customerId  - Şirket Id
     */
    Select = (customerId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    customer_id: customerId,
                };
                const response = await ApiService(
                    "get",
                    Endpoint.Customer.getOne,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Müşteri Aktif/Pasif Durumu Değiştirme
     * @param {string} customerId  - Şirket Id
     */
    StatusChange = (customerId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    customer_id: customerId,
                };

                const response = await ApiService(
                    "post",
                    Endpoint.Customer.status,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
}

export default new Customer();
