import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  Spin,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";

const { Option } = Select;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      permData: [],
      customer: [],
      labs: [],
      region: [],

      userId: null,
      loading: true,
      btnloading: false,
      searchText: "",
      searchedColumn: "",
      tableColumns: [
        {
          title: "Ad",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Ad"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Soyad",
          dataIndex: "lastname",
          key: "lastname",
          ...Helper.getColumnSearchProps(
            "lastname",
            this.handleSearch,
            this.handleReset,
            "Soyad"
          ),
          sorter: (a, b) => a.lastname.localeCompare(b.lastname), // string sıralama
        },
        {
          title: "E-Posta",
          dataIndex: "mail",
          key: "mail",
          ...Helper.getColumnSearchProps(
            "mail",
            this.handleSearch,
            this.handleReset,
            "E-Posta"
          ),
          sorter: (a, b) => a.mail.localeCompare(b.mail), // string sıralama
        },
        {
          title: "Telefon",
          dataIndex: "phone",
          key: "phone",
          ...Helper.getColumnSearchProps(
            "phone",
            this.handleSearch,
            this.handleReset,
            "Telefon"
          ),
          sorter: (a, b) => {
            // string sıralama
            if (a.phone) {
              return a.phone.localeCompare(b.phone);
            } else {
              const text = "N/A";
              return text.localeCompare(b.phone);
            }
          },

          render: (text) => {
            return text ? text : "N/A";
          },
        },
        {
          title: "Yetki",
          key: "permName",
          dataIndex: "permName",
          sorter: (a, b) => {
            // string sıralama
            if (a.permName) {
              return a.permName.localeCompare(b.permName);
            } else {
              const text = "N/A";
              return text.localeCompare(b.permName);
            }
          },
          render: (text) => {
            return text ? text : "N/A";
          },
        },

        {
          title: "Laboratuvar",
          key: "labName",
          dataIndex: "labName",
          sorter: (a, b) => {
            // string sıralama
            if (a.labName) {
              return a.labName.localeCompare(b.labName);
            } else {
              const text = "N/A";
              return text.localeCompare(b.labName);
            }
          },

          render: (text) => {
            return text ? text : "N/A";
          },
        },

        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
      labShow: false,
      customerShow: false,
      regionShow: false,
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseUsers = await DataService.User.SelectAll();
      const responsePerms = await DataService.User.permSelectAll();
      const responseCustomer = await DataService.Customer.SelectAll();
      const responseLabs = await DataService.Laboratory.SelectAll();
      const responseRegion = await DataService.Region.SelectAll();
      console.log("getDAta", {
        responseUsers,
        responsePerms,
      });
      if (
        responseUsers.status &&
        responsePerms.status &&
        responseCustomer.status &&
        responseLabs.status &&
        responseRegion.status
      ) {
        this.setState({
          data: responseUsers.data,
          permData: responsePerms.response.data,
          customer: responseCustomer.response.data,
          labs: responseLabs.response.data,
          region: responseRegion.response.data,
          loading: false,
        });
      } else {
        if (!responseUsers.status) Helper.ErrorMessage(responseUsers.message);
        if (!responsePerms.status) Helper.ErrorMessage(responsePerms.message);
        if (!responseCustomer.status)
          Helper.ErrorMessage(responseCustomer.message);
        if (!responseLabs.status) Helper.ErrorMessage(responseLabs.message);
        if (!responseRegion.status) Helper.ErrorMessage(responseRegion.message);
        this.setState({ loading: false });
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  _editClick = async ({ user_id }) => {
    try {
      console.log("userId", user_id);
      const responseUser = await DataService.User.Select(user_id);

      if (responseUser.status) {
        const {
          user_id,
          perm_id,
          permName,
          region_id,
          regionName,
          customer_id,
          lab_id,
          labName,
          mail,
          pass,
          name,
          lastname,
          phone,
          lang,
          customerId,
        } = responseUser.data;
        const shows = this.permChanged(perm_id);
        this.setState({ visible: true, userId: user_id, ...shows }, () => {
          setTimeout(() => {
            this.formRef.current.setFieldsValue({
              perm_id: perm_id,
              region_id: region_id,
              lab_id: lab_id,
              customer_id: customerId,
              mail: mail,
              pass: "",
              name: name,
              lastname: lastname,
              phone: phone,
              lang: lang,
            });
          }, 500);
        });
      } else {
        Helper.ErrorMessage(responseUser.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  _deleteClick = ({ user_id, name, lastname }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name}  ${lastname} Kullanıcısını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      this.deleted(user_id);
    });
  };
  deleted = (user_id) => {
    this.setState({ loading: true });
    DataService.User.Delete(user_id)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      userId: null,
      btnloading: false,
      regionShow:false,
      customerShow:false,
      labShow:false
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({
    perm_id,
    region_id,
    customer_id,
    lab_id,
    mail,
    pass,
    name,
    lastname,
    phone,
    lang,
  }) => {
    try {
      let result;
      this.setState({ btnloading: true });
      const customer =
        this.state.regionShow == true
          ? "9457fc28ceb408103e13533e4a5b6bd1"
          : customer_id;
      if (this.state.userId) {
        result = await DataService.User.Update({
          user_id: this.state.userId,
          perm_id,
          region_id,
          customer_id: customer,
          lab_id,
          mail,

          name,
          lastname,
          phone,
          lang,
        });
      } else {
        result = await DataService.User.Register({
          perm_id,
          region_id,
          customer_id: customer,
          lab_id,
          mail,
          pass,
          name,
          lastname,
          phone,
          lang,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this._closeModal();
        this.setState({ loading: false, btnloading: false }, this.getData);
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  permChanged = (permId) => {
    let labShow = false,
      regionShow = false,
      customerShow = false;
    if (
      permId == "730c2610e7ff1b5088d592378384a777" ||
      permId == "f54a19e2d81c5bd09024a31cffe295b0"
    ) {
      regionShow = true;
    } else if (
      permId == "eda47dd3df7856681473ba3d7fdf730c" ||
      permId == "25b2ecc9e2111a773615b2184fc792e3"
    ) {
      customerShow = true;
    } else if (
      permId == "6bd9ecfeb76640f23685c713a99d69dc" ||
      permId == "c017d489f0fc91ab32a4444d5964f197" ||
      permId == "70850ecbb0174f3b03e1ec9f5e30e2f0"
    ) {
      labShow = true;
    }
    return { labShow, regionShow, customerShow };
  };
  permChange = (permId) => {
    const result = this.permChanged(permId);
    this.setState({ ...result });
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Kullanıcılar" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Users"}
                dates={["tarih"]}
              />

              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Yeni Kullanıcı Ekle
              </Button>
            </Space>
          </div>

          <Modal
            title={
              this.state.userId ? "Kullanıcı Düzenle" : "Yeni Kullanıcı Ekle"
            }
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Row gutter="16">
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="Ad"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastname"
                    label="Soyad"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter="16">
                <Col span={12}>
                  <Form.Item
                    name="mail"
                    label="E-Posta"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    label="Telefon"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              {!this.state.userId && (
                <Form.Item
                  name="pass"
                  label="Şifre"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}

              <Row gutter="16">
                <Col span={12}>
                  <Form.Item
                    name="perm_id"
                    label="Yetki"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(permId) => this.permChange(permId)}
                    >
                      {this.state.permData.map((item, index) => {
                        return (
                          <Option key={index} value={item.perm_id}>
                            {item.permTypeName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {this.state.customerShow && (
                    <Form.Item
                      name="customer_id"
                      label="Firma"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Seçiniz"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.customer.map((item, index) => {
                          return (
                            <Option key={index} value={item.customer_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                  {this.state.regionShow && (
                    <Form.Item
                      name="region_id"
                      label="Bölge"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Seçiniz"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.region.map((item, index) => {
                          return (
                            <Option key={index} value={item.region_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                  {this.state.labShow && (
                    <Form.Item
                      name="lab_id"
                      label="Laboratuvar"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Seçiniz"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.labs.map((item, index) => {
                          return (
                            <Option key={index} value={item.laboratory_id}>
                              {item.LabName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <Form.Item
                name="lang"
                label="Dil"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="tr">Türkçe</Option>
                  <Option value="en">İngilizce</Option>
                </Select>
              </Form.Item>

              <div className="formButton">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.btnloading}
                >
                  {this.state.userId ? (
                    <>
                      {" "}
                      <EditOutlined /> Kaydet{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <PlusOutlined /> Ekle{" "}
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
