import React, { Component } from "react";

import Table from "../../../../Components/UI/Table";
import PageHeader from "../../../../Components/UI/PageHeader";

import { Card, Modal, Button, Tooltip, Form, Select, Input, Space } from "antd";

import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

const tableColumns = [
  {
    title: "Test Kategori Adı",
    dataIndex: "name",
    key: "name"
  },
  {
    
          title: "Seçenekler",
          fixed: 'right',
    dataIndex: "options",
    key: "options",
    render: (text, record) => (
      <Space size="middle">
        <Button shape="round" type="primary" warning>
          Düzenle
        </Button>
        <Button type="link" icon={<DeleteOutlined />} danger>
        </Button>
      </Space>
    )
  }
];

const tableData = [
  {
    key: "1",
    name: "Kategori 1"
  },
  {
    key: "2",
    name: "Kategori 2"
  },
  {
    key: "3",
    name: "Kategori 3"
  }
];

class index extends Component {
  state = {
    visible: false
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  closeModal = e => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Kategori" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Tooltip title="Yeni Kategori Ekle" placement="left">
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={this.showModal}
                />
              </Tooltip>
            </Space>
          </div>

          <Modal
            title="Yeni Kategori Ekle"
            visible={this.state.visible}
            onCancel={this.closeModal}
            footer=""
          >
            <Form layout="vertical" hideRequiredMark>
              <Form.Item
                name="name"
                label="Kategori Adı"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                ]}
              >
                <Input />
              </Form.Item>

              <Button type="primary" htmlType="submit">
                <PlusOutlined /> Ekle
              </Button>
            </Form>
          </Modal>
        </div>

        <Card bordered={false} className="cardTable">
          <Table columns={tableColumns} data={tableData} />
        </Card>
      </div>
    );
  }
}

export default index;
