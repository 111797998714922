// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class TestStatus {
  constructor() {}

  /**
   * Test Status Ekleme
  
   * @param {string} testTypeNameTr
   * @param {string} testTypeNameEn
  
   */
  Create = ({ testTypeNameTr, testTypeNameEn }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          name: testTypeNameTr,
          name_en: testTypeNameEn,
        };
        const response = await ApiService(
          "post",
          Endpoint.TestStatus.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Status Düzenleme
   * @param {string} testTypeId
   * @param {string} testTypeNameTr
   * @param {string} testTypeNameEn
   */
  Update = ({ testTypeId, testTypeNameTr, testTypeNameEn }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_status_id: testTypeId,
          name: testTypeNameTr,
          name_en: testTypeNameEn,
        };
        const response = await ApiService(
          "put",
          Endpoint.TestStatus.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Status Silme İşlemi
   * @param {string} testTypeId
   */
  Delete = (testTypeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_status_id: testTypeId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.TestStatus.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Test Status Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit=0, page=0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.TestStatus.getAll}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Test Status Detayı Alma
   * @param {string} testTypeId
   */
  Select = (testTypeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_status_id: testTypeId,
        };
        const response = await ApiService(
          "get",
          Endpoint.TestStatus.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Status Aktif/Pasif Durumu Değiştirme
   * @param {string} testTypeId
   */
  StatusChange = (testTypeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_status_id: testTypeId,
        };

        const response = await ApiService(
          "post",
          Endpoint.TestStatus.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new TestStatus();
