import React, { Component } from 'react';

import PageHeader from '../../../Components/UI/PageHeader';

import { Card, Modal, Button, Tooltip, Form, Input, Select, Space } from 'antd';

class index extends Component {
    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    
                    <PageHeader title="Form Tasarımları" subTitle="" />
                
                </div>


                <Card bordered={false} className="cardTable">
                    {/* şimdilik kalacak */}
                </Card>
            </div>
        );
    }
}

export default index;