import React, { Component } from "react";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import Table from "../../../Components/UI/Table";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from "lodash";

const { Option } = Select;

export default class index extends Component {
  constructor(props){
    super(props)
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      sectorData: [],
      productId: null,
      tableColumns: [
        {
          title: "Ürün Adı",
          dataIndex: "name",
          key: "name",
          responsive: ["md"],
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Ürün Adı"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
        {
          title: "Ürün Adı EN",
          dataIndex: "product_nameEn",
          key: "product_nameEn",
          responsive: ["md"],
          ...Helper.getColumnSearchProps(
            "product_nameEn",
            this.handleSearch,
            this.handleReset,
            "Ürün Adı EN"
          ),
          sorter:(a,b) => a.product_nameEn.localeCompare(b.product_nameEn) // string sıralama
        },
        {
          title: "İş Kolu (sektör)",
          dataIndex: "sector",
          key: "sector",
          responsive: ["lg"],
          ...Helper.getColumnSearchProps(
            "sector",
            this.handleSearch,
            this.handleReset,
            "İş Kolu (sektör)"
          ),
          sorter:(a,b) => a.sector.localeCompare(b.sector) // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseProduct = await DataService.Product.SelectAll(4000, 1);
      const responseSector = await DataService.Sector.SelectAll(4000, 1);

      if (responseProduct.status && responseSector.status) {
        const { data } = responseProduct.response;
        const sector = responseSector.response.data;

        let producsts = [],
          sectors = [];
        for (const key in sector) {
          if (sector.hasOwnProperty(key)) {
            const element = sector[key];
            sectors.push({
              value: element.sector_id,
              text: element.name,
            });
          }
        }

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];

            producsts.push({
              ...element,
              id: key,
              sector: element.sector_name,
            });
          }
        }
        console.log("sector", { sectors, data });
        this.setState({
          data: producsts,
          sectorData: sectors,
          loading: false,
        });
      } else {
        if (!responseProduct.status)
          Helper.ErrorMessage(responseProduct.message);
        if (!responseSector.status) Helper.ErrorMessage(responseSector.message);
      }
    } catch (error) {
      console.log("Products", JSON.stringify(error));
    }
  };
  _editClick = ({ product_id, sector_name, name,product_nameEn }) => {
    this.setState({ visible: true, productId: product_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          sectorId: _.find(this.state.sectorData, { text: sector_name })?.value,
          productName: name,
          productNameEN:product_nameEn
        });
      }, 100);
    });
  };
  _deleteClick = ({ product_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name}  Ürününü silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(product_id);
      }
    });
  };
  deleted = (productId) => {
    this.setState({ loading: true });
    DataService.Product.Delete(productId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      productId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ sectorId, productName,productNameEN }) => {
    try {
      const { productId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (productId) {
        result = await DataService.Product.Update({
          productId,
          sectorId,
          productName,
          productNameEN
        });
      } else {
        result = await DataService.Product.Create({
          sectorId,
          productName,
          productNameEN
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, productId: null, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const sector = this.state.sectorData.map((item, index) => (
      <Option key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Ürünler" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Product"}
                dates={["tarih"]}
              />

                <Button
                  shape="round"
                  className="btn-add"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                >Ürün Ekle</Button>
            </Space>
          </div>

          <Modal
            title={this.state.productId ? (
              "Ürün Düzenle"
            ) : (
              "Ürün Ekle"
            )}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="productName"
                label="Ürün Adı"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input placeholder="Ürün Adı" />
              </Form.Item>
              <Form.Item
                name="productNameEN"
                label="Ürün Adı EN"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input placeholder="Ürün Adı EN" />
              </Form.Item>
              <Form.Item
                name="sectorId"
                label="İş Kolu (sektör)"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select showSearch placeholder="İş Kolu Seçiniz">
                  {sector}
                </Select>
              </Form.Item>

              <div className="formButton">
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.productId ? (
                  <>
                    <EditOutlined /> Kaydet
                  </>
                ) : (
                  <>
                    <PlusOutlined /> Ekle
                  </>
                )}
              </Button>
              </div>
            </Form>
          </Modal>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}
