import React, { Component } from 'react';

import Table from '../../../Components/UI/Table';

import PageHeader from '../../../Components/UI/PageHeader';


import { Card, Row, Col } from 'antd';
import { Modal, Button, Tag, Tooltip } from 'antd';

import { PlusOutlined, BranchesOutlined, FallOutlined } from '@ant-design/icons';

// import Icon from '@ant-design/icons';
// import DnaSvg from '../../../Assets/icons/dna.svg';


// Vue Lims: columns: 
const tableColumns = [
    {
        title: 'Özellik Adı',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Kategori',
        dataIndex: 'category',
        key: 'category',
    }
];

const tableData = [
    {
        key: '1',
        name: 'Özellik Adı',
        category: 'Marka',
    },
    {
        key: '2',
        name: 'Özellik Adı',
        category: 'Model',
    },
    {
        key: '3',
        name: 'Özellik Adı',
        category: 'Parça',
    },
    {
        key: '4',
        name: 'Özellik Adı',
        category: 'Ürün Tipi',
    }
];


class index extends Component {
    
    state = { visible: false };
    showModal = () => {
        this.setState({
        visible: true,
        });
    };
    closeModal = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        
        return (
            <div>

                <div className="pageHeaderWrapper">
                    
                    <PageHeader title="Numune Özellikleri" subTitle="" />

                    <Tooltip title="Yeni Numune Özellik Ekle" placement="left">
                        <Button shape="circle" icon={<PlusOutlined />} onClick={this.showModal} />
                    </Tooltip>
                    
                    <Modal
                        title="Yeni Numune Özellik Ekle"
                        visible={this.state.visible}
                        onCancel={this.closeModal}
                        footer=""
                        >
                        <p>Numune eklerken kullanılan datalar</p>
                        <p><strong>marka, model, parça, ürün tipi</strong></p>
                    </Modal>
                </div>


                <Card bordered={false} >
                    <Table columns={tableColumns} data={tableData} />
                </Card>


            </div>
        );
    }
}

export default index;