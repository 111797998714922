import React, { Component } from "react";
import "./login.css";

import Logo from "../../Assets/logo/logo.svg";

import { NavLink } from "react-router-dom";

import { Form, Input, Button } from "antd";

import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";
import DataService from "../../Provider/Service/DataService";
import Helper from "../../Helper";
class ResetPassword extends Component {
  state = {
    btnload: false,
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    console.log('params',params);
    Helper.setLocalStorage('Token',`Bearer ${params.token}`);
  }
  
  _resetPassword = async ({ password,repassword }) => {
    try {
    
    
      this.setState({ btnload: true });
      let redirect = false;
      const a = Helper.getlocalStorage('Token');
      console.log('a',a);
      const result = await DataService.User.ResetPassword(password,repassword);
      
      if (result.status) {
        Helper.SuccessMessage(result.message);
    
        redirect = true;
      } else {
        Helper.ErrorMessage(result.message);
      }
      this.setState({ btnload: false }, () => {
        setTimeout(() => {
          if (redirect) {
            Helper.removeLocalStorage('Token');
            window.location.href = "/login";
          }
        }, 1000);
      });
      
    } catch (error) {
      Helper.ErrorMessage(error.toString());
      this.setState({ btnload: false });
    }
  };

  render() {
    return (
      <div class="login-layout ant-layout">
        <div className="loginBrand">
          <img src={Logo} alt="Logo" width="120" />
        </div>
        <div className="loginCard">
          <div className="cardHead">
            <h2>Şifre Sıfırlama</h2>
            <span>Lütfen yeni şifrenizi giriniz.</span>
          </div>

          <div className="cardContent">
            <Form
              className="formLogin"
              hideRequiredMark
              layout="vertical"
              onFinish={this._resetPassword}
            >
              <Form.Item
                label="Şifreniz"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Şifre Alanı Boş Bırakılamaz.",
                
                    // pattern:"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  
                  prefix={<KeyOutlined />}
                  placeholder="Parola"
                />
              </Form.Item>
              <Form.Item
                label="Şifreniz Tekrar"
                name="repassword"
                rules={[
                  {
                    required: true,
                    message: "Şifre Alanı Boş Bırakılamaz.",
               
                    
                     // pattern:"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  
                  prefix={<KeyOutlined />}
                  placeholder="Parola Tekrar"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  icon={<LoginOutlined />}
                  htmlType="submit"
                  loading={this.state.btnload}
                >
                  Sıfırla
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
