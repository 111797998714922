import React, { useEffect, useState } from "react";

import { Card, Spin,Result } from "antd";

import { Pie } from "react-chartjs-2";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
const TestsGraphic = ( ) => {
  const [testData, setTestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const setData = (data) => {
    let labels = [];
    let counts = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        labels.push(element.name);
        counts.push(element.count);
      }
    }
    const chartPieData = {
      labels: labels,
      datasets: [
        {
          data: counts,
          backgroundColor: ["rgba(255, 206, 11, 1)", "rgba(28, 180, 81, 1)", "rgba(223, 152, 0, 1)", "rgba(205, 201, 255, 1)"],
          hoverBackgroundColor: ["rgba(255, 206, 11, 1)", "rgba(28, 180, 81, 1)", "rgba(223, 152, 0, 1)", "rgba(205, 201, 255, 1)"],
        },
      ],
    };
  
    setTestData(chartPieData);
    setLoading(false);
  };
  const getData = async () => {
    DataService.Dashboard.Test()
      .then((result) => {
        if (result.status) {
        
          setData(result.data);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Spin spinning={loading}>
      <Card title="Testler">
        {
          !testData ? (
            <Result title="Grafik oluşturulamadı." />
          )   : (
            <Pie data={testData} />
            
          )}
        
     
      </Card>
    </Spin>
  );
};

export default TestsGraphic;
