import { Input, Button } from "antd";
import React from "react";
import Swal from "sweetalert2";

import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
class HelperService {
  state = {
    searchText: "",
    searchedColumn: "",
  };
  /**
   * Tarayıcı hafızasında kayıtlı değerlere ulaşılır.
   * @param {string} key
   */
  getlocalStorage = (key) => {
    return localStorage.getItem(key);
  };
  /**
   * Tarayıcı hafızasına değer kayıt etme
   * @param {string} key  - Değişken İsmi
   * @param {string} value  - Değişken Değeri
   */
  setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };
  /**
   * Tarayıcı hafızasına değer silme
   * @param {string} key  - Değişken İsmi

   */
  removeLocalStorage = (key) => {
    localStorage.removeItem(key);
  };
  /**
   * Ekranda gösterilen error mesaj
   * @param {string} errorMessage
   */
  ErrorMessage = (errorMessage) => {
    Swal.fire({
      icon: "error",
      title: "Hata!",
      text: errorMessage,
      confirmButtonText: "Tamam",
    });
  };
  /**
   * Ekranda gösterilen succcess mesaj
   * @param {string} successMessage
   */
  SuccessMessage = (successMessage) => {
    Swal.fire({
      icon: "success",
      title: "Başarılı!",
      text: successMessage,
      confirmButtonText: "Tamam",
    });
  };
  /**
   * Ekranda gösterilen warning mesaj
   * @param {string} warningMessage
   */
  warningMessage = (warningMessage) => {
    Swal.fire({
      icon: "warning",
      title: "Dikkat!",
      text: warningMessage,
      confirmButtonText: "Tamam",
    });
  };
  /**
   * Ekranda gösterilen alert mesaj
   * @param {string} warningMessage
   */
  alertMessage = ({
    title = "",
    message = "",
    buttonText = "Evet",
    onDone = () => 0,
  }) => {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      confirmButtonText: buttonText,
      cancelButtonText: "Vazgeç",
    }).then((result) => {
      if (result.isConfirmed) {
        if (typeof onDone == "function") {
          onDone();
        }
      }
    });
  };
  /**
   * string bir ifadenin null veya boş olup olmadığını kontrol eder.
   * @param {string} value
   */
  StringIsNullOrEmpty = (value) => {
    var isNullOrEmpty = true;
    if (value) {
      if (typeof value == "string") {
        if (value.length > 0) isNullOrEmpty = false;
      }
    }
    return isNullOrEmpty;
  };
  /**
   * Bir kolonda arama yapmak için kullanılan metod,state'lere ulaşmak ve değiştirmek için ilgili metodları parametre olarak alır
   */
  /*
  getColumnSearchProps = (dataIndex, handleSearch, handleReset, title) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Ara ${title}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Sıfırla
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined />,
      onFilter: (value, record) => {
        if (!this.StringIsNullOrEmpty(record[dataIndex])) {
          return record[dataIndex]
            ?.toString()
            ?.toLowerCase()
            .includes(value.toLowerCase().toString());
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  };
   */
  getColumnSearchProps = (dataIndex, handleSearch, handleReset, title) => {

    return {
      filterDropdown: ({
                         setSelectedKeys,
                         selectedKeys,
                         confirm,
                         clearFilters,
                       }) => (
          <div style={{ padding: 8 }}  onKeyDown={(e) => e.stopPropagation()}>
            <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={`Ara ${title}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}

                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
              Ara
            </Button>
            <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
            >
              Sıfırla
            </Button>
          </div>
      ),
      filterIcon: (filtered) => (
          <SearchOutlined />

      ),
      onFilter: (value, record) => {
        if (!this.StringIsNullOrEmpty(record[dataIndex])) {
          return record[dataIndex]?.toString()?.toLowerCase()
              .includes(value.toLowerCase().toString());
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select(),100);
        }
      },
      render: (text) =>
          this.state.searchedColumn === dataIndex ? (
              <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[this.state.searchText]}
                  autoEscape
                  textToHighlight={text.toString()}
              />
          ) : (
              text
          ),
    };
  };
  /**
   * Filtreleme metodu
   */
  getFiltersData = (filters, filteredInfo, key) => {
    return {
      filters: filters,
      onFilter: (value, record) => {
        return record[key] === value;
      },
      filteredValue: filteredInfo[key] || null,
      sortDirections: ["descend", "ascend"],
    };
  };
}
export default new HelperService();
