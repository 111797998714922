// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Sector {
  constructor() {}

  /**
   * Sektör Ekleme
   * @param {string} sectorName
   */
  Create = ({ sectorName }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          name:sectorName,
        };
        const response = await ApiService(
          "post",
          Endpoint.Sector.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Sektör Düzenleme
   * @param {string} sectorId
   * @param {string} sectorName
   */
  Update = ({ sectorId, sectorName }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          sector_id:sectorId,
          name:sectorName,
        };
        const response = await ApiService(
          "put",
          Endpoint.Sector.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Sektör Silme İşlemi
   * @param {string} sectorId
   */
  Delete = (sectorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          sector_id:sectorId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.Sector.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Sektör Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit=0, page=0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Sector.getAll}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Sektör Detayı Alma
   * @param {string} sectorId
   */
  Select = (sectorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          sector_id:sectorId,
        };
        const response = await ApiService(
          "get",
          Endpoint.Sector.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Sektör Aktif/Pasif Durumu Değiştirme
   * @param {string} sectorId
   */
  StatusChange = (sectorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          sector_id:sectorId,
        };

        const response = await ApiService(
          "post",
          Endpoint.Sector.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new Sector();
