import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import Table from "../../../../../Components/UI/Table";
import PageHeader from "../../../../../Components/UI/PageHeader";

import {
  Button,
  Card,
  Descriptions,
  Input,
  Select,
  Space,
  Row,
  Col,
  Spin,
  InputNumber,
} from "antd";

import Helper from "../../../../../Helper";
import DataService from "../../../../../Provider/Service/DataService";
import Swal from "sweetalert2";
const { Option } = Select;

class SampleTestEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      tableColumns: [
        {
          title: "Ürün Adı TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Ürün Adı"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name),
        },

        {
          title: "İş Kolu",
          dataIndex: "sector_name",
          key: "sector_name",
          ...Helper.getColumnSearchProps(
            "sector_name",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
          sorter: (a, b) => a.sector_name.localeCompare(b.sector_name),
        },

        {
          title: "Ref Min",
          dataIndex: "refMin",
          key: "refMin",
          render: this._renderRefMin,
        },
        {
          title: "Ref Max",
          dataIndex: "refMax",
          key: "refMax",
          render: this._renderRefMax,
        },

        {
          title: "Seçenekler",
          dataIndex: "options",
          key: "options",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => this._resultSaved(record)}
                >
                  Kaydet
                </Button>
              </Space>
            );
          },
        },
      ],
      data: [],
      details: {
        name: "N/A",
        name_en: "N/A",
        sector_name: "N/A",
        status_name: "N/A",
        status_name_en: "N/A",
        unit_name: "N/A",
        unit_name_en: "N/A",
        method_name: "N/A",
        method_name_en: "N/A",
        number: "N/A",
        number_characters: 0,
      },

      testId: "",
    };
  }
  componentDidMount() {
    this.getData();
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  getData = async () => {
    try {
      if (this.props.match?.params?.testId) {
        const { testId } = this.props.match?.params;
        const resultProduct = await DataService.Test.getProducts(testId);
        const resultTest = await DataService.Test.Select(testId);

        if (resultProduct.status && resultTest.status) {
          let data = [];
          for (const key in resultProduct.response.data) {
            if (resultProduct.response.data.hasOwnProperty(key)) {
              const element = resultProduct.response.data[key];
              data.push({
                key,
                ...element,
              });
            }
          }
          this.setState({
            loading: false,
            data: data,
            details: resultTest.data,
            testId: testId,
          });
        } else {
          !resultProduct.status && Helper.ErrorMessage("Ürün Bulunamadı");
          !resultTest.status && Helper.ErrorMessage("Test Bulunamadı");
          this.props.history.goBack();
        }
      } else {
        Helper.ErrorMessage("Test Bulunamadı");
        this.props.history.goBack();
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
      this.props.history.goBack();
    }
  };
  _renderRefMin = (text, record) => {
    const { number, number_characters, testOption } = this.state.details;
    if (number == 1) {
      let numberCharacters = Number(number_characters);
      numberCharacters = numberCharacters > 0 ? numberCharacters : 0;
      const steps =
        numberCharacters == 0
          ? 1
          : parseFloat(`0.${Array(numberCharacters).join(numberCharacters)}1`);
      const defaultValue = text && parseFloat(text).toFixed(numberCharacters);
      this[`refmin-operator-${Number(record.key)}`] =
        record?.ref_min_operator || "=";
      return (
        <>
          <Select
            style={{ width: 70 }}
            placeholder="Seçiniz"
            defaultValue={record?.ref_min_operator || "="}
            onChange={(value) =>
              (this[`refmin-operator-${Number(record.key)}`] = value)
            }
          >
            <Option value=">">{">"}</Option>
            <Option value="=">{"="}</Option>
            <Option value="<">{"<"}</Option>
          </Select>
          <Input
            ref={(refs) => (this[`refmin-${Number(record.key)}`] = refs)}
            style={{ width: 100 }}
            min={steps}
            step={steps}
            defaultValue={(isNaN(defaultValue) ? '-' : defaultValue) || steps}
            type="number"
          />
        </>
      );
    } else {
      return (
        <Select
          style={{ width: 150 }}
          placeholder="Seçiniz"
          optionFilterProp="children"
          onChange={(value) => (this[`refmin-${Number(record.key)}`] = value)}
          defaultValue={text}
        >
          {testOption?.map((item) => {
            return <Option value={item.name}>{item.name}</Option>;
          })}
        </Select>
      );
    }
  };
  _renderRefMax = (text, record) => {
    const { number, number_characters, testOption } = this.state.details;
    if (number == 1) {
      let numberCharacters = Number(number_characters);
      numberCharacters = numberCharacters > 0 ? numberCharacters : 0;
      const steps =
        numberCharacters == 0
          ? 1
          : parseFloat(`0.${Array(numberCharacters).join(numberCharacters)}1`);
      const defaultValue = text && parseFloat(text).toFixed(numberCharacters);
      this[`refmax-operator-${Number(record.key)}`] =
        record?.ref_max_operator || "=";
      return (
        <>
          <Select
            style={{ width: 70 }}
            placeholder="Seçiniz"
            defaultValue={record?.ref_max_operator || "="}
            onChange={(value) =>
              (this[`refmax-operator-${Number(record.key)}`] = value)
            }
          >
            <Option value=">">{">"}</Option>
            <Option value="=">{"="}</Option>
            <Option value="<">{"<"}</Option>
          </Select>
          <Input
            ref={(refs) => (this[`refmax-${Number(record.key)}`] = refs)}
            style={{ width: 100 }}
            min={steps}
            step={steps}
            defaultValue={(isNaN(defaultValue) ? '-' : defaultValue) || steps}
            type="number"
          />
        </>
      );
    } else {
      this[`refmax-${Number(record.key)}`] = "-";
      return ""
      /*
      return (
        <Select
          style={{ width: 150 }}
          placeholder="Seçiniz"
          optionFilterProp="children"
          onChange={(value) => (this[`refmax-${Number(record.key)}`] = value)}
          defaultValue={text}
        >
          {testOption?.map((item) => {
            return <Option value={item.name}>{item.name}</Option>;
          })}
        </Select>
      );
      */
    }
  };

  _resultSaved = ({ key, product_id }) => {
    const { number, number_characters } = this.state.details;
    
    let refMin =
        number == 1 ? this[`refmin-${key}`].state.value : this[`refmin-${key}`],
      refMax =
        number == 1 ? this[`refmax-${key}`].state.value : this[`refmax-${key}`],
      ref_min_operator = this[`refmin-operator-${key}`] || "=",
      ref_max_operator = this[`refmax-operator-${key}`] || "=";
    refMin = refMin ? refMin :  this[`refmin-${key}`];
    refMax = refMax ? refMax : "-"
    if (refMin && refMax) {
      if (parseFloat(refMin) >= parseFloat(refMax)) {
        Helper.warningMessage(
          `Referans Minimum değeri Referans Maximum değerine eşit veya yüksek olamaz.`
        );
        return;
      }
      if (parseFloat(refMax) <= parseFloat(refMin)) {
        Helper.warningMessage(
          `Referans Maximum değeri Referans Minimum değerine eşit veya  düşük olamaz.`
        );
        return;
      }
      const floatValues = Number(number_characters);
      const floatRefMin = parseFloat(refMin).toFixed(floatValues || 0);
      const floatRefMax = parseFloat(refMax).toFixed(floatValues || 0);
      this.resultSaved({
        refMin: isNaN(floatRefMin) ?  refMin : floatRefMin ,
        refMax: isNaN(floatRefMax) ?  '-' :floatRefMax ,
        productId: product_id,
        ref_min_operator,
        ref_max_operator,
      });
    } else {
      if (refMin || refMax) {
        Swal.fire({
          icon: "warning",
          title: "Dikkat!",
          text:
            "Referans aralığı değerlerinden en az bir tanesini girmediniz. Onaylıyor musunuz?",
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCancelButton: true,
        }).then(({ isConfirmed, isDismissed, dismiss, value }) => {
          if (isConfirmed) {
            const floatValues = Number(number_characters);
            const floatRefMin = parseFloat(refMin).toFixed(floatValues || 0);
            const floatRefMax = parseFloat(refMax).toFixed(floatValues || 0);
            this.resultSaved({
              refMin: floatRefMin,
              refMax: floatRefMax,
              productId: product_id,
            });
          }
        });
      } else {
        Helper.warningMessage(
          "Referans aralığı değerlerinden en az bir tanesini  girmelisiniz."
        );
        
      }

      // !refMin &&
      //   Helper.warningMessage(
      //     "Lütfen refmin değerini  giriniz. Örnek değer: (1000,12)"
      //   );
      // !refMax &&
      //   Helper.warningMessage(
      //     "Lütfen refmax değerini giriniz. Örnek değer: (1000,12)"
      //   );
    }
  };
  resultSaved = async (params) => {
    this.setState({ loading: true });
    try {
      const requestParams = {
        testId: this.state.testId,
        ...params,
      };

      const response = await DataService.Test.RefUpdate(requestParams);

      if (!response.status) {
        Helper.ErrorMessage(response.message);
      }
      this.setState({ loading: false });
      this.getData();
    } catch (error) {
      this.setState({ loading: false });
      Helper.ErrorMessage("Sistemsel bir hata meydana geldi");

      this.props.history.goBack();
    }
  };
  render() {
    const { details, data, tableColumns } = this.state;
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Referans Aralığı" />

          <div className="pageHeaderOptions">
            <Space>
              <NavLink
                activeClassName="nav-active"
                exact
                to="/definitions/test-features/test-ref"
              >
                <Button className="btn-list" shape="round">
                  Test Listesi
                </Button>
              </NavLink>
            </Space>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <div
              className="sample-information"
              style={{ marginBottom: "24px" }}
            >
              <h2>Numune Bilgileri</h2>
              <Row gutter="16" style={{ marginBottom: "16px" }}>
                <Col span={24} md={24}>
                  <Descriptions title="" bordered>
                    <Descriptions.Item label="İş Kolu">
                      {details.sector_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Test Adı TR">
                      {details.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Test Adı EN">
                      {details.name_en}
                    </Descriptions.Item>
                    <Descriptions.Item label="Test Metodu">
                      {details.method_name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Test Birimi">
                      {details.unit_name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Test Formatı">
                      {details.number == 1 ? "Rakam" : "Metin"}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </div>

            <Table columns={tableColumns} data={data} pagination="false" />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default SampleTestEdit;
