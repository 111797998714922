// Generic Informations


let baseAPIUrl = 'https://apilims.intertekturkey.com/';
if (window.location.hostname === 'localhost') {
    baseAPIUrl = "http://lims-api.test/";
} else {
    if (window.location.hostname !== 'lims.intertekturkey.com') {
        baseAPIUrl = "https://apilims-test.intertekturkey.com/";
    }
}

export {baseAPIUrl};
