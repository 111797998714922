import React, { Component } from "react";

import ProfileAvatar from "../../Assets/profile.jpg";
import PageHeader from "../../Components/UI/PageHeader";

import {
  Card,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  Divider,
  Menu,
  Avatar,
  Modal,
  Spin,
} from "antd";
import {
  NotificationOutlined,
  UserOutlined,
  LockOutlined,
  EditOutlined,
  UploadOutlined,
  DiffOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";

import Helper from "../../Helper";
import DataService from "../../Provider/Service/DataService";
import { helpers } from "chart.js";
const { Option } = Select;
const url = "https://apilims.intertekturkey.com/getImage/";
class index extends Component {
  state = {
    permData: [],
    customer: [],
    labs: [],
    region: [],
    visible: false,
    profileVisible: false,
    selectedFile: null,
    signatureSelectedFile: null,
    signatureSecondSelectedFile: null,
    loading: true,
    signatureUrl: null,
    signatureSecondUrl: null,
    signatureViewVisible: false,
    signatureSecondViewVisible: false,

    labShow: false,
    regionShow: false,
    customerShow: false,
    signatureVisible: false,
    signatureSecondVisible: false,
  };
  formRef = React.createRef();
  passForm = React.createRef();
  componentDidMount() {
    this.getData();
  }
  inputShow = (permId) => {
    return new Promise(async (resolve, reject) => {
      try {
        let labShow = false,
          regionShow = false,
          customerShow = false;
        if (
          permId == "730c2610e7ff1b5088d592378384a777" ||
          permId == "f54a19e2d81c5bd09024a31cffe295b0"
        ) {
          regionShow = true;
        } else if (
          permId == "eda47dd3df7856681473ba3d7fdf730c" ||
          permId == "25b2ecc9e2111a773615b2184fc792e3"
        ) {
          customerShow = true;
        } else if (
          permId == "6bd9ecfeb76640f23685c713a99d69dc" ||
          permId == "c017d489f0fc91ab32a4444d5964f197" ||
          permId == "70850ecbb0174f3b03e1ec9f5e30e2f0"
        ) {
          labShow = true;
        }
        resolve({ labShow, regionShow, customerShow });
      } catch (error) {
        reject(error);
      }
    });
  };
  getData = async () => {
    try {
      const responseUser = await DataService.User.Profile();

      const responsePerms = await DataService.User.permSelectAll();

      const responseLabs = await DataService.Laboratory.SelectAll();
      const responseRegion = await DataService.Region.SelectAll();

      if (
        responseUser.status &&
        responsePerms.status &&
        responseLabs.status &&
        responseRegion.status
      ) {
        const { labShow, regionShow, customerShow } = await this.inputShow(
          responseUser.data.perm_id
        );
        this.setState({
          data: responseUser.data,
          permData: responsePerms.response.data,

          labs: responseLabs.response.data,
          region: responseRegion.response.data,
          loading: false,
          labShow,
          regionShow,
          customerShow,
          signatureUrl: `${url}${responseUser?.data?.signature}`,
          signatureSecondUrl: `${url}${responseUser?.data?.signature2}`,
        });
        const {
          perm_id,
          region_id,
          lab_id,
          mail,
          name,
          lastname,
          phone,
          lang,
          photo,
        } = responseUser.data;
        setTimeout(() => {
          this.formRef.current.setFieldsValue({
            perm_id: perm_id,
            region_id: region_id,
            lab_id: lab_id,

            mail: mail,

            name: name,
            lastname: lastname,
            phone: phone,
            lang: lang,
            photo: `${url}${photo}`,
          });
        }, 500);
      } else {
        if (!responseUser.status) Helper.ErrorMessage(responseUser.message);
        if (!responsePerms.status) Helper.ErrorMessage(responsePerms.message);

        if (!responseLabs.status) Helper.ErrorMessage(responseLabs.message);
        if (!responseRegion.status) Helper.ErrorMessage(responseRegion.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  _onFormFinis = async ({
    perm_id,
    region_id,
    lab_id,
    mail,
    name,
    lastname,
    phone,
    lang,
  }) => {
    try {
      const response = await DataService.User.profileUpdate({
        perm_id,
        region_id,
        lab_id,
        mail,
        name,
        lastname,
        phone,
        lang,
      });
      if (response.status) {
        Helper.SuccessMessage(response.message);
      } else {
        Helper.ErrorMessage(response.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  _passFormFinish = async ({ currentPassword, password, repassword }) => {
    try {
      if (password != repassword) {
        Helper.ErrorMessage("Şifreleriniz Uyuşmuyor");
      } else {
        const response = await DataService.User.Password({
          currentPassword,
          password,
          repassword,
        });
        if (response.status) {
          Helper.SuccessMessage(response.message);
          this.passForm.current.resetFields();
          this.setState({ visible: false });
        } else {
          Helper.ErrorMessage(response.message);
        }
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  _closeModal = () => {
    this.passForm.current.resetFields();
    this.setState({ visible: false });
  };
  _profileClose = () => {
    this.setState({ profileVisible: false });
  };
  _signatureClose = () => {
    this.setState({ signatureVisible: false });
  };
  _signatureSecondClose = () => {
    this.setState({ signatureSecondVisible: false });
  };
  onFileChange = (event) => {
    console.log("event", event.target);
    this.setState({ selectedFile: event.target.files[0] });
  };
  onFileUpload = () => {
    const formData = new FormData();
    formData.append(
      "item_images",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    DataService.User.Photo(formData)
      .then((result) => {
        if (result.status) {
          DataService.User.Profile()
            .then((response) => {
              if (response.status) {
                Helper.SuccessMessage(result.message);
                this.setState({ profileVisible: false, data: response.data });
                const {
                  perm_id,
                  region_id,
                  lab_id,
                  mail,
                  name,
                  lastname,
                  phone,
                  lang,
                  photo,
                } = response.data;
                setTimeout(() => {
                  this.formRef.current.setFieldsValue({
                    perm_id: perm_id,
                    region_id: region_id,
                    lab_id: lab_id,

                    mail: mail,

                    name: name,
                    lastname: lastname,
                    phone: phone,
                    lang: lang,
                    photo: `${url}${photo}`,
                  });
                }, 500);
              } else {
                Helper.ErrorMessage(response.message);
              }
            })
            .catch((err) => {
              Helper.ErrorMessage(err);
            });
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((err) => Helper.ErrorMessage(err));
  };
  signatureOnFileChange = (event) => {
    console.log("event", event.target);
    this.setState({ signatureSelectedFile: event.target.files[0] });
  };
  signatureSecondOnFileChange = (event) => {
    console.log("event", event.target);
    this.setState({ signatureSecondSelectedFile: event.target.files[0] });
  };
  signatureOnFileUpload = () => {
    const formData = new FormData();
    formData.append(
      "item_images",
      this.state.signatureSelectedFile,
      this.state.signatureSelectedFile.name
    );

    DataService.User.Signature(formData)
      .then((result) => {
        if (result.status) {
          DataService.User.Profile()
            .then((response) => {
              if (response.status) {
                Helper.SuccessMessage(result.message);
                this.setState({
                  signatureVisible: false,
                  data: response.data,
                  signatureUrl: `${url}${response?.data?.signature}`,
                });
              } else {
                Helper.ErrorMessage(response.message);
              }
            })
            .catch((err) => {
              Helper.ErrorMessage(err);
            });
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((err) => Helper.ErrorMessage(err));
  };
  signatureSecondOnFileUpload = () => {
    const formData = new FormData();
    formData.append(
      "item_images",
      this.state.signatureSecondSelectedFile,
      this.state.signatureSecondSelectedFile.name
    );

    DataService.User.SignatureSecond(formData)
      .then((result) => {
        if (result.status) {
          DataService.User.Profile()
            .then((response) => {
              if (response.status) {
                Helper.SuccessMessage(result.message);
                this.setState({
                  signatureSecondVisible: false,
                  data: response.data,
                  signatureSecondUrl: `${url}${response?.data?.signature}`,
                });
              } else {
                Helper.ErrorMessage(response.message);
              }
            })
            .catch((err) => {
              Helper.ErrorMessage(err);
            });
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((err) => Helper.ErrorMessage(err));
  };
  render() {
    console.log("state", this.state);
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Profil" />
        </div>
        <Spin spinning={this.state.loading} delay={500}>
          <Row gutter={16}>
            <Col md={4} span={24}>
              <div className="profileCardProfile">
                <Avatar
                  src={
                    (this.state.data?.photo &&
                      `${url}${this.state.data.photo}`) ||
                    ProfileAvatar
                  }
                  size={160}
                />
                <div className="profileName">Shark Yazılım</div>
              </div>
            </Col>
            <Col md={14} span={24}>
              <Card className="cardTable">
                <Form
                  layout="vertical"
                  hideRequiredMark
                  ref={this.formRef}
                  onFinish={this._onFormFinis}
                >
                  {/* <Divider orientation="left">Kişisel Bilgiler</Divider> */}

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label="Ad"
                        rules={[
                          { required: true, message: "Boş bırakmayınız" },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="lastname"
                        label="Soyad"
                        rules={[
                          { required: true, message: "Boş bırakmayınız" },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* <Divider orientation="left">İletişim Bilgileri</Divider> */}

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="mail"
                        label="E-Mail"
                        rules={[
                          { required: true, message: "Boş bırakmayınız" },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="phone"
                        label="Telefon"
                        rules={[
                          { required: true, message: "Boş bırakmayınız" },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* <Divider orientation="left">Yetki ve Firma Bilgileri</Divider> */}

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="perm_id"
                        label="Yetki"
                        rules={[
                          {
                            required: true,
                            message: "Lütfen bu alanı boş bırakmayın",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Yetki Seçiniz"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled
                        >
                          {this.state.permData.map((item, index) => {
                            return (
                              <Option key={index} value={item.perm_id}>
                                {item.permTypeName}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    {this.state.labShow && (
                      <Col span={12}>
                        <Form.Item
                          name="lab_id"
                          label="Laboratuvar"
                          rules={[
                            {
                              required: true,
                              message: "Lütfen bu alanı boş bırakmayın",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Laboratuvar Seçiniz"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled
                          >
                            {this.state.labs.map((item, index) => {
                              return (
                                <Option key={index} value={item.laboratory_id}>
                                  {item.LabName}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row gutter={16}>
                    {this.state.regionShow && (
                      <Col span={12}>
                        <Form.Item
                          name="region_id"
                          label="Bölge"
                          rules={[
                            {
                              required: false,
                              message: "Lütfen bu alanı boş bırakmayın",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Bölge Seçiniz"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled
                          >
                            {this.state.region.map((item, index) => {
                              return (
                                <Option key={index} value={item.region_id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={12}>
                      <Form.Item
                        name="lang"
                        label="Dil"
                        rules={[
                          {
                            required: true,
                            message: "Lütfen bu alanı boş bırakmayın",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Dil Seçiniz"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="tr">Türkçe</Option>
                          <Option value="en">İngilizce</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="formButton">
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Güncelle
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Card>
            </Col>
            <Col md={6} span={24}>
              <Card>
                <Divider orientation="left">Menü</Divider>
                <Menu
                  style={{ width: "100%" }}
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                >
                  <Menu.Item key="p_1" icon={<UserOutlined />}>
                    Profil Bilgilerim
                  </Menu.Item>
                  <Menu.Item
                    key="p_2"
                    icon={<LockOutlined />}
                    onClick={() => this.setState({ visible: true })}
                  >
                    Şifre Değiştir
                  </Menu.Item>
                  <Menu.Item
                    key="p_3"
                    icon={<NotificationOutlined />}
                    onClick={() => this.setState({ profileVisible: true })}
                  >
                    Profil Fotoğrafını Değiştir
                  </Menu.Item>
                  <Menu.Item
                    key="p_4"
                    icon={<DiffOutlined />}
                    onClick={() => this.setState({ signatureVisible: true })}
                  >
                    İmza Ekle / Görüntüle
                  </Menu.Item>
                  <Menu.Item
                    key="p_5"
                    icon={<DiffOutlined />}
                    onClick={() =>
                      this.setState({ signatureSecondVisible: true })
                    }
                  >
                    2. İmza Ekle / Görüntüle
                  </Menu.Item>
                </Menu>
                ,
              </Card>
            </Col>
          </Row>
        </Spin>
        <Modal
          title="Şifre Güncelleme"
          visible={this.state.visible}
          onCancel={this._closeModal}
          footer=""
        >
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={this._passFormFinish}
            ref={this.passForm}
          >
            <Form.Item
              name="currentPassword"
              label="Mevcut Şifreniz"
              rules={[
                { required: true, message: "Lütfen bu alanı boş bırakmayın" },
              ]}
            >
              <Input placeholder="Mevcut Şifreniz " />
            </Form.Item>
            <Form.Item
              name="password"
              label="Yeni Şifreniz"
              rules={[
                { required: true, message: "Lütfen bu alanı boş bırakmayın" },
              ]}
            >
              <Input placeholder="Yeni Şifreniz " />
            </Form.Item>
            <Form.Item
              name="repassword"
              label="Yeni Şifreniz Tekrar"
              rules={[
                { required: true, message: "Lütfen bu alanı boş bırakmayın" },
              ]}
            >
              <Input placeholder="Yeni Şifreniz Tekrar " />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              <EditOutlined /> Kaydet
            </Button>
          </Form>
        </Modal>
        <Modal
          title="Profil Resmi"
          visible={this.state.profileVisible}
          onCancel={this._profileClose}
          footer=""
        >
          <input type="file" onChange={this.onFileChange} accept="image/*" />
          <button onClick={this.onFileUpload}>Yükle!</button>
        </Modal>
        <Modal
          title="İmza Ekleme / Görüntüleme"
          visible={this.state.signatureVisible}
          onCancel={this._signatureClose}
          footer=""
        >
          <img
            src={this.state.signatureUrl}
            style={{
              maxWidth: 600,
            }}
          />{" "}
          <br /> <br /> <br />
          <input
            type="file"
            onChange={this.signatureOnFileChange}
            accept="image/*"
          />
          <button onClick={this.signatureOnFileUpload}>Yükle!</button>
        </Modal>
        <Modal
          title="2. İmza Ekleme / Görüntüleme"
          visible={this.state.signatureSecondVisible}
          onCancel={this._signatureSecondClose}
          footer=""
        >
          <img
            src={this.state.signatureSecondUrl}
            style={{
              maxWidth: 600,
            }}
          />{" "}
          <br /> <br /> <br />
          <input
            type="file"
            onChange={this.signatureSecondOnFileChange}
            accept="image/*"
          />
          <button onClick={this.signatureSecondOnFileUpload}>Yükle!</button>
        </Modal>
      </div>
    );
  }
}

export default index;
