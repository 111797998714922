import React, { Component } from "react";
import PropTypes from "prop-types";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import "./index.css";

class ListSortDemo extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
  };

  static defaultProps = {
    className: "list-sort-demo",
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const data = arrayMove(this.props.data, oldIndex, newIndex);
    let arrayData = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let element = data[key];
        element.line = key;
        arrayData.push(element);
      }
    }

    this.props.onChange(arrayData);
  };
  render() {
    const SortableItem = SortableElement(
      ({ name, order, name_en, method_name }) => (
        <div className={`${this.props.className}-list`}>
          <div className={`${this.props.className}-text`}>
            <span className="sortTestOrder">{order}</span>
            <span className="sortTestName">
              {name}/{name_en}-({method_name})
            </span>
          </div>
        </div>
      )
    );

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              name={value.name}
              method_name={value.method_name}
              name_en={value.name_en}
              order={value.order}
            />
          ))}
        </ul>
      );
    });

    return (
      <div className={`${this.props.className}-wrapper`}>
        <div className={this.props.className}>
          <SortableList items={this.props.data} onSortEnd={this.onSortEnd} />
        </div>
      </div>
    );
  }
}

export default ListSortDemo;
