import React, {Component} from "react";

// import Tables from "../../Components/Table";

import {
    Card,
    Button,
    DatePicker,
    Space,
    Row,
    Col,
    Spin,
    Descriptions,
    Input,
    Divider,
} from "antd";
import moment from "moment";
import PageHeader from "../../Components/UI/PageHeader";
import Table from "../../Components/UI/Table";
import Helper from "../../Helper";
import DataService from "../../Provider/Service/DataService";

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumns: [
                {
                    title: "TESTLER",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "Metot No:",
                    dataIndex: "method",
                    key: "method",
                },
                {
                    title: "1. Sonuç",
                    dataIndex: "result_1",
                    key: "result_1",
                    render: () => <Input/>,
                },
                {
                    title: "2. Sonuç",
                    dataIndex: "result_2",
                    key: "result_2",
                    render: () => <Input/>,
                },
                {
                    title: "ORT",
                    dataIndex: "average",
                    key: "average",
                    render: () => <Input/>,
                },
                {
                    title: "Analizi Yapan",
                    dataIndex: "analyzed_by",
                    key: "analyzed_by",
                    render: () => <Input/>,
                },
                {
                    title: "Deneylerde Kul. Cih.",
                    dataIndex: "devices",
                    key: "devices",
                    render: () => <Input/>,
                },
            ],
            tableData: [],
            details: {
                reportNo: "N/A",
                sectorName: "N/A",
                sectorNameEn: "N/A",
                getLocation: "N/A",
                getPartAdd: "N/A",
                getMethod: "N/A",
                productName: "N/A",
                productNameEn: "N/A",
                getAcceptanceDate: "N/A",
                getAcceptanceHour: "",
                statusId: "N/A",
                operationName: "N/A",
            },
            users: [{userId: "123", userName: "Orhan"}],
            sampleId: "",
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        try {
            if (this.props.match?.params?.sampleId) {
                const result = await DataService.Sample.getTests(
                    this.props.match?.params?.sampleId
                );
                const data = [];
                if (result.status) {
                    for (const key in result.data) {
                        if (result.data.hasOwnProperty(key)) {
                            const {method, name} = result.data[key];

                            data.push({
                                method,
                                name,

                                key,
                            });
                        } else {
                            console.log("resultdatanoobject", result.data[key]);
                        }
                    }
                    const detailsData = {
                        ...result.details,
                        amountText: `${result.details.amount} ${result.details.amount_type}`,
                        getAcceptanceHour: result?.details?.getAcceptanceHour
                            ? moment(Number(result.details.getAcceptanceHour) * 1000).format(
                                "hh:mm"
                            )
                            : "",
                    };

                    this.setState({
                        tableData: data,
                        details: detailsData,
                        users: result.users,
                        loading: false,
                        sampleId: this.props.match?.params?.sampleId,
                    });
                } else {
                    Helper.ErrorMessage("Test Bulunamadı");
                    this.props.history.push("/sample/sample-test");
                }
            } else {
                Helper.ErrorMessage("Test Bulunamadı");
                this.props.history.push("/sample/sample-test");
            }
        } catch (error) {
            Helper.ErrorMessage(error.toString());
            this.props.history.push("/sample/sample-test");
        }
    };

    render() {
        const {details} = this.state;
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Analiz Sonuçları Kayıt Formu" subTitle=""/>

                    <div className="pageHeaderOptions">
                        <Space></Space>
                    </div>
                </div>

                <Card bordered={false} className="cardTable">
                    <div className="tableFilter" style={{marginBottom: "15px"}}>
                        <h2>Analiz Sonuçları Kayıt Formu</h2>

                        <Row gutter="16">
                            <Col span={12}>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Kayıt No:</div>
                                    </Col>
                                    <Col span={12}>{details.reportNo}</Col>
                                </Row>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Müşteri:</div>
                                    </Col>
                                    <Col span={12}>{details.customer_name}</Col>
                                </Row>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Numune Cinsi:</div>
                                    </Col>
                                    <Col span={12}>{details.productName}</Col>
                                </Row>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">
                                            Numune Adı / Gemi Adı :
                                        </div>
                                    </Col>
                                    <Col span={12}>{details?.getPart || ''}</Col>
                                </Row>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">
                                            Terminal:
                                        </div>
                                    </Col>
                                    <Col span={12}>{details?.getLocation || ''}</Col>
                                </Row>

                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Detaylı Bilgi:</div>
                                    </Col>
                                    <Col span={12}></Col>
                                </Row>

                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Numune Ambalaj Bilgisi:</div>
                                    </Col>
                                    <Col span={12}>{details.amountText}</Col>
                                </Row>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Mühür No:</div>
                                    </Col>
                                    <Col span={12}>{details.signetCode}</Col>
                                </Row>

                            </Col>
                            <Col span={12}>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Numune Geliş Tarihi, Saati:</div>
                                    </Col>
                                    <Col
                                        span={12}>{details.getAcceptanceDate} {moment(Number(details.getAcceptanceHour)).format('hh:mm')}</Col>
                                </Row>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Numune Analiz Tarihi, Saati:</div>
                                    </Col>
                                    <Col span={12}>{details.dateStart}</Col>
                                </Row>

                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Analiz Bitiş Tarihi, Saati:</div>
                                    </Col>
                                    <Col span={12}>{details?.dateFinish || ''}</Col>
                                </Row>

                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">ATF Seri No:</div>
                                    </Col>
                                    <Col span={12}>{details.atfNo}</Col>
                                </Row>

                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Numune Alma Tarihi:</div>
                                    </Col>
                                    <Col
                                        span={12}>{details.getAcceptanceDate ? `${details.getAcceptanceDate}` : '-'}</Col>
                                </Row>

                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Numuneyi Alan:</div>
                                    </Col>
                                    <Col span={12}>{details.getUser} / {details.getUserCustomer}</Col>
                                </Row>
                                <Row gutter="16" className="mb-0">
                                    <Col span={12}>
                                        <div className="td-title">Numuneyi Teslim Eden:</div>
                                    </Col>
                                    <Col span={12}>{details.getSenderName} / {details.getSenderCustomer}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <Table
                        columns={this.state.tableColumns}
                        data={this.state.tableData}
                        pagination={{
                            total: this.state.tableData.length,
                            pageSize: this.state.tableData.length,
                            hideOnSinglePage: true,
                        }}
                    />

                    <Row gutter="16">
                        <Col span={12}>
                            <div className="inner-note-section">
                                <h3>Hesaplamalar:</h3>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="inner-note-section">
                                <h3>Çözelti ve Malzeme Detayı</h3>
                            </div>
                        </Col>
                    </Row>

                    <Divider/>

                    <div className="analysis-footer">
                        <Row gutter="16">
                            <Col span={6}>
                                <strong>Form no:</strong> KL-F 064
                            </Col>
                            <Col span={6}>
                                <strong>Yayın no:</strong> 1
                            </Col>
                            <Col span={6}>
                                <strong>Revizyon no:</strong> 16.08.2020
                            </Col>
                            <Col span={6}>
                                <strong>Yayın tarihi:</strong> 15.08.2020
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        );
    }
}



