import React, { useState, useEffect } from "react";

import { Card, Button, Space, Spin, Result } from "antd";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";

const MonthlyTestsGraphic = () => {
  const [testData, setTestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const setData = (data) => {
    let reChartLineData = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        reChartLineData.push({
          name: moment(element.date).format("MMMM"),
          yapilan: element.sampleTestMadeCount,
          revize: element.sampleTestRevisedCount,
        });
      }
    }
    console.log("reChartLineData", reChartLineData);
    setTestData(reChartLineData);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    DataService.Dashboard.TestsMonth()
      .then((result) => {
        if (result.status) {
          setData(result.data);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };

  return (
    <Spin spinning={loading}>
      <Card title="Aylık Test Sayıları">
        <div style={{ width: "100%", height: 300 }}>
          {!testData ? (
            <Result title="Grafik oluşturulamadı." />
          ) : (
            <ResponsiveContainer>
              <LineChart
                data={testData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="revize"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="yapilan" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </Card>
    </Spin>
  );
};

export default MonthlyTestsGraphic;
