// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class TestPack {
  constructor() {}

  /**
   * Test Pack Ekleme
   * @param {array} testPackList
   * @param {string} testPackNameTr
   * @param {string} testPackNameEn
   * @param {number} testPackCount
   */
  Create = ({
    testPackList,
    testPackNameTr,
    testPackNameEn,
    testPackCount,
    testProductId,
    testDescription,
    testDescriptionEN,
    sectorId,
    tankbytank = false,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_pack_list: testPackList,
          name: testPackNameTr,
          name_en: testPackNameEn,
          total_test: testPackCount,
          product_id: testProductId,
          content: testDescription,
          content_en: testDescriptionEN,
          sector_id: sectorId,
          tankbytank,
        };
        const response = await ApiService(
          "post",
          Endpoint.TestPack.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Pack Düzenleme
   * @param {string} testPackId
   * @param {array} testPackList
   * @param {string} testPackNameTr
   * @param {string} testPackNameEn
   * @param {number} testPackCount
   */
  Update = ({
    testPackId,
    testPackList,
    testPackNameTr,
    testPackNameEn,
    testPackCount,
    testProductId,
    testDescription,
    testDescriptionEN,
    sectorId,
    tankbytank = false,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          pack_id: testPackId,
          test_pack_list: testPackList,
          name: testPackNameTr,
          name_en: testPackNameEn,
          total_test: testPackCount,
          product_id: testProductId,
          content: testDescription,
          content_en: testDescriptionEN,
          sector_id: sectorId,
          tankbytank,
        };
        const response = await ApiService(
          "put",
          Endpoint.TestPack.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Pack Silme İşlemi
   * @param {string} testPackId
   */
  Delete = (testPackId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          pack_id: testPackId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.TestPack.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Test Pack Listeleme
   * @param {number} limit
   * @param {number} page
   * @param {Date} startDate
   * @param {Date} endDate
   */
  SelectAll = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.TestPack.getAll}/${limit}/${page}/${startDate}/${endDate}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              data: response.reply,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Test Pack Detayı Alma
   * @param {string} testPackId
   */
  Select = (testPackId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.TestPack.getOne}/${testPackId}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Pack Aktif/Pasif Durumu Değiştirme
   * @param {string} testPackId
   */
  StatusChange = (testPackId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          pack_id: testPackId,
        };

        const response = await ApiService(
          "post",
          Endpoint.TestPack.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new TestPack();
