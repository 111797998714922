// Servis Çağrıları
import { ApiService } from "./Base";
import * as Endpoint from "../Config/EndpointUrl";

import Helper from "../../Helper";
import {
  Customer,
  Laboratory,
  Logs,
  Operation,
  Product,
  Region,
  Sector,
  Test,
  TestMethod,
  TestPack,
  TestStatus,
  TestOptions,
  TestUnit,
  TestDevice,
  User,
  Inventory,
  InventoryBrand,
  InventoryCategory,
  WorkOrder,
  Sample,
  Store,
  Menu,
  TestLab,
  Notification,
  Announcement,
  Dashboard,
  ReferanceStandart, TrendAnalytics
} from "./Services";

class DataService {
  constructor() {
    this.Customer = Customer;
    this.Laboratory = Laboratory;
    this.Logs = Logs;
    this.Operation = Operation;
    this.Product = Product;
    this.Region = Region;
    this.Sector = Sector;
    this.Test = Test;
    this.TestMethod = TestMethod;
    this.TestPack = TestPack;
    this.TestStatus = TestStatus;
    this.TestUnit = TestUnit;
    this.TestDevice = TestDevice;
    this.TestOptions = TestOptions;
    this.User = User;
    this.Inventory = Inventory;
    this.InventoryBrand = InventoryBrand;
    this.InventoryCategory = InventoryCategory;
    this.WorkOrder = WorkOrder;
    this.Sample = Sample;
    this.Store = Store;
    this.Menu = Menu;
    this.TestLab = TestLab;
    this.Notification = Notification;
    this.Announcement = Announcement;
    this.Dashboard = Dashboard;
    this.ReferanceStandart = ReferanceStandart;
    this.TrendAnalytics = TrendAnalytics;
  }
}

export default new DataService();
