// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Store {
  constructor() {}

  /**
   * Depo Ekleme
   * @param {string} storeRegionId - Bölge Id
   * @param {string} storeLabId - Lab Id
   * @param {string} storeCode - Depo Kodu
   * @param {string} storeName - Depo Adı
   * @param {string} storeNameEn - Depo Adı İngilizcce
   * @param {number} storeContainer
   * @param {Array} storeContainerShelf
   */
  Create = ({
    storeRegionId,
    storeLabId,
    storeCode,
    storeName,
    storeNameEn,
    storeContainer,
    storeContainerShelf,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          region_id: storeRegionId,
          lab_id: storeLabId,
          code: storeCode,
          name: storeName,
          name_en: storeNameEn,
          container: storeContainer,
          container_shelf: storeContainerShelf,
        };
        const response = await ApiService(
          "post",
          Endpoint.Store.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Depo Düzenleme
   * @param {string} storeId - Depo Id
   * @param {string} storeRegionId - Bölge Id
   * @param {string} storeLabId - Lab Id
   * @param {string} storeCode - Depo Kodu
   * @param {string} storeName - Depo Adı
   * @param {string} storeNameEn - Depo Adı İngilizcce
   * @param {number} storeContainer
   * @param {Array} storeContainerShelf
   */
  Update = ({
    storeId,
    storeRegionId,
    storeLabId,
    storeCode,
    storeName,
    storeNameEn,
    storeContainer,
    storeContainerShelf,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          store_id: storeId,
          region_id: storeRegionId,
          lab_id: storeLabId,
          code: storeCode,
          name: storeName,
          name_en: storeNameEn,
          container: storeContainer,
          container_shelf: storeContainerShelf,
        };
        const response = await ApiService(
          "put",
          Endpoint.Store.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Depo Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit = 0, page = 0,startDate=0,endDate=0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Store.getAll}/${limit}/${page}/${startDate}/${endDate}`;
        const response = await ApiService("get", endpoints);
      
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Depo Detayı Alma
   * @param {string} storeId
   */
  Select = (storeId) => {
    return new Promise(async (resolve, reject) => {
      try {
   
        const endpoints = `${Endpoint.Store.getOne}/${storeId}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        console.log('error',error);
        reject(error);
      }
    });
  };
  /**
   * Depo Part Listeleme
   * @param {string} storeId
   * @param {string} storePartId
   */
  Part = (storeId=0,storePartId=0) => {
    return new Promise(async (resolve, reject) => {
      try {
       
     
        const endpoints = `${Endpoint.Store.part}/${storeId}/${storePartId}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data:[]
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data:response.reply
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new Store();
