import React, { useState, useEffect } from "react";

import Table from "../../../Components/Table";

import { Card, Tag, Select, Button, Spin } from "antd";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import PageHeader from "../../../Components/UI/PageHeader";

import DateService from "../../../Provider/Service/DataService";

import Helper from "../../../Helper";

const { Option } = Select;

const TestLabs = () => {
  const [data, setData] = useState([]);
  const [selectProductId, setSelectProductId] = useState(null);
  const [productData, setProductData] = useState([]);
  const [labsData, setLabsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const tableColumns = [
    {
      title: "Test Adı",
      dataIndex: "testName",
      key: "testName",
      sorter: (a, b) => a?.testName.localeCompare(b?.testName), // string sıralama
      ...Helper.getColumnSearchProps(
        "testName",
        handleSearch,
        handleReset,
        "Test Adı "
      ),
    },
    {
      title: "Test Adı EN",
      dataIndex: "testNameEn",
      key: "testNameEn",
      sorter: (a, b) => a?.testNameEn.localeCompare(b?.testNameEn), // string sıralama
      ...Helper.getColumnSearchProps(
        "testNameEn",
        handleSearch,
        handleReset,
        "Test Adı EN"
      ),
    },
    {
      title: "Test Birimi",
      dataIndex: "testUnitName",
      key: "testUnitName",
      sorter: (a, b) => a?.testUnitName.localeCompare(b?.testUnitName), // string sıralama
      ...Helper.getColumnSearchProps(
        "testUnitName",
        handleSearch,
        handleReset,
        "Test Birimi"
      ),
    },
    {
      title: "Test Metodu",
      dataIndex: "testMethodName",
      key: "testMethodName",
      sorter: (a, b) => a?.testMethodName.localeCompare(b?.testMethodName), // string sıralama
      ...Helper.getColumnSearchProps(
        "testMethodName",
        handleSearch,
        handleReset,
        "Test Metodu"
      ),
    },
    {
      title: "İş Kolu",
      dataIndex: "sectorName",
      key: "sectorName",
      sorter: (a, b) => a?.testMethodName.localeCompare(b?.sectorName), // string sıralama
      ...Helper.getColumnSearchProps(
        "sectorName",
        handleSearch,
        handleReset,
        "İş Kolu"
      ),
    },
    {
      title: "Laboratuvar",
      dataIndex: "testLab",
      key: "testLab",
      render: (labs) => (
        <>
          {labs?.map((lab, index) => {
            const itemData = _.find(labsData, { laboratory_id: lab.lab_id });

            const color = itemData ? "green" : "red";
            const icon =
              lab?.accredited === 1 ? (
                <CheckCircleOutlined style={{ color: "#0b8235" }} />
              ) : (
                <CloseCircleOutlined style={{ color: "#f00" }} />
              );

            return (
              <Tag color={color} icon={icon} key={index} className="custom-tag">
                {itemData?.code || "N/A"}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  useEffect(() => {
    const selectAll = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const selectProducts = await DateService.Product.SelectAll();
          const selectLabs = await DateService.Laboratory.SelectAll();
          if (selectProducts.status && selectLabs.status) {
            resolve({
              status: true,
              productData: selectProducts?.response?.data || [],
              labsData: selectLabs?.response?.data || [],
            });
          } else {
            resolve({
              status: false,
              productData: !selectProducts.status && selectProducts.message,
              labsData: !selectLabs.status && selectLabs.message,
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    };
    selectAll()
      .then((result) => {
        if (result.status) {
          setProductData(result.productData);
          setLabsData(result.labsData);
          setLoading(false);
        } else {
          if (result.productData) Helper.ErrorMessage(result.productData);
          if (result.labsData) Helper.ErrorMessage(result.labsData);
        }
      })
      .catch((error) => {
        console.log("didmount-error", error);
        Helper.ErrorMessage(error.toString());
      });
    // DateService.Product.SelectAll()
    //     .then((result) => {
    //         console.log("product-then", result);

    //         if (result.status) {
    //             setProductData(result?.response?.data || []);
    //             setLoading(false);
    //         } else {
    //             Helper.ErrorMessage(result.message);
    //         }
    //     })
    //     .catch((error) => {
    //         console.log("product-error", error);
    //         Helper.ErrorMessage(error.toString());
    //     });

    return () => {};
  }, []);

  const onChange = (value) => {
    console.log("onChange", value);

    setSelectProductId(value);
  };

  const onSearch = () => {
    console.log("onSearch");
  };

  const getData = () => {
    if (!selectProductId) {
      Helper.ErrorMessage("lütfen bir ürün seçin");
      return;
    }
    setLoading(true);
    DateService.Test.testLabs(selectProductId)
      .then((result) => {
        setLoading(false);
        console.log("test-labs-then", result);
        if (result.status) {
          setData(result.data);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("test-labs-error", error);
        Helper.ErrorMessage(error.toString());
      });
  };

  return (
    <div>
      <div className="pageHeaderWrapper">
        <PageHeader title="Test & Laboratuvar" subTitle="" />
      </div>

      <Spin spinning={loading}>
        <Card bordered={false} className="cardTable">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "16px",
            }}
          >
            <Select
              style={{ width: 300, marginRight: 16 }}
              showSearch
              placeholder="Seçim yap"
              optionFilterProp="children"
              onChange={onChange}
              // onFocus={onFocus}
              // onBlur={onBlur}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {productData?.map((item, index) => {
                return (
                  <Option key={index} value={item.product_id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>

            <Button type="primary" onClick={getData}>
              Getir
            </Button>
          </div>

          <Table columns={tableColumns} dataSource={data} pagination />
        </Card>
      </Spin>
    </div>
  );
};

export default TestLabs;
