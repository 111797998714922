import React, { useState, useEffect } from "react";

import { Card, Spin, Space, Button, Modal } from "antd";

import PageHeader from "../../../Components/UI/PageHeader";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
import Table from "../../../Components/Table";
import Export from "../../../Components/Export/Export";
import Details from "./details";
const PersonelPerformance = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [selectLab, setSelectLab] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectItem, setSelectItem] = useState(null);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const detailsRow = ({ sample_id }) => {
    setSelectItem(sample_id);
    setVisibleDetails(true);
  };
  const closeDetailsRow = () => {
    setSelectItem(null);
    setVisibleDetails(false);
  };
  const tableColumns = [
    {
      title: "Rapor No",
      dataIndex: "report_no",
      key: "report_no",

      sorter: (a, b) =>
        String(a?.report_no).localeCompare(String(b?.report_no)), // string sıralama
      ...Helper.getColumnSearchProps(
        "report_no",
        handleSearch,
        handleReset,

        "Rapor No"
      ),
    },
    {
      title: "ATF No",
      dataIndex: "atf_no",
      key: "atf_no",

      sorter: (a, b) => String(a?.atf_no).localeCompare(String(b?.atf_no)), // string sıralama
      ...Helper.getColumnSearchProps(
        "atf_no",
        handleSearch,
        handleReset,

        "ATF No"
      ),
    },
    {
      title: "Lab Code",
      dataIndex: "lab_code",
      key: "lab_code",

      sorter: (a, b) => String(a?.lab_code).localeCompare(String(b?.lab_code)), // string sıralama
      ...Helper.getColumnSearchProps(
        "lab_code",
        handleSearch,
        handleReset,

        "Lab Code"
      ),
    },
    {
      title: "Kayıt No",
      dataIndex: "registry_no",
      key: "registry_no",

      sorter: (a, b) =>
        String(a?.registry_no).localeCompare(String(b?.registry_no)), // string sıralama
      ...Helper.getColumnSearchProps(
        "registry_no",
        handleSearch,
        handleReset,

        "Kayıt No"
      ),
    },

    {
      title: "Aciliyet",
      dataIndex: "work_order_urgency",
      key: "work_order_urgency",

      sorter: (a, b) =>
        String(a?.work_order_urgency).localeCompare(
          String(b?.work_order_urgency)
        ), // string sıralama
      ...Helper.getColumnSearchProps(
        "work_order_urgency",
        handleSearch,
        handleReset,

        "Aciliyet"
      ),
    },
    {
      title: "Tipi",
      dataIndex: "work_order_type_name",
      key: "work_order_type_name",

      sorter: (a, b) =>
        String(a?.work_order_type_name).localeCompare(
          String(b?.work_order_type_name)
        ), // string sıralama
      ...Helper.getColumnSearchProps(
        "work_order_type_name",
        handleSearch,
        handleReset,

        "Tipi"
      ),
    },
    {
      title: "İş Kolu",
      dataIndex: "sector_name",
      key: "sector_name",

      sorter: (a, b) =>
        String(a?.sector_name).localeCompare(String(b?.sector_name)), // string sıralama
      ...Helper.getColumnSearchProps(
        "sector_name",
        handleSearch,
        handleReset,

        "İş Kolu"
      ),
    },
    {
      title: "Revize",
      dataIndex: "revised",
      key: "revised",

      sorter: (a, b) => String(a?.revised).localeCompare(String(b?.revised)), // string sıralama
      ...Helper.getColumnSearchProps(
        "revised",
        handleSearch,
        handleReset,

        "Revize"
      ),
    },
    {
      title: "Toplam Revize",
      dataIndex: "totalRevised",
      key: "totalRevised",

      sorter: (a, b) => String(a?.totalRevised).localeCompare(String(b?.totalRevised)), // string sıralama
      ...Helper.getColumnSearchProps(
        "totalRevised",
        handleSearch,
        handleReset,

        "totalRevised"
      ),
    },
    {
      title: "Başlangıç Tarihi",
      dataIndex: "date_start",
      key: "date_start",

      sorter: (a, b) =>
        String(a?.date_start).localeCompare(String(b?.date_start)), // string sıralama
      ...Helper.getColumnSearchProps(
        "date_start",
        handleSearch,
        handleReset,

        "Başlangıç Tarihi"
      ),
    },
    {
      title: "Bitiş Tarihi",
      dataIndex: "date_finish",
      key: "date_finish",

      sorter: (a, b) =>
        String(a?.date_finish).localeCompare(String(b?.date_finish)), // string sıralama
      ...Helper.getColumnSearchProps(
        "date_finish",
        handleSearch,
        handleReset,

        "Bitiş Tarihi"
      ),
    },
    {
      title: "Geçen Süre",
      dataIndex: "finishTime",
      key: "finishTime",

      sorter: (a, b) =>
        String(a?.finishTime).localeCompare(String(b?.finishTime)), // string sıralama
      ...Helper.getColumnSearchProps(
        "finishTime",
        handleSearch,
        handleReset,

        "Geçen Süre"
      ),
    },
    {
      title: "Lab Name",
      dataIndex: "lab_name",
      key: "lab_name",

      sorter: (a, b) => String(a?.lab_name).localeCompare(String(b?.lab_name)), // string sıralama
      ...Helper.getColumnSearchProps(
        "lab_name",
        handleSearch,
        handleReset,

        "Lab Name"
      ),
    },

    {
      title: "userReportName",
      dataIndex: "userReportName",
      key: "userReportName",

      sorter: (a, b) =>
        String(a?.userReportName).localeCompare(String(b?.userReportName)), // string sıralama
      ...Helper.getColumnSearchProps(
        "userReportName",
        handleSearch,
        handleReset,

        "userReportName"
      ),  
    },
    {
      title: "userCompletedName",
      dataIndex: "userCompletedName",
      key: "userCompletedName",

      sorter: (a, b) =>
        String(a?.userCompletedName).localeCompare(
          String(b?.userCompletedName)
        ), // string sıralama
      ...Helper.getColumnSearchProps(
        "userCompletedName",
        handleSearch,
        handleReset,

        "userCompletedName"
      ),
    },
    {
      title: "Seçenekler",
      dataIndex: "options",
      key: "options",

      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              shape="round"
              type="primary"
              onClick={() => detailsRow(record)}
            >
              Detay
            </Button>
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    getData();
    return () => {};
  }, []);
  const getData = async () => {
    try {
      const result = await DataService.Dashboard.personelPerformanceList({
        page: 0,
        limit: 0,
        start_date: 0,
        finish_date: 0,
      });

      if (result?.status) {
        setData(result?.data || []);

        setLoading(false);
      } else {
        if (!result?.status)
          Helper.ErrorMessage(
            result?.message || "Personel Performans Listesi Alınamadı"
          );
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };

  return (
    <div>
      <div className="pageHeaderWrapper">
        <PageHeader title="Personel Performans İzleme" />
        <div className="pageHeaderOptions">
          <Space>
            <Export
              data={data || []}
              columns={[...tableColumns]}
              tableName={"personelreport"}
              dates={["tarih"]}
            />
          </Space>
        </div>
      </div>
      <Spin spinning={loading}>
        <Card bordered={false} className="cardTable">
          {!!data && (
            <Table columns={tableColumns} dataSource={data} pagination />
          )}
        </Card>
      </Spin>
      <Modal
        title="Personel Performans Detay"
        visible={visibleDetails}
        onCancel={closeDetailsRow}
        footer=""
        width={'80%'}
      >
        {!!visibleDetails && <Details sampleId={selectItem} />}
      </Modal>
    </div>
  );
};

export default PersonelPerformance;
