import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import './print.css'

import { Button, Space } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import PrinterComponent from "./index";

const Example = ({ details, tableData }) => {
  const refComponent = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => refComponent.current,
  });

  return (
    <>
      <div className="formButton" style={{ marginTop: "0", marginBottom: "16" }}>
        <Space size={15}>
          <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint}>
            Yazdır
          </Button>
        </Space>
      </div>

      <PrinterComponent
        ref={refComponent}
        details={details}
        tableData={tableData}
      />
    </>
  );
};

export default Example;
