import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Space,
  Spin,
  Select,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import _ from "lodash";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
const { Option } = Select;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      customer: [],
      region: [],
      id: null,

      tableColumns: [
        {
          title: "Adı Soyadı",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Adı Soyadı"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Firma",
          dataIndex: "customerName",
          key: "customerName",
          ...Helper.getColumnSearchProps(
            "customerName",
            this.handleSearch,
            this.handleReset,
            "Firma"
          ),
          sorter: (a, b) => a.customerName.localeCompare(b.customerName), // string sıralama
        },
        {
          title: "Bölge",
          dataIndex: "regionName",
          key: "regionName",
          ...Helper.getColumnSearchProps(
            "regionName",
            this.handleSearch,
            this.handleReset,
            "Bölge"
          ),
          sorter: (a, b) => a.regionName.localeCompare(b.regionName), // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responsePerson = await DataService.Sample.SelectPerson();
      const responseCustomer = await DataService.Customer.SelectAll();
      const responseRegion = await DataService.Region.SelectAll();

      if (
        responsePerson.status &&
        responseCustomer.status &&
        responseRegion.status
      ) {
        const newArray = [],
          data = responsePerson?.data || [],
          customerData = responseCustomer?.response?.data || [],
          regionData = responseRegion?.response?.data || [];
        for (const key in data) {
          if (data.hasOwnProperty.call(data, key)) {
            const element = data[key];
            newArray.push({
              ...element,
              customerName:
                _.find(customerData, { customer_id: element.customer_id })
                  ?.name || "N/A",
              regionName:
                _.find(regionData, { region_id: element.region_id })?.name ||
                "N/A",
            });
          }
        }
        const newCustomer = customerData.concat({
          active: 1,
          address: "",
          city_id: "0",
          customer_id: "0",
          district_id: "0",
          iban: "",
          name: "N/A",
          phone: "",
          phone_gsm: "",
          tax_administration: "",
          tax_no: "",
          title: "",
          topCustomerName: null,
          top_customer_id: null,
        });
        const newRegion = regionData.concat({
          active: 0,
          city_id: 0,
          district_id: 0,
          name: "N/A",
          name_en: "N/A",
          region_id: "0",
        });
        this.setState({
          data: newArray,
          customer: newCustomer,
          region: newRegion,
          loading: false,
        });
      } else {
        Helper.warningMessage(responsePerson.message);
      }
    } catch (error) {
      console.log("Numuneyi Alan veya Teslim Eden", JSON.stringify(error));
    }
  };

  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _deleteClick = ({ id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Teslim Alan-Eden bilgisini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(id);
      }
    });
  };
  deleted = (id) => {
    this.setState({ loading: true });
    DataService.Sample.PersonDelete(id)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  _editClick = ({ customer_id, id, name, region_id }) => {
    this.setState({ visible: true, id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          customerId: customer_id,
          regionId: region_id,
          nameSurname: name,
        });
      }, 100);
    });
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      id: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ nameSurname, customerId, regionId }) => {
    try {
      const { id } = this.state;
      this.setState({ btnloading: true });
      let result;
      if(id){
        result = await DataService.Sample.PersonUpdate({
          id,
          nameSurname,
          customerId,
          regionId,
        });
      }else{
        result = await DataService.Sample.PersonCreate({
     
          nameSurname,
          customerId,
          regionId,
        });
      }
  

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            id: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false, visible: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Teslim Alan-Eden Bilgileri" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
            <Button
            shape="round"
            className="btn-add"
            icon={<PlusOutlined />}
            onClick={this._showModal}
          >
            Yeni Ekle
          </Button>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"TestType"}
                dates={["tarih"]}
              />
            </Space>
          </div>

          <Modal
            title="Numuneyi Teslim Alan veya Teslim Eden"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="nameSurname"
                label="Adı Soyadı"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="customerId" label="Firma Adı" rules={[]}>
                <Select
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.customer.map((item, index) => {
                    return (
                      <Option key={index} value={item.customer_id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="regionId" label="Bölge Adı" rules={[]}>
                <Select
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.region.map((item, index) => {
                    return (
                      <Option key={index} value={item.region_id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                <PlusOutlined /> Kaydet
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
