import React, { useEffect, useState } from "react";

import { Card, Button, Space, Spin, Result } from "antd";
import { NavLink } from "react-router-dom";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import Table from "../../../Components/UI/Table";

const WorkOrders = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const tableColumns = [
    {
      title: "Rapor No",
      dataIndex: "report_no",
      key: "report_no",
    },
    {
      title: "ATF No",
      dataIndex: "atf_no",
      key: "atf_no",
    },
    {
      title: "LAB",
      dataIndex: "lab_code",
      key: "lab_code",
    },
    {
      title: "Müşteri",
      dataIndex: "customer_1_name",
      key: "customer_1_name",
    },
    {
      title: "Ürün",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Durumu",
      dataIndex: "statusName",
      key: "statusName",
    },
    {
      title: "Seçenekler",
      dataIndex: "options",
      key: "options",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="primary"
            onClick={() => followWorkOrder(record)}
          >
            Takibi Bırak
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    DataService.Dashboard.FollowWorkOrderList()
      .then((result) => {
        if (result.status) {
          setData(result.response.data);
          setLoading(false);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  };
  const followWorkOrder = ({ workOrderId }) => {
    setLoading(true);
    DataService.WorkOrder.Follow(workOrderId)
      .then((result) => {
        setLoading(false);

        if (result.status) {
          Helper.SuccessMessage(result.message);
          getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  return (
    <Spin spinning={loading}>
      <Card
        bordered={false}
        className="cardTable"
        title="Takip Edilen İş Emirleri"
        extra={<a href="/sample/work-orders">Tümünü Gör</a>}
      >
        {data?.length > 0 ? (
          <Table columns={tableColumns} data={data} />
        ) : (
          <Result title="Kayıt bulunamadı." />
        )}
      </Card>
    </Spin>
  );
};

export default WorkOrders;
