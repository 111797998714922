import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import { Card, Modal, Button, Tooltip, Form, Input, Space,Spin } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";

class index extends Component {
  constructor(props){
    super(props)
    this.  state = {
      visible: false,
      loading:true,
      btnloading:false,
      data: [],
      testUnitId: null,
  
      tableColumns: [
        {
          title: "Test Birimi Adı TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Test Birimi Adı TR"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
        {
          title: "Test Birimi Adı EN",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            "Test Birimi Adı EN"
          ),
          sorter:(a,b) => a.name_en.localeCompare(b.name_en) // string sıralama
        },
        {
          title: "Test Birimi Icon",
          dataIndex: "icon",
          key: "icon",
          ...Helper.getColumnSearchProps(
            "icon",
            this.handleSearch,
            this.handleReset,
            "Test Birimi Icon"
          ),
          sorter:(a,b) => a.icon.localeCompare(b.icon) // string sıralama
        },
        {
          
            title: "Seçenekler",
            fixed: 'right',
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record)
        }
      ]
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.TestUnit.SelectAll(4000, 1)
      .then(result => {
        if (result.status) {
          const { data } = result.response;
          let testunits = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              testunits.push({
                ...element,
                id: key
              });
            }
          }
          this.setState({
            data: testunits,
            loading:false
          });
        }
      })
      .catch(error => {
        console.log("Test Unit", JSON.stringify(error));
      });
  };
  _editClick = ({ test_unit_id, name, name_en, icon }) => {
    this.setState({ visible: true, testUnitId: test_unit_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          testUnitNameTr: name,
          testUnitNameEn: name_en,
          testUnitIcon: icon
        });
      }, 100);
    });
  };
  _deleteClick = ({ test_unit_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Test Birimini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır"
    }).then(result => {
      if (result.value) {
        this.deleted(test_unit_id);
      }
    });
  };
  deleted = testUnitId => {
    this.setState({loading:true})
    DataService.TestUnit.Delete(testUnitId)
      .then(result => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch(error => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button type="link" icon={<DeleteOutlined />} danger onClick={() => this._deleteClick(record)}>
        </Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true
    });
  };
  _closeModal = e => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      testUnitId: null,
      btnloading:false
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ testUnitNameTr, testUnitNameEn, testUnitIcon }) => {
    try {
      const { testUnitId } = this.state;
      this.setState({btnloading:true})
      let result;
      if (testUnitId) {
        result = await DataService.TestUnit.Update({
          testUnitNameTr,
          testUnitNameEn,
          testUnitIcon,
          testUnitId
        });
      } else {
        result = await DataService.TestUnit.Create({
          testUnitNameTr,
          testUnitNameEn,
          testUnitIcon
        });
      }
      this.setState({btnloading:false})
      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, testUnitId: null,btnloading:false },
          this.getData
        );
      } else {
     
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Birimi" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"TestUnit"}
                dates={["tarih"]}
              />
              
              <Tooltip title="Yeni Test Birimi Ekle" placement="left">
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                />
              </Tooltip>
            </Space>
          </div>

          <Modal
            title="Yeni Test Birimi Ekle"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="testUnitNameTr"
                label="Test Birimi Adı TR"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="testUnitNameEn"
                label="Test Birimi Adı EN"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="testUnitIcon"
                label="Test Birimi Icon"
                rules={[
                  { required: false, message: "Lütfen bu alanı boş bırakmayın" }
                ]}
              >
                <Input />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.btnloading} >
                {this.state.testUnitId ? (
                  <>
                    {" "}
                    <PlusOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <EditOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
            </Form>
          </Modal>
        </div>

     <Spin spinning={this.state.loading} > 
     <Card bordered={false} className="cardTable">
          <Tables
            columns={this.state.tableColumns}
            onChange={this._tableHandleChange}
            dataSource={this.state.data}
            pagination={{
              defaultPageSize: 1
            }}
            onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
          />
        </Card>
     </Spin>
      </div>
    );
  }
}

export default index;
