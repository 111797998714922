import React, {Component} from "react";
import "../index.css";
import {NavLink} from "react-router-dom";

import PageHeader from "../../../../Components/UI/PageHeader";
import {Button, Card, Space, Spin, Steps} from "antd";

import _ from "lodash";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
import Registration from "./Registration";
import Sample from "./Sample";
import Analysis from "./Analysis";
import Confirmation from "./Confirmation";
import Result from "./Result";

const {Step} = Steps;

// img upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const toBase64 = (url) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        )
        .catch((err) => console.log("fetcherr", err));

class index extends Component {
    state = {
        stepCurrent: null,
        loading: true,
        customerData: [],
        sectorData: [],
        operationData: [],
        testData: [],
        testpackData: [],
        testsData: [],
        testspackData: [],
        labData: [],
        locationData: [],
        partData: [],
        workOrderData: [],
        storeData: [],
        personCustomerData: [],
        personData: [],
        userData: [],
        methodData: [],
        productData: [],
        formData: {
            WorkOrderSectorId: null,
            WorkOrderProductId: null,
            SampleStock: [],
        },
        formTextData: {},
        result: false,
        selectedWorkBranch: {}
    };

    componentDidMount() {
        this.getData();
    }

    sectorChangeSet = async (sectorId = 0) => {
        try {
            const responseGetPart = await DataService.Sample.getPart(sectorId);
            this.setState({workOrderData: responseGetPart?.data || []});
            const responseGetPartCat = await DataService.Sample.getPartCat(sectorId);
            this.setState({partData: responseGetPartCat?.data || []});

            let type = 1;
            if (sectorId === '31479c2a2efc8bd273259ea67f4f5fdc') {
                type = 0;
            }
            const responseMethod = await DataService.Sample.methodSelect(type);
            this.setState({methodData: responseMethod?.data || []});


        } catch (error) {
            Helper.ErrorMessage(error);
        }

        console.log('sectorId', sectorId);
    }
    getData = async () => {
        console.log('propsss', this.props);
        try {
            const responseCustomers = await DataService.Customer.SelectAll();
            const responseSector = await DataService.Sector.SelectAll();
            const responseOperation = await DataService.Operation.SelectAll();
            const responseTests = await DataService.Test.SelectAll();
            const responseTestPacks = await DataService.TestPack.SelectAll();
            const responseLabs = await DataService.Laboratory.SelectAll();
            const responseGetLocation = await DataService.Sample.getLocation();
            //const responseGetPart = await DataService.Sample.getPart();
            //const responseGetPartCat = await DataService.Sample.getPartCat();

            const responseUser = await DataService.User.SelectAll(
                "f54a19e2d81c5bd09024a31cffe295b0"
            );
            const responseStore = await DataService.Store.SelectAll();
            //const responseMethod = await DataService.Sample.methodSelect();
            const responsePerson = await DataService.Sample.SelectPerson();
            const responsePersonCustomer = await DataService.Sample.SelectCustomer();
            const responseProduct = await DataService.Product.SelectAll();
            if (
                responseCustomers.status &&
                responseSector.status &&
                responseOperation.status &&
                responseTests.status &&
                responseTestPacks.status &&
                responseLabs.status &&
                responseUser.status &&
                responseStore.status &&
                //responseMethod.status &&
                responsePerson.status &&
                responsePersonCustomer.status &&
                //responseGetPartCat.status &&
                responseProduct.status
            ) {
                this.setState({
                    customerData: responseCustomers?.response?.data || [],
                    sectorData: responseSector?.response?.data || [],
                    operationData: responseOperation?.response?.data || [],
                    testData: responseTests?.response?.data || [],
                    testpackData: responseTestPacks?.response?.data || [],
                    testsData: responseTests?.response?.data || [],
                    testspackData: responseTestPacks?.response?.data || [],
                    labData: responseLabs?.response?.data || [],
                    locationData: responseGetLocation?.data || [],
                    //partData: responseGetPartCat?.data || [],
                    //workOrderData: responseGetPart?.data || [],
                    storeData: responseStore?.response?.data || [],
                    personCustomerData: responsePersonCustomer?.data || [],
                    personData: responsePerson?.data || [],
                    userData: responseUser?.data || [],
                    //methodData: responseMethod?.data || [],
                    productData: responseProduct?.response?.data || [],

                    loading: false,
                    stepCurrent: 0,
                });
            } else {
                if (!responseCustomers.status)
                    Helper.ErrorMessage(responseCustomers.message);
                if (!responseSector.status) Helper.ErrorMessage(responseSector.message);
                if (!responseStore.status) Helper.ErrorMessage(responseStore.message);
                if (!responseTests.status) Helper.ErrorMessage(responseTests.message);
                if (!responseLabs.status) Helper.ErrorMessage(responseLabs.message);
                if (!responseUser.status) Helper.ErrorMessage(responseUser.message);
                if (!responseGetLocation.status)
                    Helper.ErrorMessage(responseGetLocation.message);
                // if (!responseGetPart.status)
                //    Helper.ErrorMessage(responseGetPart.message);

                if (!responseTestPacks.status)
                    Helper.ErrorMessage(responseTestPacks.message);
                if (!responseOperation.status)
                    Helper.ErrorMessage(responseOperation.message);
            }
        } catch (error) {
            Helper.ErrorMessage(error);
        }
    };

    next = ({formData, formTextData, selectedWorkBranch}) => {
        this.setState({
            stepCurrent: this.state.stepCurrent + 1,
            formData: {
                ...this.state.formData,
                ...formData,
            },
            formTextData: {
                ...this.state.formTextData,
                ...formTextData,
            },
            selectedWorkBranch: selectedWorkBranch
        });
    };
    previous = (formData, selectedWorkBranch) => {
        this.setState({
            stepCurrent: this.state.stepCurrent - 1,
            formData: {
                ...this.state.formData,
                ...formData,
            },
            selectedWorkBranch: selectedWorkBranch
        });
    };
    onFinish = async () => {
        this.setState({loading: true});
        let images = [];
        let SampleTestData = [];
        let SampleTestCount = this.state?.formData?.SampleTestCount || 0;
        for (const key in this.state.formData.images) {
            if (this.state.formData.images.hasOwnProperty(key)) {
                const element = this.state.formData.images[key];

                try {
                    let image;
                    if (element.url) {
                        image = await toBase64(element.url);
                        image = new Blob([image], {type: "image/jpeg"});
                    } else if (element.originFileObj) {
                        image = await getBase64(element.originFileObj);
                        image = new Blob([image], {type: element.originFileObj.type});
                    } else {
                        image = new Blob([element.url], {type: "image/jpeg"});
                    }

                    images.push({
                        image,
                        name: element.name,
                    });
                } catch (error) {
                    console.log("fileList-keys", error);
                }
            }
        }
        if (typeof this.state.formData.SampleTests == "object") {
            for (const key in this.state.formData.SampleTests) {
                if (this.state.formData.SampleTests.hasOwnProperty(key)) {
                    const element = this.state.formData.SampleTests[key];
                    const test = _.find(this.state.testData, {test_id: element});
                    const testpack = _.find(this.state.testpackData, {
                        pack_id: element,
                    });
                    if (test)
                        SampleTestData.push({
                            type: "test",
                            sample_test_id: element,
                            parent_test_id: test.parent_test_id,
                        });
                    if (testpack)
                        SampleTestData.push({
                            type: "test_pack",
                            sample_test_id: element,
                            parent_test_id: "0",
                        });
                }
            }
        } else {
            const test = _.find(this.state.testData, {
                test_id: this.state.formData.SampleTests,
            });
            SampleTestData.push({
                type: "test",
                sample_test_id: this.state.formData.SampleTests,
                parent_test_id: test?.parent_test_id,
            });
        }

        const requestParams = {
            ...this.state.formData,
            SampleId: this.state.SampleId,
            SampleTests: SampleTestData,
            SampleTestCount,
            images,
        };

        DataService.Sample.Create(requestParams)
            .then((result) => {
                if (result.status) {
                    Helper.SuccessMessage(result.message);

                    this.setState({
                        result: result.data,
                        btnloading: false,
                        loading: false,
                    });
                } else {
                    Helper.ErrorMessage(result.message);
                    this.setState({
                        btnloading: false,
                        loading: false,
                    });
                }
            })
            .catch((err) => Helper.ErrorMessage(err));
    };
    stepContent = () => {
        const {
            stepCurrent,
            customerData,
            sectorData,
            productData,
            operationData,
            workOrderData,
            locationData,
            partData,
            personData,
            personCustomerData,
            methodData,
            labData,
            testData,
            testpackData,
            formData,
            formTextData,
            storeData,
            selectedWorkBranch,
        } = this.state;

        switch (stepCurrent) {
            case 0:
                return (
                    <Registration
                        next={this.next}
                        data={{
                            customer: customerData,
                            sector: sectorData,
                            product: productData,
                            operation: operationData,
                            workorder: workOrderData,
                            selectedWorkBranch: selectedWorkBranch,
                        }}
                        sectorChange={this.sectorChangeSet}
                        initialValue={formData}
                    />
                );
            case 1:
                return (
                    <Sample
                        next={this.next}
                        previous={this.previous}
                        data={{
                            workorder: workOrderData,
                            location: locationData,
                            part: partData,
                            person: personData,
                            personCustomer: personCustomerData,
                            method: methodData,
                            selectedWorkBranch: selectedWorkBranch,
                            labData,
                            storeData,
                        }}
                        stockData={this.state.formData.SampleStock}
                        initialValue={formData}
                    />
                );
            case 2:
                return (
                    <Analysis
                        next={this.next}
                        previous={this.previous}
                        data={{
                            test: testData,
                            testPack: testpackData,
                            selectedWorkBranch: selectedWorkBranch,
                        }}
                        initialValue={formData}
                    />
                );
            case 3:
                return (
                    <Confirmation
                        previous={this.previous}
                        onFinish={this.onFinish}
                        formTextData={formTextData}
                        data={
                            {
                                ...formData,
                                selectedWorkBranch: selectedWorkBranch,
                            }
                        }
                    />
                );

            default:
                return <div/>;
        }
    };

    render() {
        const {stepCurrent, formData, formTextData} = this.state;

        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Numune Kayıt"/>

                    <Space>
                        <NavLink
                            activeClassName="nav-active"
                            exact
                            to="/sample/sample-registration"
                        >
                            <Button className="btn-list" shape="round">
                                Numune Listesi
                            </Button>
                        </NavLink>
                    </Space>
                </div>
                <Spin spinning={this.state.loading} delay={500}>
                    <Card bordered={false} className="cardTable">
                        {!this.state.result ? (
                            <>
                                <div className="antStepsContainer">
                                    <Steps current={stepCurrent}>
                                        <Step title="Kayıt Bilgileri"/>
                                        <Step title="Numune Bilgileri"/>
                                        <Step title="Analiz Bilgileri"/>
                                        <Step title="Onayla"/>
                                    </Steps>
                                </div>

                                <div className="antStepsContent">{this.stepContent()}</div>
                            </>
                        ) : (
                            <Result
                                data={this.state.result.sample.sample_stock}
                                formData={{
                                    form: formData,
                                    text: formTextData,
                                }}
                            />
                        )}
                    </Card>
                </Spin>
            </div>
        );
    }
}

export default index;
