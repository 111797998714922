import React, {Component} from "react";
import "./index.css";

import {Col, Form, Input, InputNumber, Radio, Row, Select} from "antd";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";

const {Option} = Select;
const dates = [
    {
        label: "3 Ay",
        value: "3m",
    },
    {
        label: "4 Ay",
        value: "4m",
    },
    {
        label: "6 Ay",
        value: "6m",
    },
    {
        label: "1 Yıl",
        value: "1y",
    },
    {
        label: "5 Yıl",
        value: "5y",
    },
    {
        label: "Süresiz",
        value: "end",
    },
];

class SampleAmountItem extends Component {
    state = {
        type_id: 1,
        amount: 0,
        amount_type: "Lt",
        shelfData: [],
        signet_code: "",
        containerData: [],
        stock_id: "",
        bottle: 0,
        initialValues: {}
    };

    componentDidMount() {
        //this.setState({type_id: (this.props?.data?.type_id ?? 1)})
        //this.onChange('return', (this.props?.data?.type_id ?? 1));
        //console.log('type_id',(this.props?.data?.type_id ?? 1));
        let type_id = 1;
        console.log('WorkOrderTypeId', this.props.WorkOrderTypeId);
        if (this.props.WorkOrderTypeId === 4) {
            type_id = 4;
        } else {
            type_id = (this.props.data?.type_id ?? 1);
        }

        console.log('componentDidMount', type_id);

        if (this.props.hasOwnProperty('data')) {
            console.log("DATA", this.props.data);
            this.setState(
                {
                    ...this.state,
                    ...this.props.data,
                    initialValues: this.props.data,
                    //type_id: this.props.WorkOrderTypeId,
                    type_id: type_id,
                },
                () => {
                    this.onChange({
                        //target: {value: this.props.WorkOrderTypeId},
                        //target: {value: (this.props.data?.type_id ?? 1)},
                        target: {value: type_id},
                    });
                }
            );
            console.log("STATE DATA", this.props)
        } else {

            this.setState(
                {
                    //type_id: this.props.WorkOrderTypeId,
                    type_id: type_id,
                },
                () => this.onChange({target: {value: type_id}})
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.store_id != this.state.store_id) {
            this.getContainer();
        }
        if (prevState.endless_storage != this.state.endless_storage) {
            this.getShelf();
        }
        if (prevState.top_store_part_id != this.state.top_store_part_id) {
            this.getShelf();
        }
        /*
        if (prevProps.WorkOrderTypeId != this.props.WorkOrderTypeId) {
            this.setState({type_id: this.props.WorkOrderTypeId}, () =>
                this.onChange({target: {value: this.state.type_id}})
            );
        }
        if (prevState.initialValues != this.state.initialValues) {
            if (this.props.hasOwnProperty('data') && this.props.data) {
                this.setState({
                        ...this.state,
                        ...this.props.data,
                        initialValues: this.props.data,
                        type_id: this.props.WorkOrderTypeId,
                    }, () =>
                        this.onChange({target: {value: this.props.WorkOrderTypeId}})
                );
            }
        }

         */
    }

    getContainer = async () => {
        try {
            const {store_id} = this.state;
            const result = await DataService.Store.Part(store_id, 0);

            if (result.status) {
                this.setState({
                    containerData: result.data,
                    shelfData: [],
                    endless_storage: undefined,
                });
            } else {
                this.setState({
                    containerData: [],
                    shelfData: [],
                    endless_storage: undefined,
                });
                Helper.ErrorMessage(result.message);
            }
        } catch (error) {
            Helper.ErrorMessage(error);
        }
    };
    getShelf = async () => {
        try {
            const {store_id, endless_storage, top_store_part_id} = this.state;
            const result = await DataService.Store.Part(
                store_id,
                endless_storage || top_store_part_id
            );
            if (result.status) {
                this.setState({
                    shelfData: result.data,
                });
            } else {
                Helper.ErrorMessage(result.message);
            }
        } catch (error) {
            Helper.ErrorMessage(error);
        }
    };
    propsChange = () => {
        const {
            type_id,
            signet_code,
            amount,
            amount_type,
            lab_id,
            storage_time,
            endless_storage,
            store_id,
            store_part_id,
            stock_id,
            bottle,
        } = this.state;
        this.props.onChange({
            type_id,
            signet_code,
            amount,
            amount_type,
            lab_id: lab_id != null ? lab_id : undefined,
            storage_time,
            endless_storage,
            store_id,
            store_part_id,
            stock_id,
            bottle,
        });
    };
    onChange = async (e) => {
        const {value} = e.target;

        switch (value) {
            case 1:
                this.setState(
                    {
                        ...this.state,
                        type_id: value,
                        amount_type: "Lt",
                    },
                    this.propsChange
                );
                break;
            case 2:
                this.setState(
                    {
                        ...this.state,
                        type_id: value,
                        amount_type: "Lt",
                    },
                    this.propsChange
                );
                break;
            case 3:
                this.setState(
                    {
                        ...this.state,
                        type_id: value,
                        amount_type: "Lt",
                    },
                    this.propsChange
                );
                break;

            default:
                this.setState({type_id: value}, this.propsChange);
                break;
        }
    };
    onChangeItem = (item) => {
        this.setState(item, () => {
            const {
                type_id,
                signet_code,
                amount,
                amount_type,
                lab_id,
                lab_id_text,
                storage_time,
                storage_time_text,
                endless_storage,
                endless_storage_text,
                store_id,
                store_id_text,
                store_part_id,
                store_part_id_text,
                bottle,
            } = this.state;
            this.props.onChange({
                type_id,
                signet_code,
                amount,
                amount_type,
                lab_id,
                lab_id_text,
                storage_time,
                storage_time_text,
                endless_storage,
                endless_storage_text,
                store_id,
                store_id_text,
                store_part_id,
                store_part_id_text,
                bottle,
            });
        });
    };

    render() {
        const value = this.state.type_id;
        console.log('type_id', this.state.type_id);

        const selectAfter = (
            <Form.Item
                name={`${this.props.sampleAmount}-amount_type`}
                initialValue="Lt"
                style={{marginBottom: "10px", height: "20px"}}
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Select
                    size="small"
                    className="select-after"
                    onChange={(amount_type) => this.onChangeItem({amount_type})}
                    value={this.state.amount_type}
                >
                    <Option value="Kg">Kg</Option>
                    <Option value="Lt">Lt</Option>
                    <Option value="Adet">Adet</Option>
                </Select>
            </Form.Item>
        );
        return (
            <Col md={6}>
                <Row gutter={16}>
                    <Col md={16}>
                        <Form.Item
                            name={`${this.props.sampleAmount}-amount`}
                            label="Numune Miktarı"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                addonAfter={selectAfter}
                                onChange={(e) => this.onChangeItem({amount: e.target.value})}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item
                            name={`${this.props.sampleAmount}-bottle`}
                            label="Numune Şişesi"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber onChange={(e) => this.onChangeItem({bottle: e})}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name={`${this.props.sampleAmount}-signet_code`}
                    label="Mühür Bilgisi"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    style={{marginBottom: "5px"}}
                >
                    <Input
                        onChange={(e) => this.onChangeItem({signet_code: e.target.value})}
                    />
                </Form.Item>
                <Form.Item
                    name={`${this.props.sampleAmount}-type_id`}
                    /* initialValue={this.props.WorkOrderTypeId} */
                    initialValue={(this.props.data?.type_id ?? 1)}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Radio.Group
                        onChange={this.onChange}
                        value={this.state.type_id}
                        /* disabled={this.props.WorkOrderTypeId == 4} */
                    >
                        <Radio key={1} value={1} disabled={this.props.WorkOrderTypeId == 4}>Test</Radio>
                        <Radio key={2} value={2} disabled={this.props.WorkOrderTypeId == 4}>Paçal</Radio>
                        <Radio key={3} value={3}>Transfer</Radio>
                        <Radio key={4} value={4}>Saklama</Radio>
                    </Radio.Group>
                </Form.Item>

                {value === 3 ? (
                    <div
                        className="radioContainer"
                        style={{backgroundColor: "#e6fffb"}}
                    >
                        <h3>Transfer Bilgileri</h3>
                        <Form.Item
                            name={`${this.props.sampleAmount}-lab_id`}
                            label="Laboratuvar"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                        >
                            <Select
                                size="small"
                                showSearch
                                style={{width: "100%"}}
                                placeholder="Seçiniz."
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                onChange={(lab_id, option) =>
                                    this.onChangeItem({lab_id, lab_id_text: option.children})
                                }
                            >
                                {this.props.labData.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.laboratory_id}>
                                            {item.LabName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                ) : null}

                {value === 4 ? (
                    <div
                        className="radioContainer"
                        style={{backgroundColor: "#fff0f6"}}
                    >
                        <h3>Saklama Bilgileri</h3>
                        <Form.Item
                            name={`${this.props.sampleAmount}-storage_time`}
                            label="Süre"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                        >
                            <Select
                                size="small"
                                showSearch
                                style={{width: "100%"}}
                                placeholder="Seçiniz"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                onChange={(storage_time, option) =>
                                    this.onChangeItem({
                                        storage_time,
                                        storage_time_text: option.children,
                                    })
                                }
                            >
                                {dates.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={`${this.props.sampleAmount}-store_id`}
                            label="Depo"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                        >
                            <Select
                                size="small"
                                showSearch
                                style={{width: "100%"}}
                                placeholder=""
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                onChange={(store_id, option) =>
                                    this.onChangeItem({
                                        store_id,
                                        store_id_text: option.children,
                                    })
                                }
                            >
                                {this.props.storeData.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.store_id}>
                                            {item.storeName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={`${this.props.sampleAmount}-endless_storage`}
                            label="Konteyner"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                        >
                            <Select
                                size="small"
                                showSearch
                                style={{width: "100%"}}
                                placeholder=""
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                onChange={(endless_storage, option) =>
                                    this.onChangeItem({
                                        endless_storage,
                                        endless_storage_text: option.children,
                                    })
                                }
                            >
                                {this.state.containerData.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.store_part_id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={`${this.props.sampleAmount}-store_part_id`}
                            label="Raf"
                            rules={[
                                {required: true, message: "Lütfen bu alanı boş bırakmayın"},
                            ]}
                        >
                            <Select
                                size="small"
                                showSearch
                                style={{width: "100%"}}
                                placeholder=""
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                onChange={(store_part_id, option) =>
                                    this.onChangeItem({
                                        store_part_id,
                                        store_part_id_text: option.children,
                                    })
                                }
                            >
                                {this.state.shelfData.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.store_part_id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                ) : null}
            </Col>
        );
    }
}

export default SampleAmountItem;
