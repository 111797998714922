import React, { Component, PureComponent } from "react";

import { NavLink } from "react-router-dom";
import "./header.css";

import Logo from "../../../Assets/logo/logo.svg";

import ProfileAvatar from "../../../Assets/profile.jpg";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MailOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  ExperimentOutlined,
  ToolOutlined,
  PieChartOutlined,
  BellOutlined,
  PlusOutlined,
  PicCenterOutlined,
  DiffOutlined,
  HomeOutlined,
  RightOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Menu,
  Dropdown,
  Drawer,
  Avatar,
  Button,
  Alert,
  Row,
  Col,
  Badge,
} from "antd";

import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import Helper from "../../../Helper";
import _ from "lodash";
import moment from "moment";
import DataService from "../../../Provider/Service/DataService";
const { Header } = Layout;
const { SubMenu } = Menu;

const dropdownTimeLineContainer = (
  <div className="dropdownContent">
    <div className="dropdownHeader">Hızlı Menü</div>

    <div className="quickLinks">
      <Row gutter={16}>
        <Col span={24} md={12}>
          <NavLink
            activeClassName="nav-active"
            exact
            to="/sample/work-orders/add"
          >
            <Button type="primary">
              <PlusOutlined />
              <span className="text">Yeni İş Emri Ekle</span>
            </Button>
          </NavLink>
        </Col>
        <Col span={24} md={12}>
          <NavLink
            activeClassName="nav-active"
            exact
            to="/sample/sample-registration"
          >
            <Button type="primary">
              <PicCenterOutlined />
              <span className="text">Numune Listesi</span>
            </Button>
          </NavLink>
        </Col>
        <Col span={24} md={12}>
          <NavLink
            activeClassName="nav-active"
            exact
            to="/sample/sample-report"
          >
            <Button type="primary">
              <DiffOutlined />
              <span className="text">Numune Raporları</span>
            </Button>
          </NavLink>
        </Col>
        <Col span={24} md={12}>
          <NavLink
            activeClassName="nav-active"
            exact
            to="/sample/storage-and-monitoring"
          >
            <Button type="primary">
              <HomeOutlined />
              <span className="text">Depo</span>
            </Button>
          </NavLink>
        </Col>
      </Row>
    </div>

    {/* <Result
      icon={<SmileOutlined />}
      title="Harika, Henüz özelleştirme yapmadınız!"
      extra={<Button type="primary">Hemen Başla</Button>}
    /> */}

    {/* <Timeline>
			<Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
			<Timeline.Item>Solve initial network problems 2015-09-01</Timeline.Item>
			<Timeline.Item>Technical testing 2015-09-01</Timeline.Item>
			<Timeline.Item>Network problems being solved 2015-09-01</Timeline.Item>
		</Timeline> */}
  </div>
);

const dropdownProfileContainer = (
  <div className="dropdownContent">
    <Menu>
      {/* <Menu.Item key="p_0">
				<NavLink activeClassName="nav-active" exact to="/Login">Giriş Yap</NavLink>
			</Menu.Item> */}
      <Menu.Item key="p_1">
        <NavLink activeClassName="nav-active" exact to="/profile">
          Profil
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="p_2">
				<a target="_blank" href="https://sharkyazilim.com">Shark Yazılım</a>
			</Menu.Item> */}
      <Menu.Divider />
      {/* <Menu.Item key="p_3">
				<NavLink activeClassName="nav-active" exact to="/lockscreen">Kilitle</NavLink>
			</Menu.Item>
			<Menu.Item key="p_4">
				<NavLink activeClassName="nav-active" exact to="/settings">Ayarlar</NavLink>
			</Menu.Item> */}
      <Menu.Item key="p_5">
        <Button
          type="link"
          onClick={() => {
            Helper.removeLocalStorage("Token");
            window.location.href = "/login";
          }}
        >
          Çıkış Yap
        </Button>
        {/* <NavLink activeClassName="nav-active" exact to="/login"></NavLink> */}
      </Menu.Item>
    </Menu>
  </div>
);
const NotificationItem = ({ title, comment, url, view, dateCreate }) => {
  return (
    <div className="notification-card">
      <div className="card-icon">
        <Badge dot={!Boolean(view)}>
          <BellOutlined />
        </Badge>
      </div>
      <div className="card-content">
        <span className="text">{comment}</span>

        <span className="time">
          {moment(dateCreate).startOf("hour").fromNow()}
        </span>
      </div>
      <div className="card-action">
        <NavLink to={url}>
          <Button shape="circle" icon={<RightOutlined />} size="small" />
        </NavLink>
      </div>
    </div>
  );
};
class RnHeader extends PureComponent {
  state = {
    collapsed: false,
    drawerVisible: false,
    page: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.pathname != this.props.location.pathname &&
      this.props.location.pathname != this.state.page
    ) {
      this.setState({ collapsed: false, page: this.props.location.pathname });
    }
  }
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      drawerVisible: false,
    });
  };
  _updateNotification = async (notifications) => {
    try {
      let update = [];
      for (const key in notifications) {
        if (notifications.hasOwnProperty(key)) {
          const element = notifications[key];
          if (element.view == 0) update.push(element.id);
        }
      }
      if (update.length != 0) {
        const updates = await DataService.Notification.update(update);
        this.props.updateNotification();
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  dropdownNotificationContainer = () => {
    const { notifications } = this.props;
    this._updateNotification(notifications.slice(0, 5));
    const notificationCount =
      notifications?.filter?.((item) => item.view == 0)?.length || 0;

    return (
      <div className="dropdownContent">
        <div className="dropdownHeader">Bildirimler</div>
        <div className="notification-card-list">
          {notifications.length > 0 ? (
            notifications.slice(0, 5).map((item, index) => {
              return <NotificationItem key={index} {...item} />;
            })
          ) : (
            <Result title="Bildirim Bulunamadı" />
          )}
        </div>

        <div className="text-center">
          <Button type="primary">
            <NavLink
              activeClassName="nav-active"
              exact
              to="/settings/notification"
            >
              Tümünü Göster {notificationCount > 0 && `(${notificationCount})`}
            </NavLink>
          </Button>
        </div>
      </div>
    );
  };
  render() {
    const notificationCount =
      this.props?.notifications?.filter?.((item) => item.view == 0)?.length ||
      0;

    return (
      <div className="layout-container">
        <Header theme="dark" className="layoutHeader">
          <div className="logo">
            <NavLink activeClassName="nav-active" exact to="/dashboard">
              <img src={Logo} alt="Logo" width="120" />
            </NavLink>
          </div>

          <div className={`navWrapper ${this.state.collapsed ? "show" : ""}`}>
            <Menu
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["1"]}
              mode={this.state.collapsed ? "inline" : "horizontal"} // horizontal
              theme="light"
              // inlineCollapsed={this.state.collapsed}
            >
              {this.props.menu.map((item, index) => {
                if (item.subMenu.length == 0) {
                  return (
                    <Menu.Item key={item.line}>
                      <NavLink activeClassName="nav-active" exact to={item.url}>
                        {item.name_en == "Dashboard" ? "Dashboard" : item.name}
                      </NavLink>
                    </Menu.Item>
                  );
                } else {
                  return (
                    <SubMenu
                      key={item.line}
                      title={
                        <span>
                          {item.icon && React.createElement(item.icon)}
                          <span>{item.name}</span>
                        </span>
                      }
                    >
                      {item.subMenu.map((items, indexs) => {
                        if (items.underMenu == 0) {
                          return (
                            <Menu.Item key={`${item.line}_${indexs}`}>
                              <NavLink
                                activeClassName="nav-active"
                                exact
                                to={items.url}
                              >
                                {items.name}
                              </NavLink>
                            </Menu.Item>
                          );
                        } else {
                          return (
                            <SubMenu
                              key={`${items.line}_${indexs}`}
                              title={
                                <span>
                                  <span>{items.name}</span>
                                </span>
                              }
                            >
                              {_.filter(this.props.menuData, {
                                topMenu: items.menuId,
                              }).map((itemss, indexss) => {
                                if (itemss.underMenu == 0) {
                                  return (
                                    <Menu.Item
                                      key={`${itemss.line}_${indexs}_${indexss}`}
                                    >
                                      <NavLink
                                        activeClassName="nav-active"
                                        exact
                                        to={itemss.url}
                                      >
                                        {itemss.name}
                                      </NavLink>
                                    </Menu.Item>
                                  );
                                } else {
                                  return (
                                    <SubMenu
                                      key={`${itemss.line}_${indexs}_${indexss}`}
                                      title={
                                        <span>
                                          <span>{itemss.name}</span>
                                        </span>
                                      }
                                    >
                                      {_.filter(this.props.menuData, {
                                        topMenu: itemss.menuId,
                                      }).map((itemsss, indexsss) => {
                                        return (
                                          <Menu.Item
                                            key={`${itemss.line}_${indexs}_${indexss}_${indexsss}`}
                                          >
                                            <NavLink
                                              activeClassName="nav-active"
                                              exact
                                              to={itemsss.url}
                                            >
                                              {itemsss.name}
                                            </NavLink>
                                          </Menu.Item>
                                        );
                                      })}
                                    </SubMenu>
                                  );
                                }
                              })}
                            </SubMenu>
                          );
                        }
                      })}
                    </SubMenu>
                  );
                }
              })}
            </Menu>
          </div>

          <div className="profileActions">
            {/* <div className="actionItem">

							<a className="ant-dropdown-link" onClick={this.showDrawer}>
							<AppstoreOutlined />
							</a>

							<Drawer
								title="Shark Yazılım"
								placement="right"
								closable={false}
								onClose={this.closeDrawer}
								visible={this.state.drawerVisible}
								>
								<Alert message="Geliştiriliyor" type="success" />
							</Drawer>
						</div> */}

            {/* <div className="mobileToggleButton">
							<Button onClick={this.toggleCollapsed}>
								{React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
							</Button>
							
						</div> */}

            <div className="actionItem mobileToggleButton">
              <a onClick={this.toggleCollapsed}>
                {React.createElement(
                  this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
                )}
              </a>
            </div>

            <div className="actionItem">
              <Dropdown
                overlay={this.dropdownNotificationContainer}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Badge count={notificationCount || 0}>
                    <BellOutlined />
                  </Badge>
                </Button>
              </Dropdown>
            </div>

            {/* <div className="actionItem">
              <Dropdown
                overlay={this.dropdownNotificationContainer}
                trigger={["click"]}
                placement="bottomRight"
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <BellOutlined />
                </a>
              </Dropdown>
            </div> */}

            <div className="actionItem">
              <Dropdown
                overlay={dropdownTimeLineContainer}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <AppstoreOutlined />
                </Button>
              </Dropdown>
            </div>

            <div className="actionItem profileItem">
              <Dropdown
                overlay={dropdownProfileContainer}
                trigger={["click"]}
                placement="bottomRight"
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="profileCard">
                    <Avatar
                      src={this.props.user.photo || ProfileAvatar}
                      size={30}
                    />
                    <span className="username">{this.props.user.fullName}</span>
                  </div>

                  {/* <span className="avatar-item">
									
										<Badge count={1}>
											<Avatar shape="square" icon={<UserOutlined />} />
										</Badge>
									</span> */}
                </a>
              </Dropdown>
            </div>
          </div>
        </Header>
      </div>
    );
  }
}

export default RnHeader;
