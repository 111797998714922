import React, { Component } from 'react';

import { Result, Button } from 'antd';

class page404 extends Component {
    render() {
        return (
            <div>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sayfa henüz oluşturulmadı veya yayında değil"
                    extra={
                    <Button type="primary" key="console">
                        Girişe Dön
                    </Button>
                    }
                />
            </div>
        );
    }
}

export default page404;