// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class TestOptions {
  constructor() {}

  /**
   * Test Method Ekleme
   * @param {string} testId
   * @param {string} testOptionsNameTr
   * @param {string} testOptionsNameEn
   */
  Create = ({  testId, testOptionsNameTr, testOptionsNameEn}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_id:testId,  name:testOptionsNameTr, name_en:testOptionsNameEn
        };
        const response = await ApiService(
          "post",
          Endpoint.TestOptions.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Method Düzenleme
   * @param {string} testOptionsId
   * @param {string} testId
   * @param {string} testOptionsNameTr
   * @param {string} testOptionsNameEn
   */
  Update = ({ testOptionsId, testId, testOptionsNameTr, testOptionsNameEn }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_option_id:testOptionsId, test_id:testId,  name:testOptionsNameTr, name_en:testOptionsNameEn
        };
        const response = await ApiService(
          "put",
          Endpoint.TestOptions.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Method Silme İşlemi
   * @param {string} TestOptionsId
   */
  Delete = (testOptionsId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_option_id:testOptionsId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.TestOptions.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Test Method Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit=0, page=0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.TestOptions.getAll}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Test Method Detayı Alma
   * @param {string} TestOptionsId
   */
  Select = (testOptionsId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_option_id:testOptionsId,
        };
        const response = await ApiService(
          "get",
          Endpoint.TestOptions.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Method Aktif/Pasif Durumu Değiştirme
   * @param {string} TestOptionsId
   */
  StatusChange = (testOptionsId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_option_id:testOptionsId,
        };

        const response = await ApiService(
          "post",
          Endpoint.TestOptions.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new TestOptions();
