// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Operation {
  constructor() {}

  /**
   * Operasyon Ekleme
   * @param {string} operationName - Operasyon Adı
   * @param {string} type - Operasyon Tipi
   */
  Create = ({ operationName,type }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          name:operationName,
          type
        };
        const response = await ApiService(
          "post",
          Endpoint.Operation.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Operasyon Düzenleme
   * @param {string} operationId -  Operasyon Id
   * @param {string} operationName - Operasyon Adı
   * @param {string} type - Operasyon Tipi
   */
  Update = ({ operationId, operationName,type }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          operation_id:operationId,
          name:operationName,
          type
        };
        const response = await ApiService(
          "put",
          Endpoint.Operation.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Operasyon Silme İşlemi
   * @param {string} operationId - Operation ID
   */
  Delete = (operationId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          operation_id:operationId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.Operation.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Operasyon Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit=0, page=0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Operation.getAll}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error)
      }
    });
  };

  /**
   * Operasyon Detayı Alma
   * @param {string} operationId  - Operation Id
   */
  Select = (operationId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          operation_id:operationId,
        };
        const response = await ApiService(
          "get",
          Endpoint.Operation.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Operasyon Aktif/Pasif Durumu Değiştirme
   * @param {string} operationId  - Operasyon Id
   */
  StatusChange = (operationId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          operation_id:operationId,
        };

        const response = await ApiService(
          "post",
          Endpoint.Operation.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new Operation();
