import React, { PureComponent } from "react";

import { Tree } from "antd";
export default class Menus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      treeData: [],
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({
      treeData: this.props.treeData,
      checkedKeys: this.props.checked,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.treeData != this.props.treeData) {
      this.setState({ treeData: this.props.treeData });
    }
    if (prevProps.checked != this.props.checked) {
      this.setState({ checkedKeys: this.props.checked });
    }
  }
  getCheckeds = () => {
    return this.state.checkedKeys;
  };
  onExpand = (expandedKeys) => {
    console.log("onExpand", expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onCheck = (checkedKeys) => {
    console.log("onCheck", checkedKeys);
    this.setState({
      checkedKeys,
    });
  };

  onSelect = (selectedKeys, info) => {
    console.log("onSelect", info);
    this.setState({
      selectedKeys,
    });
  };
  render() {
    const {
      expandedKeys,
      autoExpandParent,
      checkedKeys,
      selectedKeys,
      treeData,
    } = this.state;

    return (
      <Tree
        checkable
        onExpand={this.onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={this.onCheck}
        checkedKeys={checkedKeys}
        onSelect={this.onSelect}
        selectedKeys={selectedKeys}
        treeData={treeData}
      />
    );
  }
}
