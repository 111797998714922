import React, { Component } from "react";

import { NavLink, Redirect, Link } from "react-router-dom";

import Table from "../../../Components/UI/Table";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";
import PageHeader from "../../../Components/UI/PageHeader";
import Swal from "sweetalert2";
import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Radio,
  Spin,
} from "antd";

import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
const { Option } = Select;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      data: [],

      tableColumns: [
        {
          title: "İş Kolu",
          dataIndex: "sector_name",
          key: "sector_name",
          ...Helper.getColumnSearchProps(
            "sector_name",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
          sorter: (a, b) => a.sector_name?.localeCompare(b.sector_name), // string sıralama
        },
        {
          title: "Lab Adı",
          dataIndex: "labName",
          key: "labName",
          ...Helper.getColumnSearchProps(
            "labName",
            this.handleSearch,
            this.handleReset,
            "Lab Adı"
          ),
          sorter: (a, b) => a.labName.localeCompare(b.labName), // string sıralama
        },
        {
          title: "Paket Adı",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Paket Adı"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Ürün",
          dataIndex: "productName",
          key: "productName",
          ...Helper.getColumnSearchProps(
            "productName",
            this.handleSearch,
            this.handleReset,
            "Ürün Adı"
          ),
          sorter: (a, b) => a?.productName?.localeCompare(b?.productName), // string sıralama
        },
        {
          title: "Toplam Test",
          dataIndex: "total_test",
          key: "total_test",
          sorter: (a, b) => a.total_test - b.total_test, // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    DataService.TestPack.SelectAll()
      .then((result) => {
        if (result.status) {
          const { data } = result.response;

          let testpacks = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];

              testpacks.push({
                ...element,
                id: key,
              });
            }
          }
          this.setState({
            data: testpacks,
            loading: false,
          });
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error);
      });
  };

  _deleteClick = ({ pack_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Test Paketini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(pack_id);
      }
    });
  };
  deleted = (testPackId) => {
    this.setState({ loading: true });
    DataService.TestPack.Delete(testPackId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Link
          activeClassName="nav-active"
          to={`/definitions/test-pack/edit/${record.pack_id}`}
        >
          <Button shape="round" type="primary">
            Düzenle
          </Button>
        </Link>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Paketleri" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Customers"}
                dates={["tarih"]}
              />

              <NavLink
                activeClassName="nav-active"
                exact
                to="/definitions/test-pack/add"
              >
                <Button
                  shape="round"
                  className="btn-add"
                  icon={<PlusOutlined />}
                >
                  {" "}
                  Test Paketi Ekle{" "}
                </Button>
              </NavLink>
            </Space>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
