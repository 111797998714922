import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import { Card, Modal, Button, Tooltip, Form, Input, Space, Spin } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";

export default class index extends Component {
  constructor(props){
    super(props);
    this. state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      InventoryBrandId: null,
  
      tableColumns: [
        {
          title: "Envanter Marka Adı",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Envanter Marka Adı"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
  
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.InventoryBrand.SelectAll()
      .then((result) => {
        if (result.status) {
          const { data } = result.response;
          let inventoryBrand = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              inventoryBrand.push({
                ...element,
                id: key,
              });
            }
          }
          this.setState({
            data: inventoryBrand,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Inventory Brand", error);
      });
  };
  _editClick = ({ brand_id, name }) => {
    this.setState({ visible: true, InventoryBrandId: brand_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({ InventoryBrandName: name });
      }, 100);
    });
  };
  _deleteClick = ({ brand_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Envanter Brandini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(brand_id);
      }
    });
  };
  deleted = (InventoryBrandId) => {
    this.setState({ loading: true });
    DataService.InventoryBrand.Delete(InventoryBrandId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      InventoryBrandId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ InventoryBrandName }) => {
    try {
      const { InventoryBrandId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (InventoryBrandId) {
        result = await DataService.InventoryBrand.Update({
          InventoryBrandId,
          InventoryBrandName,
        });
      } else {
        result = await DataService.InventoryBrand.Create({
          InventoryBrandName,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.setState(
          { loading: true, visible: false, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Envanter Markası" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"InventoryBrand"}
                dates={["tarih"]}
              />

              
                <Button
                  shape="round"
                  className="btn-add"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                >Yeni Ekle</Button>
              
            </Space>
          </div>

          <Modal
            title={this.state.InventoryBrandId ? (
              "Envanter Markası Düzenle"
            ) : (
              "Envanter Markası Ekle"
            )}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="InventoryBrandName"
                label="Envanter Marka Adı"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input  />
              </Form.Item>

              <div className="formButton">
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.InventoryBrandId ? (
                  <>
                    {" "}
                    <EditOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <PlusOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
              </div>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}
