// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Test {
  constructor() {}

  /**
   * Test Ekleme
   * @param {string} testNameTr
   * @param {string} testNameEn
   * @param {string} testSectorId
   * @param {string} testUnitId
   * @param {string} testMethodId
   * @param {number} testFormat
   * @param {number} testReportingAccuracy
   * @param {number} testRefMin
   * @param {number} testRefMax
   * @param {string} testParentId
   */
  Create = ({
    testNameTr,
    testNameEn,
    testSectorId,
    testMethodId,
    testUnitId,
    testFormat,
    testReportingAccuracy,
    testParentId,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          name: testNameTr,
          name_en: testNameEn,
          sector_id: testSectorId,
          unit_id: testUnitId,
          method_id: testMethodId,

          number: parseInt(testFormat),
          number_characters: testReportingAccuracy
            ? parseInt(testReportingAccuracy)
            : 0,
          parent_test_id: testParentId || 0,
        };
        const response = await ApiService(
          "post",
          Endpoint.Test.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Düzenleme
   * @param {string} testId
   * @param {string} testNameTr
   * @param {string} testNameEn
   * @param {string} testSectorId
   * @param {string} testUnitId
   * @param {string} testMethodId
   * @param {number} testFormat
   * @param {number} testReportingAccuracy
   * @param {number} testRefMin
   * @param {number} testRefMax
   * @param {string} testParentId
  
   */
  Update = ({
    testId,
    testNameTr,
    testNameEn,
    testSectorId,

    testMethodId,
    testUnitId,

    testFormat,
    testReportingAccuracy,
    testParentId,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_id: testId,

          name: testNameTr,
          name_en: testNameEn,
          sector_id: testSectorId,
          unit_id: testUnitId,
          method_id: testMethodId,

          number: parseInt(testFormat),
          number_characters: testReportingAccuracy
            ? parseInt(testReportingAccuracy)
            : 0,
          parent_test_id: testParentId || 0,
        };
        const response = await ApiService(
          "put",
          Endpoint.Test.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Silme İşlemi
   * @param {string} testId
   */
  Delete = (testId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_id: testId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.Test.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Test Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Test.getAll}/${limit}/${page}`;
        console.log("endpoints", endpoints);
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Test Detayı Alma
   * @param {string} testId
   */
  Select = (testId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "get",
          `${Endpoint.Test.getOne}/${testId}`
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Aktif/Pasif Durumu Değiştirme
   * @param {string} testId
   */
  StatusChange = (testId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_id: testId,
        };

        const response = await ApiService(
          "post",
          Endpoint.Test.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Ref Min Max Update
   * @param {string} testId
   * @param {string} productId
   * @param {string} refMin
   * @param {string} refMax
   */
  RefUpdate = ({
    testId,
    productId,
    refMin,
    refMax,
    ref_min_operator,
    ref_max_operator,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          testId,
          productId,
          refMin,
          refMax,
          ref_min_operator,
          ref_max_operator,
        };

        const response = await ApiService(
          "post",
          Endpoint.Test.refUpdate,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   *  Test için Ürün Listeleme
   * @param {number} limit
   * @param {number} page
   * @param {number} sectorId
   */
  getProducts = (testId = 0, limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Test.getProducts}/${limit}/${page}/${testId}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   *  Test Laboratuvarlari
   * @param {number} productId
   */
  testLabs = (productId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Test.getTestLabs}/${productId}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   *  Test Fiyatlarının girişi
   * @param {testId} string - Test ID
   * @param {price} number - Fiyat
   * @returns
   */
  setPrice = ({ testId, price }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          testId,
          price,
        };
        const response = await ApiService(
          "post",
          Endpoint.Test.setPrice,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new Test();
