// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Notification {
  constructor() {}

  /**
   * Notification  Listeleme
   * @param {number} limit
   * @param {number} page
   * @param {Date} startDate
   * @param {Date} endDate
   */
  SelectAll = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Notification.getAll}/${limit}/${page}/${startDate}/${endDate}`;

        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Notification  Update
   * @param {Array} notification
   */
  update = (notification) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          notification,
        };
        const response = await ApiService(
          "put",
          Endpoint.Notification.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Notification  yetki rolleri
   * @returns
   */
  getTypeRole = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.Notification.typeAll);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Yetki bazlı notification tip listesi
   * @returns
   */
  getPermAll = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "get",
          Endpoint.Notification.getPermAll
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı rolleri
   * @param {number} permId
   * @param {number} typeId

   */
  getPermTypeAll = (permId, typeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Notification.permAll}/${permId}/${typeId}`;

        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: response.reply,
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Bildirim yetki kayıt
   * @param {string} permId  - Kullanıcı role id
   * @param {number} notificationTypeId  - bildirim type id
   * @param {{permId}} notification_perm  -  kullanıcı role id array
   * @returns
   */
  permSaved = (permId, notificationTypeId, notification_perm) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          permId,
          notificationTypeId,
          notification_perm,
        };
        const response = await ApiService(
          "post",
          Endpoint.Notification.permSave,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}
export default new Notification();
