import React, { Component } from 'react';

import PageHeader from '../../../Components/UI/PageHeader';

import ImgBlend from "../../../Assets/sample-status/blend-2.png";
import ImgBlend2 from "../../../Assets/sample-status/blend.png";
import ImgSample from "../../../Assets/sample-status/sample.png";
import ImgStorage from "../../../Assets/sample-status/storage.png";
import ImgTransfer from "../../../Assets/sample-status/transfer.png";
import ImgQrExample from "../../../Assets/sample-status/qr-test.png";


import { Statistic, Card, Row, Col, Tag, Button, Progress, Timeline, Space, Result } from 'antd';
import { FallOutlined, BranchesOutlined, PlusOutlined, CommentOutlined, ArrowUpOutlined, ArrowDownOutlined, ExperimentOutlined, BellOutlined, UserOutlined, IdcardOutlined, FileDoneOutlined, BarChartOutlined, NotificationOutlined, DeleteOutlined } from '@ant-design/icons';


import { Doughnut, Pie } from 'react-chartjs-2';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';


import Table from '../../../Components/UI/Table';

const reChartLineData = [
    {
      name: 'Nisan', yapilan: 4000, revize: 1400, amt: 2400,
    },
    {
      name: 'Mayıs', yapilan: 3000, revize: 1398, amt: 2210,
    },
    {
      name: 'Haziran', yapilan: 2000, revize: 500, amt: 2290,
    },
    {
      name: 'Ağustos', yapilan: 2780, revize: 200, amt: 2000,
    },
    {
      name: 'Temmuz', yapilan: 1890, revize: 100, amt: 2181,
    },
    {
      name: 'Ağustos', yapilan: 2390, revize: 500, amt: 2500,
    },
    {
      name: 'Eylül', yapilan: 3490, revize: 100, amt: 2100,
    },
];


const reChartStackedBarData = [
    {
      name: 'ALIAGA', test: 4000, kayit: 2400, amt: 2400,
    },
    {
      name: 'ANTALYA', test: 3000, kayit: 1398, amt: 2210,
    },
    {
      name: 'İSKENDERUN', test: 2000, kayit: 9800, amt: 2290,
    },
    {
      name: 'İSTANBUL', test: 2780, kayit: 3908, amt: 2000,
    },
    {
      name: 'MERSIN', test: 1890, kayit: 4800, amt: 2181,
    },
    {
      name: 'KIRIKKALE', test: 2390, kayit: 3800, amt: 2500,
    },
    {
      name: 'MARMARA', test: 3490, kayit: 4300, amt: 2100,
    },
    {
      name: 'TRABZON', test: 3490, kayit: 4300, amt: 2100,
    },
    {
      name: 'IGA', test: 3490, kayit: 4300, amt: 2100,
    },
];




const chartDoughnutData = {
    labels: ['Bekleyen', 'İşlemde', 'Bekleyen', 'Raporlanan', 'Revize', 'Saklanan'],
    datasets: [{
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
    }]
};

const chartPieData = {
	labels: [
		'Yapılan Test',
		'Tekrar Test',
		'Revize Gelen'
	],
	datasets: [{
		data: [300, 50, 100],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}]
};



const tableColumns = [
    {
        title: 'ATF No',
        dataIndex: 'atf_no',
        key: 'atf_no',
    },
    {
        title: 'LAB',
        dataIndex: 'lab',
        key: 'lab',
    },
    {
        title: 'Müşteri',
        dataIndex: 'customer',
        key: 'customer',
    },
    {
        title: 'Seçenekler',
        dataIndex: 'options',
        key: 'options',
        render: (text, record) => (
          <Space size="middle">
            <Button shape="round" type="primary" >İncele</Button>
          </Space>
        ),
    }
];

const tableData = [
    {
        key: '1',
        atf_no: '100001',
        lab: 'LAB',
        customer: 'Müşteri'
    },
    {
        key: '2',
        atf_no: '100001',
        lab: 'LAB',
        customer: 'Müşteri'
    },
    {
        key: '2',
        atf_no: '100001',
        lab: 'LAB',
        customer: 'Müşteri'
    },
];

class index extends Component {
    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="PageHeader Widget" />
                </div>


                <Row gutter={16} style={{ marginBottom: '16px' }}>
                    <Col span={8}>
                        <Card bordered={false} >
                            <h2>Statistic</h2>
                            <span>03088</span>

                            <div className="cardIcon">
                                <PlusOutlined />
                            </div>

                            <div className="cardFooter">
                                <Tag color="blue">blue</Tag>
                                <Tag color="geekblue">geekblue</Tag>
                                <Tag color="purple">purple</Tag>
                            </div>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} >
                            <h2>Statistic</h2>
                            <span>03088</span>

                            <div className="cardIcon">
                                <BranchesOutlined />
                            </div>
                            
                            <div className="cardFooter">
                                <Tag color="geekblue">geekblue</Tag>
                                <Tag color="geekblue">geekblue</Tag>
                                <Tag color="red">red</Tag>
                            </div>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} >
                            <h2>Statistic</h2>
                            <span>03088</span>

                            <div className="cardIcon">
                                <FallOutlined />
                            </div>
                            
                            <div className="cardFooter">
                                <Tag color="purple">purple</Tag>
                                <Tag color="green">green</Tag>
                                <Tag color="blue">blue</Tag>
                            </div>
                        </Card>
                    </Col>
                </Row>



                <div className="site-statistic-demo-card">
                    <Row gutter={16}>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Kullanıcılar"
                                        value={119}
                                        valueStyle={{ color: '#6c0aff' }}
                                        // prefix={<ArrowUpOutlined />}
                                        // suffix="%"
                                    />
                                    <UserOutlined className="widget-icon"  />
                                </div>
                            </Card>
                        </Col>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Müşteriler"
                                        value={500}
                                        valueStyle={{ color: '#6c0aff' }}
                                        // prefix={<ArrowDownOutlined />}
                                    />
                                    <IdcardOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Raporlar"
                                        value={923}
                                        valueStyle={{ color: '#6c0aff' }}
                                        // prefix={<ArrowUpOutlined />}
                                    />
                                    <FileDoneOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Testler"
                                        value={900}
                                        valueStyle={{ color: '#6c0aff' }}
                                    />
                                    
                                    <ExperimentOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>


                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Teklifler"
                                        value={900}
                                        valueStyle={{ color: '#6c0aff' }}
                                    />

                                    <BarChartOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Sözleşmeler"
                                        value={900}
                                        valueStyle={{ color: '#6c0aff' }}
                                    />
                                    
                                    <BarChartOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Gelir"
                                        value={900}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={<ArrowUpOutlined />}
                                    />
                                    
                                    <BarChartOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Gider"
                                        value={900}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={<ArrowDownOutlined />}
                                    />
                                    
                                    <BarChartOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>


                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="İş Emirleri"
                                        value={900}
                                        valueStyle={{ color: '#6c0aff' }}
                                    />
                                    
                                    <BarChartOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>
                        <Col className="dash-col" span={24} md={6}>
                            <Card>
                                <div className="widget-statistic-flex align-center">
                                    <Statistic
                                        title="Numuneler"
                                        value={900}
                                        valueStyle={{ color: '#6c0aff' }}
                                    />
                                    
                                    <BarChartOutlined className="widget-icon" />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>


                <Row gutter={16} >

                    <Col className="dash-col"  span={24}>
                        <Card>
                            <h2>Numuneler</h2>
                            <span class="statistic-count">1029</span>

                            <div className="cardIcon">
                                <PlusOutlined />
                            </div>

                            <div className="cardFooter">
                                <Tag color="blue">Bekleyen (22)</Tag>
                                <Tag color="geekblue">İşlemde (99)</Tag>
                                <Tag color="purple">Raporda Bekleyen (120)</Tag>
                                <Tag color="green">Raporlanan (33)</Tag>
                                <Tag color="red">Revize İstenen (4)</Tag>
                                <Tag color="purple">Saklanan (55)</Tag>
                            </div>
                        </Card>
                    </Col>

                </Row>


                <Row gutter={16}>
                    <Col className="dash-col" span={24} md={12}>
                        <Card title="Bildirimler" extra={<a href="/settings/notification">Tümünü Gör</a>}>
                            <div className="notification-card-list">
                                <div className="notification-card">
                                    <div className="card-icon">
                                        <BellOutlined />
                                    </div>
                                    <div className="card-content">
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                                <div className="notification-card">
                                    <div className="card-icon">
                                        <BellOutlined />
                                    </div>
                                    <div className="card-content">
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                                <div className="notification-card">
                                    <div className="card-icon">
                                        <BellOutlined />
                                    </div>
                                    <div className="card-content">
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                                <div className="notification-card">
                                    <div className="card-icon">
                                        <BellOutlined />
                                    </div>
                                    <div className="card-content">
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                                <div className="notification-card">
                                    <div className="card-icon">
                                        <BellOutlined />
                                    </div>
                                    <div className="card-content">
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col className="dash-col" span={24} md={12} >
                        <Card bordered={false} className="cardTable" title="Son İş Emirleri" extra={<a href="#">Tümünü Gör</a>}>
                            <Table columns={tableColumns} data={tableData} />
                        </Card>
                    </Col>

                    <Col className="dash-col" span={24} md={12} >
                        <Card title="Sistem Logları" extra={<a href="#">Tümünü Gör</a>}>
                            <Timeline>
                                <Timeline.Item color="green">
                                    <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item color="green">
                                    <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item color="red">
                                    <span>Çıkış yapıldı. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item>
                                    <span>Test Paketi Eklendi. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item color="gray">
                                    <span>Teklif gönderildi. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item color="gray">
                                    <span>Numune kaydı yapıldı. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item color="green">
                                    <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item color="green">
                                    <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item color="red">
                                    <span>Çıkış yapıldı. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                                <Timeline.Item>
                                    <span>Test Paketi Eklendi. <span className="time">20.08.2020 20:20</span></span>
                                </Timeline.Item>
                            </Timeline>
                        </Card>
                    </Col>


                    <Col className="dash-col" span={24} md={12} >
                        <Card title="Duyurular" extra={<a href="/modules/announcement">Tümünü Gör</a>}>

                            <div className="announcement-card-list">
                                <div className="announcement-card">
                                    <div className="card-icon">
                                        <NotificationOutlined />
                                    </div>
                                    <div className="card-content">
                                        <h4>Lorem Heading</h4>
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                                <div className="announcement-card">
                                    <div className="card-icon">
                                        <NotificationOutlined />
                                    </div>
                                    <div className="card-content">
                                        <h4>Lorem Heading</h4>
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                                <div className="announcement-card">
                                    <div className="card-icon">
                                        <NotificationOutlined />
                                    </div>
                                    <div className="card-content">
                                        <h4>Lorem Heading</h4>
                                        <span className="text">Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan mıgır metinlerdir. </span>
                                        <span className="time">1 saat önce</span>
                                    </div>
                                    <div className="card-action">
                                        <Button type="primary">Göster</Button>
                                    </div>
                                </div>
                            </div>
                            
                        </Card>
                    </Col>

                </Row>




                <Row gutter={16} style={{ marginBottom: '16px' }}>


                    <Col className="dash-col" span={24} md={12}>
                        <Card title="Numuneler">
                            <Doughnut data={chartDoughnutData} />
                        </Card>
                    </Col>
                    <Col className="dash-col" span={24} md={12}>
                        <Card title="Testler">
                            <Pie data={chartPieData} />
                        </Card>
                    </Col>
                    
                    <Col span={24} md={12}>
                        <Card title="Aylık Test Sayıları">

                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer>
                                    <LineChart
                                        data={reChartLineData}
                                        margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="revize" stroke="#8884d8" activeDot={{ r: 8 }} />
                                        <Line type="monotone" dataKey="yapilan" stroke="#82ca9d" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>

                        </Card>
                    </Col>

                    <Col span={24} md={12}>
                        <Card title="Test/Kayıt Sayıları">

                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        data={reChartStackedBarData}
                                        margin={{
                                        top: 20, right: 30, left: 20, bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="test" stackId="a" fill="#8884d8" />
                                        <Bar dataKey="kayit" stackId="a" fill="#82ca9d" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>

                        </Card>
                    </Col>
                </Row>




                <Card>
                    <h2>Durumlar</h2>
                    <Tag color="#fd6205">Talep Alındı</Tag>
                    <Tag color="#fe9835">Numune Yolda</Tag>
                    <Tag color="#ffce0b">Numune Ulaştı</Tag>
                    <Tag color="#feff9a" style={{ color: '#000' }}>Ödeme Bekliyor(eski)</Tag>
                    <Tag color="#1abc9c">Ödeme Bekliyor</Tag>
                    <Tag color="#9894ff">Numune Analizde</Tag>
                    <Tag color="#fd54ff">Rapolanacak</Tag>
                    <Tag color="#cdc9ff">Raporlandı</Tag>
                    <Tag color="#9bd006">Muhasebe</Tag>
                    <Tag color="#1cb451">İşlem Tamamlandı</Tag>
                    <Tag color="#ff0000">Sorunlu Numune</Tag>
                    <Tag color="#f39c12">Revize Numune</Tag>
                </Card>


                <Row gutter={16} >
                    <Col span={24} md={8}>
                        <Card>
                            <Result
                                title="Sonuç Bulunamadı"
                                extra={
                                <Button type="primary" key="console">
                                    Git
                                </Button>
                                }
                            />
                        </Card>
                    </Col>

                    <Col span={24} md={8}>
                        <Card>
                            <div className="qr-print-wrapper">
                                <div className="qr-print-img">
                                    <img className="qr-print-status-img" src={ImgQrExample} />
                                    <div className="qr-print-status">
                                        <img className="qr-print-status-img" src={ImgTransfer} />
                                    </div>
                                </div>
                                <div className="qr-prin-details">
                                    <span>Müşteri Adı : Test müşteri data</span>
                                    <span>Numune Adı : Test numune data</span>
                                    <span>Gemi Adı: Test Gemi Adı</span>
                                    <span>Alım Yeri: ISTANBUL Teslim Noktası</span>
                                    <span>Alım Tarihi: 20.10.2020</span>
                                    <span>Saklama: ise raf bilgisi</span>
                                </div>
                            </div>


                            <h2>Numune Durumları</h2>
                            
                            <div className="sample-status-wrapper">
                                <div className="sample-status-card">
                                    <img className="sample-status-img" src={ImgBlend} />
                                    <span>Paçal</span>
                                </div>
                                <div className="sample-status-card">
                                    <img className="sample-status-img" src={ImgBlend2} />
                                    <span>Paçal2</span>
                                </div>
                                <div className="sample-status-card">
                                    <img className="sample-status-img" src={ImgSample} />
                                    <span>Test</span>
                                </div>
                                <div className="sample-status-card">
                                    <img className="sample-status-img" src={ImgStorage} />
                                    <span>Saklama</span>
                                </div>
                                <div className="sample-status-card">
                                    <img className="sample-status-img" src={ImgTransfer} />
                                    <span>Transfer</span>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col span={24} md={8}>
                        <Card>
                            <div className="quickLinks">
                                <Row gutter={16} >
                                    <Col span={24} md={12}>
                                        <Button>
                                            <PlusOutlined />
                                            <span className="text">İş Emri Ekle</span>
                                        </Button>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Button>
                                            <PlusOutlined />
                                            <span className="text">İş Emri Ekle</span>
                                        </Button>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Button>
                                            <PlusOutlined />
                                            <span className="text">İş Emri Ekle</span>
                                        </Button>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Button>
                                            <PlusOutlined />
                                            <span className="text">İş Emri Ekle</span>
                                        </Button>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Button>
                                            <PlusOutlined />
                                            <span className="text">İş Emri Ekle</span>
                                        </Button>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Button>
                                            <PlusOutlined />
                                            <span className="text">İş Emri Ekle</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>


            </div>
        );
    }
}

export default index;