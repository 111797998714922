import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, Select, Spin, Row, Col } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import DataService from "../../Provider/Service/DataService";
import Helper from "../../Helper";
import _ from "lodash";
const { Option } = Select;

const city = require("../../Static/city.json");
const district = require("../../Static/district.json");
/**
 *
 * @param {void} onChange - Müşteri Ekleme Tamamlandığında çağarılır.
 */
const CustomerForm = ({ onChange }) => {
  const [topCustomer, setTopCustomer] = useState([]); // Üst Müşteri Grubu

  const [btnloading, setBtnloading] = useState(false); // Form submit olduğunda buton loading olayları
  const [loading, setLoading] = useState(true); // Component yüklenene kadar Sayfanın loading olması
  const [cityValue, setCityValue] = useState("0");
  const formRef = useRef();

  useEffect(() => {
    let result = getData();
    return () => {
      result = null;
    };
  }, []);

  const getData = async () => {
    try {
      const result = await DataService.Customer.SelectAll();
      if (result.status) {
        const { data } = result.response;
        let topCustomer = [{ value: 0, text: "Ana Firma" }];

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            topCustomer.push({
              value: element.customer_id,
              text: element.name,
            });
          }
        }
        setTopCustomer(topCustomer);

        setLoading(false);
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };

  const onFormFinish = async ({
    customerAdress,
    customerGsmPhone,
    customerIban,
    customerName,
    customerPhone,
    customerTaxNumber,
    customerTaxOffice,
    customerTopId,
    custpmerTitle,
    customerCityId,
    customerDistrictId,
  }) => {
    try {
      setBtnloading(true);
      let result = await DataService.Customer.Create({
        customerAdress,
        customerGsmPhone,
        customerIban,
        customerName,
        customerPhone,
        customerTaxNumber,
        customerTaxOffice,
        customerTopId,
        custpmerTitle,
        customerCityId,
        customerDistrictId,
      });
      setBtnloading(false);
      if (result.status) {
        formRef.current.resetFields();

        onChange(customerName);
      } else {
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      setBtnloading(false);
      Helper.ErrorMessage(error.toString());
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFormFinish}
        ref={formRef}
      >
        <Form.Item
          name="customerTopId"
          label="Üst Firma"
          rules={[
            {
              required: true,
              message: "Lütfen bu alanı boş bırakmayın",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Üst Firma  Seçiniz"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {topCustomer.map((item, index) => {
              return (
                <Option key={index} value={item.value}>
                  {item.text}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Row gutter="16">
          <Col span="12">
            <Form.Item
              name="customerName"
              label="Firma Adı"
              rules={[
                {
                  required: true,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Firma Adı" />
            </Form.Item>
            <Form.Item
              name="custpmerTitle"
              label="Firma Ünvanı"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Firma Ünvanı" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="customerIban"
              label="Firma IBAN"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Firma IBAN" maxLength={26} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter="16">
          <Col span="12">
            <Form.Item
              name="customerTaxNumber"
              label="Firma Vergi Numarası"
              rules={[
                {
                  required: true,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Firma Vergi Numarası" maxLength={11} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="customerTaxOffice"
              label="Firma Vergi Dairesi"
              rules={[
                {
                  required: true,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Firma Vergi Dairesi" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter="16">
          <Col span="12">
            <Form.Item
              name="customerPhone"
              label="Telefon"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Telefon" maxLength={11} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="customerGsmPhone"
              label="Cep Telefonu"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Cep Telefonu" maxLength={11} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter="16">
          <Col span="12">
            <Form.Item
              name="customerCityId"
              label="Şehir"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Şehir Seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(cityValue) => setCityValue(cityValue)}
              >
                {city.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.text}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="customerDistrictId"
              label="İlçe"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                  type: "string",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="İlçe  Seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {_.filter(district, { city_value: cityValue }).map(
                  (item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.text}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="customerAdress"
          label="Adres"
          rules={[
            {
              required: false,
              message: "Lütfen bu alanı boş bırakmayın",
              type: "string",
            },
          ]}
        >
          <Input.TextArea placeholder="Adres" />
        </Form.Item>

        <div className="formButton">
          <Button type="primary" htmlType="submit" loading={btnloading}>
            <PlusOutlined /> Ekle
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default CustomerForm;
