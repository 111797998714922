import React, { Component } from "react";
import "./login.css";

import Logo from "../../Assets/logo/logo.svg";

import { NavLink } from "react-router-dom";

import { Form, Input, Button } from "antd";

import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";
import DataService from "../../Provider/Service/DataService";
import Helper from "../../Helper";
class LoginRemember extends Component {
  state = {
    btnload: false,
  };
  _forgotPassword = async ({ email }) => {
    try {
      this.setState({ btnload: true });
      let redirect = false;
      const result = await DataService.User.ForgotPassword(email);
      if (result.status) {
        Helper.SuccessMessage(result.message);
        redirect = true;
      } else {
        Helper.ErrorMessage(result.message);
      }
      this.setState({ btnload: false }, () => {
        setTimeout(() => {
          if (redirect) {
            window.location.href = "/login";
          }
        }, 1000);
      });
    } catch (error) {
      Helper.ErrorMessage(error.toString());
      this.setState({ btnload: false });
    }
  };

  render() {
    return (
      <div class="login-layout ant-layout">
        <div className="loginBrand">
          <img src={Logo} alt="Logo" width="120" />
        </div>
        <div className="loginCard">
          <div className="cardHead">
            <h2>Parola Hatırlat</h2>
            <span>Mail adresinize hatırlatma maili gönderilecektir.</span>
          </div>

          <div className="cardContent">
            <Form
              className="formLogin"
              hideRequiredMark
              layout="vertical"
              onFinish={this._forgotPassword}
            >
              <Form.Item
                label="E-Mail Adresi"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Geçerli bir e-mail adresi giriniz.",
                    type: "email",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="info@intertek.com"
                  prefix={<UserOutlined />}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  icon={<LoginOutlined />}
                  htmlType="submit"
                  loading={this.state.btnload}
                >
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginRemember;
