import React, {useEffect, useState} from "react";

import {PageHeader, Space, Spin} from "antd";
import Table from "../../../Components/Table";
import Export from "../../../Components/Export/Export";
import Helper from "../../../Helper";

const RTable = ({
                    data = [],
                    title = "Ürün ve Müşteri Sonuçları",
                    showSector = false
                }) => {
    const [loading, setLoading] = useState(true);
    const [selectLab, setSelectLab] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    console.log(data);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const sectorColums = [
        {
            title: "Sektör",
            dataIndex: "sectorName",
            key: "sectorName",

            sorter: (a, b) => a?.sectorName.localeCompare(b?.sectorName), // string sıralama
            ...Helper.getColumnSearchProps(
                "sectorName",
                handleSearch,
                handleReset,

                "Sektör"
            )
        }
    ];
    const tableColumns = [
        {
            title: "Metot Adı",
            dataIndex: "methodName",
            key: "methodName",
            render: (value, record) => {
                if (value === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            },
            sorter: (a, b) => a?.methodName.localeCompare(b?.methodName), // string sıralama
            ...Helper.getColumnSearchProps(
                "methodName",
                handleSearch,
                handleReset,

                "Metot Adı"
            )
        },
        {
            title: "Test Adı",
            dataIndex: "testName",
            key: "testName",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            },
            sorter: (a, b) => a?.testName.localeCompare(b?.testName), // string sıralama
            ...Helper.getColumnSearchProps(
                "testName",
                handleSearch,
                handleReset,

                "Methot Adı"
            )
        },
        {
            title: "Ürün Adı",
            dataIndex: "productName",
            key: "productName",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            },
            sorter: (a, b) => a?.productName.localeCompare(b?.productName), // string sıralama
            ...Helper.getColumnSearchProps(
                "productName",
                handleSearch,
                handleReset,

                "Ürün Adı"
            )
        },
        {
            title: "İskenderun",
            dataIndex: "ISK",
            key: "ISK",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "İstanbul",
            dataIndex: "IST",
            key: "IST",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Mersin",
            dataIndex: "MER",
            key: "MER",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Aliağa",
            dataIndex: "ALA",
            key: "ALA",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Antalya",
            dataIndex: "ANT",
            key: "ANT",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Kırıkkale",
            dataIndex: "KRK",
            key: "KRK",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Marmara",
            dataIndex: "MAR",
            key: "MAR",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Trabzon",
            dataIndex: "TRB",
            key: "TRB",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Arnavutköy",
            dataIndex: "ARN",
            key: "ARN",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        },
        {
            title: "Toplam",
            dataIndex: "toplam",
            key: "toplam",
            render: (value, record) => {
                if (record?.methodName === "TOPLAM") {
                    return <b>{value}</b>;
                } else {
                    return value;
                }
            }
        }
    ];
    useEffect(() => {
        setLoading(false);

        setFilterData(filteringData(data));
        return () => {
            setLoading(true);
        };
    }, [data]);
    const filteringData = (opts) => {
        const result = opts.map((item, index) => {
            let toplam = 0;
            for (const key in item) {
                if (Object.hasOwnProperty.call(item, key)) {
                    const element = item[key];
                    if (Number(element)) {
                        toplam = toplam + element;
                    }
                }
            }
            return {
                ...item,
                toplam
            };
        });
        return result;
    };
    const renderFooter = () => {
        return (
            <div>
                <p>Toplam</p>
                <ol>
                    <li>
                        <b>İskenderun: 12</b>
                    </li>
                    <li>
                        <b>Mersin: 14</b>
                    </li>
                    <li>
                        <b>Aliağa: 15</b>
                    </li>
                    <li>
                        <b>Antalya: 16</b>
                    </li>
                    <li>
                        <b>İstanbul: 13</b>
                    </li>
                    <li>
                        <b>Kırıkkale: 17</b>
                    </li>
                    <li>
                        <b>Marmara: 18</b>
                    </li>
                    <li>
                        <b>Trabzon: 19</b>
                    </li>
                    <li>
                        <b>Arnavutköy: 20</b>
                    </li>
                    <li>
                        <b>Genel Toplam: 220</b>
                    </li>
                </ol>
            </div>
        );
    };
    return (
        <Spin spinning={loading}>
            <div className="pageHeaderWrapper">
                <PageHeader title={title}/>
                <div className="pageHeaderOptions">
                    <Space>
                        <Export
                            data={filterData || []}
                            columns={[...tableColumns]}
                            tableName={"DetailedReport"}
                            dates={["tarih"]}
                        />
                    </Space>
                </div>
            </div>

            <Table
                columns={
                    showSector ? [...sectorColums, ...tableColumns] : tableColumns
                }
                dataSource={filterData}
            />
        </Spin>
    );
};

export default RTable;
