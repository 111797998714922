import React, { useState, useEffect } from "react";

import { Spin, Select, Button, Row, Col, PageHeader, Space } from "antd";

import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
import Table from "../../../Components/Table";
const DetailsPerformancePersonel = ({ sampleId }) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const [tableColumns, setTableColumns] = useState([
    {
      title: "Test Adı",
      dataIndex: "testName",
      key: "testName",

      sorter: (a, b) => String(a?.testName).localeCompare(String(b?.testName)), // string sıralama
      ...Helper.getColumnSearchProps(
        "testName",
        handleSearch,
        handleReset,

        "Test Adı"
      ),
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",

      sorter: (a, b) => String(a?.result).localeCompare(String(b?.result)), // string sıralama
      ...Helper.getColumnSearchProps(
        "result",
        handleSearch,
        handleReset,

        "Result"
      ),
    },
    {
      title: "Revize",
      dataIndex: "revised",
      key: "revised",

      sorter: (a, b) => String(a?.revised).localeCompare(String(b?.revised)), // string sıralama
      ...Helper.getColumnSearchProps(
        "revised",
        handleSearch,
        handleReset,

        "revised"
      ),
    },

    {
      title: "Ureal Result",
      dataIndex: "ureal_result",
      key: "ureal_result",

      sorter: (a, b) =>
        String(a?.ureal_result).localeCompare(String(b?.ureal_result)), // string sıralama
      ...Helper.getColumnSearchProps(
        "ureal_result",
        handleSearch,
        handleReset,

        "Ureal Result"
      ),
    },
    {
      title: "Ureal Value",
      dataIndex: "ureal_value",
      key: "ureal_value",

      sorter: (a, b) =>
        String(a?.ureal_value).localeCompare(String(b?.ureal_value)), // string sıralama
      ...Helper.getColumnSearchProps(
        "ureal_value",
        handleSearch,
        handleReset,

        "Ureal Value"
      ),
    },

    {
      title: "Başlangıç Tarihi",
      dataIndex: "date_start",
      key: "date_start",

      sorter: (a, b) =>
        String(a?.date_start).localeCompare(String(b?.date_start)), // string sıralama
      ...Helper.getColumnSearchProps(
        "date_start",
        handleSearch,
        handleReset,

        "Başlangıç Tarihi"
      ),
    },
    {
      title: "Bitiş Tarihi",
      dataIndex: "date_finish",
      key: "date_finish",

      sorter: (a, b) =>
        String(a?.date_finish).localeCompare(String(b?.date_finish)), // string sıralama
      ...Helper.getColumnSearchProps(
        "date_finish",
        handleSearch,
        handleReset,

        "Bitiş Tarihi"
      ),
    },
    {
      title: "Geçen Süre",
      dataIndex: "finishTime",
      key: "finishTime",

      sorter: (a, b) =>
        String(a?.finishTime).localeCompare(String(b?.finishTime)), // string sıralama
      ...Helper.getColumnSearchProps(
        "finishTime",
        handleSearch,
        handleReset,

        "Geçen Süre"
      ),
    },
    {
      title: "Kullanıcı",
      dataIndex: "testUserName",
      key: "testUserName",

      sorter: (a, b) =>
        String(a?.testUserName).localeCompare(String(b?.testUserName)), // string sıralama
      ...Helper.getColumnSearchProps(
        "testUserName",
        handleSearch,
        handleReset,

        "Kullanıcı"
      ),
    },
    {
      title: "Toplam Revize",
      dataIndex: "totalRevised",
      key: "totalRevised",

      sorter: (a, b) =>
        String(a?.totalRevised).localeCompare(String(b?.totalRevised)), // string sıralama
      ...Helper.getColumnSearchProps(
        "totalRevised",
        handleSearch,
        handleReset,

        "totalRevised"
      ),
    },
  ]);
  const getData = () => {
    DataService.Dashboard.personelPerformanceDetails({ sampleId })
      .then((result) => {
        setLoading(false);
        if (!result.status) {
          Helper.ErrorMessage(result.message);
          return;
        }
        setData(result.data);
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  useEffect(() => {
    getData();

    return () => {
      setLoading(true);
    };
  }, []);

  return (
    <Spin spinning={loading}>
      <Table columns={tableColumns} dataSource={data} pagination />
    </Spin>
  );
};

export default DetailsPerformancePersonel;
