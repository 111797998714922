import React, { Component } from 'react';

import PageHeader from "../../../Components/UI/PageHeader";

import { Card, Button, DatePicker, Space, Row, Col, Spin } from "antd";

class index extends Component {
    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Döküman Yönetimi" subTitle="" />

                    <div className="pageHeaderOptions">
                        <Space>
                            {/* <Export
                                data={this.state.data}
                                columns={[...this.state.tableColumns].slice(0, 6)}
                                tableName={"Logs"}
                                dates={["tarih"]}
                            /> */}
                        </Space>
                    </div>
                </div>


                <Card bordered={false} className="cardTable">
                    <div className="tableFilter" style={{ marginBottom: "15px" }}>
                        test
                    </div>
                </Card>
                
            </div>
        );
    }
}

export default index;