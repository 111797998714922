import React, { Component } from "react";

import Table from "../../../Components/UI/Table";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Row,
  Col,
  Spin,
} from "antd";
import moment from "moment";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from "lodash";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

moment.locale("tr");
class index extends Component {
  constructor(props){
    super(props);
    this. state = {
      visible: false,
      data: [],
      CategoryData: [],
      BrandData: [],
      LabData: [],
      InventoryId: null,
      loading: true,
      btnloading: false,
      searchText:'',
      searchedColumn:'',
      tableColumns: [
        {
          title: "Adı TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Adı TR"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: "Adı EN",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            "Adı EN"
          ),
          sorter: (a, b) => a.name_en.localeCompare(b.name_en),
        },
        {
          title: "Kategori",
          dataIndex: "catName",
          key: "catName",
          ...Helper.getColumnSearchProps(
            "catName",
            this.handleSearch,
            this.handleReset,
            "Kategori"
          ),
          sorter: (a, b) => a.catName.localeCompare(b.catName),
        },
        {
          title: "Etiket",
          dataIndex: "brandName",
          key: "brandName",
          ...Helper.getColumnSearchProps(
            "brandName",
            this.handleSearch,
            this.handleReset,
            "Etiket"
          ),
          sorter: (a, b) => a.brandName.localeCompare(b.brandName),
        },
        {
          title: "Laboratuvar",
          dataIndex: "labName",
          key: "labName",
          ...Helper.getColumnSearchProps(
            "labName",
            this.handleSearch,
            this.handleReset,
            "Laboratuvar"
          ),
          sorter: (a, b) => a.labName.localeCompare(b.labName),
        },
        {
          title: "Garanti Başlangıç",
          dataIndex: "date_warranty_start",
          key: "date_warranty_start",
  
          sorter: (a, b) =>
            new Date(a.date_warranty_start).getTime() -
            new Date(b.date_warranty_start).getTime(),
        },
        {
          title: "Garanti Bitiş",
          dataIndex: "date_warranty_end",
          key: "date_warranty_end",
          sorter: (a, b) =>
            new Date(a.date_warranty_end).getTime() -
            new Date(b.date_warranty_end).getTime(),
        },
        {
          title: "Yıllık Bakım",
          dataIndex: "date_repair",
          key: "date_repair",
          sorter: (a, b) =>
            new Date(a.date_repair).getTime() - new Date(b.date_repair).getTime(),
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseInventory = await DataService.Inventory.SelectAll();
      const responseCategory = await DataService.InventoryCategory.SelectAll();
      const responseBrand = await DataService.InventoryBrand.SelectAll();
      const responseLab = await DataService.Laboratory.SelectAll();
      if (
        responseInventory.status &&
        responseCategory.status &&
        responseBrand.status &&
        responseLab.status
      ) {
        const { data } = responseInventory.response;
        const CategoryData = responseCategory.response.data;
        const BrandData = responseBrand.response.data;
        const LabData = responseLab.response.data;

        let inventory = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];

            inventory.push({
              ...element,
              id: key,
            });
          }
        }
        this.setState({
          data: inventory,
          CategoryData,
          BrandData,
          LabData,
          loading: false,
        });
      } else {
        if (!responseInventory.status)
          Helper.ErrorMessage(responseInventory.message);
        if (!responseCategory.status)
          Helper.ErrorMessage(responseCategory.message);
        if (!responseBrand.status) Helper.ErrorMessage(responseBrand.message);
        if (!responseLab.status) Helper.ErrorMessage(responseLab.message);
      }
    } catch (error) {
      console.log("Inventory", error);
    }
  };
  _editClick = ({
    inventory_id,
    cat_id,
    catName,
    brand_id,
    brandName,
    lab_id,
    labName,
    name,
    name_en,
    date_warranty_start,
    date_warranty_end,
    date_repair,
  }) => {
    this.setState({ visible: true, InventoryId: inventory_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          InventoryNameTR: name,
          InventoryNameEN: name_en,
          InventoryCategoryId:  cat_id,
          InventoryBrandId: brand_id,
          InventoryLabId: lab_id,
          InventoryWarrantyDate: [
            moment(date_warranty_start),
            moment(date_warranty_end),
          ],
          InventoryDateRepair: moment(date_repair),
        });
      }, 100);
    });
  };
  _deleteClick = ({ inventory_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Envanter silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(inventory_id);
      }
    });
  };
  deleted = (InventoryId) => {
    this.setState({ loading: true });
    DataService.Inventory.Delete(InventoryId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      InventoryId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({
    InventoryNameTR,
    InventoryNameEN,
    InventoryCategoryId,
    InventoryBrandId,
    InventoryLabId,
    InventoryWarrantyDate,
    InventoryDateRepair,
  }) => {
    try {
      console.log('formfinish',{
        InventoryNameTR,
        InventoryNameEN,
        InventoryCategoryId,
        InventoryBrandId,
        InventoryLabId,
        InventoryWarrantyDate,
        InventoryDateRepair:InventoryDateRepair.unix(),
      })
      const { InventoryId } = this.state;
      this.setState({ btnloading: true });
      let result,
        startDate = InventoryWarrantyDate[0].unix(),
        endDate = InventoryWarrantyDate[1].unix();
      if (InventoryId) {
        result = await DataService.Inventory.Update({
          InventoryId,
          InventoryNameTR,
          InventoryNameEN,
          InventoryCategoryId:
            typeof InventoryCategoryId == "object"
              ? InventoryCategoryId[1]
              : InventoryCategoryId,
          InventoryBrandId:
            typeof InventoryBrandId == "object"
              ? InventoryBrandId[1]
              : InventoryBrandId,
          InventoryLabId:
            typeof InventoryLabId == "object"
              ? InventoryLabId[1]
              : InventoryLabId,
          InventoryWarrantyStartDate: startDate,
          InventoryWarrantyEndDate: endDate,
          InventoryDateRepair:InventoryDateRepair.unix(),
        });
      } else {
        result = await DataService.Inventory.Create({
          InventoryNameTR,
          InventoryNameEN,
          InventoryCategoryId,
          InventoryBrandId,
          InventoryLabId,
          InventoryWarrantyStartDate: startDate,
          InventoryWarrantyEndDate: endDate,
          InventoryDateRepair:InventoryDateRepair.unix(),
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.setState(
          { loading: false, visible: false, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const category = this.state.CategoryData.map((item, index) => {
      return (
        <Option key={index} value={item.cat_id}>
          {item.name}
        </Option>
      );
    });
    const brand = this.state.BrandData.map((item, index) => {
      return (
        <Option key={index} value={item.brand_id}>
          {item.name}
        </Option>
      );
    });
    const labs = this.state.LabData.map((item, index) => {
      return (
        <Option key={index} value={item.laboratory_id}>
          {item.LabName}
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Envanter" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Inventory"}
                dates={["tarih"]}
              />

              <Tooltip title="Yeni Envanter Ekle" placement="left">
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                />
              </Tooltip>
            </Space>
          </div>

          <Modal
            title="Yeni Envanter Ekle"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="InventoryNameTR"
                    label="Envanter Adı TR"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input placeholder="Envanter Adı TR" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="InventoryNameEN"
                    label="Envanter Adı EN"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input placeholder="Envanter Adı EN" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="InventoryLabId"
                label="Laboratuvar"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {labs}
                </Select>
              </Form.Item>

              <Form.Item
                name="InventoryCategoryId"
                label="Kategori"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {category}
                </Select>
              </Form.Item>
              <Form.Item name="InventoryBrandId" label="Marka" rules={[{}]}>
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brand}
                </Select>
              </Form.Item>

              <Form.Item
                name="InventoryWarrantyDate"
                label="Garanti Başlangıç/Bitiş Tarihi"
                rules={[{ required: false }]}
              >
                <RangePicker
                  format={dateFormat}
                  style={{ width: "100%" }}
                  placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                />
              </Form.Item>

              <Form.Item
                name="InventoryDateRepair"
                label="Yıllık Bakım Tarihi"
                rules={[{ required: false }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Tarih Seçiniz"
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.InventoryId ? (
                  <>
                    {" "}
                    <EditOutlined /> Düzenle{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <PlusOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
            </Form>
          </Modal>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
