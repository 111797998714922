import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import PermDefault from "./permDefault";

import PermLabManager from "./permLabManager";
import PermLabPersonel from "./permLabPersonel";
import PermLabRegistrationOfficer from "./permLabRegistrationOfficer";
import PermLabTechnicalOfficer from "./permLabTechnicalOfficer";
import PermOperationCenter from "./permOperationCenter";

import PermSeniorManagement from "./permSeniorManagement";
import PermLabGozetim from "./permLabGozetim";

const Roles = ({ permId, sampleTestStatus, sampleStatus }) => {
  switch (permId) {
    case "234e82361ec29a9f1ef7cf9bd37782fc": // "name": "Üst Yönetim",
      return (
        <PermSeniorManagement
          sampleTestStatus={sampleTestStatus}
          sampleStatus={sampleStatus}
        />
      );

    case "cbe31d3b0c1d5c8700a3d5ae3c445771": // "name": "Lims Teknik Yetkilisi",
      return <PermLabTechnicalOfficer user={201} customer={102} />;

    case "bba0328e0b5726131ff55fe94c1edff4": // "name": "Operasyon Merkezi",
      return <PermOperationCenter user={201} customer={102} />;
        // qr ekle
    case "6bd9ecfeb76640f23685c713a99d69dc": // "name": "Lab Müdür",
      return <PermLabManager user={201} customer={102} />;
    case "c017d489f0fc91ab32a4444d5964f197": // "name": "Laboratuvar Personeli",
      return <PermLabPersonel user={201} customer={102} />;
    case "70850ecbb0174f3b03e1ec9f5e30e2f0": // "name": "Kayıt Yetkilisi",
      return <PermLabRegistrationOfficer user={201} customer={102} />;
      // buraya kadar qr
    case "730c2610e7ff1b5088d592378384a777": // "name": "Bölge Müdürü",
      return <PermLabGozetim />;

    case "f54a19e2d81c5bd09024a31cffe295b0": // "name": "Gözetim Personeli",
      return <PermLabGozetim />;

    case "eda47dd3df7856681473ba3d7fdf730c": // "name": "Firma Yetkilisi",
      return <div>"Firma Yetkilisi",</div>;

    case "25b2ecc9e2111a773615b2184fc792e3": // "name": "Firma Çalışanı",
      return <div>"Firma Çalışanı",</div>;
    case "0ae5cbdf55f9a7a3994d5067d6bde13c": // "name": "Muhasebe Birimi",
      return <div>"Muhasebe Birimi",</div>;
    case "42255033af64833afe301e0215282c72": // "name": "İnsan Kaynakları",
      return <div>"İnsan Kaynakları",</div>;

    case "dbf36ff3e3827639223983ee8ac47b42": // "name": "Super Admin",
      return (
        <PermSeniorManagement
          sampleTestStatus={sampleTestStatus}
          sampleStatus={sampleStatus}
        />
      );

    // return <div>"Super Admin"</div>;
    default:
      return <PermDefault user={200} customer={100} />;
  }
};

const RolesComponent = ({ permId }) => {
  const [sampleStatus, setSampleStatus] = useState([]);
  const [sampleTestStatus, setSampleTestStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    DataService.Dashboard.Select()
      .then((result) => {
        if (result.status) {
          result?.data?.sampleStatusCount &&
            setSampleStatus(result.data.sampleStatusCount);
          result?.data?.sampleTestStatusCount &&
            setSampleTestStatus(result.data.sampleTestStatusCount);
          setLoading(false);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  }, []);
  return (
    <Spin spinning={loading}>
      <Roles
        permId={permId}
        sampleStatus={sampleStatus}
        sampleTestStatus={sampleTestStatus}
      />
    </Spin>
  );
};

export default RolesComponent;
