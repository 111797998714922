import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import {
  Modal,
  Button,
  Tooltip,
  Card,
  Form,
  Input,
  Select,
  Space,
  Result,
  Badge,
} from "antd";
import PageHeader from "../../../Components/UI/PageHeader";

import { BellOutlined, RightOutlined } from "@ant-design/icons";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";

import moment from "moment";
const ListItem = ({ title, comment, url, view, dateCreate, onPress }) => {
  return (
    <div className="notification-card">
      <div className="card-icon">
        <Badge dot={!Boolean(view)}>
          <BellOutlined />
        </Badge>
      </div>
      <div className="card-content">
        <span className="text">{comment}</span>
        <span className="time">
          {moment(dateCreate).startOf("hour").fromNow()}
        </span>
      </div>
      <div className="card-action">
        <Button onClick={onPress} type="primary">
          Göster
        </Button>
      </div>
    </div>
  );
};

export default class index extends Component {
  state = {
    data: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const result = await DataService.Notification.SelectAll();
      if (result.status) {
        this.setState({
          data: result.response.data,
        });
      } else {
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  readNotification = async ({ url, id }) => {
    try {
      const result = await DataService.Notification.update([id]);
      if (result.status) {
        window.location.href = url;
      } else {
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Bildirimler" />
        </div>

        <Card bordered={false} className="cardTable">
          <div className="notification-card-list">
            {this.state.data.length > 0 ? (
              this.state.data.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    {...item}
                    onPress={() => this.readNotification(item)}
                  />
                );
              })
            ) : (
              <Result title="Bildirim Bulunamadı" />
            )}
          </div>
        </Card>
      </div>
    );
  }
}
