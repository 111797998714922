// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Announcement {
  constructor() {}

  /**
   * Duyuru Ekleme
   * @param {string} AnnouncementPermId
   * @param {string} AnnouncementLabId
   * @param {string} AnnouncementRegionId
   * @param {string} AnnouncementCustomerId
   * @param {string} AnnouncementTitle
   * @param {string} AnnouncementComment
   * @param {string} AnnouncementDateStart
   * @param {string} AnnouncementDateFinish
   */
  Create = ({
    AnnouncementPermId,
    AnnouncementLabId,
    AnnouncementRegionId,
    AnnouncementCustomerId,
    AnnouncementTitle,
    AnnouncementComment,
    AnnouncementDateStart,
    AnnouncementDateFinish,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          perm_id: AnnouncementPermId,
          lab_id: AnnouncementLabId,
          region_id: AnnouncementRegionId,
          customer_id: AnnouncementCustomerId,
          title: AnnouncementTitle,
          comment: AnnouncementComment,
          date_start: AnnouncementDateStart,
          date_finish: AnnouncementDateFinish,
        };

        const response = await ApiService(
          "post",
          Endpoint.Announcement.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Duyuru Düzenleme
   * @param {string} AnnouncementId - Duyuru Id
   * @param {string} AnnouncementPermId
   * @param {string} AnnouncementLabId
   * @param {string} AnnouncementRegionId
   * @param {string} AnnouncementCustomerId
   * @param {string} AnnouncementTitle
   * @param {string} AnnouncementComment
   * @param {string} AnnouncementDateStart
   * @param {string} AnnouncementDateFinish
   */
  Update = ({
    AnnouncementId,
    AnnouncementPermId,
    AnnouncementLabId,
    AnnouncementRegionId,
    AnnouncementCustomerId,
    AnnouncementTitle,
    AnnouncementComment,
    AnnouncementDateStart,
    AnnouncementDateFinish,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          ann_id: AnnouncementId,
          perm_id: AnnouncementPermId,
          lab_id: AnnouncementLabId,
          region_id: AnnouncementRegionId,
          customer_id: AnnouncementCustomerId,
          title: AnnouncementTitle,
          comment: AnnouncementComment,
          date_start: AnnouncementDateStart,
          date_finish: AnnouncementDateFinish,
        };

        const response = await ApiService(
          "put",
          Endpoint.Announcement.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Duyuru Listeleme
   * @param {number} limit
   * @param {number} page
   * @param {Date} startDate
   * @param {Date} endDate
   */
  SelectAll = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Announcement.getAll}/${limit}/${page}/${startDate}/${endDate}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };

  /**
   * Duyuruların Dashboard Gösterilmesi

   */
  Dashboard = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "get",
          Endpoint.Announcement.dashboard
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new Announcement();
