import React, { useState } from "react";
import { DatePicker, Button } from "antd";
import moment from "moment";
import Helper from "../../../Helper";
const { RangePicker } = DatePicker;

const App = ({ onClick }) => {
  const [dates, setDates] = useState([
    moment().add(-30, "days"),
    moment().add(0, "days"),
  ]);
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 30;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 30;
    return tooEarly || tooLate;
  };
  const _onClick = () => {
    if (dates.length == 2) {
      onClick(dates);
    } else {
      console.log("dates", dates);
      Helper.warningMessage("Lütfen iki tarih arasında bir seçim yapınız.");
    }
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}
    >
      <RangePicker
        // disabledDate={disabledDate}
        onCalendarChange={(value) => {
          setDates(value);
        }}
        placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
        defaultValue={[moment().add(-2, "week"), moment().add(2, "week")]}
        format={"DD MM YYYY"}
      />
      {/* İş Listesi */}
      <Button onClick={_onClick} style={{ marginLeft: "15px" }}>
        Listele
      </Button>
    </div>
  );
};

export default App;
