import React, { Component } from 'react';

import { NavLink } from "react-router-dom"

import Table from '../../../Components/UI/Table';
import PageHeader from '../../../Components/UI/PageHeader';


import qrCode from "../../../Assets/qrcode.svg";

import { Modal, Button, Tooltip, Card, Form, Input, Select, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;



class index extends Component {


    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Numune Durumu" />
                </div>


                <Card bordered={false} className="cardTable">
                </Card>
            </div>
        );
    }
}

export default index;