import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Space,
  Spin,
  Select,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import _ from "lodash";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
const { Option } = Select;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      sectors: [],
      data: [],

      id: null,

      tableColumns: [
        {
          title: " Numunenin Alındığı Yer  - TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            " Numunenin Alındığı Yer - TR"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: " Numunenin Alındığı Yer  - EN",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            " Numunenin Alındığı Yer - EN"
          ),
          sorter: (a, b) => a.name_en.localeCompare(b.name_en), // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const response = await DataService.Sample.getPart(3);
      if (response.status) {
        this.setState({
          data: response?.data || [],

          loading: false,
        });
      } else {
        this.setState({ loading: false });
        Helper.warningMessage(response.message);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log("Terminal Bilgisi", JSON.stringify(error));
    }

    DataService.Sector.SelectAll(4000, 1)
        .then((result) => {
          if (result.status) {
            const { data } = result.response;
            let sectors = [];

            sectors = data;

            this.setState({
              sectors: sectors
            });
          }
        })
        .catch((error) => {
          console.log("Sector", JSON.stringify(error));
        });
  };

  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _deleteClick = ({ id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} adlı Numunenin alındığı yeri silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(id);
      }
    });
  };
  deleted = (id) => {
    this.setState({ loading: true });
    DataService.Sample.sampleAtDelete(id)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  _editClick = ({ id, name, name_en, sector_id }) => {
    this.setState({ visible: true, id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          name,
          name_en,
          sector_id
        });
      }, 100);
    });
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      id: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ name, name_en, sector_id }) => {
    try {
      const { id } = this.state;
      this.setState({ btnloading: true });
      let result;
      if(id){
        result = await DataService.Sample.sampleAtUpdate({ name, name_en, sector_id, id });
      }else{
        result = await DataService.Sample.sampleAtCreate({ name, name_en, sector_id });
      }


      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            id: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false, visible: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const {
      sectors
    } = this.state;
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Numunenin Alındığı Yer" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
            <Button
              shape="round"
              className="btn-add"
              icon={<PlusOutlined />}
              onClick={this._showModal}
            >Yeni Kayıt</Button>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={" Numunenin Alındığı Yer"}
                dates={["tarih"]}
              />
            </Space>
          </div>

          <Modal
            title=" Numunenin Alındığı Yer Düzenleme"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="name"
                label=" Numunenin Alındığı Yer - TR"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="name_en"
                label="Numunenin Alındığı Yer - EN"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                  name="sector_id"
                  label="İş Kolu"
                  initialValue="834cb54b61bbed9d4296c995e4c5d8b2"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                      type: "string",
                    },
                  ]}
              >
                <Select
                    showSearch
                >
                  {sectors.map((item) => {
                    return (
                        <Option
                            key={item.id}
                            value={item.sector_id}
                        >
                          {item.name}
                        </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                <PlusOutlined /> Kaydet
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
