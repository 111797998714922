import React, {Component} from "react";
import "../index.css";

import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import {Form, InputNumber, Select, Row, Col, Space, Button} from "antd";

import PackList from "../TestList";
import _ from "lodash";
import Helper from "../../../../Helper";

const {Option, OptGroup} = Select;

class Analysis extends Component {
    state = {
        loading: true,
        multiple: 0,
        test: [],
        testPack: [],
        filterTest: null,
        filterTestPack: null,
        packList: [],
        WorkOrderSectorId: null,
        WorkOrderProductId: null,
    };
    forms = React.createRef();

    componentDidMount() {
        const {
            WorkOrderSectorId,
            SampleMultiple,
            SampleTests,
            SampleTestCount,
            WorkOrderProductId,
        } = this.props.initialValue;
        console.log('initialValue', this.props.initialValue);
        this.setState(
            {
                loading: false,
                ...this.props.data,
                WorkOrderSectorId,
                multiple: (SampleTestCount > 0 && WorkOrderSectorId !== "31479c2a2efc8bd273259ea67f4f5fdc" ? 0 : 1),
                WorkOrderProductId,
            },
            () => {
                if (!Boolean(SampleMultiple) && WorkOrderSectorId !== "31479c2a2efc8bd273259ea67f4f5fdc") {
                    this.forms.current.setFieldsValue({
                        SampleMultiple,
                        SampleTests,
                        SampleTestCount,
                    });
                    this._changeTests();
                } else {
                    this.forms.current.setFieldsValue({
                        SampleMultiple:1,
                        SampleTests,
                    });
                    this._changeTests();
                    console.log('girdiii',SampleMultiple);
                }
            }
        );
    }

    onChangeMultiple = (value) => {
        /*
          if (Boolean(value)) {
              this.forms.current.setFieldsValue({
                  SampleTests: [],
              });
          } else {
              this.forms.current.setFieldsValue({
                  SampleTests: [],
                  SampleTestCount: 2,
              });
          }
         */
        this.setState({
            multiple: Boolean(value),
            testCount: Boolean(value) ? 0 : 2,

            filterTest: null,
            filterTestPack: null,
            //packList: [],
        });
    };

    searchTests = (search) => {
        const {test, testPack} = this.state;
        if (search.length > 0) {
            const filterTest = _.filter(test, (item) => {
                const searchItem = `${item.name} / ${item.name_en} - (${item.method_name})`;
                return (
                    searchItem.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >=
                    0
                );
            });
            const filterTestPack = _.filter(testPack, (item) => {
                const searchItem = `${item.name} / ${item.name_en}`;
                return (
                    searchItem.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >=
                    0
                );
            });

            this.setState({
                filterTest,
                filterTestPack,
            });
        } else {
            this.setState({
                filterTest: test,
                filterTestPack: testPack,
            });
        }
    };
    _changeTests = () => {
        const {test, testPack} = this.state;
        const changeTest = this.forms.current.getFieldsValue();

        const SampleTests = changeTest.SampleTests;
        console.log('SampleTests',SampleTests);

        const packList = [];
        for (const key in SampleTests) {
            if (SampleTests.hasOwnProperty.call(SampleTests, key)) {
                const element = SampleTests[key];

                const testData = _.find(test, {test_id: element});
                const testPackData = _.find(testPack, {
                    pack_id: element,
                });
                console.log(`key:${key}`, {testData, testPackData});
                if (testData) {
                    packList.push(testData);
                }
                if (testPackData) {
                    let subdata = [];
                    for (const key in testPackData.tests) {
                        if (testPackData.tests.hasOwnProperty(key)) {
                            const element = testPackData.tests[key];
                            subdata.push({
                                name: element.name,
                            });
                        }
                    }

                    packList.push(testPackData);
                }
            }
        }

        this.setState({
            packList,
        });
    };

    previous = () => {
        const formData = this.forms.current.getFieldsValue();
        this.props.previous(formData, this.props.data.selectedWorkBranch);
    };
    getFormText = (element) => {
        return new Promise((resolve, reject) => {
            try {
                const {test, testPack} = this.state;
                const {SampleTests} = element;
                let SampleTestsText = "";

                for (const key in SampleTests) {
                    if (SampleTests.hasOwnProperty.call(SampleTests, key)) {
                        const element = SampleTests[key];
                        const testData = _.find(test, {test_id: element});
                        const testPackData = _.find(testPack, {
                            pack_id: element,
                        });
                        if (testData) {
                            SampleTestsText += `${testData.name}, `;
                        }
                        if (testPackData) {
                            SampleTestsText += `${testPackData.name}, `;
                        }
                    }
                }
                const formTextData = {
                    SampleTests: SampleTestsText,
                    SampleMultiple: element.SampleMultiple ? "Evet" : "Hayır",
                };
                resolve(formTextData);
            } catch (error) {
                reject(error);
            }
        });
    };
    onFormFinish = async (formData) => {
        try {
            const formTextData = await this.getFormText(formData);
            console.log("formTextDAta", this.props);
            this.props.next({formData, formTextData});
        } catch (error) {
            Helper.ErrorMessage(error.toString());
        }
    };

    render() {
        const {
            test,
            testPack,
            loading,
            WorkOrderSectorId,
            WorkOrderProductId,
            filterTest,
            filterTestPack,
            packList,
            multiple,
        } = this.state;
        const filterTestPackData = Array.isArray(filterTestPack)
            ? filterTestPack
            : testPack;
        /*
        const testPackFilters = multiple
            ? {
                product_id: WorkOrderProductId,
            }
            : {
                product_id: WorkOrderProductId,
                tankbytank: 1,
            };
         */
        const testPackFilters = {
            product_id: WorkOrderProductId,
        };
        const testPackData =
            WorkOrderProductId != null
                ? _.filter(filterTestPackData, testPackFilters)
                : filterTestPackData;
        console.log("filterTestData", {
            testPackFilters,
            testPackData,
            filterTestPack,
            testPack,
            filterTestPackData,
            WorkOrderProductId,
        });
        const filterTestData = Array.isArray(filterTest) ? filterTest : test;
        const testData =
            WorkOrderSectorId != null
                ? _.filter(filterTestData, {
                    sector_id: WorkOrderSectorId,
                })
                : filterTestData;

        const optionsTests = testData.map((item, index) => {
            return (
                <Option key={item.test_id} value={item.test_id}>
                    {item?.sector_name || "-"} | {item.name}/{item.name_en}{" "}
                    {item?.unit_name || "-"} ({item?.method_name || "-"}) - (Hassasiyet{" "}
                    {item?.number_characters || "-"})
                </Option>
            );
        });
        const revisedTestPack = testPackData.map((item, index) => {
            return (
                <Option key={item.pack_id} value={item.pack_id}>
                    {item.name}/{item.name_en} -({item?.method_name || "-"})
                </Option>
            );
        });
        if (loading) {
            return <div/>;
        }
        return (
            <Form
                layout="vertical"
                hideRequiredMark
                onFinish={this.onFormFinish}
                ref={this.forms}
            >
                <Row gutter={16}>
                    <Col md={6} span={24}>
                        <Form.Item
                            name="SampleMultiple"
                            label="Data Seçimi"
                            rules={[
                                {
                                    required: false,
                                    message: "Lütfen bu alanı boş bırakmayın",
                                },
                            ]}
                            initialValue={Number(0)}
                        >
                            <Select
                                placeholder="Seçiniz"
                                filterOption={false}
                                disabled={(this.state.WorkOrderSectorId === '31479c2a2efc8bd273259ea67f4f5fdc' ? true : false)}
                                onChange={this.onChangeMultiple}
                            >
                                <Option value={1}>Bir Test veya Paket</Option>
                                <Option value={0}>Birden Fazla Aynı Test (Tank by tank)</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    {/*
                        this.state.multiple ? (
                            <Col md={18} span={24}>
                                <Form.Item
                                    name="SampleTests"
                                    label="Test ve Test Paketleri "
                                    rules={[
                                        {
                                            required: false,
                                            message: "Lütfen bu alanı boş bırakmayın",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        placeholder="Seçiniz"
                                        filterOption={false}
                                        onSearch={this.searchTests}
                                        onChange={this._changeTests}
                                    >
                                        <OptGroup label="Test Paketleri">{revisedTestPack}</OptGroup>
                                        <OptGroup label="Testler">{optionsTests}</OptGroup>
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            <>
                                <Col md={2} span={24}>
                                    <Form.Item
                                        name="SampleTestCount"
                                        label="Tank Sayısı "
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                            },
                                        ]}
                                        initialValue={2}
                                    >
                                        <InputNumber
                                            min={2}
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={16} span={24}>
                                    <Form.Item
                                        name="SampleTests"
                                        label="Test Seçimi "
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Seçiniz"
                                            filterOption={false}
                                            onSearch={this.searchTests}
                                            mode="multiple"
                                        >
                                            {optionsTests}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>
                        )
                    */}
                    {
                        !this.state.multiple && (
                            <>
                                <Col md={2} span={24}>
                                    <Form.Item
                                        name="SampleTestCount"
                                        label="Tank Sayısı "
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                            },
                                        ]}
                                        initialValue={2}
                                    >
                                        <InputNumber
                                            min={2}
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )
                    }
                    <Col md={(!this.state.multiple ? 16 : 18)} span={24}>
                        <Form.Item
                            name="SampleTests"
                            label="Test ve Test Paketleri "
                            rules={[
                                {
                                    required: false,
                                    message: "Lütfen bu alanı boş bırakmayın",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Seçiniz"
                                filterOption={false}
                                onSearch={this.searchTests}
                                onChange={this._changeTests}
                            >
                                <OptGroup label="Test Paketleri">{revisedTestPack}</OptGroup>
                                <OptGroup label="Testler">{optionsTests}</OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {/*
                  Boolean(this.state.multiple) && (
                    <Row>
                        <Col md={24}>
                            <PackList data={packList || []}/>
                        </Col>
                    </Row>
                )
                */}
                <Row>
                    <Col md={24}>
                        <PackList data={packList || []}/>
                    </Col>
                </Row>
                <div className="stepActions">
                    <Space align="center">
                        <Button
                            size="large"
                            style={{margin: "0 8px"}}
                            onClick={this.previous}
                            icon={<ArrowLeftOutlined/>}
                        >
                            Önceki
                        </Button>

                        <Button
                            size="large"
                            type="primary"
                            // onClick={this.next}
                            htmlType="submit"
                            icon={<ArrowRightOutlined/>}
                        >
                            Sonraki
                        </Button>
                    </Space>
                </div>
            </Form>
        );
    }
}

export default Analysis;
