import React from "react";
import { Table, InputNumber, Checkbox, Select } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import _ from "lodash";
const { Option } = Select;
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class SortableTable extends React.Component {
  state = {
    dataSource: [],
    columns: [
      {
        title: "Sıralama",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        title: "İş Kolu",
        dataIndex: "sector_name",
      },
      {
        title: "Name TR",
        dataIndex: "name_tr",
      },
      {
        title: "Name EN",
        dataIndex: "name_en",
      },
      {
        title: "Method",
        dataIndex: "method_name",
      },
      {
        title: "Birim",
        dataIndex: "unit_name",
      },
      {
        title: "Min Değer",
        dataIndex: "ref_min",
        render: (text, record) => {
          if (record.number == 0) {
            return (
              <Select
                style={{ width: 150 }}
                placeholder="Seçiniz"
                optionFilterProp="children"
                onChange={(value) => this.save({ ...record, ref_min: value })}
                defaultValue={text}
              >
                {record.testOption?.map((item) => {
                  return <Option value={item.name}>{item.name}</Option>;
                })}
              </Select>
            );
          }
          let numberCharacters = Number(record.number_characters);

          numberCharacters = numberCharacters > 0 ? numberCharacters : 0;
          const steps =
            numberCharacters == 0
              ? 1
              : parseFloat(
                  `0.${Array(numberCharacters).join(numberCharacters)}1`
                );
          const defaultValue =
            text && parseFloat(text).toFixed(numberCharacters || 0);
          return (
            <>
              <Select
                style={{ width: 70 }}
                placeholder="Seçiniz"
                defaultValue={record?.ref_min_operator || "="}
                onChange={(value) =>
                  this.save({ ...record, ref_min_operator: value })
                }
              >
                <Option value=">">{">"}</Option>
                <Option value="=">{"="}</Option>
                <Option value="<">{"<"}</Option>
              </Select>
              <InputNumber
                defaultValue={
                  (isNaN(defaultValue) ? "-" : defaultValue) || steps
                }
                onBlur={(e) =>
                  this.save({ ...record, ref_min: e.target.value })
                }
                step={steps}
                disabled={
                  _.find(this.state.checkedRefs, { key: record.key })
                    ?.minChecked || false
                }
              />
              <Checkbox
                onChange={(e) => this.onCheckedMin(record, e.target.checked)}
                checked={
                  _.find(this.state.checkedRefs, { key: record.key })
                    ?.minChecked
                }
              />
            </>
          );
        },
      },
      {
        title: "Max Değer",
        dataIndex: "ref_max",
        render: (text, record) => {
          if (record.number == 0) {
            return "-";
          }
          let numberCharacters = Number(record.number_characters);

          numberCharacters = numberCharacters > 0 ? numberCharacters : 0;
          const steps =
            numberCharacters == 0
              ? 1
              : parseFloat(
                  `0.${Array(numberCharacters).join(numberCharacters)}1`
                );
          const defaultValue =
            text && parseFloat(text).toFixed(numberCharacters || 0);

          return (
            <>
              <Select
                style={{ width: 70 }}
                placeholder="Seçiniz"
                defaultValue={record?.ref_max_operator || "="}
                onChange={(value) => {
                  this.save({ ...record, ref_max_operator: value });
                }}
              >
                <Option value=">">{">"}</Option>
                <Option value="=">{"="}</Option>
                <Option value="<">{"<"}</Option>
              </Select>
              <InputNumber
                defaultValue={
                  (isNaN(defaultValue) ? "-" : defaultValue) || steps
                }
                onBlur={(e) =>
                  this.save({ ...record, ref_max: e.target.value })
                }
                step={steps}
                disabled={
                  _.find(this.state.checkedRefs, { key: record.key })
                    ?.maxChecked || false
                }
              />
              <Checkbox
                onChange={(e) => this.onCheckedMax(record, e.target.checked)}
                checked={
                  _.find(this.state.checkedRefs, { key: record.key })
                    ?.maxChecked
                }
              />
            </>
          );
        },
      },
    ],
    checkedRefs: [],
  };
  onCheckedMin = (row, checked) => {
    const newData = [...this.state.checkedRefs];
    const newDatas = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const indexs = newDatas.findIndex((item) => row.key === item.key);
    const item = newData[index];
    const datas = newDatas[indexs];
    newData.splice(index, 1, { ...item, minChecked: checked });
    newDatas.splice(index, 1, { ...datas, ref_min: !checked ? 0 : "-" });
    this.setState({
      checkedRefs: newData,
      dataSource: newDatas,
    });
  };
  onCheckedMax = (row, checked) => {
    const newData = [...this.state.checkedRefs];
    const newDatas = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const indexs = newDatas.findIndex((item) => row.key === item.key);
    const item = newData[index];
    const datas = newDatas[indexs];
    newData.splice(index, 1, { ...item, maxChecked: checked });
    newDatas.splice(index, 1, { ...datas, ref_max: !checked ? 0 : "-" });
    this.setState({
      checkedRefs: newData,
      dataSource: newDatas,
    });
  };
  componentDidMount() {
    this.setData(this.props.data);
  }
  componentDidUpdate(prev, next) {
    if (prev.data !== this.props.data) {
      this.setData(this.props.data);
    }
  }
  setData = (dataSource) => {
    let data = [];
    let checkedRefs = [];
    for (const key in dataSource) {
      if (dataSource.hasOwnProperty(key)) {
        const element = dataSource[key];
        data.push({
          name_tr: element.name,
          name_en: element.name_en,
          method_name: element.method_name,
          index: Number(element.line),
          key: element.test_id,
          ref_min: element?.ref_min || 0,
          ref_max: element?.ref_max || 0,
          unit_name: element.unit_name,
          sector_name: element.sector_name,
          number_characters: element?.number_characters || 0,
          ref_max_operator: element?.ref_max_operator || "=",
          ref_min_operator: element?.ref_min_operator || "=",
          number: element.number,
          testOption: element.testOption,
        });
        checkedRefs.push({
          key: element.test_id,
          minChecked: (element?.ref_min == "-" && true) || false,
          maxChecked: (element?.ref_max == "-" && true) || false,
        });
      }
    }

    this.setState({ dataSource: data, checkedRefs });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dataSource } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      this.setState({ dataSource: newData });
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };
  save = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
  };
  getData = () => {
    const { dataSource } = this.state;
    let newData = [];

    for (const key in dataSource) {
      if (dataSource.hasOwnProperty(key)) {
        const element = dataSource[key];

        newData.push({
          line: key,
          test_id: element.key,
          ref_min: element.ref_min,
          ref_max: element.ref_max,
          ref_min_operator: element.ref_min_operator || "=",
          ref_max_operator: element.ref_max_operator || "=",
        });
      }
    }
    return newData;
  };
  render() {
    const { dataSource, columns } = this.state;

    const DraggableContainer = (props) => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
    return (
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: this.DraggableBodyRow,
          },
        }}
      />
    );
  }
}

export default SortableTable;

/*

Custom CSS
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

*/
