import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import Table from "../../../Components/UI/Table";
import PageHeader from "../../../Components/UI/PageHeader";

import {
  Modal,
  Button,
  Tooltip,
  Card,
  Form,
  Tag,
  Select,
  Space,
  Spin,
} from "antd";
import _ from "lodash";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
const statusArray = [
  {
    id: 0,
    name: "Talep Alındı",
    color: "#fd6205",
  },
  {
    id: 1,
    name: "Numune Yolda",
    color: "#fe9835",
  },
  {
    id: 2,
    name: "Numune Ulaştı",
    color: "#ffce0b",
  },
  {
    id: 3,
    name: "Ödeme Bekliyor",
    color: "#1abc9c",
  },
  {
    id: 4,
    name: "Numune Analizde",
    color: "#9894ff",
  },
  {
    id: 5,
    name: "Rapolanacak",
    color: "#fd54ff",
  },
  {
    id: 6,
    name: "Raporlandı",
    color: "#cdc9ff",
  },
  {
    id: 7,
    name: "Muhasebe",
    color: "#9bd006",
  },
  {
    id: 8,
    name: "İşlem Tamamlandı",
    color: "#1cb451",
  },
];
const statusFilterArray = [
  {
    value: 0,
    text: "Talep Alındı",
  },
  {
    value: 1,
    text: "Numune Yolda",
  },
  {
    value: 2,
    text: "Numune Ulaştı",
  },
  {
    value: 3,
    text: "Ödeme Bekliyor",
  },
  {
    value: 4,
    text: "Numune Analizde",
  },
  {
    value: 5,
    text: "Raporlanacak",
  },
  {
    value: 6,
    text: "Raporlandı",
  },
  {
    value: 7,
    text: "Muhasebe",
    color: "#9bd006",
  },
  {
    value: 8,
    text: "İşlem Tamamlandı",
  },
];
class Sample extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      searchText: "",
      searchedColumn: "",
      tableColumns: [
        {
          title: "Rapor No",
          dataIndex: "report_no",
          key: "report_no",
          sorter: (a, b) => a.report_no.localeCompare(b.report_no),
          ...Helper.getColumnSearchProps(
            "report_no",
            this.handleSearch,
            this.handleReset,
            "Rapor No"
          ),
        },
        {
          title: "Numunenin Alındığı Yer",
          dataIndex: "get_part",
          key: "get_part",
          sorter: (a, b) => a.get_part.localeCompare(b.get_part),
          ...Helper.getColumnSearchProps(
            "get_part",
            this.handleSearch,
            this.handleReset,
            "Numunenin Alındığı Yer"
          ),
        },
        {
          title: "Müşteri",
          dataIndex: "customerName",
          key: "customerName",
          sorter: (a, b) => a.customerName.localeCompare(b.customerName),
          ...Helper.getColumnSearchProps(
            "customerName",
            this.handleSearch,
            this.handleReset,
            "Müşteri"
          ),
        },
        {
          title: "İş Kolu",
          dataIndex: "sectorName",
          key: "sectorName",
          sorter: (a, b) => a.sectorName.localeCompare(b.sectorName),
          ...Helper.getColumnSearchProps(
            "sectorName",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
        },
        {
          title: "Ürün",
          dataIndex: "productName",
          key: "productName",
          sorter: (a, b) => a.productName.localeCompare(b.productName),
          ...Helper.getColumnSearchProps(
            "productName",
            this.handleSearch,
            this.handleReset,
            "Ürün"
          ),
        },
        {
          title: "Operasyon",
          dataIndex: "operationName",
          key: "operationName",
          sorter: (a, b) => a.operationName.localeCompare(b.operationName),
          ...Helper.getColumnSearchProps(
            "operationName",
            this.handleSearch,
            this.handleReset,
            "Operasyon"
          ),
        },
        {
          title: "Durum",
          dataIndex: "status_id",
          key: "status_id",
          filters: statusFilterArray,
          onFilter: (value, record) => {
            return record.status_id === value + 1;
          },
          render: (status_id,record) => {
         
            const status = _.find(statusArray, { id: (status_id-1) });
            return (
              <Tag color={status?.color || "red"} key={status_id}>
                {record?.statusName || "Bulunamadı"}
              </Tag>
            );
          },
        },
        {
          title: "Seçenekler",
          dataIndex: "options",
          key: "options",
          render: (text, record) => (
            <Space size="middle">
            
                <Button shape="round" type="primary" disabled={record.status_id==6} href={`/sample/sample-test/edit/${record.sampleId}`}>
                  Sonuç Gir
                </Button>
            </Space>
          ),
        },
      ],
      data: [],
    };
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      this.setState({ loading: false });
      const result = await DataService.Sample.SelectAll(1);
      if (result.status) {
        console.log('result',result.response.data);
        this.setState({ data: result.response.data, loading: false });
      } else {
        this.setState({ loading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
      Helper.ErrorMessage(error.toString());
      this.setState({ loading: false });
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Numune Test" />
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Table columns={this.state.tableColumns} data={this.state.data} pagination={true} />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default Sample;
