import React from "react";

import { Row, Col } from "antd";




import { SamplesGraphic, Tests, Logs, Announcement } from "../Components";
const permLabTechnicalOfficer = ({}) => {
  return (
    <div>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <SamplesGraphic />
        </Col>
        <Col className="dash-col" span={24} md={12}>
          <Tests />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <Logs />
        </Col>
         <Col className="dash-col" span={24} md={12}>
          <Announcement />
        </Col>
      </Row>
    </div>
  );
};

export default permLabTechnicalOfficer;
