// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class User {
  constructor() {}

  /**
   * Kullanıcı Kayıt
   * @param {string} email  - Email Adresi
   * @param {string} password  - Password
   * @param {string} name  - Adı
   * @param {string} surname  - Soyadı
   * @param {string} phone  - Telefon Numarası
   */
  Register = ({
    perm_id,
    region_id,
    customer_id,
    lab_id,
    mail,
    pass,
    name,
    lastname,
    phone,
    lang,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          perm_id,
          region_id,
          customer_id,
          lab_id,
          mail,
          pass,
          name,
          lastname,
          phone,
          lang,
        };
        const response = await ApiService(
          "post",
          Endpoint.User.register,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı Düzenleme
   * @param {string} userId  - Kullanıcı Id
   * @param {string} permissionId  - Kullanıcı İzni
   * @param {string} regionId  - Bölge Id
   * @param {string} customerId  - Firma Id
   * @param {string} laboratuvarID  - Laboratuvar Id
   * @param {string} email  - Mail Adresi
   * @param {string} name  - Kullanıcı Adı
   * @param {string} surname  - Kullanıcı Soyadı
   * @param {string} phone  - Kullanıcı Telefon Numarası
   * @param {string} language  - Kullanıcı Dili
   */
  Update = ({
    user_id,
    perm_id,
    region_id,
    customer_id,
    lab_id,
    mail,

    name,
    lastname,
    phone,
    lang,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          user_id,
          perm_id,
          region_id,
          customer_id,
          lab_id,
          mail,

          name,
          lastname,
          phone,
          lang,
        };
        const response = await ApiService(
          "put",
          Endpoint.User.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı giriş işlemleri
   * @param {string} email  - Mail Adresi
   * @param {string} password  - Şifresi
   */
  Login = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          mail: email,
          pass: password,
        };
        const response = await ApiService(
          "post",
          Endpoint.User.login,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          Helper.setLocalStorage("Token", response.reply.Authorization);
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı Listeleme işlemleri
   * userID boş ise tüm kullanıcıları listeler
   * @param {number} userId  - Kullanıcı ID
   */
  Select = (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.User.getOne}/${userId}`;
        const response = await ApiService("get", endpoints);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı Listeleme işlemleri
   * @param {number} limit
   * @param {number} page
   * @param {number} permId
   */
  SelectAll = (permId = 0, limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.User.getAll}/${limit}/${page}/${permId}`;
        const response = await ApiService("get", endpoints);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply.posts,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı Yetkileri Listeleme
   * @param {number} limit
   * @param {number} page
   */
  permSelectAll = (limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.User.permGetAll}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Profil  Bilgileri
   */
  Profile = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.User.profile);
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Profil Düzenleme
   * @param {string} permissionId  - Kullanıcı İzin Id
   * @param {string} regionId  - Bölge Id
   * @param {string} customerId  - Firma Id
   * @param {string} laboratuvarId  - Laboratuvar Id
   * @param {string} email  - Mail Adresi
   * @param {string} name  - Kullanıcı Adı
   * @param {string} surname  - Kullanıcı Soyadı
   * @param {string} phone  - Kullanıcı Telefon Numarası
   * @param {string} language  - Kullanıcı Dili
   */
  profileUpdate = ({
    permissionId,
    regionId,
    customerId,
    laboratuvarId,
    email,
    name,
    surname,
    phone,
    language,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          perm_id: permissionId,
          region_id: regionId,
          customer_id: customerId,
          lab_id: laboratuvarId,
          mail: email,
          name: name,
          lastname: surname,
          phone: phone,
          lang: language,
        };
        const response = await ApiService(
          "put",
          Endpoint.User.profileUpdate,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Şifre Değiştirme
   * @param {string} currentPassword  - Mevcut Şifre
   * @param {string} password  - Yeni Şifre
   * @param {string} repassword  - Yeni Şifre Tekrar
   */
  Password = ({ currentPassword, password, repassword }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          lastPass: currentPassword,
          pass: password,
          passControl: repassword,
        };
        const response = await ApiService(
          "put",
          Endpoint.User.password,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Photo
   */
  Photo = (photo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("post", Endpoint.User.photo, photo);
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * İmza Ekleme
   */
  Signature = (photo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "post",
          Endpoint.User.signature,
          photo
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * 2. İmza Ekleme
   */
  SignatureSecond = (photo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "post",
          Endpoint.User.signatureSecond,
          photo
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Files
   */
  Files = (files) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          files,
        };
        const response = await ApiService(
          "get",
          Endpoint.User.files,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı Status
   * @param {string} userId  - Kullanıcı  Id
   */
  Status = ({ userId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          user_id: userId,
        };
        const response = await ApiService(
          "put",
          Endpoint.User.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Kullanıcı Silme
   * @param {string} userId  - Kullanıcı  Id
   */
  Delete = (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          user_id: userId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.User.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   *  Email Adresi ile şifre sıfırlama
   * @param {string} mail  - E-Mail Adresi
   */
  ForgotPassword = (mail) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          mail,
        };
        const response = await ApiService(
          "post",
          Endpoint.User.forgot,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   *  Yeni Şifre Oluşturma
   * @param {string} password  - Şifre
   * @param {string} repassword - Şifre Tekrar
   */
  ResetPassword = (password, repassword) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (password != repassword) {
          resolve({
            status: false,
            message: "Şifreleriniz uyuşmuyor.",
          });
          return;
        }
        const requestParams = {
          password,
          confirm_password: repassword,
        };
        const response = await ApiService(
          "post",
          Endpoint.User.reset,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}
export default new User();
