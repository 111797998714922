import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import dateTimeMoment from "moment";
import "./print.css";
class ComponentToPrint extends React.Component {
  state = {
    size : 120,
  }
  setSize=(size) => this.setState({size})
  _renderDetails = () => {
    const { data } = this.props;
    if (data?.stock.type_id == 4) {
      return (
        <>
          <span class="mt-1"><strong>Saklama</strong>: {data?.stock.storage_time_text}</span>
          <span><strong>Depo </strong>: {data.stock.store_id_text}</span>
          <span><strong>Konteyner </strong>: {data.stock.endless_storage_text} / {data.stock.store_part_id_text}</span>
        </>
      );
    } else if (data?.stock.type_id == 3) {
      return <span><strong>Laboratuvar </strong>: {data.stock.lab_id_text}</span>;
    } else {
      return;
    }
  };
  render() {
    const { data } = this.props;
    return (
      <div className="qr-print-wrapper">
        <div className="qr-print-img">
          <QRCode size={this.state.size}  value={this.props.value} />
        </div>
        <div className="qr-prin-details">
          <span><strong>Müşteri Adı </strong>: {data.customer}</span>
          <span><strong>Kayıt No </strong>: {data.registryNo}</span>
          <span><strong>Numune Adı </strong>: {data.name}</span>

          <span><strong>Alım Yeri </strong>: {data.sampleLocation}</span>
          <span><strong>Alım Tarihi </strong>: {data.dateDelivery}</span>
          {this._renderDetails()}
        </div>
      </div>
    );
  }
}

const Example = ({
  value,
  title,
  customer,
  name,
  sampleLocation,
  dateDelivery,
  stock,
  registryNo
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent : () => componentRef.current.setSize(100),
    onAfterPrint : () => componentRef.current.setSize(120),
  });
  
  return (
    <div className="qr-screen-wrapper">
      <ComponentToPrint
        ref={componentRef}
        value={value}
        data={{ customer, name, sampleLocation, dateDelivery, stock, registryNo }}
      />
      <div className="qr-yourCode">
        <text>Qr Kodunuz: {value} </text>
      </div>
      <div className="text-center qr-printButton">
        <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint}>
          {title}
        </Button>
      </div>
    </div>
  );
};

export default Example;
