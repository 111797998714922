// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Laboratory {
  constructor() {}

  /**
   * Laboratuvar Ekleme
   * @param {string} labCode - Laboratuvar Kodu
   * @param {string} labNameTr - Laboratuvar İsmi Türkçe
   * @param {string} labNameEn - Laboratuvar İsmi İngilizce
   * @param {string} labRegionId - Bölge Id
   * @param {string} labAdress - Bölge Id
   * @param {string} labAccredite - Bölge Id
   */
  Create = ({
    labCode,
    labNameTr,
    labNameEn,
    labRegionId,
    labAdress,
    labAccredite,
    images,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          code: labCode,
          name: labNameTr,
          name_en: labNameEn,
          region_id: labRegionId,
          lab_code: labAccredite,
          address: labAdress,
        };
        const requestForm = new FormData();
        requestForm.append("code", labCode);
        requestForm.append("name", labNameTr);
        requestForm.append("name_en", labNameEn);
        requestForm.append("region_id", labRegionId);
        requestForm.append("lab_code", labAccredite);
        requestForm.append("address", labAdress);

        for (const key in images) {
          if (images.hasOwnProperty(key)) {
            const element = images[key];
            requestForm.append("item_images[]", element.image, element.name);
          }
        }
        const response = await ApiService(
          "post",
          Endpoint.Laboratory.create,
          requestForm
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * laboratuvar Düzenleme
   * @param {string} labId -  Laboratuvar Id
   * @param {string} labCode - Laboratuvar Kodu
   * @param {string} labNameTr - Laboratuvar İsmi Türkçe
   * @param {string} labNameEn - Laboratuvar İsmi İngilizce
   * @param {string} labRegionId - Bölge Id
   * @param {string} labAdress - Bölge Id
   * @param {string} labAccredite - Bölge Id
   */
  Update = ({
    labId,
    labCode,
    labNameTr,
    labNameEn,
    labRegionId,
    labAdress,
    labAccredite,
    images,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          laboratory_id: labId,
          code: labCode,
          name: labNameTr,
          name_en: labNameEn,
          region_id: labRegionId,
          lab_code: labAccredite,
          address: labAdress,
        };
        const requestForm = new FormData();
        requestForm.append("laboratory_id", labId);
        requestForm.append("code", labCode);
        requestForm.append("name", labNameTr);
        requestForm.append("name_en", labNameEn);
        requestForm.append("region_id", labRegionId);
        requestForm.append("lab_code", labAccredite);
        requestForm.append("address", labAdress);

        for (const key in images) {
          if (images.hasOwnProperty(key)) {
            const element = images[key];
            requestForm.append("item_images[]", element.image, element.name);
          }
        }
        const response = await ApiService(
          "post",
          Endpoint.Laboratory.update,
          requestForm,
          true
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Laboratuvar Silme İşlemi
   * @param {string} labId - Laboratuvar ID
   */
  Delete = (labId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          laboratory_id: labId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.Laboratory.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Laboratuvarları Listeleme
   * @param {number} limit
   * @param {number} page
   * @param {Date} startDate
   * @param {Date} endDate
   */
  SelectAll = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Laboratory.getAll}/${limit}/${page}/${startDate}/${endDate}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * laboratuvar Detayı Alma
   * @param {string} labId  - Laboratuvar Id
   */
  Select = (labId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          laboratory_id: labId,
        };
        const response = await ApiService(
          "post",
          Endpoint.Laboratory.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Laboratuvar Aktif/Pasif Durumu Değiştirme
   * @param {string} labId  - Laboratuvar Id
   */
  StatusChange = (labId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          laboratory_id: labId,
        };

        const response = await ApiService(
          "post",
          Endpoint.Laboratory.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new Laboratory();
