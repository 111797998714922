import React, { Component } from "react";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  Spin,
  Upload,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import Table from "../../../Components/UI/Table";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
// img upload
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const toBase64 = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .catch((err) => console.log("fetcherr", err));
const imageUrl = "https://apilims.intertekturkey.com/getImage/";
// analiz listesi excel ALİA	ANT	KRK	TRB	İSK	MER	İST	MAR	İGA

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      regionData: [],
      labId: null,
      fileList: [],
      tableColumns: [
        {
          title: "Akredite No",
          dataIndex: "lab_code",
          key: "lab_code",
          responsive: ["md"],
          ...Helper.getColumnSearchProps(
            "lab_code",
            this.handleSearch,
            this.handleReset,
            "Akredite No"
          ),
          sorter: (a, b) => a.lab_code.localeCompare(b.lab_code), // string sıralama
        },
        {
          title: "Laboratuvar Adı",
          dataIndex: "LabName",
          key: "LabName",
          responsive: ["md"],
          ...Helper.getColumnSearchProps(
            "LabName",
            this.handleSearch,
            this.handleReset,
            "Laboratuvar Adı"
          ),
          sorter: (a, b) => a.LabName.localeCompare(b.LabName), // string sıralama
        },
        // {
        //   title: "Laboratuvar Adı (EN)",
        //   dataIndex: "LabNameEn",
        //   key: "LabNameEn",
        //   responsive: ["md"],
        //   ...Helper.getColumnSearchProps(
        //     "LabNameEn",
        //     this.handleSearch,
        //     this.handleReset,
        //     "Laboratuvar Adı En"
        //   ),
        //   sorter:(a,b) => a.LabNameEn.localeCompare(b.LabNameEn) // string sıralama
        // },
        {
          title: "Kodu",
          dataIndex: "code",
          key: "code",
          ...Helper.getColumnSearchProps(
            "code",
            this.handleSearch,
            this.handleReset,
            "Kodu"
          ),
          sorter: (a, b) => a.code.localeCompare(b.code), // string sıralama
        },
        {
          title: "Bölge",
          dataIndex: "regionName",
          key: "regionName",
          ...Helper.getColumnSearchProps(
            "regionName",
            this.handleSearch,
            this.handleReset,
            "Bölge"
          ),
          sorter: (a, b) => a.regionName.localeCompare(b.regionName), // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseLaboratory = await DataService.Laboratory.SelectAll();
      const responseRegion = await DataService.Region.SelectAll();
      if (responseLaboratory.status && responseRegion.status) {
        const { data } = responseLaboratory.response;
        const regionData = responseRegion.response.data;
        let labs = [],
          regions = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            labs.push({
              ...element,
              id: key,
              LabNameEn:
                element.LabNameEn == "" ? element.LabName : element.LabNameEn,
            });
          }
        }
        for (const key in regionData) {
          if (regionData.hasOwnProperty(key)) {
            const element = regionData[key];
            regions.push({
              value: element.region_id,
              text: element.name,
            });
          }
        }
        this.setState({
          data: labs,
          regionData: regions,
          loading: false,
        });
      }
    } catch (error) {
      console.log("Labs", JSON.stringify(error));
    }
  };
  _editClick = ({
    laboratory_id,
    code,
    LabName,
    LabNameEn,
    region_id,
    address,
    lab_code,
    signPhoto,
  }) => {
    let fileList = [];
    if (!!signPhoto) {
      fileList.push({
        uid: 0,
        name: signPhoto,
        status: "done",
        url: `${imageUrl}${signPhoto}`,
      });
    }

    this.setState({ visible: true, labId: laboratory_id, fileList }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          labCode: code,
          labNameTr: LabName,
          labNameEn: LabNameEn,
          labRegionId: region_id,
          labAccredite: lab_code,
          labAdress: address,
        });
      }, 100);
    });
  };
  _deleteClick = ({ laboratory_id, LabName }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${LabName} Laboratuvarını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(laboratory_id);
      }
    });
  };
  deleted = (labId) => {
    this.setState({ loading: true });
    DataService.Laboratory.Delete(labId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      labId: null,
      btnloading: false,
      fileList:[]
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({
    labCode,
    labNameTr,
    labNameEn,
    labRegionId,
    labAdress,
    labAccredite,
  }) => {
    try {
      const { labId, fileList } = this.state;
      this.setState({ btnloading: true });
      let images = [];
      for (const key in fileList) {
        if (fileList.hasOwnProperty(key)) {
          const element = fileList[key];

          try {
            let image;
            if (element.url) {
              image = await toBase64(element.url);
              image = new Blob([image], { type: "image/jpeg" });
            } else if (element.originFileObj) {
              image = await getBase64(element.originFileObj);
              image = new Blob([image], { type: element.originFileObj.type });
            } else {
              image = new Blob([element.url], { type: "image/jpeg" });
            }

            images.push({
              image,
              name: element.name,
            });
          } catch (error) {
            console.log("fileList-keys", error);
          }
        }
      }
      let result;
      if (labId) {
        result = await DataService.Laboratory.Update({
          labCode,
          labNameTr,
          labNameEn,
          labRegionId,
          labId,
          labAdress,
          labAccredite,
          images,
        });
      } else {
        result = await DataService.Laboratory.Create({
          labCode,
          labNameTr,
          labNameEn,
          labRegionId,
          labAdress,
          labAccredite,
          images,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, labId: null, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      this.setState({ btnloading: false });
      Helper.ErrorMessage(error.toString());
    }
  };
  // img upload
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleChange = ({ fileList }) => {
    this.setState({ fileList });
  };

  handleCancel = () => this.setState({ previewVisible: false });
  render() {
    const region = this.state.regionData.map((item, index) => (
      <Option key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    const uploadButton = (
      <div>
        <UploadOutlined />
        <div className="ant-upload-text">Yükle</div>
      </div>
    );
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Laboratuvar" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Laboratuvarlar"}
                dates={["tarih"]}
              />

              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Laboratuvar Ekle
              </Button>
            </Space>

            <Modal
              title="Yeni Laboratuvar Ekle"
              visible={this.state.visible}
              onCancel={this._closeModal}
              footer=""
            >
              <Form
                layout="vertical"
                hideRequiredMark
                onFinish={this._formFinish}
                ref={this.formRef}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="labNameTr"
                      label="Laboratuvar Adı TR"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="labNameEn"
                      label="Laboratuvar Adı EN"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="labCode"
                      label="Laboratuvar Kodu"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Input placeholder="Örneğin; LBR" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="labAccredite"
                      label="Akredite Numarası"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Input placeholder="Örneğin; AB-003-T" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="labRegionId"
                  label="Bölge"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select showSearch placeholder="Seçiniz">
                    {region}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="labAdress"
                  label="Adres"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <TextArea placeholder="" />
                </Form.Item>
                <Form.Item name="imageFile" label="Resim Ekle">
                  <Upload
                    listType="picture-card"
                    fileList={this.state.fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    accept="image/*"
                  >
                    {this.state.fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <div className="formButton">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    {this.state.labId ? (
                      <>
                        <PlusOutlined /> Kaydet
                      </>
                    ) : (
                      <>
                        <EditOutlined /> Ekle
                      </>
                    )}
                  </Button>
                </div>
              </Form>
            </Modal>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
