import React, { Component } from "react";

import { Row, Col, Form, Select, Button, DatePicker, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

class ListItemsInspection extends Component {
  state={
    productId:null
  }

  render() {
    const { productData, regionData } = this.props;
  
    const products = productData.map((item, index) => {
      return (
        <Option key={index} value={item.product_id}>
          {item.name}
        </Option>
      );
    });
    const regions = regionData.map((item, index) => {
      return (
        <Option key={index} value={item.region_id}>
          {item.name}
        </Option>
      );
    });
    
    return (
      <div>
        <Row gutter={16}>
          <Col md={6} span={24}>
            <Form.Item
              name={this.props.location}
              label="Bölge"
              rules={[
                { required: true, message: "Lütfen bu alanı boş bırakmayın" },
              ]}
              initialValue={this.props.regionId}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="Seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={this.props.regionDisabled}
              >
                {regions}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} span={24}>
            <Form.Item
              name={this.props.productNameInspection}
              label="Ürünler"
              rules={[
                { required: true, message: "Lütfen bu alanı boş bırakmayın" },
              ]}
            >
              <Select
           
                showSearch
                placeholder="Seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(productId) => this.setState({ productId })}
              >
                {products}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col md={6} span={24}>
                        <Form.Item
                            name="sample_taken_date"
                            label="Tarih"
                            rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                            >
                            <DatePicker style={{ width: '100%' }} placeholder="Tarih Seçiniz" />
                        </Form.Item>
                    </Col>
                     */}

          <Col
            md={4}
            span={24}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <Button onClick={this.props.onClick} type="danger">
              <PlusOutlined /> Sil
            </Button>
          </Col>
        </Row>

        <Divider />
      </div>
    );
  }
}

export default ListItemsInspection;
