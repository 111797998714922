// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class TestLab {
  constructor() {}

  /**
   * Test Lab Ekleme
   * @param {array} labTest
   * @param {string} testId
   * @param {string} testUreal
   * @param {string} testAccredited
   */
  Create = (labTestInfo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          labTestInfo
        };
        const response = await ApiService(
          "post",
          Endpoint.TestLab.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Test Lab Silme İşlemi
   * @param {string} testId
   */
  Delete = (testId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_id: testId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.TestLab.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Lab Test Listeleme
   */
  selectAll = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${Endpoint.TestLab.selectAll}/0/0`;
        const response = await ApiService("get", url);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",

          data: [],
        };

        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply.posts,
          };
        } else {
          result.message = response.message;
        }

        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Detayları
   * @param {string} testId
   */
  select = (testId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${Endpoint.TestLab.select}/${testId}`;
        const response = await ApiService("get", url);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          info: {},
          data: [],
        };

        if (response.status) {
          result = {
            status: true,
            message: response.message,
            info: response?.reply?.testLabInfo || {},
            data: response?.reply?.testLabProduct || [],
          };
        } else {
          result.message = response.message;
        }

        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new TestLab();
