import React, { useEffect, useState } from "react";

import { Card, Button, Space, Spin, Result } from "antd";
import { NavLink } from "react-router-dom";
import axios from "axios";
import * as Endpoint from "../../../Provider/Config/EndpointUrl";
import { baseAPIUrl } from "../../../Provider/Config/Config";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import Table from "../../../Components/UI/Table";

const WorkOrders = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const tableColumns = [
    {
      title: "Rapor No",
      dataIndex: "report_no",
      key: "report_no",
    },
    {
      title: "ATF No",
      dataIndex: "atf_no",
      key: "atf_no",
    },
    {
      title: "LAB",
      dataIndex: "lab_code",
      key: "lab_code",
    },
    {
      title: "Durumu",
      dataIndex: "statusName",
      key: "statusName",
    },
    {
      title: "Seçenekler",
      dataIndex: "options",
      key: "options",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="primary"
            onClick={() => getLink(record.sampleId)}
          >
            İncele
          </Button>
        </Space>
      ),
    },
  ];
  const   _showReport = (fileName) => {
    axios.defaults.headers.common["X-API-KEY"] =
      "e3784eb2-b842-4a31-a204-14bc90bc5856";
    axios.defaults.headers.common["Authorization"] = Helper.getlocalStorage(
      "Token"
    );
    axios({
      url: `${baseAPIUrl}${Endpoint.Sample.getPdf}/${fileName}`, //your url
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  };
  const getLink = (sampleId) => {
    DataService.Dashboard.CreateLink(sampleId)
      .then((result) => {
     

        if (result.status) {
          if (result.data.type == 0) {
            window.location.href = result.data.url;
          } else {
            _showReport(result.data.url)
           
          }
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  useEffect(() => {
    DataService.Dashboard.WorkOrder()
      .then((result) => {
        if (result.status) {
          setData(result.response.data);
          setLoading(false);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  }, []);
  return (
    <Spin spinning={loading}>
      <Card
        bordered={false}
        className="cardTable"
        title="Son İş Emirleri"
        extra={<a href="/sample/work-orders">Tümünü Gör</a>}
      >
        {data?.length > 0 ? (
          <Table columns={tableColumns} data={data} />
        ) : (
          <Result title="Kayıt bulunamadı." />
        )}
      </Card>
    </Spin>
  );
};

export default WorkOrders;
