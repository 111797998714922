import React, { Component } from "react";

import { NavLink, Redirect } from "react-router-dom";

import PageHeader from "../../../../Components/UI/PageHeader";

import PackList from "../PackList";

import ListSort from "../../../../Components/UI/ListSort";
import {
  QuestionCircleOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CrownOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  Form,
  Button,
  Card,
  Space,
  Row,
  Col,
  Select,
  Divider,
  Input,
  Checkbox,
} from "antd";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
import _ from "lodash";

const { Option, OptGroup } = Select;
const { TextArea } = Input;
class index extends Component {
  state = {
    data: [],
    filteredData: [],
    testData: [],
    products: [],
    sectors: [],
    productId: null,
    sectorId: null,
    testPackNameTr: "",
    testPackNameEn: "",
    redirect: false,
    page: "/",
    description: "",
    description_en: "",
    tankbytank: false,
  };

  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const resultTests = await DataService.Test.SelectAll();
      const resultProduct = await DataService.Product.SelectAll();
      const resultSector = await DataService.Sector.SelectAll();
      if (resultTests.status && resultProduct.status && resultSector.status) {
        const sectorData = [
          {
            sector_id: 0,
            name: "Tümü",
          },
        ];
        this.setState({
          data: resultTests.response.data,
          testData: resultTests.response.data,
          products: resultProduct.response.data,
          sectors: sectorData.concat(resultSector.response.data),
        });
      } else {
        !resultTests.status && Helper.ErrorMessage(resultTests.message);
        !resultProduct.status && Helper.ErrorMessage(resultProduct.message);
        !resultSector.status && Helper.ErrorMessage(resultSector.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  _findTest = (testId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await DataService.Test.Select(testId);
        if (response.status) {
          resolve(response.data);
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  _onValueChange = async (data) => {
    try {
      this.setState({ loading: true });
      let filteredData = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const element = data[key];

          const find = await this._findTest(element);

          if (find) {
            const {
              name,
              test_id,
              name_en,
              method_name,
              sector_name,
              unit_name,
              number_characters,
              number,
              ref_max,
              ref_max_operator,
              ref_min,
              ref_min_operator,
              testOption,
            } = find;
            filteredData.push({
              line: key,
              name,
              test_id,
              name_en,
              method_name,
              unit_name,
              sector_name,
              number_characters,
              number,
              ref_max,
              ref_max_operator,
              ref_min,
              ref_min_operator,
              testOption,
            });
          }
        }
      }

      this.setState({
        filteredData,
        testData: this.state.data,
        loading: false,
      });
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  _onFinish = () => {
    const {
      testPackNameTr,
      testPackNameEn,
      productId,
      description,
      description_en,
      sectorId,
      tankbytank,
    } = this.state;

    const testPackList = this.refPaclist?.getData() || [];
    if (testPackNameTr == "" || testPackNameEn == "") {
      Helper.ErrorMessage("Lütfen Paket ismini boş bırakmayınız.");
      return;
    }
    if (!productId) {
      Helper.ErrorMessage("Lütfen Ürün Seçiniz");
      return;
    }
    if (testPackList.length == 0) {
      Helper.ErrorMessage("En az 1 adet test ekleyiniz.");
      return;
    }

    const params = {
      testPackList: testPackList,
      testPackNameTr,
      testPackNameEn,
      testPackCount: testPackList.length,
      testProductId: productId,
      testDescription: description,
      testDescriptionEN: description_en,
      sectorId,
      tankbytank,
    };

    DataService.TestPack.Create(params)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(
            "İşleminiz başarılı bir şekilde gerçekleştirildi."
          );

          this.setState({
            loading: false,
            visible: false,
            page: "/definitions/test-pack",
            redirect: true,
          });
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  searchTests = (search) => {
    if (search.length > 0) {
      const testData = _.filter(this.state.data, (item) => {
        const searchItem = `${item.name} / ${item.name_en} (${item.method_name})`;

        return searchItem.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      });

      this.setState({
        testData,
      });
    } else {
      const { data } = this.state;
      this.setState({
        testData: data,
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.page} />;
    }
    const testData = this.state?.sectorId
      ? _.filter(this.state.testData, { sector_id: this.state.sectorId })
      : this.state.testData;
    const group = _.groupBy(
      _.orderBy(testData, ["sector_name"], ["asc"]),
      "sector_id"
    );

    const sortabletest = _.orderBy(group, ["sector_name"], ["asc"]);

    const tests = sortabletest.map((item, index) => {
      return (
        <OptGroup
          label={
            <text style={{ color: "red", fontWeight: "bold", fontSize: 20 }}>
              {item[index]?.sector_name || item.sector_name}
            </text>
          }
          key={index}
        >
          {item.map((testItem, testIndex) => {
            return (
              <Option key={testIndex} value={testItem.test_id}>
                {testItem.name} / {testItem.name_en}{" "}
                {testItem?.unit_name || "-"} ({testItem?.method_name || "-"})
              </Option>
            );
          })}
        </OptGroup>
      );
    });
    const products = this.state.products.map((item, index) => {
      return (
        <Option key={index} value={item.product_id}>
          {item.name}
        </Option>
      );
    });
    const sectors = this.state.sectors.map((item, index) => {
      return (
        <Option key={index} value={item.sector_id}>
          {item.name}
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Paketi Ekle" />

          <div className="pageHeaderOptions">
            <Space>
              {/* <Button type="primary" primary icon={<PlusOutlined />} onClick={this.showInfoModal}>
                                Bilgi
                            </Button> */}

              <NavLink
                activeClassName="nav-active"
                exact
                to="/definitions/test-pack"
              >
                <Button type="primary" shape="round" className="btn-add">
                  Test Paketleri
                </Button>
              </NavLink>
            </Space>
          </div>
        </div>

        <Card bordered={false} className="cardTable">
          <Row gutter={16}>
            <Col span={24}>
              <Form layout="vertical">
                <Row gutter="16">
                  <Col span={5}>
                    <Form.Item name="name_tr" label="Paket Adı TR">
                      <Input
                        onChange={(e) =>
                          this.setState({ testPackNameTr: e.target.value })
                        }
                        value={this.state.testPackNameTr}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="name_en" label="Paket Adı EN">
                      <Input
                        onChange={(e) =>
                          this.setState({ testPackNameEn: e.target.value })
                        }
                        value={this.state.testPackNameEn}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="sector" label="İş Kolu">
                      <Select
                        showSearch
                        placeholder="Seçiniz"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(sectorId) => this.setState({ sectorId })}
                      >
                        {sectors}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="product" label="Ürün">
                      <Select
                        showSearch
                        placeholder="Seçiniz"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(productId) => this.setState({ productId })}
                      >
                        {products}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/*
                    <Col span={4} style={{display: "flex", alignItems: 'center'}}>
                      <Checkbox
                          value={this.state.tankbytank}
                          onClick={() =>
                              this.setState({tankbytank: !this.state.tankbytank})
                          }
                      >
                        Tankbytank
                      </Checkbox>
                    </Col>
                  */}
                </Row>

                <Form.Item name="test_list" label="Testler">
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="Seçiniz"
                    style={{ width: "100%" }}
                    onChange={this._onValueChange}
                    filterOption={false}
                    onSearch={this.searchTests}
                  >
                    {tests}
                  </Select>
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="description" label="Açıklama TR">
                      <TextArea
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="description_en" label="Açıklama EN">
                      <TextArea
                        onChange={(e) =>
                          this.setState({ description_en: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {/* <Row gutter="16">
            <Col span={24}>
              <h3 style={{marginBottom: '0'}}>
                Testler ({this.state.filteredData.length || 0})
              </h3>
              {<small>Test sıralamasını sürükle/bırak ile değiştirebilirsiniz.</small> }

              <ListSort
                data={this.state.filteredData}
                onChange={(filteredData) => this.setState({ filteredData })}
              />
            </Col>
          </Row> */}

          <PackList
            ref={(refs) => (this.refPaclist = refs)}
            data={this.state.filteredData}
          />

          <div className="formButton">
            <Button
              type="primary"
              onClick={this._onFinish}
              style={{ marginTop: 15 }}
              //    onClick={()=>console.log('filteredDAta',this.refPaclist.getData())}
            >
              <PlusOutlined />
              Kaydet
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default index;
