// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class TestDevice {
  constructor() {}

  /**
   * Test Device Ekleme
   * @param {string} testDeviceDeviceId
   * @param {string} testDeviceTestId
   */
  Create = ({ testDeviceDeviceId, testDeviceTestId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_id: testDeviceTestId,
          device_id: testDeviceDeviceId,
        };
        const response = await ApiService(
          "post",
          Endpoint.TestDevice.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Device Düzenleme
   * @param {string} testDeviceId
   * @param {string} testDeviceDeviceId
   * @param {string} testDeviceTestId
   */
  Update = ({ testDeviceId, testDeviceDeviceId, testDeviceTestId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_device_id: testDeviceId,
          test_id: testDeviceTestId,
          device_id: testDeviceDeviceId,
        };
        const response = await ApiService(
          "put",
          Endpoint.TestDevice.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Silme İşlemi
   * @param {string} testDeviceId
   */
  Delete = (testDeviceId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_device_id: testDeviceId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.TestDevice.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Test Device Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.TestDevice.getAll}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Test Device Detayı Alma
   * @param {string} testDeviceId
   */
  Select = (testDeviceId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          test_device_id: testDeviceId,
        };
        const response = await ApiService(
          "get",
          Endpoint.TestDevice.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Device Inventory Listeleme
   * @param {number} limit
   * @param {number} page
   */
  inventorySelectAll = (limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.TestDevice.inventory}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Device Test Listeleme
   * @param {number} limit
   * @param {number} page
   */
  testSelectAll = (limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.TestDevice.test}/${limit}/${page}`;
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new TestDevice();
