import React, { Component } from "react";
import "../index.css";

import { PlusOutlined, ArrowRightOutlined } from "@ant-design/icons";

import CustomerForm from "../../../Customers/Form";
import {
  Button,
  Form,
  Input,
  Select,
  Divider,
  Row,
  Col,
  Modal,
  Spin,
} from "antd";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
import _ from "lodash";

const { Option } = Select;
class Registration extends Component {
  state = {
    loading: true,
    selectedWorkBranch: {},
    customerAddVisible: false,
    registerDisable: false,
    customer: [],
    sector: [],
    product: [],
    workorder: [],
    operation: [],
    spinning: false,
  };
  forms = React.createRef();
  componentDidMount() {
    const { data, initialValue } = this.props;

    this.setState({ loading: false, ...data }, () => {
      this.forms.current.setFieldsValue(initialValue);
      // alert("Component did mount");
      this.setState({
        selectedWorkBranch: this.state.sector.filter((item) => item.sector_id === this.props.initialValue.WorkOrderSectorId)[0]
      });
    });
  }

  _showModal = () => {
    this.setState({
      customerAddVisible: true,
    });
  };
  _closeModal = () => {
    this.setState({
      customerAddVisible: false,
    });
  };
  _closeModals = (e) => {
    this.setState({
      customerAddVisible: false,
      spinning: true,
    });

    DataService.Customer.SelectAll()
      .then((result) => {
        if (result.status) {
          const customerId = _.find(result.response.data, { name: e })
            .customer_id;

          this.setState(
            {
              customer: result.response.data,
              spinning: false,
            },
            () => {
              this.forms.current.setFieldsValue({
                WorkOrderCustomerId: customerId,
              });
            }
          );
        } else {
          Helper.ErrorMessage(result.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
        this.setState({ loading: false });
      });
  };

  _sectorChange = async (sectorId) => {
    const responseGetPart = await DataService.Sample.getPart(sectorId);
    this.setState({workorder: responseGetPart?.data || [],});

    try {
      this.setState({
        spinning: true,
      });
      const responseProduct = await DataService.Product.SelectAll(
        0,
        0,
        sectorId
      );
      this.props.sectorChange(sectorId);
      if (responseProduct.status) {
        this.setState({
          selectedWorkBranch: this.state.sector.filter((item) => item.sector_id === sectorId)[0],
          product: responseProduct.response.data,
          spinning: false,
        });
      } else {
        this.setState({
          spinning: false,
        });
        Helper.ErrorMessage(responseProduct.message);
      }
    } catch (error) {
      this.setState({
        spinning: false,
      });
      Helper.ErrorMessage(error);
    }
  };


  addItem = ({ value }) => {
    if(!value) return;
    const newData = [...this.state.workorder];

    newData.push({
      name: value,
    });
    this.setState(
      {
        workorder: newData,
      },
      () => {
        this.forms.current.setFieldsValue({
          WorkOrderName: value,
        });

        this.WorkOrderName.setValue("");
      }
    );
  };
  getFormText = (element) => {
    return new Promise((resolve, reject) => {
      try {
        const customer =
          _.find(this.state.customer, {
            customer_id: element.WorkOrderCustomerId,
          })?.name || "N/A";
        const sector =
          _.find(this.state.sector, { sector_id: element.WorkOrderSectorId })
            ?.name || "N/A";
        const product =
          _.find(this.state.product, { product_id: element.WorkOrderProductId })
            ?.name || "N/A";
        const operation =
          _.find(this.state.operation, {
            operation_id: element.WorkOrderOperationId,
          })?.name || "N/A";
        const sampleType = element.WorkOrderTypeId == 1 ? "Analiz" : "Saklama";
        resolve({
          customer,
          sector,
          product,
          operation,
          sampleType,
          WorkOrderName: element.WorkOrderName,
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  onFormFinish = async (formData) => {
    try {
      const formTextData = await this.getFormText(formData);

      this.props.next({ formData, formTextData, selectedWorkBranch: this.state.selectedWorkBranch });
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };

  render() {
    const {
      customer,
      sector,
      product,
      operation,
      workorder,
      loading,
      spinning,
    } = this.state;

    if (loading) {
      return <div />;
    }
    return (
      <div className="antStepsFilter">
        <Spin spinning={spinning}>
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={this.onFormFinish}
            ref={this.forms}
          >
            <Row gutter={16}>
              <Col md={8} span={24}>
                <Row gutter={16}>
                  <Col md={20} span={18}>
                    <Form.Item
                      name="WorkOrderCustomerId"
                      label="Müşteri"
                      // extra="Müşteri listenizde yok ise Müşteri Ekle butonu ile ekleyebilirsiniz"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen bu alanı boş bırakmayın",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Seçiniz"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLocaleLowerCase()
                            .indexOf(input.toLocaleLowerCase()) >= 0
                        }
                      >
                        {customer.map((item, index) => {
                          return (
                            <Option key={index} value={item.customer_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={4} span={6}>
                    <Button
                      type="primary"
                      primary
                      onClick={this._showModal}
                      style={{ width: "100%", marginTop: "30px" }}
                    >
                      <PlusOutlined />
                    </Button>
                    <Modal
                      title="Yeni Müşteri Ekle"
                      visible={this.state.customerAddVisible}
                      onCancel={this._closeModal}
                      footer=""
                    >
                      {this.state.customerAddVisible && (
                        <CustomerForm onChange={this._closeModals} />
                      )}
                    </Modal>
                  </Col>
                </Row>
              </Col>

              <Col md={8} span={24}>
                <Form.Item
                  name="WorkOrderSectorId"
                  label={(this.state.selectedWorkBranch?.hasOwnProperty('name') && this.state.selectedWorkBranch.name) === 'LUBOIL (OCM)' ? "Kategori": "İş Kolu"}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={(this.state.selectedWorkBranch?.hasOwnProperty('name') && this.state.selectedWorkBranch.name) === 'LUBOIL (OCM)' ? "Kategori seçiniz":"İş Kolu Seçiniz"}
                    filterOption={(input, option) =>
                      option.children
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    onChange={this._sectorChange}
                  >
                    {sector.map((item, index) => {
                      return (
                        <Option key={index} value={item.sector_id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} span={24}>
                <Form.Item
                  name="WorkOrderProductId"
                  label={(this.state.selectedWorkBranch?.hasOwnProperty('name') && this.state.selectedWorkBranch.name) === 'LUBOIL (OCM)' ? 'Numunenin Adı ve Tarifi' : 'Ürün'}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select
                    // mode="multiple"
                    showSearch
                    placeholder="Ürün Seçiniz"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                  >
                    {product.map((item, index) => {
                      return (
                        <Option key={index} value={item.product_id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} span={24}>
                <Form.Item
                  name="WorkOrderOperationId"
                  label="Operasyon"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select
                    // mode="multiple"
                    showSearch
                    placeholder="Seçiniz"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    onChange={this._operationChange}
                  >
                    {operation.map((item, index) => {
                      return (
                        <Option key={index} value={item.operation_id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} span={24}>
                <Form.Item
                  name="WorkOrderName"
                  label={(this.state.selectedWorkBranch?.hasOwnProperty('name') && this.state.selectedWorkBranch.name) === 'LUBOIL (OCM)' ? 'Makina' : 'Numunenin Alındığı Yer / Sample At'}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            ref={(refs) => (this.WorkOrderName = refs)}
                          />
                          <a
                            style={{
                              flex: "none",
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.addItem({
                                type: "WorkOrderName",
                                value: this.WorkOrderName.state?.value || null,
                              })
                            }
                          >
                            <PlusOutlined /> Ekle
                          </a>
                        </div>
                      </div>
                    )}
                    onChange={this._shipChange}
                  >
                    {workorder.map((item) => {
                      return (
                        <Option key={item.index} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} span={24}>
                <Form.Item
                  name="WorkOrderTypeId"
                  label="Numune Tipi / Sample Type"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select placeholder="">
                    <Option value={1}>Analiz</Option>
                    <Option value={4}>Saklama</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} />
              <Col md={8}>
                <Button
                  className="btn-next-step"
                  size=""
                  type="primary"
                  //  onClick={this.stepNext}
                  icon={<ArrowRightOutlined />}
                  disabled={this.state.registerDisable}
                  htmlType="submit"
                >
                 Numune Bilgilerini Güncelle
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Registration;
