import React from "react";

import { Row, Col, Button, Space } from "antd";

import {
  SamplesGraphic,
  Tests,
  WorkOrders,
  Announcement,
  FollowWorkOrder,
} from "../Components";
const permOperationCenter = ({}) => {
  return (
    <div>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <SamplesGraphic />
        </Col>
        <Col className="dash-col" span={24} md={12}>
          <Tests />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <WorkOrders />
        </Col>
        <Col className="dash-col" span={24} md={12}>
          <FollowWorkOrder />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <Announcement />
        </Col>
      </Row>
    </div>
  );
};

export default permOperationCenter;
