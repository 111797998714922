import React from "react";

import { Row, Col } from "antd";

import {
  SamplesGraphic,
  Tests,
  TestsCount,
  MonthlyTests,
  Announcement,
} from "../Components";
const permSeniorManagement = ({ sampleTestStatus, sampleStatus }) => {
  return (
    <div>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <SamplesGraphic data={sampleStatus} />
        </Col>
        <Col className="dash-col" span={24} md={12}>
          <Tests data={sampleTestStatus} />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <TestsCount />
        </Col>

        <Col className="dash-col" span={24} md={12}>
          <MonthlyTests />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={24}>
          <Announcement />
        </Col>
      </Row>
    </div>
  );
};

export default permSeniorManagement;
