// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Inventory {
  constructor() {}

  /**
   * Inventory Ekleme
   * @param {string} InventoryNameTR
   * @param {string} InventoryNameEN
   * @param {string} InventoryCategoryId
   * @param {string} InventoryBrandId
   * @param {string} InventoryLabId
   * @param {Date} InventoryWarrantyStartDate
   * @param {Date} InventoryWarrantyEndDate
   * @param {Date} InventoryDateRepair
   */
  Create = ({
    InventoryNameTR,
    InventoryNameEN,
    InventoryCategoryId,
    InventoryBrandId,
    InventoryLabId,
    InventoryWarrantyStartDate,
    InventoryWarrantyEndDate,
    InventoryDateRepair
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          cat_id: InventoryCategoryId,
          brand_id: InventoryBrandId,
          lab_id: InventoryLabId,

          name: InventoryNameTR,
          name_en: InventoryNameEN,
          date_warranty_start: InventoryWarrantyStartDate,
          date_warranty_end: InventoryWarrantyEndDate,
          date_repair:InventoryDateRepair
        };
        const response = await ApiService(
          "post",
          Endpoint.Inventory.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Inventory Düzenleme
   * @param {string} InventoryId
   * @param {string} InventoryNameTR
   * @param {string} InventoryNameEN
   * @param {string} InventoryCategoryId
   * @param {string} InventoryBrandId
   * @param {string} InventoryLabId
   * @param {Date} InventoryWarrantyStartDate
   * @param {Date} InventoryWarrantyEndDate
   * @param {Date} InventoryDateRepair
   */
  Update = ({
    InventoryId,
    InventoryNameTR,
    InventoryNameEN,
    InventoryCategoryId,
    InventoryBrandId,
    InventoryLabId,
    InventoryWarrantyStartDate,
    InventoryWarrantyEndDate,
    InventoryDateRepair
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          inventory_id: InventoryId,
          cat_id: InventoryCategoryId,
          brand_id: InventoryBrandId,
          lab_id: InventoryLabId,

          name: InventoryNameTR,
          name_en: InventoryNameEN,
          date_warranty_start: InventoryWarrantyStartDate,
          date_warranty_end: InventoryWarrantyEndDate,
          date_repair:InventoryDateRepair
        };
        const response = await ApiService(
          "put",
          Endpoint.Inventory.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Inventory Silme İşlemi
   * @param {string} InventoryId
   */
  Delete = (InventoryId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          inventory_id: InventoryId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.Inventory.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * Inventory Listeleme
   * @param {number} limit
   * @param {number} page
   * @param {Date} startDate
   * @param {Date} endDate
   */
  SelectAll = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Inventory.getAll}/${limit}/${page}/${startDate}/${endDate}`;

        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Inventory Detayı Alma
   * @param {string} InventoryId
   */
  Select = (InventoryId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          inventory_id: InventoryId,
        };
        const response = await ApiService(
          "get",
          Endpoint.Inventory.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Inventory Aktif/Pasif Durumu Değiştirme
   * @param {string} InventoryId
   */
  StatusChange = (InventoryId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          inventory_id: InventoryId,
        };

        const response = await ApiService(
          "post",
          Endpoint.Inventory.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new Inventory();
