import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import Table from "../../../../Components/UI/Table";
import PageHeader from "../../../../Components/UI/PageHeader";

import {
  Modal,
  Button,
  Tooltip,
  Card,
  Form,
  Tag,
  Select,
  Space,
  Spin,
} from "antd";
import _ from "lodash";
import Helper from "../../../../Helper";
import DataService from "../../../../Provider/Service/DataService";

class Sample extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      tableColumns: [
        {
          title: "İş Kolu",
          dataIndex: "sector_name",
          key: "sector_name",
          ...Helper.getColumnSearchProps(
            "sector_name",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
          sorter: (a, b) => a.sector_name.localeCompare(b.sector_name),
        },
        {
          title: "Test Adı / Sample Name",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            ""
          ),
          sorter: (a, b) => a.name.localeCompare(b.name),
          render: (text, record) => `${text} / ${record?.name_en || "-"}`,
        },

        {
          title: "Metodu",
          dataIndex: "method_name",
          key: "method_name",
          ...Helper.getColumnSearchProps(
            "method_name",
            this.handleSearch,
            this.handleReset,
            "Metodu"
          ),
          sorter: (a, b) => a.method_name.localeCompare(b.method_name),
        },

        {
          title: "Birim",
          dataIndex: "unit_name",
          key: "unit_name",
          ...Helper.getColumnSearchProps(
            "unit_name",
            this.handleSearch,
            this.handleReset,
            "Birim"
          ),
          sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
        },

        {
          title: "Format",
          dataIndex: "format_name",
          key: "format_name",
          sorter: (a, b) => a.format_name.localeCompare(b.format_name),
        },
    {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
      data: [],
    };
  }
  componentDidMount() {
    this.getData();
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  getData = async () => {
    try {
      const responseTest = await DataService.Test.SelectAll();
      if (responseTest.status) {
        const { data } = responseTest.response;
        console.log("testdata", data);
        let tests = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            tests.push({
              ...element,
              id: key,
              accredited_name: element.accredited == 1 ? "Evet" : "Hayır",
              format_name: element.number == 1 ? "Rakam" : "Metin",
            });
          }
        }
        this.setState({
          data: tests,

          loading: false,
        });
      }
    } catch (error) {
      console.log("Test", JSON.stringify(error));
    }
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <NavLink
          to={`/definitions/test-features/test-ref/edit/${record.test_id}`}
        >
          <Button shape="round" type="primary">
            Referans Değeri Gir
          </Button>
        </NavLink>
      </Space>
    );
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Referans Değerleri" />
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Table columns={this.state.tableColumns} data={this.state.data} />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default Sample;
