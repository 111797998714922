import React, { useState, useEffect, useRef } from "react";

import { Select, Button, Spin, Form, Row, Col, DatePicker } from "antd";

import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
import Table from "./Table";
const { Option } = Select;
const { RangePicker } = DatePicker;
const DetailedReport = () => {
  const [data, setData] = useState(null);
  const [productData, setProductData] = useState([]);

  const [loading, setLoading] = useState(true);

  const formRef = useRef();
  useEffect(() => {
    getData();
    return () => {};
  }, []);
  const getData = async () => {
    try {
      const getProduct = await DataService.Product.SelectAll();

      if (getProduct?.status) {
        setProductData(getProduct?.response?.data || []);

        setLoading(false);
      } else {
        Helper.ErrorMessage(getProduct?.message || "Ürün Listesi Alınamadı");
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };

  const formFinish = ({ productId, date }) => {
    setLoading(true);
    const start_date = date ? date[0].unix() : 0;
    const finish_date = date ? date[1].unix() : 0;

    DataService.Dashboard.detailReportsStatisticProduct({
      productId,

      start_date,
      finish_date,
    })
      .then((result) => {
        setLoading(false);
        setData(result?.response?.data);
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={formFinish}
        style={{
          marginBottom: "16px",
        }}
        ref={formRef}
      >
        <Row gutter={15}>
          <Col span={12} md={6}>
            <Form.Item
              name="productId"
              label="Ürün"
              rules={[
                {
                  required: true,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <Select
                style={{ width: "100%", marginRight: 16 }}
                showSearch
                placeholder="Seçim yap"
                optionFilterProp="children"
                mode="tags"
              >
                {productData?.map((item, index) => {
                  return (
                    <Option key={index} value={item.product_id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} md={6}>
            <Form.Item
              name="date"
              label="Tarih Aralığı Seçiniz"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <RangePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12} md={6}>
            <Button
              type="primary"
              style={{ marginTop: 30 }}
              htmlType="submit"
              loading={loading}
            >
              Getir
            </Button>
          </Col>
        </Row>
      </Form>
      {!!data && <Table data={data} showSector={true} title="Ürüne Göre Raporlama" />}
    </Spin>
  );
};

export default DetailedReport;
