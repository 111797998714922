import React, { Component } from "react";

import { Form, Input, InputNumber, Row, Col } from "antd";
import _ from "lodash";
class ContainerItem extends Component {

  
  shelfValueChange = (value) => {
    const { shelfData } = this.props;
  
    let data = [];
    for (const key in shelfData) {
      if (shelfData.hasOwnProperty(key)) {
        const element = shelfData[key];
        if ((element.id == this.props.shelfNumber)) {
          data.push({
            id: this.props.shelfNumber,
            value,
          });
        } else {
          data.push(element);
        }
      }else{
          console.log('asdasdasd')
      }
    }
  

    this.props.onChange(data);
  };
  render() {
 
    return (
      <div>
        <Row gutter={16}>
          <Col span={8} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: 10}}>
            {parseInt(this.props.value) + 1}. Konteyner
          </Col>
          <Col span={16}>
            <Form.Item
              name={`${this.props.shelfNumber}-storeContainerShelf`}
              label="Raf Sayısı"
              rules={[]}
              style={{ marginBottom: "5px" }}
              initialValue={this.props.shelfData[this.props.value].value}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                // max={10}
                defaultValue={0}
                onChange={this.shelfValueChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContainerItem;
