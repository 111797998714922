import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Select,
  Input,
  Space,
  Spin,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
const { Option } = Select;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      inventoryData: [],
      testData: [],
      testDeviceId: null,

      tableColumns: [
        {
          title: "Test Cihazı Adı TR",
          dataIndex: "inventoryName",
          key: "inventoryName",
          ...Helper.getColumnSearchProps(
            "inventoryName",
            this.handleSearch,
            this.handleReset,
            "Test Cihazı Adı TR"
          ),
          sorter: (a, b) => a.inventoryName.localeCompare(b.inventoryName), // string sıralama
        },
        {
          title: "Test Cihazı Adı EN",
          dataIndex: "inventoryNameEn",
          key: "inventoryNameEn",
          ...Helper.getColumnSearchProps(
            "inventoryNameEn",
            this.handleSearch,
            this.handleReset,
            "Test Cihazı Adı EN"
          ),
          sorter: (a, b) => a.inventoryNameEn.localeCompare(b.inventoryNameEn), // string sıralama
        },
        {
          title: "Test Name TR",
          dataIndex: "testName",
          key: "testName",
          ...Helper.getColumnSearchProps(
            "testName",
            this.handleSearch,
            this.handleReset,
            "Test Name TR"
          ),
          sorter: (a, b) => a.testName.localeCompare(b.testName), // string sıralama
        },
        {
          title: "Test Name EN",
          dataIndex: "testNameEn",
          key: "testNameEn",
          ...Helper.getColumnSearchProps(
            "testNameEn",
            this.handleSearch,
            this.handleReset,
            "Test Name EN"
          ),
          sorter: (a, b) => a.testNameEn.localeCompare(b.testNameEn), // string sıralama
        },

        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseTestDevice = await DataService.TestDevice.SelectAll();
      const responseInventory = await DataService.TestDevice.inventorySelectAll();
      const responseTest = await DataService.TestDevice.testSelectAll();
      if (
        responseTestDevice.status &&
        responseInventory.status &&
        responseTest.status
      ) {
        const { data } = responseTestDevice.response;
        const inventoryData = responseInventory.response.data;
        const testData = responseTest.response.data;
        let testdevices = [];
        let tests = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            testdevices.push({
              ...element,
              id: key,
            });
          }
        }
        for (const key in testData) {
          if (testData.hasOwnProperty(key)) {
            const element = testData[key];

            tests.push({
              text: `${element.name}/${element.name_en}`,
              value: element.test_id,
              id: key,
            });
          }
        }
        this.setState({
          data: testdevices,
          inventoryData,
          testData: tests,
          loading: false,
        });
      } else {
        if (!responseTestDevice.status)
          Helper.ErrorMessage(responseTestDevice.message);
        if (!responseInventory.status)
          Helper.ErrorMessage(responseInventory.message);
        if (!responseTest.status) Helper.ErrorMessage(responseTest.message);
      }
    } catch (error) {
      console.log("Test Devices", JSON.stringify(error));
    }
  };
  _editClick = ({
    test_device_id,
    test_id,
    device_id,
    testName,
    inventoryName,
  }) => {
    this.setState({ visible: true, testDeviceId: test_device_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          testDeviceDeviceId: [inventoryName, device_id],
          testDeviceTestId: [testName, test_id],
        });
      }, 100);
    });
  };
  _deleteClick = ({ test_device_id, inventoryName }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${inventoryName} Test Cihazını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(test_device_id);
      }
    });
  };
  deleted = (testDeviceId) => {
    this.setState({ loading: true });
    DataService.TestDevice.Delete(testDeviceId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      testDeviceId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ testDeviceDeviceId, testDeviceTestId }) => {
    try {
      const { testDeviceId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (testDeviceId) {
        result = await DataService.TestDevice.Update({
          testDeviceId,
          testDeviceDeviceId:
            typeof testDeviceDeviceId == "object"
              ? testDeviceDeviceId[1]
              : testDeviceDeviceId,
          testDeviceTestId:
            typeof testDeviceTestId == "object"
              ? testDeviceTestId[1]
              : testDeviceTestId,
        });
      } else {
        result = await DataService.TestDevice.Create({
          testDeviceDeviceId,
          testDeviceTestId,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            testDeviceId: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const inventory = this.state.inventoryData.map((item, index) => {
      return (
        <Option key={index} value={item.inventory_id}>
          {item.inventoryName}
        </Option>
      );
    });
    const tests = this.state.testData.map((item, index) => {
      return (
        <Option key={index} value={item.value}>
          {item.text}
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Cihazı" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"TestDevices"}
                dates={["tarih"]}
              />

              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Yeni Ekle
              </Button>
            </Space>
          </div>

          <Modal
            title={
              this.state.testDeviceId
                ? "Test Cihazı Düzenle"
                : "Test Cihazı Ekle"
            }
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="testDeviceDeviceId"
                label="Test Cihazı Adı"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {inventory}
                </Select>
              </Form.Item>
              <Form.Item
                name="testDeviceTestId"
                label="Test"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {tests}
                </Select>
              </Form.Item>

              <div className="formButton">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.btnloading}
                >
                  {this.state.testDeviceId ? (
                    <>
                      {" "}
                      <EditOutlined /> Kaydet{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <PlusOutlined /> Ekle{" "}
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
