import React, { Component } from 'react';
import "./login.css"

import Logo from "../../Assets/logo/logo.svg";

import { NavLink } from "react-router-dom";

import { Form, Input, Button } from 'antd';

import { UserOutlined, KeyOutlined, LoginOutlined } from '@ant-design/icons';

class LockScreen extends Component {
    render() {
        return (
            <div class="login-layout ant-layout">
                
                <div className="loginBrand">
                    <img src={Logo} alt="Logo" width="120" />
                </div>
                <div className="loginCard">
                    <div className="cardHead">
                        <h3>Merhaba, Shark Yazılım</h3>
                        <span>Uzun süre işlem yapmadığınız için oturumunuz kapatıldı. Lütfen tekrar giriş yapın.</span>
                    </div>

                    <div className="cardContent">

                        <Form className="formLogin"
                        >
                            <div className="formGroup">
                                <label className="">Parola <a href="#"><NavLink  exact to="/login-remember">Parolamı Unuttum</NavLink></a></label>
                                <Input.Password size="large" placeholder="Parola" prefix={<KeyOutlined />} />
                            </div>
                            <div className="formGroup">
                                <NavLink  exact to="/">
                                    <Button type="primary" shape="round" size="large" icon={<LoginOutlined />}>
                                        Giriş Yap
                                    </Button>
                                </NavLink>
                            </div>
                        </Form>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default LockScreen;