import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import {
  Modal,
  Button,
  Tooltip,
  Card,
  Checkbox,
  Form,
  InputNumber,
  Select,
  Space,
  Spin,
} from "antd";

import Table from "../../../../Components/UI/Table";
import PageHeader from "../../../../Components/UI/PageHeader";

import {
  UnorderedListOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Helper from "../../../../Helper";
import DataService from "../../../../Provider/Service/DataService";
import _ from "lodash";
const { Option } = Select;

const tableData = [
  {
    key: "1",
    id: "123",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "2",
    id: "124",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "3",
    id: "125",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "4",
    id: "123",
    sector_name: "Other",
    name: "Bakteri ",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "5",
    id: "124",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "6",
    id: "125",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "7",
    id: "123",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "8",
    id: "124",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
  {
    key: "9",
    id: "125",
    sector_name: "CB",
    name: "Bakteri Sayımı",
    method_name: "IP 613",
    status_name: "Kirlilik",
    unit_name: "cfu/mL - cfu/L",
    ureal: 0,
    accredited_name: "Hayır",
    format_name: "Rakam",
  },
];

export default class index extends Component {
  constructor(props) {
    super();
    this.state = {
      tableColumns: [
        {
          title: "İş Kolu",
          dataIndex: "sector_name",
          key: "sector_name",
          ...Helper.getColumnSearchProps(
            "sector_name",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
          sorter: (a, b) => a.sector_name.localeCompare(b.sector_name),
        },
        {
          title: "Test Adı TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Test Adı TR"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name),
          render:(text,record) => text || '-'
        },
        {
          title: "Test Adı EN",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            "Test Adı EN"
          ),
          sorter: (a, b) => a.name_en.localeCompare(b.name_en),
          render:(text,record) => text || '-'
        },
        {
          title: "Metodu",
          dataIndex: "method_name",
          key: "method_name",
          ...Helper.getColumnSearchProps(
            "method_name",
            this.handleSearch,
            this.handleReset,
            "Metodu"
          ),
          sorter: (a, b) => a.method_name.localeCompare(b.method_name),
        },
        {
          title: "Tipi",
          dataIndex: "status_name",
          key: "status_name",
          ...Helper.getColumnSearchProps(
            "status_name",
            this.handleSearch,
            this.handleReset,
            "Tipi"
          ),
          sorter: (a, b) =>{
            const a_status_name = a?.status_name || 'N/A';
            const b_status_name = b?.status_name || 'N/A';
            return a_status_name.localeCompare(b_status_name)
          },
          render: (text, record) => (text || 'N/A'),
        },
        {
          title: "Birim",
          dataIndex: "unit_name",
          key: "unit_name",
          ...Helper.getColumnSearchProps(
            "unit_name",
            this.handleSearch,
            this.handleReset,
            "Birim"
          ),
          sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
        },
  
      ],
      loading: true,
      data: [],
      checkeds: [],
    };
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const result = await DataService.Test.SelectAll();
      
      if (result.status) {
        this.setState({ loading: false, data: result.response.data });
      } else {
        Helper.ErrorMessage(result.message);
        this.setState({ loading: false });
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ checkeds: selectedRowKeys });
    },
  };
  _onFinish = async () => {
    try {
      this.setState({ loading: true });
      const { checkeds } = this.state;
      let labTestInfo = [];
      for (const key in checkeds) {
        if (checkeds.hasOwnProperty(key)) {
          const element = checkeds[key];

          labTestInfo.push({
            test_id: element,
            labTest:[]
          });
        }
      }

      const result = await DataService.TestLab.Create(labTestInfo);
      if (result.status) {
        console.log('asdasdsadsad');
        Helper.SuccessMessage("Başarılı bir şekilde kayıt edildi");
        this.props.history.push('/settings/lab-test');
      } else {
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Laboratuvara Test Tanımla" />

          <Space size="">
            <NavLink activeClassName="nav-active" exact to="/settings/lab-test">
              <Button className="btn-add" shape="round">
                <UnorderedListOutlined />
                Laboratuvar Testleri (100)
              </Button>
            </NavLink>
          </Space>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Table
              rowSelection={{
                type: "checkbox",
                ...this.rowSelection,
              }}
              columns={this.state.tableColumns}
              data={this.state.data}
              rowKey={"test_id"}
            />

            <div className="selected" style={{ textAlign: "left" }}>
              {this.state.checkeds.length} test seçildi.
            </div>

            <div className="formButton" style={{ marginBottom: "26px" }}>
              <Space size={15}>
                <Button
                  type="primary"
                  onClick={this._onFinish}
                  style={{ marginTop: 16 }}
                  loading={this.state.loading}
                >
                  <PlusOutlined />
                  Seçilenleri Ekle
                </Button>
              </Space>
            </div>
          </Card>
        </Spin>
      </div>
    );
  }
}
