import React, { Component } from "react";

import Tables from "../../../Components/Table";
import PageHeader from "../../../Components/UI/PageHeader";
import Export from "../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Radio,
  InputNumber,
  Spin,
  Row,
  Col,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from 'lodash';
const { Option } = Select;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      testsData:[],
      methodData: [],
      sectorData: [],
      typeData: [],
      unitData: [],
      testId: null,
      itemDisabled: false,
      parentDisabled: false,
      loading: true,
      btnloading: false,
      tableColumns: [
        {
          title: "İş Kolu",
          dataIndex: "sector_name",
          key: "sector_name",
          ...Helper.getColumnSearchProps(
            "sector_name",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
          sorter: (a, b) => a.sector_name.localeCompare(b.sector_name),
        },
        {
          title: "Test Adı / Sample Name",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            ""
          ),
          sorter: (a, b) => a.name.localeCompare(b.name),
          render: (text, record) => `${text} / ${record?.name_en || "-"}`,
        },

        {
          title: "Metodu",
          dataIndex: "method_name",
          key: "method_name",
          ...Helper.getColumnSearchProps(
            "method_name",
            this.handleSearch,
            this.handleReset,
            "Metodu"
          ),
          sorter: (a, b) => a.method_name.localeCompare(b.method_name),
        },

        {
          title: "Birim",
          dataIndex: "unit_name",
          key: "unit_name",
          ...Helper.getColumnSearchProps(
            "unit_name",
            this.handleSearch,
            this.handleReset,
            "Birim"
          ),
          sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
        },

        {
          title: "Format",
          dataIndex: "format_name",
          key: "format_name",
          sorter: (a, b) => a.format_name.localeCompare(b.format_name),
        },
        {
          title: "Raporlama Hassasiyeti",
          dataIndex: "number_characters",
          key: "number_characters",
          sorter: (a, b) => {
            const aText = a?.number_characters || 0;
            const bText = b?.number_characters || 0;

            return aText - bText;
          },
          render: (text, record) => text || "N/A",
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseTest = await DataService.Test.SelectAll();

      const responseTestMethod = await DataService.TestMethod.SelectAll();
      const responseTestType = await DataService.TestStatus.SelectAll();
      const responseTestUnit = await DataService.TestUnit.SelectAll();
      const responseSector = await DataService.Sector.SelectAll();

      if (
        responseTest.status &&
        responseTestMethod.status &&
        responseTestType.status &&
        responseTestUnit.status &&
        responseSector.status
      ) {
        const { data } = responseTest.response;

        const methodData = responseTestMethod.response.data;
        const typeData = responseTestType.response.data;
        const unitData = responseTestUnit.response.data;
        const sectorData = responseSector.response.data;

        let tests = [],
          types = [],
          methods = [],
          units = [],
          sectors = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            tests.push({
              ...element,
              id: key,
              accredited_name: element.accredited == 1 ? "Evet" : "Hayır",
              format_name: element.number == 1 ? "Rakam" : "Metin",
            });
          }
        }
        for (const key in methodData) {
          if (methodData.hasOwnProperty(key)) {
            const element = methodData[key];
            methods.push({
              value: element.test_method_id,
              text: element.name,
            });
          }
        }
        for (const key in typeData) {
          if (typeData.hasOwnProperty(key)) {
            const element = typeData[key];
            types.push({
              value: element.test_status_id,
              text: element.name,
            });
          }
        }
        for (const key in unitData) {
          if (unitData.hasOwnProperty(key)) {
            const element = unitData[key];
            units.push({
              value: element.test_unit_id,
              text: element.name,
            });
          }
        }
        for (const key in sectorData) {
          if (sectorData.hasOwnProperty(key)) {
            const element = sectorData[key];
            sectors.push({
              value: element.sector_id,
              text: element.name,
            });
          }
        }

        this.setState({
          data: tests,
          testsData:tests,
          methodData: methods,
          sectorData: sectors,
          typeData: types,
          unitData: units,
          loading: false,
        });
      }
    } catch (error) {
      console.log("Test", JSON.stringify(error));
    }
  };
  _editClick = ({
    test_id,
    name,
    name_en,
    sector_id,
    unit_id,
    method_id,
    number,
    number_characters,
    unit_name,
    sector_name,
    method_name,
    parent_test_id
  }) => {
    console.log('editClick',{
      test_id,
      name,
      name_en,
      sector_id,
      unit_id,
      method_id,
      number,
      number_characters,
      unit_name,
      sector_name,
      method_name,
      parent_test_id
    })
    this.setState(
      {
        visible: true,
        testId: test_id,
        itemDisabled: number == 1 ? false : true,
        parentDisabled: parent_test_id != 0 ? true : false
      },
      () => {
        setTimeout(() => {
          this.formRef.current.setFieldsValue({
            testNameTr: name,
            testNameEn: name_en,
            testSectorId: sector_id,
            testUnitId: unit_id,
            testMethodId: method_id,
            testFormat: number,
            testReportingAccuracy: number_characters,
            testParentFormat:parent_test_id !=0 ? 1 : 0,
            testParentId:parent_test_id != 0 ? parent_test_id : undefined

          });
        }, 100);
      }
    );
  };
  _deleteClick = ({ test_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Testini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(test_id);
      }
    });
  };
  deleted = (testId) => {
    this.setState({ loading: true });
    DataService.Test.Delete(testId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      testId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({
    testNameTr,
    testNameEn,
    testSectorId,

    testMethodId,
    testUnitId,

    testFormat,
    testReportingAccuracy,
    testParentId
  }) => {
    try {
      const { testId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (testId) {
        result = await DataService.Test.Update({
          testId,
          testNameTr,
          testNameEn,
          testSectorId: testSectorId,

          testMethodId: testMethodId,
          testUnitId: testUnitId,

          testFormat,
          testReportingAccuracy,
          testParentId
        });
      } else {
        result = await DataService.Test.Create({
          testNameTr,
          testNameEn,
          testSectorId,

          testMethodId,
          testUnitId,

          testFormat,
          testReportingAccuracy,
          testParentId
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, testId: null, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  _formValueChanged = ({ testFormat, testParentFormat }) => {
    const { itemDisabled, parentDisabled } = this.state;
    if (testFormat == 0 && !itemDisabled) {
      this.setState({ itemDisabled: true });
    } else if (testFormat == 1 && itemDisabled) {
      this.setState({ itemDisabled: false });
    }
    if (testParentFormat == 1 && !parentDisabled) {
      this.setState({ parentDisabled: true });
    } else if (testParentFormat == 0 && parentDisabled) {
      this.setState({ parentDisabled: false });
    }
  };
  searchTests = (search) => {
    if (search.length > 0) {
      const testsData = _.filter(this.state.data, (item) => {
        const searchItem = `${item.name} / ${item.name_en} - (${item.method_name})`;
        return searchItem.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      });

      this.setState({
        testsData,
      });
    } else {
      const { testData } = this.props;
      this.setState({
        testsData: testData,
      });
    }
  };
  render() {
    const { sectorData, unitData, typeData, methodData, data,testsData } = this.state;

    const sectors = sectorData.map((item, index) => {
      return (
        <Option key={index} value={item.value}>
          {item.text}
        </Option>
      );
    });
    const units = unitData.map((item, index) => {
      return (
        <Option key={index} value={item.value}>
          {item.text}
        </Option>
      );
    });
    const types = typeData.map((item, index) => {
      return (
        <Option key={index} value={item.value}>
          {item.text}
        </Option>
      );
    });
    const methods = methodData.map((item, index) => {
      return (
        <Option key={index} value={item.value}>
          {item.text}
        </Option>
      );
    });
    const tests = testsData.map((item, index) => {
      return (
        <Option key={index} value={item.test_id}>
          {item.name} / {item.name_en} - ({item.method_name})
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Tanımlama" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Test"}
                dates={["tarih"]}
              />

              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Test Ekle{" "}
              </Button>
            </Space>
          </div>

          <Modal
            title={this.state.testId ? "Test Düzenle" : "Yeni Test Ekle"}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              onValuesChange={this._formValueChanged}
              ref={this.formRef}
            >
              <Form.Item
                name="testSectorId"
                label="İş Kolu"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {sectors}
                </Select>
              </Form.Item>
              <Row gutter={16}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="testNameTr"
                    label="Test Adı TR"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input placeholder="Test Adı TR" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="testNameEn"
                    label="Test Adı EN"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input placeholder="Test Adı EN" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="testMethodId"
                    label="Test Methodu"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {methods}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="testUnitId"
                    label="Test Birimi"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seçiniz"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {units}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} md={12}>
                  {/* 0 Metin 1 Rakam */}
                  <Form.Item
                    name="testFormat"
                    label="Format (Rakam/Metin)"
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Radio.Group >
                      <Radio value={1} checked>
                        Rakam
                      </Radio>
                      <Radio value={0}>Metin</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  {/* Düz inpıt sayı yollancak*/}
                  {!this.state.itemDisabled && (
                    <Form.Item
                      name="testReportingAccuracy"
                      label="Raporlama Hassasiyeti"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen sayı tipinde değer giriniz.",
                          type: "number",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Form raporlama hassasiyeti örn: 0,2"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Form.Item
                name="testParentFormat"
                label="Bağlı Test"
                initialValue={0}
                rules={[]}
              >
                <Radio.Group>
                  <Radio value={1}>Var</Radio>
                  <Radio value={0}>Yok</Radio>
                </Radio.Group>
              </Form.Item>
              {this.state.parentDisabled && (
                <Form.Item
                  name="testParentId"
                  label="Bağlı Olduğu Testi Seçiniz."
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Seçiniz"
                    filterOption={false}
                    onSearch={this.searchTests}
                  >
                    {tests}
                  </Select>
                </Form.Item>
              )}

              <div className="formButton">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.btnloading}
                >
                  {this.state.testId ? (
                    <>
                      {" "}
                      <EditOutlined /> Kaydet{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <PlusOutlined /> Ekle{" "}
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
             
              dataSource={data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
