import React, {Component, useState} from "react";

// import Tables from "../../Components/Table";

import {
    Card,
    Button,
    DatePicker,
    Space,
    Row,
    Col,
    Spin,
    Descriptions,
    Input,
    Divider,
} from "antd";
import moment from "moment";

import Table from "../../../../Components/UI/Table";
import QRCode from "react-qr-code";
import _ from "lodash";

const Item = ({label, value}) => {
    return (
        <Row gutter="16" className="mb-0">
            <Col span={12}>
                <div className="td-title">{label}</div>
            </Col>
            <Col span={12}>{value}</Col>
        </Row>
    );
};

const tableColumns = [
    {
        title: "Test Adı",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Birim",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Metot",
        dataIndex: "method",
        key: "method",
    },
    {
        title: "Ref Min",
        dataIndex: "ref_min",
        key: "ref_min",
        render: (item, record) => {
            const operators =
                record?.ref_min_operator == "=" ? "" : record?.ref_min_operator;
            const refValue = record?.ref_min == "-" ? "" : record?.ref_min;

            return `${operators}${refValue}`;
        },
    },
    {
        title: "Ref Max",
        dataIndex: "ref_max",
        key: "ref_max",
        render: (item, record) => {
            const operators =
                record?.ref_max_operator == "=" ? "" : record?.ref_max_operator;
            const refValue = record?.ref_max == "-" ? "" : record?.ref_max;

            return `${operators}${refValue}`;
        },
    },
    {
        title: "1. Sonuç",
        dataIndex: "result_1",
        key: "result_1",
        render: () => <div className="analysis-value-box"></div>,
    },
    {
        title: "2. Sonuç",
        dataIndex: "result_2",
        key: "result_2",
        render: () => <div className="analysis-value-box"></div>,
    },
    {
        title: "ORT",
        dataIndex: "average",
        key: "average",
        render: () => <div className="analysis-value-box"></div>,
    },
    {
        title: "Analizi Yapan",
        dataIndex: "analyzed_by",
        key: "analyzed_by",
        render: () => <div className="analysis-value-box bigger"></div>,
    },
    {
        title: "Deneylerde Kul. Cih.",
        dataIndex: "devices",
        key: "devices",
        render: () => <div className="analysis-value-box bigger"></div>,
    },
];
const withoutTableColums = [
    {title: "LAB", dataIndex: "labCode", key: "labCode"},
    {
        title: "Test Adı",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Birim",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Metot",
        dataIndex: "method",
        key: "method",
    },

    {
        title: "1. Sonuç",
        dataIndex: "result_1",
        key: "result_1",
        render: () => <div className="analysis-value-box"></div>,
    },
    {
        title: "2. Sonuç",
        dataIndex: "result_2",
        key: "result_2",
        render: () => <div className="analysis-value-box"></div>,
    },
    {
        title: "ORT",
        dataIndex: "average",
        key: "average",
        render: () => <div className="analysis-value-box"></div>,
    },
    {
        title: "Analizi Yapan",
        dataIndex: "analyzed_by",
        key: "analyzed_by",
        render: () => <div className="analysis-value-box bigger"></div>,
    },
    {
        title: "Deneylerde Kul. Cih.",
        dataIndex: "devices",
        key: "devices",
        render: () => <div className="analysis-value-box bigger"></div>,
    },
];

class AnalysisRegistration extends Component {
    state = {
        filterColums: null,
    };

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tableData != this.props.tableData) {
            this.getData();
        }
    }

    getData = () => {
        const {tableData} = this.props;
        const somes = tableData.some((element, index, array) => {
            return !!element.pack_id;
        });

        if (somes) {
            this.setState({filterColums: tableColumns});
        } else {
            this.setState({filterColums: withoutTableColums});
        }
    };

    render() {
        const {details, tableData} = this.props;
        const datas = _.sortBy(tableData, ["labCode"], ["asc"]);

        let packingData = '';
        if (details.sectorId === '31479c2a2efc8bd273259ea67f4f5fdc') {
            packingData = `${details?.bottle || ""}X${details?.amount || ""} ${details?.amount_type || ""}`;
        } else {
            packingData = `${details?.amount || ""} ${details?.amount_type || ""}`;
        }

        return (
            <div className="analysis-print-wrapper">
                <div className="tableFilter" style={{marginBottom: "15px"}}>
                    <h2 className="analysis-big-title">ANALİZ SONUÇLARI KAYIT FORMU</h2>

                    <Row gutter="16">
                        <Col span={12}>
                            <Item label="Kayıt No:" value={details?.registryNo || ""}/>
                            <Item label="Rapor No:" value={details?.reportNo || ""}/>
                            <Item label="Müşteri:" value={details?.customer_name || ""}/>
                            <Item
                                label={details.sectorName === "LUBOIL (OCM)" ? "Numunenin Adı ve Tarifi" : "Numune Cinsi:"}
                                value={details.productName}/>
                            <Item
                                label={details.sectorName === "LUBOIL (OCM)" ? "Makina" : "Numune Adı / Gemi Adı:"}
                                value={details?.getPart || ""}
                            />
                            {details.sectorName !== "LUBOIL (OCM)" &&
                                <Item label="Terminal:" value={details?.getLocation || ""}/>}
                            {details.sectorName === "LUBOIL (OCM)" &&
                                <Item label="Parça" value={details?.getPartCat || ""}/>}
                            <Item label={details.sectorName === "LUBOIL (OCM)" ? "Yağ Marka" : "Detaylı Bilgi:"}
                                  value={details.sectorName === "LUBOIL (OCM)" ? details.getMethod : ''}/>
                            <Item
                                label="Numune Ambalaj Bilgisi:"
                                value={packingData}
                            />
                            <Item label="Mühür No:" value={details?.signetCode || ""}/>
                            <Item
                                label="Numuneyi Alan:"
                                value={`${details?.getUser || ""} / ${
                                    details?.getUserCustomer || ""
                                }`}
                            />
                            <Item
                                label="Numuneyi Teslim Eden:"
                                value={`${details?.getSenderName || ""} / ${
                                    details?.getSenderCustomer || ""
                                }`}
                            />
                        </Col>
                        <Col span={12}>
                            <Item
                                label="Numune Geliş Tarihi, Saati:"
                                value={details?.getAcceptanceDate || ""}
                            />
                            <Item
                                label="Numune Analiz Tarihi, Saati:"
                                value={details?.dateStart || ""}
                            />
                            <Item
                                label="Analiz Bitiş Tarihi, Saati:"
                                value={details?.dateFinish || ""}
                            />
                            <Item label="ATF Seri No:" value={details?.atfNo || ""}/>
                            <Item
                                label="Numune Alma Tarihi:"
                                value={details?.dateDelivery ? `${details?.dateDelivery}  
                ${moment(Number(details?.dateDeliveryHour) * 1000).format(
                                    "hh:mm:ss"
                                )}` : '-'}
                            />

                            <div className="qr-print-img" style={{textAlign: "right"}}>
                                <QRCode size={80} value={'https://verify.intertekturkey.com/' + details?.sampleId}/>
                            </div>
                        </Col>
                    </Row>
                </div>

                {this.state.filterColums && (
                    <Table
                        columns={this.state.filterColums}
                        data={datas}
                        pagination={{
                            total: datas.length,
                            pageSize: datas.length,
                            hideOnSinglePage: true,
                        }}
                    />
                )}

                <Row gutter="16">
                    <Col span={12}>
                        <div className="analysis-note-section">
                            <h3 className="analysis-inner-title">HESAPLAMALAR</h3>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="analysis-note-section border-0">
                            <h3 className="analysis-inner-title">
                                ÇÖZELTİ VE MALZEME DETAYI
                            </h3>
                        </div>
                    </Col>
                </Row>

                <div className="analysis-footer">
                    <Row gutter="16">
                        <Col span={6}>
                            <strong>Form no:</strong> KF-L-064
                        </Col>
                        <Col span={6}>
                            <strong>Yayın no:</strong> 02
                        </Col>
                        <Col span={6}>
                            <strong>Rev.No/Tarih:</strong> 0 / -
                        </Col>
                        <Col span={6}>
                            <strong>Yayın tarihi:</strong> 18.03.2022
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default AnalysisRegistration;
