import React, { useEffect, useState } from "react";

import { Card, Spin, Result } from "antd";

import { Doughnut } from "react-chartjs-2";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
const SamplesGraphic = () => {
  const [sampleData, setSampleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const setData = (data) => {
    let labels = [];
    let counts = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        labels.push(element.name);
        counts.push(element.count);
      }
    }
    const chartDoughnutData = {
      labels: labels,
      datasets: [
        {
          label: "# of Votes",
          data: counts,
          backgroundColor: [
            "rgba(253, 98, 5, 1)", // talep alindi
            "rgba(254, 152, 53, 1)", // numune yolda
            "rgba(255, 206, 11, 1)", // numune ulasti
            "rgba(26, 188, 156, 1)", // odeme bekliyor
            "rgba(152, 148, 255, 1)", // numune analizda
            "rgba(253, 84, 255, 1)", // raporlanacak
            "rgba(205, 201, 255, 1)", // raporlandi
            "rgba(155, 208, 6, 1)", // muhasebe islem
            "rgba(28, 180, 81, 1)", // tamamlandi
            "rgba(255, 0, 0, 1)", // sorunlu numune
            "rgba(223, 152, 0, 1)", // revize numune
          ],
          borderWidth: 0,
        },
      ],
    };
    setSampleData(chartDoughnutData);
    setLoading(false);
  };

  const getData = async () => {
    DataService.Dashboard.Sample()
      .then((result) => {
        if (result.status) {
          setData(result.data);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Spin spinning={loading}>
      <Card title="Numuneler">
        {!sampleData ? (
          <Result title="Kayıt bulunamadı." />
        ) : (
         
          <Doughnut data={sampleData} />
        )}
      </Card>
    </Spin>
  );
};

export default SamplesGraphic;
