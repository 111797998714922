// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Menu {
  constructor() {}

  /**
   * Menu Listeleme

   */
  SelectAll = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "get",

          Endpoint.Menu.getAll
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Yetkiye Göre Listeleme

   */
  permSelect = (permId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "get",

          `${Endpoint.Menu.permSelect}/${permId}`
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   *
   * @param {*} permId  - Role Id
   * @param {*} menu  - Menuler
   */

  PermSave = (permId, menu) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          perm_id: permId,
          menu,
        };
        const response = await ApiService(
          "post",

          Endpoint.Menu.permAll,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}
export default new Menu();
