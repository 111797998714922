import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import {
  Modal,
  Button,
  Tooltip,
  Card,
  Checkbox,
  Form,
  InputNumber,
  Select,
  Space,
  Spin,
  Popconfirm,
  Input,
} from "antd";

import Table from "../../../Components/UI/Table";
import PageHeader from "../../../Components/UI/PageHeader";

import {
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
const { Option } = Select;



export default class index extends Component {
  constructor(props) {
    super();
    this.state = {
      tableColumns: [
        {
          title: "İş Kolu",
          dataIndex: "sectorName",
          key: "sectorName",
          ...Helper.getColumnSearchProps(
            "sectorName",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
          sorter: (a, b) => a.sectorName.localeCompare(b.sectorName),
        },
        {
          title: "Test Adı",
          dataIndex: "testName",
          key: "testName",
          render: (text, record) => `${text} / ${record?.labNameEn || "-"}`,
          ...Helper.getColumnSearchProps(
            "testName",
            this.handleSearch,
            this.handleReset,
            "Test Adı"
          ),
          sorter: (a, b) => a.testName.localeCompare(b.testName),
        },
        {
          title: "Metodu",
          dataIndex: "methodName",
          key: "methodName",
          ...Helper.getColumnSearchProps(
            "methodName",
            this.handleSearch,
            this.handleReset,
            "Metodu"
          ),
          sorter: (a, b) => a.methodName.localeCompare(b.methodName),
        },
   
        {
          title: "Birim",
          dataIndex: "unitName",
          key: "unitName",
          ...Helper.getColumnSearchProps(
            "unitName",
            this.handleSearch,
            this.handleReset,
            "Birim"
          ),
          sorter: (a, b) => a.unitName.localeCompare(b.unitName),
        },

        {
          title: "Seçenekler",
          dataIndex: "options",
          key: "options",
          render: (text, record) => (
            <Space size="middle">
              <NavLink
                activeClassName="nav-active"
                exact
                to={`/settings/lab-test/edit/${record.testId}`}
              >
                <Button type="primary" shape="round">
                  Ureal Gir
                </Button>
              </NavLink>
            </Space>
          ),
        },
        {
          title: "Kaldır",
          dataIndex: "options",
          key: "options",
          render: (text, record) => (
            <Popconfirm
              title="Testi silmek istediğinize emin misiniz?"
              onConfirm={() => this.deleted(record.testId)}
              onCancel={() => console.log("cancel")}
              okText="Evet"
              cancelText="Hayır"
            >
              <Space size="middle">
                <Button type="link" icon={<DeleteOutlined />} danger />
              </Space>
            </Popconfirm>
          ),
        },
      ],
      data: [],
      loading: true,
    };
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const result = await DataService.TestLab.selectAll();

      if (result.status) {
        let data = [];
        for (const key in result.data) {
          if (result.data.hasOwnProperty(key)) {
            const element = result.data[key];
            data.push({
              ...element,
              key,
            });
          }
        }
        this.setState({ data, loading: false });
      } else {
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      Helper.ErrorMessage("Sistemsel bir hata meydana geldi");
    }
  };
  deleted = (testId) => {
    this.setState({ loading: true });
    DataService.TestLab.Delete(testId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage("Silme İşlemi Başarılı");
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        Helper.ErrorMessage(err.toString());
        this.setState({ loading: false });
      });
  };
  _renderSaved = ({ key, testId }) => {
    this.setState({ loading: true });
    const ureal = this[`ureal-${key}`].state.value,
      acredite = this[`checkbox-${key}`].rcCheckbox.state.checked;
    const requestParams = [
      {
        test_id: testId,
        ureal: parseFloat(ureal),
        accredited: acredite ? 1 : 0,
      },
    ];
    DataService.TestLab.Create(requestParams)
      .then((result) => {
        this.setState({ loading: false });
        if (!result.status) {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helper.ErrorMessage(error.toString());
      });
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Laboratuvar Testleri" />

          <Space size="">
            {/* 
                        <Button className="btn-add" shape="round" onClick={this._showModal}>
                            <PlusOutlined />
                            Yeni Test Ekle
                        </Button> */}

            <NavLink
              activeClassName="nav-active"
              exact
              to="/settings/lab-test/definition"
            >
              <Button className="btn-add" shape="round">
                <PlusOutlined />
                Test Tanımla
              </Button>
            </NavLink>
          </Space>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Table columns={this.state.tableColumns} data={this.state.data} />
          </Card>
        </Spin>
      </div>
    );
  }
}
