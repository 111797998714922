import React, { Component } from "react";

import { NavLink, Redirect } from "react-router-dom";

import PageHeader from "../../../../Components/UI/PageHeader";
import PackList from "../PackList";

import {
  QuestionCircleOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CrownOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";

import {
  Form,
  Button,
  Card,
  Space,
  Row,
  Col,
  Select,
  Divider,
  Input,
  Spin,
  Descriptions,
} from "antd";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
import _ from "lodash";
const { Option, OptGroup } = Select;

class index extends Component {
  state = {
    data: [],
    filteredData: [],
    productData: [],
    info: {},
    testId: null,
    redirect: false,
    page: "/",
    loading: true,
  };
  formRef = React.createRef();
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const {
        match: { params },
      } = this.props;

      if (params.testId) {
        const resultTests = await DataService.TestLab.select(params.testId);
        const resultProduct = await DataService.Product.SelectAll();
    
        
        if (resultProduct.status) {
          const filteredData = this.getEditProduct(resultTests.data);
          this.setState({
            testId: params.testId,
            data: resultProduct.response.data,
            productData: resultProduct.response.data,
            filteredData: filteredData,
            info: resultTests.info,
            loading: false,
          });
        } else {
          Helper.ErrorMessage(resultProduct.message);
        }
      } else {
        Helper.ErrorMessage("Test Id Bulunamadı");
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  getEditProduct = (data) => {
    
    const newData = [];
    const products = [];
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        newData.push({
          line: key,
          name_tr: element.productName,
          name_en: element.productNameEn,
          product_id: element.ProductId,
          ureal: element.ureal,
          accredite: element.accredited,
          sector_name: element.sectorName,
        });
        products.push(element.ProductId)
      }
    }
    this.formRef.current.setFieldsValue({
        product_list:products
    })
    return newData;
  };
  _onValueChange = (data) => {
    let filteredData = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        const find = _.filter(this.state.data, { product_id: element });

        if (find.length == 1) {
          const { name, product_id, product_nameEn, sector_name } = find[0];
          filteredData.push({
            line: key,
            product_id,
            name_tr: name,
            name_en: product_nameEn,
            sector_name,
          });
        }
      }
    }
    console.log("filteredDAta", filteredData);
    this.setState({ filteredData });
  };
  _onFinish = () => {
    const productList = this.refProductList?.getData() || [];
    const { testId } = this.state;
    const labTest = [];
    for (const key in productList) {
      if (Object.hasOwnProperty.call(productList, key)) {
        const element = productList[key];
        labTest.push({
        
          ureal: element.ureal,
          accredited: Number(element.accredite),
          productId: element.product_id,
        });
      }
    }
    const labTestInfo = [{
        test_id:testId,
        labTest
    }]
    DataService.TestLab.Create(labTestInfo)
      .then((result) => {
       
        if (result.status) {
          Helper.SuccessMessage("Başarılı bir şekilde kayıt edildi.");
          this.props.history.push("/settings/lab-test");
        } else {
          Helper.ErrorMessage(result.message);
          this.props.history.push("/settings/lab-test");
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  searchProduct = (search) => {
    if (search.length > 0) {
      const filterData = _.filter(this.state.data, (item) => {
        const searchItem = `${item.name} / ${item.name_en} (${item.method_name})`;

        return searchItem.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      });

      this.setState({
        productData:filterData,
      });
    } else {
      const { data } = this.state;
      this.setState({
        productData: data,
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.page} />;
    }

    const products = this.state.productData.map((item, index) => {
      return (
        <Option key={index} value={item.product_id}>
          {item.name}
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Laboratuvar Testi Düzenleme" />

          <div className="pageHeaderOptions">
            <Space>
              <NavLink
                activeClassName="nav-active"
                exact
                to="/settings/lab-test"
              >
                <Button type="primary" shape="round" className="btn-add">
                  Laboratuvar Testleri
                </Button>
              </NavLink>
            </Space>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <div
              className="sample-information"
              style={{ marginBottom: "24px" }}
            >
              <h2>Test Bilgileri</h2>
              <Row gutter="16" style={{ marginBottom: "16px" }}>
                <Col span={24} md={24}>
                  <Descriptions title="" bordered>
                    <Descriptions.Item label="İş Kolu">
                      {this.state.info?.sectorName || "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Test Adı">
                      {this.state.info?.testName || "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Lab Adı">
                      {this.state.info?.labName || "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Metodu">
                      {this.state.info?.methodName || "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipi">
                      {this.state.info?.statusName || "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Birim">
                      {this.state.info?.unitName || "-"}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24}>
                  <Form layout="vertical" ref={this.formRef}>
                    <Form.Item name="product_list" label="Ürünler">
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Test Bul"
                        onChange={this._onValueChange}
                        filterOption={false}
                        onSearch={this.searchProduct}
                      >
                        {products}
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>

            <PackList
              ref={(refs) => (this.refProductList = refs)}
              data={this.state.filteredData}
              floatValue = {this.state.info?.numberCharacters}
            />
            <div className="formButton">
              <Button
                type="primary"
                onClick={this._onFinish}
                style={{ marginTop: 15 }}
              >
                <EditOutlined />
                Kaydet
              </Button>
            </div>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
