import React, { Component, useState } from "react";

import { NavLink, Link } from "react-router-dom";

import PageHeader from "../../../Components/UI/PageHeader";

import Table from "../../../Components/UI/Table";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Row,
  Col,
  Select,
  Tree,
  Spin,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from "lodash";
import MenuComponent from "./Menus";
const { Option } = Select;

class index extends Component {
  state = {
    visible: false,
    permData: [],
    menuData: [],
    checkedData: [],
    allData: [],
    permId: undefined,
    loading: true,
    btnloading: false,
    menuLoading:true
  };
  MenuComponent = React.createRef();
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const responseMenuAll = await DataService.Menu.SelectAll();
      const responseMenuPerm = await DataService.User.permSelectAll();
      if (responseMenuPerm.status && responseMenuAll.status) {
        const menuPerm = _.filter(responseMenuPerm.response.data, {
          active: 1,
        });
        let permData = [];

        for (const key in menuPerm) {
          if (menuPerm.hasOwnProperty(key)) {
            const element = menuPerm[key];
            permData.push({
              permName: element.permTypeName,
              permNameEn: element.permTypeNameEn,
              permId: element.perm_id,
            });
          }
        }

        let menuData = await this.getTreeMenu(responseMenuAll);

        this.setState({
          permData,
          menuData,
          allData: responseMenuAll.data,
          loading: false,
      
        });
      } else {
        if (!responseMenuAll.status)
          Helper.ErrorMessage(responseMenuAll.message);
        if (!responseMenuPerm.status)
          Helper.ErrorMessage(responseMenuPerm.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  getTreeMenu = (responseMenu) => {
    return new Promise(async (resolve, reject) => {
      try {
        let menuAllData = [];
        for (const key in responseMenu.data) {
          if (responseMenu.data.hasOwnProperty(key)) {
            const element = responseMenu.data[key];
            menuAllData.push({
              title: element.name,
              key: element.menuId,
              topMenu: element.topMenu,
              children: [],
            });
          }
        }

        const menu = _.filter(menuAllData, { topMenu: "0" });
        const secondMenu = _.filter(menuAllData, (secondMenu) => {
          return _.find(menu, { key: secondMenu.topMenu });
        });
        const thirdMenu = _.filter(menuAllData, (thirdMenu) => {
          return _.find(secondMenu, { key: thirdMenu.topMenu });
        });
        const fourMenu = _.filter(menuAllData, (fourMenu) => {
          return _.find(thirdMenu, { key: fourMenu.topMenu });
        });
        let menuData = [];
        for (const key in menu) {
          if (menu.hasOwnProperty(key)) {
            let element = menu[key];
            const first = _.filter(secondMenu, { topMenu: element.key });
            element.children = first;
            for (const key in element.children) {
              if (element.children.hasOwnProperty(key)) {
                let seconds = element.children[key];

                const second = _.filter(thirdMenu, { topMenu: seconds.key });

                seconds.children = second;
                for (const key in seconds.children) {
                  if (seconds.children.hasOwnProperty(key)) {
                    let thirds = seconds.children[key];
                    const third = _.filter(fourMenu, {
                      topMenu: thirds.key,
                    });
                    thirds.children = third;
                  }
                }
              }
            }
            menuData.push(element);
          }
        }
        resolve(menuData);
      } catch (error) {
        reject(error);
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  closeModal = (e) => {
    this.setState({
      visible: false,
      btnloading: false,
    });
  };
  _permSave = async () => {
    try {
      this.setState({ btnloading: true });
      const getCheckeds = this.MenuComponent?.current?.getCheckeds();
      if (getCheckeds.length > 0 && this.state.permId) {
        let menu = [];
        for (const key in getCheckeds) {
          if (getCheckeds.hasOwnProperty(key)) {
            const element = getCheckeds[key];
            const find = _.find(this.state.allData, { menuId: element });
            if (find) {
              menu.push({
                menu_id: find.menuId,
                topMenu: find.topMenu,
              });
            }
            console.log("finds", { find, menudata: this.state.allData });
          }
        }
        const response = await DataService.Menu.PermSave(
          this.state.permId,
          menu
        );
        this.setState({ btnloading: false });
        if (response.status) {
          Helper.SuccessMessage(response.message);
          this.getData();
        } else {
          Helper.ErrorMessage(response.message);
        }
      } else {
        if (getCheckeds.length == 0) {
          Helper.warningMessage("Lütfen en az bir menü seçiniz.");
        } else {
          Helper.warningMessage("Lütfen yetki tipini seçiniz.");
        }
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  _changePerm = async (permId) => {
    try {
      this.setState({ loading: true });
      const result = await DataService.Menu.permSelect(permId);

      let checkedData = [];
      if (result.status) {
        for (const key in result.data) {
          if (result.data.hasOwnProperty(key)) {
            const element = result.data[key];
            checkedData.push(element.menu_id);
          }
        }
      } else {
        Helper.ErrorMessage(result.message);
      }
      this.setState({ permId, checkedData, loading: false });
    } catch (error) {
      console.log("error", error);
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Menü Yetki Yönetimi" />
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Row gutter={16}>
              <Col span={12}>
                <h3>Düzenlemek İstediğiniz Yetkiyi Seçin</h3>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Yetki Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(permId) => this._changePerm(permId)}
                >
                  {this.state.permData.map((item, index) => {
                    return (
                      <Option key={index} value={item.permId}>
                        {item.permName}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={12}>
                <h3>Erişebileceği Menüleri Seçin</h3>
                <MenuComponent
                    ref={this.MenuComponent}
                    treeData={this.state.menuData}
                    checked={this.state.checkedData}
                  />
                <div className="formButton">
                  <Button
                    type="primary"
                    onClick={this._permSave}
                    loading={this.state.loading}
                  >
                    Kaydet
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
