// Servis Çağrıları
import {ApiService} from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

class TrendAnalytics {
    constructor() {
    }

    // "WorkOrderCustomerId", "WorkOrderProductId", "WorkOrderSectorId", "WorkOrderName", "SamplePart"
    /**
     * Sample Add
     * @param {string} WorkOrderCustomerId - WorkOrderCustomerId
     * @param {string} WorkOrderProductId - WorkOrderProductId
     * @param {string} WorkOrderSectorId - WorkOrderSectorId
     * @param {string} WorkOrderName - WorkOrderName
     * @param {string} SamplePart - SamplePart
     */
    Check = ({
                 WorkOrderCustomerId,
                 WorkOrderProductId,
                 WorkOrderSectorId,
                 WorkOrderName,
                 SamplePart,
                 SampleId = null
             }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService(
                    "post",
                    Endpoint.TrendAnalytics.check,
                    {
                        customer_id: WorkOrderCustomerId,
                        product_id: WorkOrderProductId,
                        sector_id: WorkOrderSectorId,
                        get_part: WorkOrderName,
                        get_part_cat: SamplePart,
                        sample_id: SampleId
                    },
                    false
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                    console.log("TrendAnalytics", result)
                }
                resolve(result);
            } catch (error) {
                console.log("TrendAnalyticsError", {error});
                reject(error);
            }
        });
    };
}

export default new TrendAnalytics();
