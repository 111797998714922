// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class InventoryBrand {
  constructor() {}

  /**
   * InventoryBrand Ekleme
   * @param {string} InventoryBrandName
 
   */
  Create = ({
    InventoryBrandName
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          name: InventoryBrandName,
       
        };
        const response = await ApiService(
          "post",
          Endpoint.InventoryBrand.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * InventoryBrand Düzenleme
   * @param {string} InventoryBrandId
   * @param {string} InventoryBrandName

   */
  Update = ({
    InventoryBrandId,InventoryBrandName
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          brand_id: InventoryBrandId,

          name: InventoryBrandName,
         
        };
        const response = await ApiService(
          "put",
          Endpoint.InventoryBrand.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * InventoryBrand Silme İşlemi
   * @param {string} InventoryBrandId
   */
  Delete = (InventoryBrandId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          brand_id: InventoryBrandId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.InventoryBrand.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * InventoryBrand Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit=0, page=0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.InventoryBrand.getAll}/${limit}/${page}`;
    
        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * InventoryBrand Detayı Alma
   * @param {string} InventoryBrandId
   */
  Select = (InventoryBrandId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          brand_id: InventoryBrandId,
        };
        const response = await ApiService(
          "get",
          Endpoint.InventoryBrand.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * InventoryBrand Aktif/Pasif Durumu Değiştirme
   * @param {string} InventoryBrandId
   */
  StatusChange = (InventoryBrandId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          brand_id: InventoryBrandId,
        };

        const response = await ApiService(
          "post",
          Endpoint.InventoryBrand.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new InventoryBrand();
