import React, { Component } from "react";
import ListSort from "../../../Components/UI/ListSort";
import {
  QuestionCircleOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CrownOutlined,
} from "@ant-design/icons";
const dataArray = [
  {
    icon: <QuestionCircleOutlined />,
    color: "#FF5500",
    title: "Senior Product Designer",
    text: "Senior Product Designer",
  },
  {
    icon: <PlusCircleOutlined />,
    color: "#5FC296",
    title: "Senior Animator",
    text: "Senior Animator",
  },
  {
    icon: <CheckCircleOutlined />,
    color: "#2DB7F5",
    title: "Visual Designer",
    text: "Visual Designer",
  },
  {
    icon: <CrownOutlined />,
    color: "#FFAA00",
    title: "Computer Engineer",
    text: "Computer Engineer",
  },
];
class index extends Component {
  render() {
    return (
      <div>
        Hammadde
        {/* <ListSort data={dataArray} /> */}
      </div>
    );
  }
}

export default index;
