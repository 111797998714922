import React from "react";
import ReactExport from "react-data-export";
import { Table, Dropdown, Menu, Button } from "antd";
import ExcelExport from "./Excel";
import Pdf from "./Pdf";
import { DownloadOutlined } from "@ant-design/icons";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Export extends React.Component {
  state = {
    data: [],
    loading: true,
    columns: [],
    exportExcel: false,
    exportPdf: false,
  };

  menuOnclick = ({ key }) => {
    if (key == 1) this.setState({ exportExcel: true });
    else if (key == 2) this.setState({ exportPdf: true });
  };

  render() {
    const { type } = this.props;
    return (
      <div>
        <Dropdown
          overlay={
            <div className="dropdownContent">
              <Menu onClick={this.menuOnclick}>
                <Menu.Item key="1">Excel</Menu.Item>
                <Menu.Item key="2">Pdf</Menu.Item>
              </Menu>
            </div>
          }
        >
          <Button type="link" className="text-button">
            <DownloadOutlined /> Dışa Aktar 
          </Button>
        </Dropdown>
        {this.state.exportExcel && (
          <ExcelExport
            clear={() => this.setState({ exportExcel: false })}
            tableName={this.props.tableName}
            data={this.props.data}
            columns={this.props.columns}
          />
        )}
        {this.state.exportPdf && (
          <Pdf
            columnStyles={this.props.columnStyles}
            foot={this.props.foot}
            dates={this.props.dates}
            clear={() => this.setState({ exportPdf: false })}
            tableName={this.props.tableName}
            data={this.props.data}
            columns={this.props.columns}
          />
        )}
      </div>
    );
  }
}
export default Export;
