import React from "react";
import Webcam from "react-webcam";

const WebcamCapture = ({ onFinish }) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    onFinish(imageSrc);
  }, [webcamRef, setImgSrc]);

  return (
    <div  className='video-container' style={{display:'flex',height:'100%',width:'100%',flexDirection:'column'}} >
      <Webcam audio={false} ref={webcamRef}   screenshotFormat="image/jpeg" style={{height:'100%',width:'100%'}} />
      <button onClick={capture}  style={{marginTop:25}} >Capture photo</button>
    </div>
  );
};

export default WebcamCapture;
