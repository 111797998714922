import React, { useState, useEffect, useRef } from "react";

import { Card, Tabs, Space } from "antd";

import PageHeader from "../../../Components/UI/PageHeader";
import SampleReport from "./sample";
import CustomerReport from './customer';
import ProductReport from './product';
const { TabPane } = Tabs;

const DetailedReport = () => {
  const [activeTabs, setActiveTabs] = useState(1);

  return (
    <div>
      <div className="pageHeaderWrapper">
        <PageHeader title="Detaylı Rapor" subTitle="" />
      </div>

      <Card bordered={false} className="cardTable">
        <Tabs tabPosition={"top"} onChange={(value) => setActiveTabs(value)}>
          <TabPane tab="Testlere Göre Rapor" key="1">
            {activeTabs == 1 && <SampleReport />}
          </TabPane>
          <TabPane tab="Ürünlere Göre Rapor" key="2">
            {activeTabs == 2 && <ProductReport />}
          </TabPane>
          <TabPane tab="Müşteriye Göre Rapor" key="3">
            {activeTabs == 3 && <CustomerReport />}
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default DetailedReport;
