import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Table } from "antd";


export default class Pdf extends React.Component {
  componentDidMount = async () => {
    this.exportPdf();
    this.props.clear();
  };
  editText = (text) => {
    var trMap = {
      ç: "c",
      Ç: "C",
      ğ: "g",
      Ğ: "G",
      ş: "s",
      Ş: "S",
      ü: "u",
      Ü: "U",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      _: " ",
    };
    for (var key in trMap) {
      text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
    }
    return text;
  };
  exportPdf = () =>
    new Promise((resolve, reject) => {
      let pageNo = 0;
      const doc = new jsPDF("l", "pt", [595.28, 841.89]);
      doc.page = 1;
      const bodyArray = [];
      const columns = JSON.parse(JSON.stringify(this.props.columns));
      const headArray = this.props.columns.map((column) =>
        this.editText(column.title)
      );
      const dataArr = JSON.parse(JSON.stringify(this.props.data));
      dataArr.map((item) => {
        const arr = [];
        this.props.columns.map((column) => {
          if (item[column.dataIndex] != null) {
            const findArray = (item) => item == column.dataIndex;
            if (this.props.dates)
              if (this.props.dates.find(findArray))
                item[column.dataIndex] = new Date(item[column.dataIndex])
                  .toLocaleString()
                  .toString();
            arr.push(this.editText(item[column.dataIndex].toString()));
          } else arr.push("");
        });
        bodyArray.push(arr);
      });
      const columnStyle = {};
      for (const index in this.props.columns) {
        columnStyle[index] = {
          columnWidth: this.props.columns[index].width / 50,
        };
      }

      const footer = (data) => {
        doc.text(
          "Sayfa No: " + data.pageCount,
          data.settings.margin.left,
          doc.internal.pageSize.height - 30
        );
        doc.text(
          "LIMS " + this.editText(this.props.tableName),
          doc.internal.pageSize.width - 200,
          doc.internal.pageSize.height - 30
        );
      };

      const logo = new Image();
    
      doc.setFontSize(15);
      doc.setTextColor(27, 65, 140);
      doc.setFontType("bold");
      doc.text(this.editText(this.props.tableName), 390, 80);
      doc.setFontSize(10);
      doc.autoTable({
        startY: 105,
        tableWidth: "auto",
        didDrawPage: footer,
        showHead: "everyPage",
        tableLineColor: 200,
        tableLineWidth: 0,
        head: [headArray],
        body: bodyArray,
        headStyles: { fillColor: [27, 65, 140] },
        columnStyles: this.props.columnStyles,
      });

      if (this.props.foot) doc.addPage();
      const footTableData = [];
      if (this.props.foot) {
        const footTableData = [];
        this.props.foot.map((item) =>
          footTableData.push([this.editText(item)])
        );
        doc.autoTable({
          tableWidth: "auto",
          columnWidth: "wrap",
          showHead: "firstPage",
          tableLineColor: 200,
          tableLineWidth: 0,
          head: [["Toplamlar"]],
          body: footTableData,
          headStyles: { fillColor: [27, 65, 140] },
        });
        doc.text(
          "LIMS " + this.editText(this.props.tableName) + " Rapor",
          doc.internal.pageSize.width - 200,
          doc.internal.pageSize.height - 30
        );
      }

      doc.save(this.props.tableName + ".pdf");

      resolve(true);
    });

  render() {
    return <div></div>;
  }
}
