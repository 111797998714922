import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";
import { Card, Modal, Button, Tooltip, Form, Input, Space, Spin } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
class index extends Component {
  constructor(props){
    super(props)
    this. state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      sectorId: null,
  
      tableColumns: [
        {
          title: "İş Kolu Adı",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "İş Kolu Adı"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
  
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.Sector.SelectAll(4000, 1)
      .then((result) => {
        if (result.status) {
          const { data } = result.response;
          let sectors = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              sectors.push({
                ...element,
                id: key,
              });
            }
          }
          this.setState({
            data: sectors,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Sector", JSON.stringify(error));
      });
  };
  _editClick = ({ sector_id, name }) => {
    this.setState({ visible: true, sectorId: sector_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({ sectorName: name });
      }, 100);
    });
  };
  _deleteClick = ({ sector_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} firmasını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(sector_id);
      }
    });
  };
  deleted = (sector_id) => {
    this.setState({ loading: true });
    DataService.Sector.Delete(sector_id)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      sectorId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ sectorName }) => {
    try {
      const { sectorId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (sectorId) {
        result = await DataService.Sector.Update({
          sectorName,
          sectorId,
        });
      } else {
        result = await DataService.Sector.Create({
          sectorName,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, sectorId: null, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="İş Kolu" subTitle="" />

            <Button
              shape="round"
              className="btn-add"
              icon={<PlusOutlined />}
              onClick={this._showModal}
            >Yeni İş Kolu Ekle</Button>

          <Modal
            title={this.state.sectorId ? (
              "İş Kolu Düzenle"
            ) : (
              "İş Kolu Ekle"
            )}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="sectorName"
                label="İş Kolu Adı"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <div className="formButton">
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.sectorId ? (
                  <>
                    {" "}
                    <EditOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <PlusOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
              </div>
            </Form>
          </Modal>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              // title={() => (
              //   <div style={{ height: "20px" }}>
              //     <div style={{ position: "absolute", right: 17, top: 17 }}>
              //       <Export
              //         data={this.state.data}
              //         columns={[...this.state.tableColumns].slice(0, 6)}
              //         tableName={"Sector"}
              //         dates={["tarih"]}
              //       />
              //     </div>
              //   </div>
              // )}
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
