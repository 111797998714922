import React, {Component} from "react";

import Table from "../../Components/UI/Table";
import PageHeader from "../../Components/UI/PageHeader";
import Tables from "../../Components/Table";
import Export from "../../Components/Export/Export";
import {
    Modal,
    Button,
    Tooltip,
    Card,
    Form,
    Input,
    Select,
    Space,
    Spin,
    Row,
    Col,
} from "antd";

import {PlusOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import Swal from "sweetalert2";

import DataService from "../../Provider/Service/DataService";
import Helper from "../../Helper";
import _ from "lodash";
import {isEmail, ReactMultiEmail} from "react-multi-email";

const {Option} = Select;

const city = require("../../Static/city.json");
const district = require("../../Static/district.json");

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emails: [],
            visible: false,
            data: [],
            customerId: null,
            topCustomer: [{value: 0, text: "Ana Firma"}],
            city: "0",
            loading: true,
            btnloading: false,
            tableColumns: [
                {
                    title: "Üst Firma",
                    dataIndex: "topCustomerName",
                    key: "topCustomerName",
                    width: 100,
                    ...Helper.getColumnSearchProps(
                        "topCustomerName",
                        this.handleSearch,
                        this.handleReset,
                        "Üst Firma"
                    ),
                    sorter: (a, b) => {
                        if (a.topCustomerName) {
                            return a.topCustomerName.localeCompare(b.topCustomerName);
                        } else {
                            const text = "Ana Firma";
                            return text.localeCompare(b.topCustomerName);
                        }
                    },
                    render: (text) => {
                        return text ? text : "Ana Firma";
                    },
                },
                {
                    title: "Firma Adı",
                    dataIndex: "name",
                    key: "name",
                    ...Helper.getColumnSearchProps(
                        "name",
                        this.handleSearch,
                        this.handleReset,
                        "Firma Adı"
                    ),
                    sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
                },

                {
                    title: "Telefon",
                    dataIndex: "phone",
                    key: "phone",
                    width: 100,
                    ...Helper.getColumnSearchProps(
                        "phone",
                        this.handleSearch,
                        this.handleReset,
                        "Telefon"
                    ),
                    sorter: (a, b) => a.phone - b.phone,
                    render: (text) => {
                        return text ? text : "N/A";
                    },
                },
                {
                    title: "GSM",
                    dataIndex: "phone_gsm",
                    key: "phone_gsm",
                    width: 80,
                    ...Helper.getColumnSearchProps(
                        "phone_gsm",
                        this.handleSearch,
                        this.handleReset,
                        "GSM"
                    ),
                    sorter: (a, b) => a.phone_gsm - b.phone_gsm,
                    render: (text) => {
                        return text ? text : "N/A";
                    },
                },

                {
                    title: "Seçenekler",
                    fixed: "right",
                    dataIndex: "options",
                    key: "options",
                    render: (text, record) => this.Actions(text, record),
                },
            ],
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getData();
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({searchText: ""});
    };
    getData = () => {
        DataService.Customer.SelectAll()
            .then((result) => {
                if (result.status) {
                    const {data} = result.response;
                    let topCustomer = [{value: 0, text: "Ana Firma"}];
                    let customers = [];

                    for (const key in data) {
                        if (data.hasOwnProperty(key)) {
                            const element = data[key];
                            topCustomer.push({
                                value: element.customer_id,
                                text: element.name,
                            });
                            customers.push({
                                ...element,
                                id: key,
                            });
                        }
                    }
                    this.setState({
                        data: customers,
                        topCustomer,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log("Customer", JSON.stringify(error));
            });
    };
    _editClick = ({
                      address,
                      customer_id,
                      iban,
                      name,
                      phone,
                      phone_gsm,
                      tax_administration,
                      tax_no,
                      title,
                      top_customer_id,
                      city_id,
                      district_id,
                  }) => {
        this.setState(
            {visible: true, customerId: customer_id, city: city_id},
            () => {
                setTimeout(() => {
                    this.formRef.current.setFieldsValue({
                        customerAdress: address,
                        customerGsmPhone: phone_gsm,
                        customerIban: iban,
                        customerName: name,
                        customerPhone: phone,
                        customerTaxNumber: tax_no,
                        customerTaxOffice: tax_administration,
                        customerTopId: top_customer_id ? parseInt(top_customer_id) : 0,
                        custpmerTitle: title,
                        customerCityId: city_id,
                        customerDistrictId: district_id,
                    });
                }, 100);
            }
        );

        DataService.Customer.mailList(customer_id)
            .then((result) => {
                if (result.data?.length > 0) {
                    const mailFormat = result.data.map((item) => item.mail);
                    this.setState({emails: mailFormat});
                } else {
                    this.setState({emails: []});
                }

                console.log('response', result);
            })
    };
    _deleteClick = ({customer_id, name}) => {
        Swal.fire({
            title: "Silmek istediğinize emin misiniz?",
            text: `${name} firmasını silmek üzeresiniz.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Evet",
            cancelButtonText: "Hayır",
        }).then((result) => {
            if (result.value) {
                this.deleted(customer_id);
            }
        });
    };
    deleted = (customerId) => {
        DataService.Customer.Delete(customerId)
            .then((result) => {
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    this.getData();
                } else {
                    Helper.ErrorMessage(result.message);
                }
            })
            .catch((error) => {
                console.log("error", error);
                Helper.ErrorMessage("Bir hata meydana geldi");
            });
    };
    Actions = (text, record) => {
        return (
            <Space size="middle">
                <Button
                    shape="round"
                    type="primary"
                    onClick={() => this._editClick(record)}
                >
                    Düzenle
                </Button>
                <Button
                    type="link"
                    icon={<DeleteOutlined/>}
                    danger
                    onClick={() => this._deleteClick(record)}
                ></Button>
            </Space>
        );
    };
    _showModal = () => {
        this.setState({
            visible: true,
        });
    };
    _closeModal = (e) => {
        this.formRef.current.resetFields();
        this.setState({
            visible: false,
            customerId: null,
            btnloading: false,
        });
    };
    _tableHandleChange = (pagintions, filters, sorter) => {
        this.setState({filteredInfo: filters});
    };
    _formFinish = async ({
                             customerAdress,
                             customerGsmPhone,
                             customerIban,
                             customerName,
                             customerPhone,
                             customerTaxNumber,
                             customerTaxOffice,
                             customerTopId,
                             custpmerTitle,
                             customerCityId,
                             customerDistrictId,
                             email
                         }) => {
        try {
            const {customerId} = this.state;
            this.setState({btnloading: true});
            let result;
            if (customerId) {
                result = await DataService.Customer.Update({
                    customerAdress,
                    customerGsmPhone,
                    customerIban,
                    customerName,
                    customerPhone,
                    customerTaxNumber,
                    customerTaxOffice,
                    customerTopId,
                    custpmerTitle,
                    customerId,
                    customerCityId,
                    customerDistrictId,
                    email
                });
            } else {
                result = await DataService.Customer.Create({
                    customerAdress,
                    customerGsmPhone,
                    customerIban,
                    customerName,
                    customerPhone,
                    customerTaxNumber,
                    customerTaxOffice,
                    customerTopId,
                    custpmerTitle,
                    customerCityId,
                    customerDistrictId,
                    email
                });
            }

            if (result.status) {
                Helper.SuccessMessage(
                    "İşleminiz başarılı bir şekilde gerçekleştirildi."
                );
                this.formRef.current.resetFields();
                this.setState(
                    {
                        loading: true,
                        visible: false,
                        customerId: null,
                        btnloading: false,
                    },
                    this.getData
                );
            } else {
                Helper.ErrorMessage(result.message);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    render() {
        const {emails} = this.state;

        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Müşteriler"/>

                    <div className="pageHeaderOptions">
                        <Space>
                            <Export
                                data={this.state.data}
                                columns={[...this.state.tableColumns].slice(0, 6)}
                                tableName={"Customers"}
                                dates={["tarih"]}
                            />

                            <Tooltip title="Yeni Müşteri Ekle" placement="left">
                                <Button
                                    shape="circle"
                                    icon={<PlusOutlined/>}
                                    onClick={this._showModal}
                                />
                            </Tooltip>
                        </Space>
                    </div>

                    <Modal
                        title="Yeni Müşteri Ekle"
                        visible={this.state.visible}
                        onCancel={this._closeModal}
                        footer=""
                    >
                        <Form
                            layout="vertical"
                            hideRequiredMark
                            onFinish={this._formFinish}
                            ref={this.formRef}
                        >
                            <Form.Item
                                name="customerTopId"
                                label="Üst Firma"
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Üst Firma  Seçiniz"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.topCustomer.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.value}>
                                                {item.text}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Row gutter="16">
                                <Col span="12">
                                    <Form.Item
                                        name="customerName"
                                        label="Firma Adı"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Firma Adı"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="custpmerTitle"
                                        label="Firma Ünvanı"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Firma Ünvanı"/>
                                    </Form.Item>
                                </Col>
                                <Col span="12">
                                    <Form.Item
                                        name="customerIban"
                                        label="Firma IBAN"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Firma IBAN" maxLength={26}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter="16">
                                <Col span="12">
                                    <Form.Item
                                        name="customerTaxNumber"
                                        label="Firma Vergi Numarası"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Firma Vergi Numarası" maxLength={11}/>
                                    </Form.Item>
                                </Col>
                                <Col span="12">
                                    <Form.Item
                                        name="customerTaxOffice"
                                        label="Firma Vergi Dairesi"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Firma Vergi Dairesi"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter="16">
                                <Col span="12">
                                    <Form.Item
                                        name="customerPhone"
                                        label="Telefon"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Telefon" maxLength={11}/>
                                    </Form.Item>
                                </Col>
                                <Col span="12">
                                    <Form.Item
                                        name="customerGsmPhone"
                                        label="Cep Telefonu"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Cep Telefonu" maxLength={11}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter="16">
                                <Col span="12">
                                    <Form.Item
                                        name="customerCityId"
                                        label="Şehir"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Şehir Seçiniz"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(city) => this.setState({city})}
                                        >
                                            {city.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.value}>
                                                        {item.text}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span="12">
                                    <Form.Item
                                        name="customerDistrictId"
                                        label="İlçe"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                                type: "string",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="İlçe  Seçiniz"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {_.filter(district, {city_value: this.state.city}).map(
                                                (item, index) => {
                                                    return (
                                                        <Option key={index} value={item.value}>
                                                            {item.text}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                name="customerAdress"
                                label="Adres"
                                rules={[
                                    {
                                        required: false,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                        type: "string",
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder="Adres"/>
                            </Form.Item>

                            <Form.Item name="email" label="E-Mail Listesi">
                                <ReactMultiEmail
                                    placeholder=""
                                    emails={emails}
                                    onChange={(_emails) => {
                                        this.setState({emails: _emails});
                                    }}
                                    validateEmail={(email) => {
                                        return isEmail(email); // return boolean
                                    }}
                                    getLabel={(email, index, removeEmail) => {
                                        return (
                                            <div data-tag key={index}>
                                                {email}
                                                <span
                                                    data-tag-handle
                                                    onClick={() => removeEmail(index)}
                                                >
                                                ×
                                            </span>
                                            </div>
                                        );
                                    }}
                                />
                            </Form.Item>

                            <div className="formButton">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.btnloading}
                                    //onClick={this._onClick}
                                >
                                    {this.state.customerId ? (
                                        <>
                                            {" "}
                                            <EditOutlined/> Kaydet{" "}
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            <PlusOutlined/> Ekle{" "}
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                </div>
                <Spin spinning={this.state.loading}>
                    <Card bordered={false} className="cardTable">
                        {/* <Table columns={tableColumns} data={tableData} /> */}

                        <Tables
                            columns={this.state.tableColumns}
                            onChange={this._tableHandleChange}
                            dataSource={this.state.data}
                            pagination={{
                                defaultPageSize: 1,
                            }}
                            onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
                            rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
                        />
                    </Card>
                </Spin>
            </div>
        );
    }
}

export default index;
