import React from "react";
import { Table, InputNumber, Checkbox, Button } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import _ from "lodash";
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class SortableTable extends React.Component {
  state = {
    dataSource: [],
    columns: [
      {
        title: "Sıralama",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        title: "İş Kolu",
        dataIndex: "sector_name",
      },
      {
        title: "Name TR",
        dataIndex: "name_tr",
      },
      {
        title: "Name EN",
        dataIndex: "name_en",
      },

      {
        title: "Ureal",
        dataIndex: "ureal",
        render: (text, record) => {
          const defaultvalue = parseFloat(text);
          return (
            <InputNumber
              defaultValue={defaultvalue}
              step={0.1}
              onBlur={(e) => this.save({ ...record, ureal: e.target.value })}
            />
          );
        },
      },
      {
        title: "Akredite",
        dataIndex: "accredite",
        render: (text, record) => {
          return (
            <Checkbox
              onChange={(e) =>
                this.save({ ...record, accredite: e.target.checked })
              }
              checked={Boolean(text)}
            />
          );
        },
      },
    ],
    checkedRefs: [],
  };

  componentDidMount() {
    this.setData(this.props.data);
  }
  componentDidUpdate(prev, next) {
    if (prev.data !== this.props.data) {
      this.setData(this.props.data);
    }
  }
  setData = (dataSource) => {
    let data = [];

    for (const key in dataSource) {
      if (dataSource.hasOwnProperty(key)) {
        const element = dataSource[key];
        const find = _.find(this.state.dataSource, { key: element.product_id });
        if (!find) {
          data.push({
            name_tr: element?.name_tr || "-",
            name_en: element?.name_en || "-",

            index: key,
            key: element.product_id,

            ureal: element?.ureal || 0,
            accredite: element?.accredite || false,
            sector_name: element?.sector_name || "-",
          });
        } else {
          data.push({
            name_tr: find.name_tr,
            name_en: find.name_en,

            index: key,
            key: find.key,

            ureal: find.ureal,
            accredite: find.accredite,
            sector_name: find.sector_name,
          });
        }
      }
    }
    this.setState({ dataSource: data });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dataSource } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      this.setState({ dataSource: newData });
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };
  save = (row) => {
    console.log("row", row);
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });

    this.setState({
      dataSource: newData,
    });
  };
  getData = () => {
    const { dataSource } = this.state;
    let newData = [];

    for (const key in dataSource) {
      if (dataSource.hasOwnProperty(key)) {
        const element = dataSource[key];

        newData.push({
          line: key,
          product_id: element.key,
          ureal: element.ureal,
          accredite: element.accredite,
        });
      }
    }
    return newData;
  };
  render() {
    const { dataSource, columns } = this.state;

    const DraggableContainer = (props) => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
    return (
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: this.DraggableBodyRow,
          },
        }}
      />
    );
  }
}

export default SortableTable;
