import React, { Component } from "react";
import "./index.css";

import PageHeader from "../../../Components/UI/PageHeader";

import { Modal, Button, Tooltip, Card, Form, Input, Select, Space ,Spin} from "antd";

import { NotificationOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment'
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
const { Option } = Select;
const ListItem = ({ title, comment, date, onClick }) => {
  return (
    <div className="announcement-card">
      <div className="card-icon">
        <NotificationOutlined />
      </div>
      <div className="card-content">
        <h4>{title}</h4>
        <span className="text">{comment}</span>
        <span className="time">{date}</span>
      </div>
      <div className="card-action">
        <Button onClick={onClick} type="primary">
          Göster
        </Button>
      </div>
    </div>
  );
};
class index extends Component {
  state = { visible: false, data: [],loading:true,announcementId:null };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.Announcement.SelectAll()
      .then((result) => {
        if (result.status) {
          this.setState({
            data: result.response.data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helper.ErrorMessage(error.toString());
      });
  };
  
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  _formFinish = ({title,detail,hedef}) => {
    console.log('formFinish',{title,detail,hedef});
  }
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Duyurular" subTitle="" />

          <div className="pageHeaderOptions">
            <Tooltip title="Yeni Duyuru Yap" placement="left">
              <Button
                shape="circle"
                icon={<PlusOutlined />}
                onClick={this.showModal}
              />
            </Tooltip>

            <Modal
              title="Yeni Duyuru Yap"
              visible={this.state.visible}
              onCancel={this.closeModal}
              footer=""
            >
              <Form layout="vertical" hideRequiredMark onFinish={this._formFinish} >
                <Form.Item
                  name="title"
                  label="Duyuru Başlığı"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Input placeholder="Duyuru Başlığı" />
                </Form.Item>
                <Form.Item
                  name="detail"
                  label="Duyuru Detayı"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Duyuru Detayı" />
                </Form.Item>
                <Form.Item
                  name="hedef"
                  label="Hedef"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bu alanı boş bırakmayın",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Seçiniz"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="">Yetki</Option>
                    <Option value="">Yetki</Option>
                    <Option value="">Diğer Yetki</Option>
                  </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  <PlusOutlined /> Gönder
                </Button>
              </Form>
            </Modal>
          </div>
        </div>

      <Spin spinning={this.state.loading} >
      <Card bordered={false} className="cardTable">
          <h3>Son Duyurular</h3>
          <div className="announcement-card-list">
            {this.state.data.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  title={item.title}
                  comment={item.comment}
                  date={moment(item?.date_start).fromNow("hour")}
                  onClick={() => console.log("duyuru", item.ann_id)}
                />
              );
            })}
          </div>
        </Card>
      </Spin>
      </div>
    );
  }
}

export default index;
