// genel API-Axios Alt yapısı
import Axios from "axios";
import Helper from "../../Helper";
import { baseAPIUrl } from "../Config/Config";
/**
 * Tüm Api istekleri ApiService fonksiyonu üzerinden yapılmaktadır.
 * @param method ile POST/GET/PUT/DELETE
 * @param url Http/Https url adress
 * @param requestParams  Gönderilecek datalar
 * @param token kullanıcı token değeri
 *
 *
 */
Axios.defaults.baseURL = baseAPIUrl;

Axios.defaults.headers.common["X-API-KEY"] =
  "e3784eb2-b842-4a31-a204-14bc90bc5856";
export const ApiService = async (
  method,
  url,
  requestParams,
  multipart = false
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Axios.defaults.timeout = 5000;
      const headers = {
        "Content-Type":
          multipart == false ? "application/json" : "multipart/form-data",

        Authorization: Helper.getlocalStorage("Token"),
      };

      const requestConfig = {
        method: method || "get",
        url,
        data: requestParams,
        headers: headers,
      };

      const result = await Axios(requestConfig);
      const response = result.data;

      resolve(response);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            localStorage.removeItem("Token");
            Helper.ErrorMessage(
              "Oturumunuz sonlanmıştır,lütfen tekrar kullanıcı girişi yapınız."
            );
            setTimeout(() => {
              window.location.href = "/login";
            }, 3000);
            return;
          case 404:
            Helper.ErrorMessage("Sayfa bulunamadı.");
            return;
          case 500:
            Helper.ErrorMessage("Sistemsel bir hata alındı.");
            return;
          case 400:
            Helper.ErrorMessage("Parametre hatası");
            return;
          case 415:
            Helper.ErrorMessage("Media Type, No Content hatası");
            return;

          default:
            Helper.ErrorMessage("Sistemsel bir hata alındı.");
            return;
        }
      } else {
        Helper.ErrorMessage("Bilinmeyen bir hata meydana geldi.");
        // localStorage.removeItem("Token");
        // Helper.ErrorMessage(
        //   "Oturumunuz sonlanmıştır,lütfen tekrar kullanıcı girişi yapınız."
        // );
        //  setTimeout(() => {
        //  window.location.href = "/login";
        //  }, 1000);
      }
      reject(err);
    }
  });
};
