import React, { Component } from 'react';

import Table from '../../Components/UI/Table';
import PageHeader from '../../Components/UI/PageHeader';

import { Modal, Button, Tooltip, Card, Form, Input, Select, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const tableColumns = [
    {
        title: 'Teklif No',
        dataIndex: 'offer_no',
        key: 'offer_no',
    },
    {
        title: 'Tarih',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Müşteri',
        dataIndex: 'customer',
        key: 'customer',
    },
    {
        title: 'Testler',
        dataIndex: 'tests',
        key: 'tests',
    },
    {
        title: 'Fiyat',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'Seçenekler',
        dataIndex: 'options',
        key: 'options',
        render: (text, record) => (
          <Space size="middle">
            <Button shape="round" type="primary" >İncele</Button>
            <Button shape="round" type="primary" >Düzenle</Button>
            <Button type="link" danger>Sil</Button>
          </Space>
        ),
    }
];

const tableData = [
];

class index extends Component {

    state = { visible: false };
    showModal = () => {
        this.setState({
        visible: true,
        });
    };

    closeModal = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    
                    <PageHeader title="Sözleşmeler" />

                    {/* <Tooltip title="Yeni Müşteri Ekle" placement="left">
                        <Button shape="circle" icon={<PlusOutlined />} onClick={this.showModal} />
                    </Tooltip>
                    
                    <Modal
                        title="Yeni Müşteri Ekle"
                        visible={this.state.visible}
                        onCancel={this.closeModal}
                        footer=""
                        >

                        <Form layout="vertical" hideRequiredMark>
                            <Form.Item
                                name="name"
                                label="Müşteri Adı"
                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                >
                                <Input placeholder="Müşteri Adı" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="E-Posta"
                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                >
                                <Input placeholder="E-Posta" />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label="Telefon"
                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                >
                                <Input placeholder="Telefon" />
                            </Form.Item>
                            <Form.Item
                                name="manager"
                                label="Yönetici"
                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                >
                                    <Input placeholder="Yönetici Ad Soyad" />
                            </Form.Item>
                            <Form.Item
                                name="city"
                                label="Şehir"
                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                >
                                <Select
                                    showSearch
                                    placeholder="Şehir Seçiniz"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="istanbul">İstanbul</Option>
                                    <Option value="ankara">Ankara</Option>
                                    <Option value="diger">Diğer Şehirler</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="address"
                                label="Adres"
                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                >
                                <Input.TextArea placeholder="Adres" />
                            </Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                >
                                <PlusOutlined /> Ekle
                            </Button>
                        </Form>

                    </Modal> */}
                </div>


                <Card bordered={false} className="cardTable">
                    <Table columns={tableColumns} data={tableData} />

                    {/* Teklif onaylandığında sözleşmeye dönüşür. sözleşme ekleyebilir. <br/>
                    Ek olarak başlangıç, bitiş, pdf ekleme, kazanç, fatura vs. */}
                </Card>
            </div>
        );
    }
}

export default index;