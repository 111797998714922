import React, { useState, useEffect } from "react";

import Table from "../../Components/Table";

import { Card, Spin, Input, Modal, Space, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import _ from "lodash";
import PageHeader from "../../Components/UI/PageHeader";

import DateService from "../../Provider/Service/DataService";

import Helper from "../../Helper";

const { Search } = Input;
const TestLabsPrices = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const [btnloading, setBtnLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState({
    testId: null,
    price: null,
    testName: null,
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => {
            setEditData({
              testId: record?.test_id,
              price: record?.price,
              testName: record?.name,
            });
            showModal();
          }}
        >
          Fiyat Güncelle
        </Button>
      </Space>
    );
  };
  const tableColumns = [
    {
      title: "İş Kolu",
      dataIndex: "sector_name",
      key: "sector_name",
      ...Helper.getColumnSearchProps(
        "sector_name",
        handleSearch,
        handleReset,
        "İş Kolu"
      ),
      sorter: (a, b) => a.sector_name.localeCompare(b.sector_name),
    },
    {
      title: "Test Adı",
      dataIndex: "name",
      key: "name",
      ...Helper.getColumnSearchProps(
        "name",
        handleSearch,
        handleReset,
        "Test Adı"
      ),
      sorter: (a, b) => a?.name.localeCompare(b?.name), // string sıralama
    },
    {
      title: "Test Adı EN",
      dataIndex: "name_en",
      key: "name_en",
      ...Helper.getColumnSearchProps(
        "name_en",
        handleSearch,
        handleReset,
        "Test Adı EN"
      ),
      sorter: (a, b) => a?.name_en.localeCompare(b?.name_en), // string sıralama
    },
    {
      title: "Metodu",
      dataIndex: "method_name",
      key: "method_name",
      ...Helper.getColumnSearchProps(
        "method_name",
        handleSearch,
        handleReset,
        "Metodu"
      ),
      sorter: (a, b) => a.method_name.localeCompare(b.method_name),
    },
    {
      title: "Birim",
      dataIndex: "unit_name",
      key: "unit_name",
      ...Helper.getColumnSearchProps(
        "unit_name",
        handleSearch,
        handleReset,
        "Birim"
      ),
      sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
    },
    {
      title: "Fiyat",
      dataIndex: "price",
      key: "price",
      render: (value) => `${value || 0} $`,
    },
    {
      title: "Seçenekler",
      fixed: "right",
      dataIndex: "options",
      key: "options",
      render: Actions,
    },
  ];
  const getData = () => {
    DateService.Test.SelectAll()
      .then((result) => {
        setLoading(false);
        if (result.status) {
          console.log("result", result);
          setData(result?.response?.data || []);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  useEffect(() => {
    getData();
    return () => {};
  }, []);
  const closeModal = () => {
    setEditData({
      testId: null,
      testName: null,
      price: null,
    });
    setModalVisible(false);
  };
  const showModal = () => setModalVisible(true);
  const savedPrice = () => {
    const { testId, price } = editData;
    if (!testId) {
      Helper.ErrorMessage("Lütfen test seçiniz.");
      return;
    }
    if (!testId || Number(price) < 0) {
      Helper.ErrorMessage("Lütfen test fiyatını giriniz.");
      return;
    }
    setBtnLoading(true);
    DateService.Test.setPrice({
      testId: testId,
      price: Number(price || 0),
    })
      .then((result) => {
        setBtnLoading(false);
        if (result.status) {
          closeModal();

          Helper.SuccessMessage(result.message);
          setLoading(true);
          getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        setBtnLoading(false);
        console.log("priceSavedError", error.toString());
        Helper.ErrorMessage(error.toString());
      });
  };
  return (
    <div>
      <div className="pageHeaderWrapper">
        <PageHeader title="Analiz Fiyatları" subTitle="" />
      </div>

      <Spin spinning={loading}>
        <Card bordered={false} className="cardTable">
          <Table columns={tableColumns} dataSource={data} pagination />
        </Card>
      </Spin>
      <Modal
        title={`${editData?.testName || ""} Testinin Fiyatını Güncelle`}
        visible={modalVisible}
        onCancel={closeModal}
        footer=""
      >
        <Input
          value={editData?.price}
          onChange={(e) => setEditData({ ...editData, price: e.target.value })}
        />
        <div className="formButton">
          <Button type="primary" loading={btnloading} onClick={savedPrice}>
            <EditOutlined /> Kaydet{" "}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TestLabsPrices;
