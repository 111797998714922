import React, { useEffect, useState } from "react";

import { Card, Timeline, Spin, Result } from "antd";
import moment from "moment";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
const ListItem = ({ type, title, date }) => {
  return (
    <Timeline.Item color="green">
      <span>
        {title} <span className="time">{date}</span>
      </span>
    </Timeline.Item>
  );
};

const Logs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const date = moment().format("MM-YYYY");

    DataService.Logs.Select(date, 10, 1)
      .then((result) => {
        if (result.status) {
          setData(result.data);
          setLoading(false);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  }, []);

  return (
    <Spin spinning={loading}>
      <Card
        title="Sistem Logları"
        extra={<a href="/modules/log">Tümünü Gör</a>}
      >
        {data.length > 0 ? (
          <Timeline>
            {data.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  type={item.level_name}
                  title={item.message}
                  date={moment(item.datetime).format("DD.MM.YYYY HH:MM:SS")}
                />
              );
            })}
          </Timeline>
        ) : (
          <Result title="Log Bulunamadı" />
        )}
      </Card>
    </Spin>
  );
};

export default Logs;
