import React from "react";

import {
  Statistic,
  Card,
  Row,
  Col,
  Progress,
  Button,
  Timeline,
  Tag,
} from "antd";
import {
  BarChartOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ExperimentOutlined,
  CommentOutlined,
  BellOutlined,
  UserOutlined,
  IdcardOutlined,
  FileDoneOutlined,
  PlusOutlined,
  BranchesOutlined,
  FallOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

// chart
import { Doughnut, Pie } from "react-chartjs-2";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

const reChartLineData = [
  {
    name: "Nisan",
    yapilan: 4000,
    revize: 1400,
    amt: 2400,
  },
  {
    name: "Mayıs",
    yapilan: 3000,
    revize: 1398,
    amt: 2210,
  },
  {
    name: "Haziran",
    yapilan: 2000,
    revize: 500,
    amt: 2290,
  },
  {
    name: "Ağustos",
    yapilan: 2780,
    revize: 200,
    amt: 2000,
  },
  {
    name: "Temmuz",
    yapilan: 1890,
    revize: 100,
    amt: 2181,
  },
  {
    name: "Ağustos",
    yapilan: 2390,
    revize: 500,
    amt: 2500,
  },
  {
    name: "Eylül",
    yapilan: 3490,
    revize: 100,
    amt: 2100,
  },
];

const reChartStackedBarData = [
  {
    name: "ALIAGA",
    test: 4000,
    kayit: 2400,
    amt: 2400,
  },
  {
    name: "ANTALYA",
    test: 3000,
    kayit: 1398,
    amt: 2210,
  },
  {
    name: "İSKENDERUN",
    test: 2000,
    kayit: 9800,
    amt: 2290,
  },
  {
    name: "İSTANBUL",
    test: 2780,
    kayit: 3908,
    amt: 2000,
  },
  {
    name: "MERSIN",
    test: 1890,
    kayit: 4800,
    amt: 2181,
  },
  {
    name: "KIRIKKALE",
    test: 2390,
    kayit: 3800,
    amt: 2500,
  },
  {
    name: "MARMARA",
    test: 3490,
    kayit: 4300,
    amt: 2100,
  },
  {
    name: "TRABZON",
    test: 3490,
    kayit: 4300,
    amt: 2100,
  },
  {
    name: "IGA",
    test: 3490,
    kayit: 4300,
    amt: 2100,
  },
];

const chartDoughnutData = {
  labels: [
    "Bekleyen",
    "İşlemde",
    "Bekleyen",
    "Raporlanan",
    "Revize",
    "Saklanan",
  ],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const chartPieData = {
  labels: ["Yapılan Test", "Tekrar Test", "Revize Gelen"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    },
  ],
};

const chartBarData = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

const permDefault = ({ user, customer }) => {
  return (
    <div>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Kullanıcılar"
                value={119}
                valueStyle={{ color: "#6c0aff" }}
                // prefix={<ArrowUpOutlined />}
                // suffix="%"
              />
              <UserOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Müşteriler"
                value={500}
                valueStyle={{ color: "#6c0aff" }}
                // prefix={<ArrowDownOutlined />}
              />
              <IdcardOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Raporlar"
                value={923}
                valueStyle={{ color: "#6c0aff" }}
                // prefix={<ArrowUpOutlined />}
              />
              <FileDoneOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Testler"
                value={900}
                valueStyle={{ color: "#6c0aff" }}
              />

              <ExperimentOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>

        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Teklifler"
                value={900}
                valueStyle={{ color: "#6c0aff" }}
              />

              <BarChartOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Sözleşmeler"
                value={900}
                valueStyle={{ color: "#6c0aff" }}
              />

              <BarChartOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Gelir"
                value={900}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
              />

              <BarChartOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Gider"
                value={900}
                valueStyle={{ color: "#cf1322" }}
                prefix={<ArrowDownOutlined />}
              />

              <BarChartOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>

        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="İş Emirleri"
                value={900}
                valueStyle={{ color: "#6c0aff" }}
              />

              <BarChartOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <div className="widget-statistic-flex align-center">
              <Statistic
                title="Numuneler"
                value={900}
                valueStyle={{ color: "#6c0aff" }}
              />

              <BarChartOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="dash-col" span={12}>
          <Card>
            <h2>Numuneler</h2>
            <span class="statistic-count">1029</span>

            <div className="cardIcon">
              <PlusOutlined />
            </div>

            <div className="cardFooter">
              <Tag color="blue">Bekleyen (22)</Tag>
              <Tag color="geekblue">İşlemde (99)</Tag>
              <Tag color="purple">Raporda Bekleyen (120)</Tag>
              <Tag color="green">Raporlanan (33)</Tag>
              <Tag color="red">Revize İstenen (4)</Tag>
              <Tag color="purple">Saklanan (55)</Tag>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <Card
            title="Bildirimler"
            extra={<a href="/settings/notification">Tümünü Gör</a>}
          >
            <div className="notification-card-list">
              <div className="notification-card">
                <div className="card-icon">
                  <BellOutlined />
                </div>
                <div className="card-content">
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
              <div className="notification-card">
                <div className="card-icon">
                  <BellOutlined />
                </div>
                <div className="card-content">
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
              <div className="notification-card">
                <div className="card-icon">
                  <BellOutlined />
                </div>
                <div className="card-content">
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
              <div className="notification-card">
                <div className="card-icon">
                  <BellOutlined />
                </div>
                <div className="card-content">
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
              <div className="notification-card">
                <div className="card-icon">
                  <BellOutlined />
                </div>
                <div className="card-content">
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
            </div>
          </Card>
        </Col>

        {/* <Col className="dash-col" span={24} md={12} >
                    <Card title="Sistem Logları" extra={<a href="#">Tümünü Gör</a>}>
                        <Timeline>
                            <Timeline.Item color="green">
                                <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item color="green">
                                <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item color="red">
                                <span>Çıkış yapıldı. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item>
                                <span>Test Paketi Eklendi. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item color="gray">
                                <span>Teklif gönderildi. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item color="gray">
                                <span>Numune kaydı yapıldı. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item color="green">
                                <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item color="green">
                                <span>Giriş yapıldı. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item color="red">
                                <span>Çıkış yapıldı. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                            <Timeline.Item>
                                <span>Test Paketi Eklendi. <span className="time">20.08.2020 20:20</span></span>
                            </Timeline.Item>
                        </Timeline>
                    </Card>
                </Col> */}

        <Col className="dash-col" span={24} md={12}>
          <Card
            title="Duyurular"
            extra={<a href="/modules/announcement">Tümünü Gör</a>}
          >
            <div className="announcement-card-list">
              <div className="announcement-card">
                <div className="card-icon">
                  <NotificationOutlined />
                </div>
                <div className="card-content">
                  <h4>Lorem Heading</h4>
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
              <div className="announcement-card">
                <div className="card-icon">
                  <NotificationOutlined />
                </div>
                <div className="card-content">
                  <h4>Lorem Heading</h4>
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
              <div className="announcement-card">
                <div className="card-icon">
                  <NotificationOutlined />
                </div>
                <div className="card-content">
                  <h4>Lorem Heading</h4>
                  <span className="text">
                    Lorem Ipsum, dizgi ve <strong>baskıda</strong> kullanılan
                    mıgır metinlerdir.{" "}
                  </span>
                  <span className="time">1 saat önce</span>
                </div>
                <div className="card-action">
                  <Button type="primary">Göster</Button>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <Card title="Numuneler">
            <Doughnut data={chartDoughnutData} />
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={12}>
          <Card title="Testler">
            <Pie data={chartPieData} />
          </Card>
        </Col>

        <Col className="dash-col" span={24} md={12}>
          <Card title="Aylık Test Sayıları">
            <div style={{ width: "100%", height: 250 }}>
              <ResponsiveContainer>
                <LineChart
                  data={reChartLineData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="revize"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="yapilan" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>

        <Col span={24} md={12}>
          <Card title="Test/Kayıt Sayıları">
            <div style={{ width: "100%", height: 250 }}>
              <ResponsiveContainer>
                <BarChart
                  data={reChartStackedBarData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="test" stackId="a" fill="#8884d8" />
                  <Bar dataKey="kayit" stackId="a" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default permDefault;
