import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import { Card, Modal, Button, Tooltip, Form, Input, Space, Spin } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";

class index extends Component {
  constructor(props){
    super(props)
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      testTypeId: null,
  
      tableColumns: [
        {
          title: "Test Tipi Adı Türkçe",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Test Tipi Adı Türkçe"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
        {
          title: "Test Tipi Adı İngilizce",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            "Test Tipi Adı İngilizce"
          ),
          sorter:(a,b) => a.name_en.localeCompare(b.name_en) // string sıralama
        },
  
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.TestStatus.SelectAll(4000, 1)
      .then((result) => {
        if (result.status) {
          const { data } = result.response;
          let testtypes = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              testtypes.push({
                ...element,
                id: key,
              });
            }
          }
          this.setState({
            data: testtypes,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Test Method", JSON.stringify(error));
      });
  };
  _editClick = ({ test_status_id, name, name_en }) => {
    this.setState({ visible: true, testTypeId: test_status_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          testTypeNameTr: name,
          testTypeNameEn: name_en,
        });
      }, 100);
    });
  };
  _deleteClick = ({ test_status_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Test Tipini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(test_status_id);
      }
    });
  };
  deleted = (test_status_id) => {
    this.setState({ loading: true });
    DataService.TestStatus.Delete(test_status_id)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      testTypeId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ testTypeNameTr, testTypeNameEn }) => {
    try {
      const { testTypeId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (testTypeId) {
        result = await DataService.TestStatus.Update({
          testTypeId,
          testTypeNameTr,
          testTypeNameEn,
        });
      } else {
        result = await DataService.TestStatus.Create({
          testTypeNameTr,
          testTypeNameEn,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            testTypeId: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Tipi" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"TestType"}
                dates={["tarih"]}
              />

              <Tooltip title="Yeni Test Tipi Ekle" placement="left">
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                />
              </Tooltip>
            </Space>
          </div>

          <Modal
            title="Yeni Test Tipi Ekle"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="testTypeNameTr"
                label="Test Tipi Adı TR"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input  />
              </Form.Item>
              <Form.Item
                name="testTypeNameEn"
                label="Test Tipi Adı EN"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input  />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.testTypeId ? (
                  <>
                    {" "}
                    <PlusOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <EditOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
