import React, { useEffect, useState } from "react";

import { Card, Button, Spin, Result } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import moment from "moment";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
const ListItem = ({ title, comment, date, onClick }) => {
  return (
    <div className="announcement-card">
      <div className="card-icon">
        <NotificationOutlined />
      </div>
      <div className="card-content">
        <h4>{title}</h4>
        <span className="text">{comment}</span>
        <span className="time">{date}</span>
      </div>
      <div className="card-action">
        <Button onClick={onClick} type="primary">
          Göster
        </Button>
      </div>
    </div>
  );
};
const Announcement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    DataService.Announcement.SelectAll()
      .then((result) => {
        if (result.status) {
           setData(result.response.data);
          setLoading(false);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  }, []);
  return (
    <Spin spinning={loading}>
      <Card
        title="Duyurular"
        extra={<a href="/modules/announcement">Tümünü Gör</a>}
      >
        <div className="announcement-card-list">
          {data?.length > 0 ? (
            data.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  title={item.title}
                  comment={item.comment}
                  date={moment(item?.date_start).fromNow("hour")}
                  onClick={() => console.log("duyuru", item.ann_id)}
                />
              );
            })
          ) : (
            <Result title="Duyuru Bulunamadı" />
          )}
        </div>
      </Card>
    </Spin>
  );
};

export default Announcement;
