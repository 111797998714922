// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class Dashboard {
  constructor() {}

  Select = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.Dashboard.select);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: {},
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Genel Rapor
   */
  Report = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "get",
          Endpoint.Dashboard.generalReport
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: {},
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply.sampleTestStatusCount,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Aylık Test Grafik
   */
  TestsMonth = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.Dashboard.monthtest);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: {},
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Numune Grafik
   */
  Sample = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.Dashboard.sample);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: {},
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply.sampleStatusCount,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Test Grafik
   */
  Test = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.Dashboard.test);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: {},
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply.sampleTestStatusCount,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * TestKayıtSayıları=  Grafik
   */
  SampleTest = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.Dashboard.sampleTest);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: {},
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * İstatistikler
   */
  Statistic = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService("get", Endpoint.Dashboard.statistic);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: {},
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * İş Emirleri

   */
  WorkOrder = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Dashboard.workorder}`;
        const response = await ApiService("get", endpoints);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              data: response.reply,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Takip Edilen İş Emirleri Listesi

   * @returns 
   */
  FollowWorkOrderList = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Dashboard.followworkorderlist}`;
        const response = await ApiService("get", endpoints);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              data: response.reply,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Dashboard gidilecek url için gerekli api
   * @param {string} sampleId
   * @returns
   */
  CreateLink = (sampleId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Dashboard.createLink}/${sampleId}`;
        const response = await ApiService("get", endpoints);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: response.reply,
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Veri çekme paneli
   */
  detailReports = ({ testId, productId, customerId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.Dashboard.detailReports}/${testId}/${productId}/${customerId}`;
        const response = await ApiService("get", endpoints);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              data: response.reply,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Veri çekme paneli İstatistikli
   * @param {array} product_id - Ürünleri array olarak gönder
   * @param {array} test_id - Testleri array olarak gönder
   * @param {number} start_date - Başlangıç tarihi timestamp
   * @param {number} finish_date - Bitiş tarihi timestamp
   */
  detailReportsStatistic = ({
    product_id = 0,
    test_id = [],
    start_date = 0,
    finish_date = 0,
    customer_id = 0,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "post",
          Endpoint.Dashboard.teststatistic,
          {
            product_id,
            test_id,
            start_date,
            finish_date,
            customer_id,
          }
        );

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              data: response.reply,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Veri çekme paneli İstatistikli Customer
   * @param {array} customerId - Müşteri Id
 
   * @param {number} start_date - Başlangıç tarihi timestamp
   * @param {number} finish_date - Bitiş tarihi timestamp
   */
  detailReportsStatisticCustomer = ({
    customerId = 0,

    start_date = 0,
    finish_date = 0,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "post",
          Endpoint.Dashboard.teststatisticCustomer,
          {
            customer_id: customerId,
            start_date,
            finish_date,
          }
        );

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              data: response.reply,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Veri çekme paneli İstatistikli Product
   * @param {array} productId - Ürün Id
 
   * @param {number} start_date - Başlangıç tarihi timestamp
   * @param {number} finish_date - Bitiş tarihi timestamp
   */
  detailReportsStatisticProduct = ({
    productId = 0,

    start_date = 0,
    finish_date = 0,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ApiService(
          "post",
          Endpoint.Dashboard.teststatisticProduct,
          {
            product_id: productId,
            start_date,
            finish_date,
          }
        );

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              data: response.reply,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Personel Performans Listesi
   * @param {number} page - Ürünleri array olarak gönder
   * @param {number} limit - Testleri array olarak gönder
   * @param {number} start_date - Başlangıç tarihi timestamp
   * @param {number} finish_date - Bitiş tarihi timestamp
   */
  personelPerformanceList = ({
    page = 0,
    limit = 0,
    start_date = 0,
    finish_date = 0,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestUrl = `${Endpoint.Dashboard.personelperformancelist}/${limit}/${page}/${start_date}/${finish_date}`;

        const response = await ApiService("get", requestUrl);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply.posts,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * Personel Performans Detay
   * @param {number} page - Ürünleri array olarak gönder
   * @param {number} limit - Testleri array olarak gönder
   * @param {number} start_date - Başlangıç tarihi timestamp
   * @param {number} finish_date - Bitiş tarihi timestamp
   */
  personelPerformanceDetails = ({ sampleId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestUrl = `${Endpoint.Dashboard.personelperformancedetails}/${sampleId}`;

        const response = await ApiService("get", requestUrl);

        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}
export default new Dashboard();
