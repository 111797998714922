import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import Table from "../../../Components/UI/Table";
import PageHeader from "../../../Components/UI/PageHeader";

import {
  Modal,
  Button,
  Tooltip,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import moment from "moment";
import _ from "lodash";
const { Option } = Select;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      stockId: null,
      data: [],
      storeData: [],
      containerData: [],
      shelfData: [],
      dates: [],
      searchText: "",
      searchedColumn: "",

      tableColumns: [
        {
          title: "Kayıt No",
          dataIndex: "registry_no",
          key: "registry_no",
          sorter: (a, b) => String(a.registry_no).localeCompare(String(b.registry_no)),
          ...Helper.getColumnSearchProps(
            "registry_no",
            this.handleSearch,
            this.handleReset,
            "Kayıt No"
          ),
          render: (text, record) => text || "N/A",
        },
        {
          title: "Rapor No",
          dataIndex: "report_no",
          key: "report_no",
          sorter: (a, b) => String(a.report_no).localeCompare(String(b.report_no)),
          ...Helper.getColumnSearchProps(
            "report_no",
            this.handleSearch,
            this.handleReset,
            "Rapor No"
          ),
          render: (text, record) => text || "N/A",
        },
        {
          title: "Analiz Tipi",
          dataIndex: "workOrderTypeName",
          key: "workOrderTypeName",
          sorter: (a, b) => String(a.workOrderTypeName).localeCompare(String( b.workOrderTypeName)),
          ...Helper.getColumnSearchProps(
            "workOrderTypeName",
            this.handleSearch,
            this.handleReset,
            "Analiz Tipi"
          ),
          render: (text, record) => text || "N/A",
        },
        {
          title: "İş Kolu No",
          dataIndex: "sectorName",
          key: "sectorName",
          sorter: (a, b) => String(a.sectorName).localeCompare(String(b.sectorName)),
          ...Helper.getColumnSearchProps(
            "sectorName",
            this.handleSearch,
            this.handleReset,
            "Analiz Tipi"
          ),
          render: (text, record) => text || "N/A",
        },
        {
          title: "LAB Adı",
          dataIndex: "labName",
          key: "labName",
          sorter: (a, b) => String(a.labName).localeCompare(String(b.labName)) ,
          ...Helper.getColumnSearchProps(
            "labName",
            this.handleSearch,
            this.handleReset,
            "LAB Adı"
          ),
          render: (text, record) => text || "N/A",
        },
        {
          title: "Depo Adı",
          dataIndex: "storeName",
          key: "storeName",
          ...Helper.getColumnSearchProps(
            "storeName",
            this.handleSearch,
            this.handleReset,
            "Depo Adı"
          ),
          render: (text, record) => text || "N/A",
          sorter: (a, b) => String(a.storeName).localeCompare(String(b.storeName)), // string sıralama
        },
        {
          title: "Konteyner Adı",
          dataIndex: "containerName",
          key: "containerName",
          render: (text, record) => text || "N/A",
          sorter: (a, b) => String(a.containerName).localeCompare(String(b.containerName)), // string sıralama
        },
        {
          title: "Raf Adı",
          dataIndex: "shelfName",
          key: "shelfName",
          render: (text, record) => text || "N/A",
          sorter: (a, b) => String(a.shelfName).localeCompare(String(b.shelfName)), // string sıralama
        },
        {
          title: "Başlangıç Tarihi",
          dataIndex: "date_start",
          key: "date_start",
          sorter: (a, b) =>
            new Date(a.date_start).getTime() - new Date(b.date_start).getTime(),
        },
        {
          title: "Bitiş Tarihi",
          dataIndex: "date_finish",
          key: "date_finish",
          sorter: (a, b) =>
            new Date(a.date_finish).getTime() -
            new Date(b.date_finish).getTime(),
        },
        {
          title: "Kalan Gün",
          dataIndex: "remainingDay",
          key: "remainingDay",
          ...Helper.getColumnSearchProps(
            "remainingDay",
            this.handleSearch,
            this.handleReset,
            "Kalan Gün"
          ),
          sorter: (a, b) => String(a.remainingDay).localeCompare(String(b.remainingDay)), // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseDetails = await DataService.Sample.stockSelect();
      const responseStore = await DataService.Store.SelectAll();

      console.log("result", {
        responseDetails,
        responseStore,
      });
      if (responseDetails.status && responseStore.status) {
        this.setState({
          data: responseDetails?.response?.data || [],
          storeData: responseStore?.response?.data || [],
          loading: false,
        });
      } else {
        if (!responseDetails.status)
          Helper.ErrorMessage(responseDetails.message);

        if (!responseStore.status) Helper.ErrorMessage(responseStore.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  getContainer = (storeId, containerId = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await DataService.Store.Part(storeId, 0);
        let resultData = {
          containerData: [],
          shelfData: [],
        };
        if (result.status) {
          resultData.containerData = result?.data;
        } else {
          reject(result.message);
        }
        resolve(resultData);
      } catch (error) {
        reject(error);
      }
    });
  };
  getShelf = (storeId, containerId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await DataService.Store.Part(storeId, containerId);
        let resultData = {
          shelfData: [],
        };
        if (result.status) {
          resultData.shelfData = result?.data;
        } else {
          reject(result.message);
        }
        resolve(resultData);
      } catch (error) {
        reject(error);
      }
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      containerData: [],
      shelfData: [],
      stockId: null,
      btnloading: false,
    });
  };
  _editClick = async ({
    stock_id,
    report_no,
    store_id,
    storeName,
    storeNameEn,
    top_store_part_id,
    containerName,
    containerNameEn,
    store_part_id,
    shelfName,
    shelfNameEn,
    date_start,
    date_finish,
    remainingDay,
  }) => {
    try {
      const { containerData } = await this.getContainer(store_id);
      const { shelfData } = await this.getShelf(store_id, top_store_part_id);
      const dates = [
        {
          number: 3,
          label: "3 Ay",
          value: moment(date_start).add(3, "months").unix(),
        },
        {
          number: 4,
          label: "4 Ay",
          value: moment(date_start).add(4, "months").unix(),
        },
        {
          number: 6,
          label: "6 Ay",
          value: moment(date_start).add(6, "months").unix(),
        },
        {
          number: 12,
          label: "1 Yıl",
          value: moment(date_start).add(1, "years").unix(),
        },
        {
          number: 60,
          label: "5 Yıl",
          value: moment(date_start).add(5, "years").unix(),
        },
        {
          number: 0,
          label: "Süresiz",
          value: moment(date_start).add(1000, "years").unix(),
        },
      ];
      const start = moment(date_start).get("month");
      const end = moment(date_finish).get("month");

      const hesapla = end - start;
      let storeTime = moment(date_start).add(1000, "years").unix();
      const times = _.find(dates, { number: hesapla });
      if (times) storeTime = times.value;

      this.setState(
        { visible: true, stockId: stock_id, containerData, shelfData, dates },
        () => {
          setTimeout(() => {
            this.formRef.current.setFieldsValue({
              storeTime: storeTime,
              storeId: store_id,
              containerId: top_store_part_id,
              shelfId: store_part_id,
            });
          }, 100);
        }
      );
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
      </Space>
    );
  };
  _formFinish = async ({ storeTime, storeId, shelfId }) => {
    try {
      const { stockId } = this.state;
      this.setState({ btnloading: true });
      if (stockId) {
        const requestParams = {
          stockId,
          storeTime,
          storeId,

          shelfId,
        };

        const response = await DataService.Sample.stockUpdate(requestParams);
        this.setState({ btnloading: false });
        if (response.status) {
          Helper.SuccessMessage(response.message);
        } else {
          Helper.ErrorMessage(response.message);
        }

        this.closeModal();
      } else {
        Helper.ErrorMessage("Lütfen  düzenlenecek elemenı seçiniz.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  onChangeContainer = async (containerId) => {
    try {
      const { shelfData } = await this.getShelf(
        this.state.storeId,
        containerId
      );
      this.setState({
        containerId,
        shelfData,
      });
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  onChangeStore = async (storeId) => {
    try {
      const { containerData } = await this.getContainer(storeId);
      this.setState({
        storeId,
        containerData,
      });
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Saklama ve İzleme" />

          <Modal
            title="Düzenle"
            visible={this.state.visible}
            onCancel={this.closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="storeTime"
                label="Süre"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder=""
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.dates.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="storeId"
                label="Depo"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder=""
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(storeId) => this.onChangeStore(storeId)}
                >
                  {this.state.storeData.map((item, index) => {
                    return (
                      <Option key={index} value={item.store_id}>
                        {item.storeName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="containerId"
                label="Konteyner"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder=""
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(containerId) =>
                    this.onChangeContainer(containerId)
                  }
                >
                  {this.state.containerData.map((item, index) => {
                    return (
                      <Option key={index} value={item.store_part_id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="shelfId"
                label="Raf"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder=""
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.shelfData.map((item, index) => {
                    return (
                      <Option key={index} value={item.store_part_id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                <EditOutlined /> Düzenle
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Table
              columns={this.state.tableColumns}
              data={this.state.data}
              pagination
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
