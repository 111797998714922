import React, { Component } from "react";

import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Space,
  Spin,
  Select,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import _ from "lodash";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
const { Option } = Select;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],

      id: null,
      emails: [],

      tableColumns: [
        {
          title: "Terminal Adı - TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Terminal Adı"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Terminal Adı  - EN",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            "Terminal Adı En"
          ),
          sorter: (a, b) => a.name_en.localeCompare(b.name_en), // string sıralama
        },
        {
          title: "Kayıtlı Mail",
          dataIndex: "countGetLocationMail",
          key: "countGetLocationMail",
          render: (text,record) => Number(text) > 0 ? `${text} adet mail adresi bulunmaktadır.` : 'Kayıtlı mail adresi bulunamadı.',
          sorter: (a, b) => a.countGetLocationMail - b.countGetLocationMail,
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const response = await DataService.Sample.getLocation();
      if (response.status) {
        this.setState({
          data: response?.data || [],

          loading: false,
        });
      } else {
        this.setState({ loading: false });
        Helper.warningMessage(response.message);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log("Terminal Bilgisi", JSON.stringify(error));
    }
  };

  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _deleteClick = ({ id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} terminalini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(id);
      }
    });
  };
  deleted = (id) => {
    this.setState({ loading: true });
    DataService.Sample.terminalDelete(id)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  _editClick = ({ id, name, name_en }) => {
    DataService.Sample.getLocationOne(id)
      .then((result) => {
        if (result.status) {
          const mailFormat = result.data.mail.map((item) => item.mail);
          this.setState({ visible: true, id, emails: mailFormat }, () => {
            setTimeout(() => {
              this.formRef.current.setFieldsValue({
                name: result.data.name,
                name_en: result.data.name_en,
                email: mailFormat,
              });
            }, 100);
          });
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      id: null,
      btnloading: false,
      emails: [],
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ name, name_en, email = [] }) => {
    try {
      const { id } = this.state;
      this.setState({ btnloading: true });
      const mailFormat = email?.map((item) => {
        return { mail: item };
      });

      let result;
      if (id) {
        result = await DataService.Sample.terminalUpdate({
          name,
          name_en,
          id,
          mail: mailFormat,
        });
      } else {
        result = await DataService.Sample.terminalCreate({
          name,
          name_en,
          mail: mailFormat,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            id: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false, visible: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const { emails } = this.state;

    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Terminal" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Yeni Ekle
              </Button>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Terminal"}
                dates={["tarih"]}
              />
            </Space>
          </div>

          <Modal
            title="Terminal Düzenleme"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="name"
                label="Terminal Adı - TR"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="name_en"
                label="Terminal Adı - EN"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="email" label="E-Mail Listesi">
                <ReactMultiEmail
                  placeholder=""
                  emails={emails}
                  onChange={(_emails) => {
                    this.setState({ emails: _emails });
                  }}
                  validateEmail={(email) => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                <PlusOutlined /> Kaydet
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
