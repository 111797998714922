import React, { useEffect } from "react";
import { Col, Card, Row } from "antd";

import _ from "lodash";
import moment from "moment";
import Printer from "../print";
const Result = ({ data, formData }) => {
  if (!data) {
    return (
      <div>
        <p>
          Result Data data:{JSON.stringify(data)} formData:
          {JSON.stringify(formData)}{" "}
        </p>
      </div>
    );
  }
  return (
    <Row gutter={16}>
      {data.map((item, index) => {
        return (
          <Col span={6} style={{ marginBottom: "16px" }}>
            <Card bordered={false} className="">
              <Printer
                key={index}
                value={item.sample_stock_id}
                title="Yazdır"
                customer={formData.text.customer}
                name={formData.text.product}
                sampleLocation={formData.text.WorkOrderName}
                dateDelivery={moment(formData.text.date_delivery).format("LL")}
                stock={_.find(data, {
                  signet_code: item.signet_code,
                })}
              />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default Result;
