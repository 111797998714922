import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Select,
  Input,
  Space,
  Row,
  Col,
  Spin,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
const { Option } = Select;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      testData: [],
      testOptionsId: null,

      tableColumns: [
        {
          title: "Test Seçenek Adı TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Test Seçenek Adı TR"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Test Seçenek Adı EN",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            "Test Seçenek Adı EN"
          ),
          sorter: (a, b) => a.name_en.localeCompare(b.name_en), // string sıralama
        },
        {
          title: "Test Name TR",
          dataIndex: "testName",
          key: "testName",
          ...Helper.getColumnSearchProps(
            "testName",
            this.handleSearch,
            this.handleReset,
            "Test Name TR"
          ),
          sorter: (a, b) => a.testName.localeCompare(b.testName), // string sıralama
        },
        {
          title: "Test Name EN",
          dataIndex: "testNameEn",
          key: "testNameEn",
          ...Helper.getColumnSearchProps(
            "testNameEn",
            this.handleSearch,
            this.handleReset,
            "Test Name EN"
          ),
          sorter: (a, b) => a.testNameEn.localeCompare(b.testNameEn), // string sıralama
        },
        {
          title: "Method Adı",
          dataIndex: "method_name",
          key: "method_name",
          ...Helper.getColumnSearchProps(
            "method_name",
            this.handleSearch,
            this.handleReset,
            "Method Adı"
          ),
          sorter: (a, b) => a.method_name.localeCompare(b.method_name), // string sıralama
        },
        {
          title: "Method Adı EN",
          dataIndex: "method_name_en",
          key: "method_name_en",
          ...Helper.getColumnSearchProps(
            "method_name_en",
            this.handleSearch,
            this.handleReset,
            "Method Adı"
          ),
          sorter: (a, b) => a.method_name_en.localeCompare(b.method_name_en), // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseOptions = await DataService.TestOptions.SelectAll();
      const responseTest = await DataService.Test.SelectAll();
      if (responseOptions.status && responseTest.status) {
        const { data } = responseOptions.response;
        const testData = responseTest.response.data;
        let testoptions = [],
          tests = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            testoptions.push({
              ...element,
              id: key,
            });
          }
        }

        for (const key in testData) {
          if (testData.hasOwnProperty(key)) {
            const element = testData[key];
            if (element.number == 0) {
              tests.push({
                text: `${element.name}/${element.name_en} - (${element.method_name})`,
                value: element.test_id,
                id: key,
              });
            }
          }
        }
        this.setState({
          data: testoptions,
          testData: tests,
          loading: false,
        });
      } else {
        Helper.ErrorMessage("Bir hata meydana geldi");
      }
    } catch (error) {
      console.log("Test Options", error);
    }
  };
  _editClick = ({ test_option_id, test_id, name, name_en }) => {
    this.setState({ visible: true, testOptionsId: test_option_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          testId: test_id,
          testOptionsNameTr: name,
          testOptionsNameEn: name_en,
        });
      }, 100);
    });
  };
  _deleteClick = ({ test_option_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Test Seçeneğini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(test_option_id);
      }
    });
  };
  deleted = (testDeviceId) => {
    this.setState({ loading: true });
    DataService.TestOptions.Delete(testDeviceId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      testOptionsId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ testId, testOptionsNameTr, testOptionsNameEn }) => {
    try {
      const { testOptionsId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (testOptionsId) {
        result = await DataService.TestOptions.Update({
          testOptionsId,
          testId,
          testOptionsNameTr,
          testOptionsNameEn,
        });
      } else {
        result = await DataService.TestOptions.Create({
          testId,
          testOptionsNameTr,
          testOptionsNameEn,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            testOptionsId: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const tests = this.state.testData.map((item, index) => {
      return (
        <Option key={index} value={item.value}>
          {item.text}
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Seçenekleri" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"TestDevices"}
                dates={["tarih"]}
              />

              <Tooltip title="Yeni Test Seçeneği Ekle" placement="left">
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                />
              </Tooltip>
            </Space>
          </div>

          <Modal
            title="Yeni Test Seçeneği Ekle"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="testId"
                label="Test"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Test Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {tests}
                </Select>
              </Form.Item>
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item
                    name="testOptionsNameTr"
                    label="Test Seçeneği TR"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    name="testOptionsNameEn"
                    label="Test Seçeneği EN"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.testOptionsId ? (
                  <>
                    {" "}
                    <PlusOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <EditOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
