import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import { Card, Modal, Button, Tooltip, Form, Input, Space, Spin } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";

class index extends Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      testMethodId: null,
  
      tableColumns: [
        {
          title: "Test Metodu Adı",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Test Metodu Adı"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
        // {
        //   title: "Test Metodu Adı EN",
        //   dataIndex: "name_en",
        //   key: "name_en",
        //   ...Helper.getColumnSearchProps(
        //     "name_en",
        //     this.handleSearch,
        //     this.handleReset,
        //     "Test Metodu Adı EN"
        //   ),
        //   sorter:(a,b) => a.name_en.localeCompare(b.name_en) // string sıralama
        // },
  
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.TestMethod.SelectAll(4000, 1)
      .then((result) => {
        if (result.status) {
          const { data } = result.response;
          let testmethods = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              testmethods.push({
                ...element,
                id: key,
              });
            }
          }
          this.setState({
            data: testmethods,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Test Method", JSON.stringify(error));
      });
  };
  _editClick = ({ test_method_id, name, name_en }) => {
    this.setState({ visible: true, testMethodId: test_method_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          testMethodNameTr: name,
          testMethodNameEn: name_en,
        });
      }, 100);
    });
  };
  _deleteClick = ({ test_method_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Test Metodunu silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(test_method_id);
      }
    });
  };
  deleted = (testMethodId) => {
    this.setState({ loading: true });
    DataService.TestMethod.Delete(testMethodId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      testMethodId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ testMethodNameTr, testMethodNameEn }) => {
    try {
      const { testMethodId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (testMethodId) {
        result = await DataService.TestMethod.Update({
          testMethodId,
          testMethodNameTr,
          testMethodNameEn,
        });
      } else {
        result = await DataService.TestMethod.Create({
          testMethodNameTr,
          testMethodNameEn,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            testMethodId: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Metodu" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"TestMethod"}
                dates={["tarih"]}
              />

              <Tooltip title="Yeni Test Metodu Ekle" placement="left">
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                />
              </Tooltip>
            </Space>
          </div>

          <Modal
            title="Yeni Test Metodu Ekle"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="testMethodNameTr"
                label="Test Metodu Adı TR"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="testMethodNameEn"
                label="Test Metodu Adı EN"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.testMethodId ? (
                  <>
                    {" "}
                    <PlusOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <EditOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
