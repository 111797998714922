import React, { Component } from "react";

import Tables from "../../../../Components/Table";
import PageHeader from "../../../../Components/UI/PageHeader";
import Export from "../../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Space,
  Spin,
  Select,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import _ from "lodash";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
const { Option } = Select;
const { TextArea } = Input;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],

      reportDescId: null,

      tableColumns: [
        {
          title: "Rapor Başlık - TR",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Rapor Başlık"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Rapor Başlık  - EN",
          dataIndex: "name_en",
          key: "name_en",
          ...Helper.getColumnSearchProps(
            "name_en",
            this.handleSearch,
            this.handleReset,
            "Rapor Başlık EN"
          ),
          sorter: (a, b) => a.name_en.localeCompare(b.name_en), // string sıralama
        },
        {
          title: "Rapor Açıklama - TR",
          dataIndex: "desc",
          key: "desc",
          ...Helper.getColumnSearchProps(
            "desc",
            this.handleSearch,
            this.handleReset,
            "Rapor Açıklama"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Rapor Açıklama  - EN",
          dataIndex: "desc_en",
          key: "desc_en",
          ...Helper.getColumnSearchProps(
            "desc_en",
            this.handleSearch,
            this.handleReset,
            "Rapor Açıklama En"
          ),
          sorter: (a, b) => a.name_en.localeCompare(b.name_en), // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const response = await DataService.Sample.getReportDesc();
      if (response.status) {
        this.setState({
          data: response?.data || [],

          loading: false,
        });
      } else {
        this.setState({ loading: false });
        Helper.warningMessage(response.message);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log("Rapor Açıklama Bilgisi", JSON.stringify(error));
    }
  };

  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _deleteClick = ({ report_desc_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} başlıklı rapor açıklamasısını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(report_desc_id);
      }
    });
  };
  deleted = (reportDescId) => {
    this.setState({ loading: true });
    DataService.Sample.reportDescDelete(reportDescId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  _editClick = ({ report_desc_id, name, name_en, desc, desc_en }) => {
    this.setState({ visible: true, reportDescId: report_desc_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          reportTitle: name,
          reportTitleEn: name_en,
          reportDesc: desc,
          reportDescEn: desc_en,
        });
      }, 100);
    });
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      id: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({
    reportTitle,
    reportTitleEn,
    reportDesc,
    reportDescEn,
  }) => {
    try {
      const { reportDescId } = this.state;
      this.setState({ btnloading: true });
      let result;
      if (reportDescId) {
        result = await DataService.Sample.reportDescUpdate({
          reportDescId,
          reportTitle,
          reportTitleEn,
          reportDesc,
          reportDescEn,
        });
      } else {
        result = await DataService.Sample.reportDescCreate({
          reportTitle,
          reportTitleEn,
          reportDesc,
          reportDescEn,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            id: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false, visible: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Rapor Açıklamaları" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Yeni Ekle
              </Button>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Terminal"}
                dates={["tarih"]}
              />
            </Space>
          </div>

          <Modal
            title="Rapor Açıklama Düzenleme"
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="reportTitle"
                label="Rapor Başlık - TR"
                rules={[
                  {
                    required: true,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="reportTitleEn"
                label="Rapor Başlık - EN"
                rules={[
                  {
                    required: false,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="reportDesc"
                label="Rapor Açıklama - TR"
                rules={[
                  {
                    required: false,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Açıklama giriniz." />
              </Form.Item>
              <Form.Item
                name="reportDescEn"
                label="Rapor Açıklama - EN"
                rules={[
                  {
                    required: false,
                    message: "Lütfen bu alanı boş bırakmayın",
                    type: "string",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Açıklama giriniz." />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                <PlusOutlined /> Kaydet
              </Button>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
