import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";

import {
  Card,
  Button,
  Row,
  Col,
  Select,
  Spin,
  Checkbox,
  Tag,
  Space,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from "lodash";
import Tables from "../../../Components/Table";
const { Option } = Select;

class index extends Component {
  state = {
    visible: false,
    permData: [],
    roleTypeData: [],
    permAllData: [],
    checkedData: [],

    permId: undefined,
    selectRoleType: undefined,

    loading: true,
    btnloading: false,
    tableColumns: [
      {
        title: "Bildirim Yetkisi",
        dataIndex: "name",
        key: "name",

        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "Bildirim Tipi",
        dataIndex: "notifyName",
        key: "notifyName",

        sorter: (a, b) => a.notifyName.localeCompare(b.notifyName),
      },
      {
        title: "Bildirim Gönderilecek Yetkileri",
        dataIndex: "outPerm",
        key: "outPerm",
        render: (itemData) => {
          return (
            <>
              {itemData.map((item) => {
                return (
                  <Tag color="geekblue" key={item.permId}>
                    {item.permName}
                  </Tag>
                );
              })}
            </>
          );
        },
      },
      {
        title: "Seçenekler",
        fixed: "right",
        dataIndex: "options",
        key: "options",
        render: (text, record) => this.Actions(text, record),
      },
    ],
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const responsePerm = await DataService.User.permSelectAll();
      const responseRoleType = await DataService.Notification.getTypeRole();
      const responsePermAll = await DataService.Notification.getPermAll();

      if (
        responsePerm.status &&
        responseRoleType.status &&
        responsePermAll.status
      ) {
        this.setState({
          permData: responsePerm.response.data,
          roleTypeData: responseRoleType.data,
          loading: false,
          data: responsePermAll.data,
        });
      } else {
        if (!responsePerm.status) Helper.ErrorMessage(responsePerm.message);
        if (!responseRoleType.status)
          Helper.ErrorMessage(responseRoleType.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };

  _permSave = async () => {
    try {
      this.setState({ btnloading: true });
      const selectedPermIds = this.state.checkedData.map((item) => ({
        permId: item,
      }));

      const result = await DataService.Notification.permSaved(
        this.state.permId,
        this.state.selectRoleType,
        selectedPermIds
      );

      this.setState({ btnloading: false }, this._closeModal);

      if (result.status) {
        Helper.SuccessMessage(result.message);
        this.setState({ loading: true }, this.getData);
      } else {
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  _changePerm = async (permId) => {
    this.setState({ permId, selectRoleType: undefined, checkedData: [] });
  };
  _changeNotificationType = (notificationTypeId) => {
    this.setState(
      { selectRoleType: notificationTypeId, loading: true },
      this.getPermAll
    );
  };
  getPermAll = () => {
    DataService.Notification.getPermTypeAll(
      this.state.permId,
      this.state.selectRoleType
    )
      .then((result) => {
        if (result.status) {
          const filter = result.response.map((item) => item.permId);

          this.setState({ checkedData: filter, loading: false });
        } else {
          this.setState({ loading: false });
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        Helper.ErrorMessage(error.toString());
      });
  };
  onChange = (checkedValues) => {
    this.setState({ checkedData: checkedValues });
  };

  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
      </Space>
    );
  };
  _editClick = (record) => {
    this.setState({
      visible: true,

      permId: record.permId,
      selectRoleType: record.notifyId,
      checkedData: record.outPerm.map((item) => item.permId),
    });
  };
  _showModal = () => {
    this.setState({
      visible: true,
      checkedData: [],
      permId: undefined,
      selectRoleType: undefined,
    });
  };
  _closeModal = () => {
    // this.formRef.current.resetFields();
    this.setState({
      visible: false,
      checkedData: [],
      permId: undefined,
      selectRoleType: undefined,
      btnloading: false,
    });
  };
  renderForms = () => {
    return (
      <Card bordered={false} className="cardTable">
        <Row gutter={16}>
          <Col span={24}>
            <h3>Bildirim Yetkisini Seçin</h3>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Yetki Seçiniz"
              optionFilterProp="children"
              label="label"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(permId) => this._changePerm(permId)}
              value={this.state.permId}
            >
              {this.state.permData.map((item, index) => {
                return (
                  <Option key={index} value={item.perm_id}>
                    {item.permTypeName}
                  </Option>
                );
              })}
            </Select>
            <h3 style={{ marginTop: "16px" }}>Bildirim Tipini Seçin</h3>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Yetki Seçiniz"
              optionFilterProp="children"
              label="label"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(notificationTypeId) =>
                this._changeNotificationType(notificationTypeId)
              }
              value={this.state.selectRoleType}
              disabled={!this.state.permId}
            >
              {this.state.roleTypeData.map((item, index) => {
                return (
                  <Option key={index} value={item.notificationTypeId}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <h3 style={{ marginTop: "16px" }}>
              Bildirim Gönderilecek Yetkileri Seçin
            </h3>
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={this.state.checkedData}
              disabled={!this.state.selectRoleType}
            >
              <Row>
                {this.state.permData.map((item, index) => {
                  return (
                    <Col span={8} key={index}>
                      <Checkbox value={item.perm_id}>
                        {item.permTypeName}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
            <div className="formButton">
              <Button
                type="primary"
                onClick={this._permSave}
                loading={this.state.loading}
              >
                Kaydet
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    );
  };
  renderTable = () => {
    return (
      <Card bordered={false} className="cardTable">
        <Tables
          columns={this.state.tableColumns}
          dataSource={this.state.data}
          rowKey="permId" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
        />
      </Card>
    );
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Bildirim Tip Yönetimi" />
          <div className="pageHeaderOptions">
            <Space>
              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Yeni Rol Ekle
              </Button>
            </Space>
          </div>
        </div>

        <Spin spinning={this.state.loading}>{this.renderTable()}</Spin>
        <Modal
          title={"Bildirim Tipi Düzenle"}
          visible={this.state.visible}
          onCancel={this._closeModal}
          footer=""
        >
          {this.renderForms()}
        </Modal>
      </div>
    );
  }
}

export default index;
