import React, { Component } from "react";

import Header from "./Header";
import Footer from "./Footer";

import { Layout, Spin } from "antd";
import ProfileAvatar from "../../Assets/profile.jpg";
import DataService from "../../Provider/Service/DataService";
import Helper from "../../Helper";
import _ from "lodash";
const { Content } = Layout;

class LayoutRn extends Component {
  state = {
    userData: {},
    menuData: [],
    menuAllData: [],
    notifications: [],
    loading: true,
    spinner: true,
  };
  componentDidMount() {
    const token = Helper.getlocalStorage("Token");
    if (token) {
      this.getData();
    } else {
      window.location.href = "/login";
    }
  }
  getData = async () => {
    try {
      const responseMenu = await DataService.Menu.SelectAll();
      const responseUser = await DataService.User.Profile();
      const responseNotification = await DataService.Notification.SelectAll();

      if (
        responseMenu.status &&
        responseUser.status &&
        responseNotification.status
      ) {
        const userData = {
          fullName: `${responseUser.data.name}  ${responseUser.data.lastname}`,
          photo: responseUser?.data?.photo ? `https://apilims.intertekturkey.com/getImage/${responseUser.data.photo}` : ProfileAvatar,
        };
        let menu = await this.getMenu(responseMenu);

        this.setState({
          userData,
          menuData: menu.menuData,
          menuAllData: menu.menuAllData,
          notifications: responseNotification.response.data,
          loading: false,
          spinner: false,
        });
      } else {
        window.location.href = "/login";
      }
    } catch (error) {
      window.location.href = "/login";
    }
  };
  getMenu = (responseMenu) => {
    return new Promise(async (resolve, reject) => {
      try {
        const topMenu = _.filter(responseMenu.data, { topMenu: "0" });
        let menuData = [];

        for (const key in topMenu) {
          if (topMenu.hasOwnProperty(key)) {
            const element = topMenu[key];

            let subMenu = _.filter(responseMenu.data, {
              topMenu: element.menuId,
            });
            menuData.push({
              ...element,
              subMenu: subMenu,
            });
          }
        }

        resolve({ menuData, menuAllData: responseMenu.data });
      } catch (error) {
        reject(error);
      }
    });
  };
  render() {
    if (this.state.loading) {
      return <div />;
    }
    return (
      <Layout>
        <Spin spinning={this.state.spinner}>
          <Header
            user={this.state.userData}
            menu={this.state.menuData}
            menuData={this.state.menuAllData}
            notifications={this.state.notifications}
            updateNotification={this.getData}
            location={this.props.location}
          />

          <Content className="layout-container">
            <div className="layout-content">{this.props.children}</div>
          </Content>

          <Footer />
        </Spin>
      </Layout>
    );
  }
}

export default LayoutRn;
