// Servis Çağrıları
import { ApiService } from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";
class InventoryCategory {
  constructor() {}

  /**
   * InventoryCategory Ekleme
   * @param {string} InventoryCategoryTopCategoryId
   * @param {string} InventoryCategoryNameTr
   * @param {string} InventoryCategoryNameEn
   * @param {number} InventoryCategoryLabDevice
   */
  Create = ({
    InventoryCategoryTopCategoryId,
    InventoryCategoryNameTr,
    InventoryCategoryNameEn,
    InventoryCategoryLabDevice,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          top_cat_id: InventoryCategoryTopCategoryId,

          name: InventoryCategoryNameTr,
          name_en: InventoryCategoryNameEn,
          lab_device: InventoryCategoryLabDevice,
        };
        const response = await ApiService(
          "post",
          Endpoint.InventoryCategory.create,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * InventoryCategory Düzenleme
   * @param {string} InventoryCategoryId
   * @param {string} InventoryCategoryTopCategoryId
   * @param {string} InventoryCategoryNameTr
   * @param {string} InventoryCategoryNameEn
   * @param {number} InventoryCategoryLabDevice
   */
  Update = ({
    InventoryCategoryId,
    InventoryCategoryNameTr,
    InventoryCategoryNameEn,
    InventoryCategoryLabDevice,
    InventoryCategoryTopCategoryId,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          cat_id: InventoryCategoryId,
          top_cat_id: InventoryCategoryTopCategoryId,

          name: InventoryCategoryNameTr,
          name_en: InventoryCategoryNameEn,
          lab_device: InventoryCategoryLabDevice,
        };
        const response = await ApiService(
          "put",
          Endpoint.InventoryCategory.update,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * InventoryCategory Silme İşlemi
   * @param {string} InventoryCategoryId
   */
  Delete = (InventoryCategoryId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          cat_id: InventoryCategoryId,
        };
        const response = await ApiService(
          "delete",
          Endpoint.InventoryCategory.delete,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {}
    });
  };
  /**
   * InventoryCategory Listeleme
   * @param {number} limit
   * @param {number} page
   */
  SelectAll = (limit = 0, page = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoints = `${Endpoint.InventoryCategory.getAll}/${limit}/${page}`;

        const response = await ApiService("get", endpoints);
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          response: {
            details: {},
            data: [],
          },
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            response: {
              details: response.reply.pagination,
              data: response.reply.posts,
            },
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * InventoryCategory Detayı Alma
   * @param {string} InventoryCategoryId
   */
  Select = (InventoryCategoryId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          cat_id: InventoryCategoryId,
        };
        const response = await ApiService(
          "get",
          Endpoint.InventoryCategory.getOne,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
          data: [],
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
            data: response.reply,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
  /**
   * InventoryCategory Aktif/Pasif Durumu Değiştirme
   * @param {string} InventoryCategoryId
   */
  StatusChange = (InventoryCategoryId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = {
          cat_id: InventoryCategoryId,
        };

        const response = await ApiService(
          "post",
          Endpoint.InventoryCategory.status,
          requestParams
        );
        let result = {
          status: false,
          message: "Bir hata meydana geldi.",
        };
        if (response.status) {
          result = {
            status: true,
            message: response.message,
          };
        } else {
          result.message = response.message;
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default new InventoryCategory();
