import React, { Component } from "react";

import { NavLink, Redirect } from "react-router-dom";

import PageHeader from "../../../../Components/UI/PageHeader";
import PackList from "../PackList";

import {
  QuestionCircleOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CrownOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";

import {
  Form,
  Button,
  Card,
  Space,
  Row,
  Col,
  Select,
  Divider,
  Input,
  Spin,
  Checkbox,
} from "antd";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
import _ from "lodash";
const { Option, OptGroup } = Select;
const { TextArea } = Input;
class index extends Component {
  state = {
    data: [],
    filteredData: [],
    testData: [],
    inputData: [],
    products: [],
    sectors: [],
    productId: null,
    sectorId: null,
    testPackNameTr: "",
    testPackNameEn: "",
    testPackId: null,
    redirect: false,
    page: "/",
    loading: true,
    tankbytank: false,
  };
  formRef = React.createRef();
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const {
        match: { params },
      } = this.props;
      const details = await DataService.TestPack.Select(params.testpackid);

      const tests = await DataService.Test.SelectAll();
      const resultProduct = await DataService.Product.SelectAll();
      const resultSector = await DataService.Sector.SelectAll();
      if (
        tests.status &&
        details.status &&
        resultProduct.status &&
        resultSector.status
      ) {
        const testsData = tests.response.data;
        const { test_pack, test_pack_list } = details.data;

        const {
          pack_id,
          name,
          name_en,
          product_id,
          content,
          content_en,
          sector_id,
          tankbytank,
        } = test_pack;
        let filteredData = [],
          inputData = [],
          testinput = [];
        for (const key in test_pack_list) {
          if (test_pack_list.hasOwnProperty(key)) {
            const element = test_pack_list[key];

            const find = _.filter(testsData, { test_id: element.test_id });

            testinput.push(element.test_id);
            if (find.length == 1) {
              filteredData.push({
                ...element,
                name: find[0].name,
                name_en: find[0].name_en,
                method_name: find[0].method_name,
                sector_name: find[0].sector_name,
                unit_name: find[0].unit_name,
              });
              inputData.push(element.test_id);
            }
          }
        }
        const sectorData = [
          {
            sector_id: 0,
            name: "Tümü",
          },
        ];
     
        this.setState(
          {
            testPackId: pack_id,
            testPackNameTr: name,
            testPackNameEn: name_en,
            productId: product_id || null,
            sectorId: sector_id || null,
            tankbytank: tankbytank === 1 ? true : false,
            data: testsData,
            testData: testsData,
            filteredData: filteredData.sort(
              (a, b) => Number(a.line) - Number(b.line)
            ),
            inputData,
            loading: false,
            products: resultProduct.response.data,
            sectors: sectorData.concat(resultSector.response.data),
          },
          () => {
            this.formRef.current.setFieldsValue({
              name_tr: name,
              name_en: name_en,
              test_list: testinput,
              product: product_id || undefined,
              description: content || "",
              description_en: content_en || "",
              sector: sector_id,
            });
          }
        );
      } else {
        !tests.status && Helper.ErrorMessage(tests.message);
        !resultProduct.status && Helper.ErrorMessage(resultProduct.message);
        !resultSector.status && Helper.ErrorMessage(resultSector.message);
      }
    } catch (error) {}
  };

  _findTest = (testId) => {
    return new Promise(async (resolve, reject) => {
      try {
        let test = false;
        const find = _.find(this.state.filteredData, { test_id: testId });
        if (find) {
          test = find;
        } else {
          const response = await DataService.Test.Select(testId);
          if (response.status) {
            test = response.data;
          }
        }

        resolve(test);
      } catch (error) {
        reject(error);
      }
    });
  };
  _onValueChange = async (data) => {
    try {
      this.setState({ loading: true });
      let filteredData = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const element = data[key];

          const find = await this._findTest(element);

          if (find) {
            const {
              name,
              test_id,
              name_en,
              method_name,
              sector_name,
              unit_name,
              number_characters,
              number,
              ref_max,
              ref_max_operator,
              ref_min,
              ref_min_operator,
              testOption,
            } = find;
            filteredData.push({
              line: key,
              name,
              test_id,
              name_en,
              method_name,
              unit_name,
              sector_name,
              number_characters,
              number,
              ref_max,
              ref_max_operator,
              ref_min,
              ref_min_operator,
              testOption,
            });
          }
        }
      }

      this.setState({
        filteredData,
        testData: this.state.data,
        loading: false,
      });
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  _onFinish = () => {
    const { sectorId, tankbytank } = this.state;
    const formdata = this.formRef.current.getFieldsValue();
    const { name_en, name_tr, product, description, description_en } = formdata;
    const testPackList = this.refPaclist?.getData() || [];

    const params = {
      testPackId: this.state.testPackId,
      testPackList: testPackList,
      testPackNameTr: name_tr,
      testPackNameEn: name_en,
      testPackCount: testPackList.length,
      testProductId: product,
      testDescription: description,
      testDescriptionEN: description_en,
      sectorId,
      tankbytank,
    };

    DataService.TestPack.Update(params)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(
            "İşleminiz başarılı bir şekilde gerçekleştirildi."
          );
          this.setState({
            loading: false,
            visible: false,
            page: "/definitions/test-pack",
            redirect: true,
          });
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  searchTests = (search) => {
    if (search.length > 0) {
      const testData = _.filter(this.state.data, (item) => {
        const searchItem = `${item.name} / ${item.name_en} (${item.method_name})`;

        return searchItem.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      });

      this.setState({
        testData,
      });
    } else {
      const { data } = this.state;
      this.setState({
        testData: data,
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.page} />;
    }
    const testData = this.state?.sectorId
      ? _.filter(this.state.testData, { sector_id: this.state.sectorId })
      : this.state.testData;
    const group = _.groupBy(
      _.orderBy(testData, ["sector_name"], ["asc"]),
      "sector_id"
    );

    const sortabletest = _.orderBy(group, ["sector_name"], ["asc"]);
    const tests = sortabletest.map((item, index) => {
      return (
        <OptGroup
          label={
            <text style={{ color: "red", fontWeight: "bold", fontSize: 20 }}>
              {item[index]?.sector_name || item.sector_name}
            </text>
          }
          key={index}
        >
          {item.map((testItem, testIndex) => {
            return (
              <Option key={testIndex} value={testItem.test_id}>
                {testItem.name} / {testItem.name_en}{" "}
                {testItem?.unit_name || "-"} ({testItem?.method_name || "-"})
              </Option>
            );
          })}
        </OptGroup>
      );
    });
    const products = this.state.products.map((item, index) => {
      return (
        <Option key={index} value={item.product_id}>
          {item.name}
        </Option>
      );
    });
    const sectors = this.state.sectors.map((item, index) => {
      return (
        <Option key={index} value={item.sector_id}>
          {item.name}
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Test Paketi Düzenle" />

          <div className="pageHeaderOptions">
            <Space>
              {/* <Button type="primary" primary icon={<PlusOutlined />} onClick={this.showInfoModal}>
                                Bilgi
                            </Button> */}

              <NavLink
                activeClassName="nav-active"
                exact
                to="/definitions/test-pack"
              >
                <Button type="primary" shape="round" className="btn-add">
                  Test Paketleri
                </Button>
              </NavLink>
            </Space>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Row gutter={16}>
              <Col span={24}>
                <Form layout="vertical" ref={this.formRef}>
                  <Row gutter="16">
                    <Col span={5}>
                      <Form.Item name="name_tr" label="Paket Adı TR">
                        <Input
                          onChange={(e) =>
                            this.setState({ testPackNameTr: e.target.value })
                          }
                          value={this.state.testPackNameTr}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="name_en" label="Paket Adı EN">
                        <Input
                          onChange={(e) =>
                            this.setState({ testPackNameEn: e.target.value })
                          }
                          value={this.state.testPackNameEn}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="sector" label="İş Kolu">
                        <Select
                          showSearch
                          placeholder="Seçiniz"
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(sectorId) => this.setState({ sectorId })}
                        >
                          {sectors}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="product" label="Ürün">
                        <Select
                          showSearch
                          placeholder="Seçiniz"
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(productId) => this.setState({ productId })}
                        >
                          {products}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/*
                      <Col
                          span={4}
                          style={{display: "flex", alignItems: "center"}}
                      >
                        <Checkbox
                            checked={this.state.tankbytank}
                            onClick={() =>
                                this.setState({tankbytank: !this.state.tankbytank})
                            }
                        >
                          Tankbytank
                        </Checkbox>
                      </Col>
                    */}
                  </Row>
                  <Form.Item name="test_list" label="Testler">
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Test Bul"
                      onChange={this._onValueChange}
                      filterOption={false}
                      onSearch={this.searchTests}
                    >
                      {tests}
                    </Select>
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="description" label="Açıklama TR">
                        <TextArea />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item name="description_en" label="Açıklama EN">
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <PackList
              ref={(refs) => (this.refPaclist = refs)}
              data={this.state.filteredData}
            />
            <div className="formButton">
              <Button
                type="primary"
                onClick={this._onFinish}
                style={{ marginTop: 15 }}
              >
                <EditOutlined />
                Kaydet
              </Button>
            </div>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
