import React, { useState, useEffect } from "react";

import { Card, Button, Space, Spin, Result, Input } from "antd";
import axios from "axios";
import * as Endpoint from "../../../Provider/Config/EndpointUrl";
import { baseAPIUrl } from "../../../Provider/Config/Config";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
const { Search } = Input;
const barcodeSrc = require("../../../Assets/barcode.png");
const Samples = () => {
  const [loading, setLoading] = useState(true);

  const [barcodeValue, setBarcodeValue] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    if (barcodeValue?.length == 32) {
      setLoading(true);
      getLink(barcodeValue);
    }
    return () => {
      setBarcodeValue(null);
    };
  }, [barcodeValue]);
  const barcodeEnterPress = (value, event) => {
    if (barcodeValue?.length == 32) {
      setLoading(true);
      getLink(barcodeValue);
    } else {
      Helper.ErrorMessage("Barkod numarası hatalı girildi.");
    }
  };
  const getLink = (barcode) => {
    DataService.Dashboard.CreateLink(barcode)
      .then((result) => {
        setLoading(false);
        if (result.status) {
          if (result.data.type == 0) {
            window.location.href = result.data.url;
          } else {
            _showReport(result.data.url);
          }
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        Helper.ErrorMessage(error.toString());
      });
  };
  const _showReport = (fileName) => {
    axios.defaults.headers.common["X-API-KEY"] =
      "e3784eb2-b842-4a31-a204-14bc90bc5856";
    axios.defaults.headers.common["Authorization"] = Helper.getlocalStorage(
      "Token"
    );
    axios({
      url: `${baseAPIUrl}${Endpoint.Sample.getPdf}/${fileName}`, //your url
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  };
  return (
    <Spin spinning={loading}>
      <Card bordered={false} className="cardTable" title="Qr Kod">
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={barcodeSrc} height={250} width={250} />
        </div>
        <Search
          placeholder="barkod numarasını giriniz."
          onChange={(e) => setBarcodeValue(e.target.value)}
          enterButton="Bul"
          size="large"
          loading={loading}
          maxLength={32}
          onSearch={barcodeEnterPress}
          style={{
            marginTop: 25,
            marginBottom: 25,
          }}
          autoFocus={true}
        />
      </Card>
    </Spin>
  );
};

export default Samples;
