import React, { Component } from 'react';
import "./pageHeader.css";

import { PageHeader } from 'antd';

const routes = [
    {
      path: 'index',
      breadcrumbName: 'First-level Menu',
    },
    {
      path: 'first',
      breadcrumbName: 'Second-level Menu',
    },
    {
      path: 'second',
      breadcrumbName: 'Third-level Menu',
    },
];

class index extends Component {

    render() {
        return (
            <PageHeader
                className="pageHeader"
                // onBack={() => null}
                // breadcrumb={{ routes }}
                title={this.props.title}
                subTitle={this.props.subTitle}
            />
        );
    }
}

export default index;