import React, { Component } from 'react';
import "./footer.css";

import { Layout } from 'antd';
const { Footer } = Layout;

class FooterRn extends Component {
    render() {
        return (
            <Footer style={{ textAlign: 'center' }}>Lims ©2020 Created by <a href="http://sharkyazilim.com" target="_blank">Shark Yazılım</a></Footer>
        );
    }
}

export default FooterRn;