import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import { Card, Button, DatePicker, Space, Row, Col, Spin } from "antd";
import moment from "moment";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from "lodash";
import "moment/locale/tr";
const dateFormat = "MM/YYYY";

class index extends Component {
  constructor(props){
    super(props);
    this.  state = {
      data: [],
      loading: true,
      date: moment(),
      searchText:'',
      searchedColumn:'',
      tableColumns: [
        {
          title: "Kullanıcı",
          dataIndex: "user",
          key: "user",
          ...Helper.getColumnSearchProps(
            "user",
            this.handleSearch,
            this.handleReset,
            "Kullanıcı"
          ),
          sorter:(a,b) => a.user.localeCompare(b.user) // string sıralama
        },
  
        {
          title: "Method",
          dataIndex: "method",
          key: "method",
        
        },
        {
          title: "Açıklama",
          dataIndex: "message",
          key: "message",
          ...Helper.getColumnSearchProps(
            "message",
            this.handleSearch,
            this.handleReset,
            "Açıklama"
          ),
        },
  
        {
          title: "Ip Adresi",
          dataIndex: "ipadress",
          key: "ipadress",
          sorter:(a,b) => a.ipadress.localeCompare(b.ipadress) // string sıralama
        },
  
        {
          title: "Tarih",
          dataIndex: "datetime",
          key: "datetime",
        
        },
      ],
    };
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    moment.locale("tr");
    this.getData();
  }
  getData = async () => {
    try {
      const { date } = this.state;
      moment.locale("tr");
      this.setState({ loading: true });
      const response = await DataService.Logs.Select(date.format("MM-YYYY"));

      if (response.status) {
        const { data } = response;

        let logsData = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];

            logsData.push({
              id: key,
              user: element.context.user,
              method: element.context.method,
              message: element.message,
              ipadress: element.context.ip,
              datetime: moment(element.datetime).format("lll"),
            });
          }
        }
        this.setState({
          data: logsData,
          loading: false,
        });
      } else {
        Helper.ErrorMessage(response.message);
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      console.log("Logs", error);
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Hata Yönetimi(Logs)" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Logs"}
                dates={["tarih"]}
              />
            </Space>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <div className="tableFilter" style={{ marginBottom: "15px" }}>
              <Row gutter={16}>
                <Col>
                  <DatePicker
                    defaultValue={moment(this.state.date, dateFormat)}
                    onChange={(date) => this.setState({ date })}
                    format={dateFormat}
                    picker="month"
                  />
                </Col>
                <Col>
                  <Button onClick={this.getData}>Filtrele</Button>
                </Col>
              </Row>
            </div>

            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
