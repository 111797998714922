import React from "react";
import { Row, Col } from "antd";
import {
  SamplesGraphic,
  Tests,
  Samples,
  Announcement,
  Statistic,
  QrComponent,
} from "../Components";
const permLabManager = ({}) => {
  return (
    <div>
      <Statistic />
  
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <SamplesGraphic />
        </Col>
        <Col className="dash-col" span={24} md={12}>
          <Tests />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <Samples />
        </Col>

        <Col className="dash-col" span={24} md={12}>
        <QrComponent />
        <Announcement />
        </Col>
      </Row>
  
  
    </div>
  );
};

export default permLabManager;
