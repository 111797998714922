import React, { Component } from "react";

import { Card, Modal, Button, Tooltip, Form, Input, Select, Space,Spin, Row, Col } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import _ from "lodash";
import Table from "../../../Components/UI/Table";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
const Cities = require("../../../Static/city.json");
const District = require("../../../Static/district.json");
const { Option } = Select;



export default class index extends Component {
  constructor(props){
    super(props)
    this.state = {
      visible: false,
      loading:true,
      btnloading:false,
      data: [],
      districtData: [],
      regionId: null,
      tableColumns: [
        {
          title: "Bölge Adı",
          dataIndex: "name",
          key: "name",
          responsive: ["md"],
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Bölge Adı"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
        {
          title: "Şehir",
          dataIndex: "city",
          key: "city",
          responsive: ["lg"],
          sorter:(a,b) => a.city.localeCompare(b.city) // string sıralama
        },
        {
          title: "İlçe",
          dataIndex: "district",
          key: "district",
          responsive: ["lg"],
          sorter:(a,b) => a.district.localeCompare(b.district) // string sıralama
        },
        {
          
            title: "Seçenekler",
            fixed: 'right',
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record)
        }
      ]
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseLaboratory = await DataService.Region.SelectAll(4000, 1);

      if (responseLaboratory.status) {
        const { data } = responseLaboratory.response;

        let region = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            const city = _.filter(Cities, {
              value: element.city_id.toString()
            })[0].text;
            const district = _.filter(District, {
              value: element.district_id.toString()
            });

            region.push({
              ...element,
              id: key,
              city,
              district: district.length != 0 ? district[0].text : "İlçe Seçiniz"
            });
          }
        }

        this.setState({
          data: region,
          loading:false
        });
      }
    } catch (error) {
      console.log("Region", error);
    }
  };
  _editClick = ({ region_id, name, name_en, city_id, district_id }) => {
    const districtData = _.filter(District, { city_value: city_id.toString() });

    this.setState({ visible: true, regionId: region_id, districtData }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          regionNameTr: name,
          regionNameEn: name_en,
          regionCity: city_id.toString(),
          regionDistrict: district_id.toString()
        });
      }, 500);
    });
  };
  _deleteClick = ({ region_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Laboratuvarını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır"
    }).then(result => {
      if (result.value) {
        this.deleted(region_id);
      }
    });
  };
  deleted = regionId => {
    this.setState({loading:true})
    DataService.Region.Delete(regionId)
      .then(result => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch(error => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button type="link" icon={<DeleteOutlined />} danger onClick={() => this._deleteClick(record)}>
        </Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true
    });
  };
  _closeModal = e => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      regionId: null,
      btnloading:false
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({
    regionNameTr,
    regionNameEn,
    regionCity,
    regionDistrict
  }) => {
    try {
      const { regionId } = this.state;
      this.setState({btnloading:true})
      let result;
      if (regionId) {
        result = await DataService.Region.Update({
          regionNameTr,
          regionNameEn,
          regionCity,
          regionDistrict,
          regionId
        });
      } else {
        result = await DataService.Region.Create({
          regionNameTr,
          regionNameEn,
          regionCity,
          regionDistrict
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, regionId: null,btnloading:false },
          this.getData
        );
      } else {
        this.setState({btnloading:false})
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  _selectCities = city_value => {
    const districtData = _.filter(District, { city_value });

    this.setState({ districtData });
  };
  render() {
    const cities = Cities.sort(function (a, b) {
      return a.value - b.value;
    }).map((item, index) => (
      <Option key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    const districts = this.state.districtData.map((item, index) => (
      <Option key={index} value={item.value}>
        {item.text}
      </Option>
    ));
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Bölgeler" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Sector"}
                dates={["tarih"]}
              />

              
                <Button
                  shape="round"
                  className="btn-add"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                >Yeni Ekle</Button>
              
            </Space>
          </div>

          <Modal
            title={this.state.regionId ? (
              "Bölge Düzenle"
            ) : (
              "Bölge Ekle"
            )}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >

              <Row gutter={16}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="regionNameTr"
                    label="Bölge Adı TR"
                    rules={[
                      { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="regionNameEn"
                    label="Bölge Adı EN"
                    rules={[
                      { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="regionCity"
                label="Şehir"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                ]}
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={this._selectCities}
                >
                  {cities}
                </Select>
              </Form.Item>
              <Form.Item
                name="regionDistrict"
                label="İlçe"
                rules={[{ message: "Opsiyonel" }]}
              >
                <Select showSearch placeholder="Seçiniz">
                  {districts}
                </Select>
              </Form.Item>

              <div className="formButton">
              <Button type="primary" htmlType="submit" loading={this.state.btnloading} >
                {this.state.regionId ? (
                  <>
                    {" "}
                    <EditOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <PlusOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
              </div>
            </Form>
          </Modal>
        </div>

       <Spin spinning={this.state.loading} >
       <Card bordered={false} className="cardTable">
          <Tables
            columns={this.state.tableColumns}
            onChange={this._tableHandleChange}
            dataSource={this.state.data}
            pagination={{
              defaultPageSize: 1
            }}
            onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
          />
        </Card>
       </Spin>
      </div>
    );
  }
}
