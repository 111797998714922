import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import { Card, Modal, Button, Tooltip, Form, Input, Select, Space,Spin } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";

const { Option } = Select;


export default class index extends Component {
  constructor(props){
    super(props)
    this.state = {
      visible: false,
      loading:true,
      btnloading:false,
      data: [],
      operationId: null,
  
      tableColumns: [
        {
          title: "Operasyon Adı",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Kateogri Adı"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
        {
          title: "Analiz Tipi",
          dataIndex: "type",
          key: "type",
          ...Helper.getColumnSearchProps(
            "type",
            this.handleSearch,
            this.handleReset,
            "Analiz Tipi"
          ),
          render:(text,record) => text == 1 ? "Analiz" : "Gözetim", 
          sorter:(a,b) => a.type.localeCompare(b.type) // string sıralama
        },
        {
          
            title: "Seçenekler",
            fixed: 'right',
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record)
        }
      ]
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.Operation.SelectAll(4000, 1)
      .then(result => {
        if (result.status) {
          const { data } = result.response;
          let operations = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              operations.push({
                ...element,
                id: key
              });
            }
          }
          this.setState({
            data: operations,
            loading:false
          });
        }
      })
      .catch(error => {
        console.log("Operations", error);
      });
  };
  _editClick = ({ operation_id, name,type }) => {
    this.setState({ visible: true, operationId: operation_id, }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({ operationName: name,type });
      }, 100);
    });
  };
  _deleteClick = ({ operation_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} firmasını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır"
    }).then(result => {
      if (result.value) {
        this.deleted(operation_id);
      }
    });
  };
  deleted = operationId => {
    this.setState({loading:true})
    DataService.Operation.Delete(operationId)
      .then(result => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch(error => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button type="link" icon={<DeleteOutlined />} danger onClick={() => this._deleteClick(record)}>
        </Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true
    });
  };
  _closeModal = e => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      operationId: null,
      btnloading:false
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ operationName,type }) => {
    try {
      const { operationId } = this.state;
      this.setState({btnloading:true})
      let result;
      if (operationId) {
        result = await DataService.Operation.Update({
          operationName,
          operationId,
          type
        });
      } else {
        result = await DataService.Operation.Create({
          operationName,
          type
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, operationId: null,btnloading:false },
          this.getData
        );
      } else {
        this.setState({loading:false})
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Operasyon" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Sector"}
                dates={["tarih"]}
              />

                <Button
                  shape="round"
                  className="btn-add"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                >Operasyon Ekle</Button>
              
            </Space>
          </div>

          <Modal
            title={this.state.operationId ? (
              "Operasyon Kaydet"
            ) : (
              "Operasyon Ekle"
            )}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="operationName"
                label="Operasyon Adı"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                ]}
              >
                <Input placeholder="Operasyon Adı" />
              </Form.Item>
              <Form.Item
                name="type"
                label="Analiz Tipi"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" }
                ]}
            
              >
                <Select>
                  <Option value={1} >Analiz</Option>
                  <Option value={2} >Gözetim</Option>
                </Select>
              </Form.Item>
              <div className="formButton">
              <Button type="primary" htmlType="submit" loading={this.state.btnloading} >
                {this.state.operationId ? (
                  <>
                    {" "}
                    <EditOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <PlusOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
              </div>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading} >  
        <Card bordered={false} className="cardTable">
          <Tables
            columns={this.state.tableColumns}
            onChange={this._tableHandleChange}
            dataSource={this.state.data}
            pagination={{
              defaultPageSize: 1
            }}
            onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
          />
        </Card>
        </Spin>
      </div>
    );
  }
}
