import React, { Component } from 'react';
import "../index.css"

import { NavLink } from "react-router-dom"

import PageHeader from '../../../Components/UI/PageHeader';

import { Modal, Button, Tooltip, Card, Form, Input, Select, Space, Row, Col, Checkbox } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;


const test_list = ['Test Adı', 'Test Adı 2', 'Test Adı 3', 'Test Adı 4', 'Test Adı 5', 'Test Adı 6', 'Test Adı 7', 'Test Adı 8', 'Test Adı 9', 'Test Adı 10', 'Test Adı 11'];


class offerEdit extends Component {
    state = {
        visible: false,
        pack_selected_item: [],
        pack_create_selected_item: [],
    };

    pack_change_event = pack_selected_item => {
        this.setState({ pack_selected_item });

        // alert(pack_selected_item)
    };
    pack_create_change_event = pack_create_selected_item => {
        this.setState({ pack_create_selected_item });

        // alert(pack_create_selected_item)
    };


    showInfoModal = () => {
        this.setState({
        infoVisible: true,
        });
    };
    showModal = () => {
        this.setState({
        visible: true,
        });
    };
    closeModal = e => {
        this.setState({
            visible: false,
            infoVisible: false,
        });
    };

    render() {

        const { pack_create_selected_item } = this.state;
        const filteredOptions = test_list.filter(o => !pack_create_selected_item.includes(o));

        return (
            <div>

                <div className="pageHeaderWrapper">
                    <PageHeader title="Teklif Oluştur" />

                    <div className="pageHeaderOptions">
                        <Space>
                            {/* <Button type="primary" primary icon={<PlusOutlined />} onClick={this.showInfoModal}>
                                Bilgi
                            </Button> */}

                            <Modal
                                title="Bilgi"
                                visible={this.state.infoVisible}
                                onCancel={this.closeModal}
                                footer=""
                                >
                                Bilgi modalı
                            </Modal>
                            

                            <NavLink activeClassName="nav-active" exact to="/offers">
                                <Button type="primary" primary>
                                    Teklif Listesi
                                </Button>
                            </NavLink>
                        </Space>
                    </div>
                </div>


                <Card bordered={false} className="cardTable">
                    <Form layout="vertical" hideRequiredMark>
                        <Row gutter={24}>
                            <Col md={14}>
                                <Form.Item
                                    name="customer"
                                    label="Müşteri"
                                    extra="Müşteri listenizde yok ise Müşteri Ekle butonu ile ekleyebilirsiniz"
                                    rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                    >
                                    <Row gutter={24}>
                                        <Col md={18}>
                                            <Select
                                                showSearch
                                                placeholder="Müşteri Seçiniz"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="customer_1">Müşteri 1</Option>
                                                <Option value="customer_2">Müşteri 2</Option>
                                                <Option value="customer_3">Müşteri 3</Option>
                                            </Select>
                                        </Col>
                                        <Col md={6}>
                                            <Button type="primary" primary onClick={this.showModal} style={{width: '100%'}}>
                                                <PlusOutlined /> Müşteri Ekle
                                            </Button>

                                            <Modal
                                                title="Yeni Müşteri Ekle"
                                                visible={this.state.visible}
                                                onCancel={this.closeModal}
                                                footer=""
                                                >

                                                <Form layout="vertical" hideRequiredMark>
                                                    <Form.Item
                                                        name="name"
                                                        label="Müşteri Adı"
                                                        rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                                        >
                                                        <Input placeholder="Müşteri Adı" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="email"
                                                        label="E-Posta"
                                                        rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                                        >
                                                        <Input placeholder="E-Posta" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Telefon"
                                                        rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                                        >
                                                        <Input placeholder="Telefon" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="manager"
                                                        label="Yönetici"
                                                        rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                                        >
                                                            <Input placeholder="Yönetici Ad Soyad" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="city"
                                                        label="Şehir"
                                                        rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                                        >
                                                        <Select
                                                            showSearch
                                                            placeholder="Şehir Seçiniz"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option value="istanbul">İstanbul</Option>
                                                            <Option value="ankara">Ankara</Option>
                                                            <Option value="diger">Diğer Şehirler</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="address"
                                                        label="Adres"
                                                        rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                                        >
                                                        <Input.TextArea placeholder="Adres" />
                                                    </Form.Item>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        >
                                                        <PlusOutlined /> Ekle
                                                    </Button>
                                                </Form>

                                            </Modal>

                                        </Col>
                                    </Row>
                                </Form.Item>
                                        
                                <Row gutter={24}>
                                    <Col md={12}>
                                        <Form.Item
                                            name="test_pack"
                                            label="Test Paketi Seç"
                                            rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                            >
                                            <Select
                                                showSearch
                                                placeholder="Paket Seçiniz"
                                                onChange={this.pack_create_change_event}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="test_pack_1">Paket 1</Option>
                                                <Option value="test_pack_2">Paket 2</Option>
                                                <Option value="test_pack_3">Paket 3</Option>
                                            </Select>
                                        </Form.Item>


                                        <Form.Item
                                            name="test_pack_manuel"
                                            label="Test Ekle"
                                            rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                            >
                                            <Select
                                                mode="multiple"
                                                placeholder="Test Seçiniz"
                                                value={pack_create_selected_item}
                                                onChange={this.pack_create_change_event}
                                            >
                                                {filteredOptions.map(item => (
                                                    <Select.Option key={item} value={item}>
                                                        {item}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <div className="selectedTests">
                                            <Form.Item
                                                name="test_pack_manuel"
                                                label="Seçilen Testler"
                                                rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                                >

                                                <Checkbox.Group options={test_list} defaultValue={['Pear']}  />
                                            </Form.Item>
                                            {/* başta boş gelecek. Test Ekledikçe check olarak buraya düşecek. Buradan check kaldırarak ta paketi iptal edebilecek. */}
                                        </div>
                                    </Col>
                                </Row>
                                
                                
                                
                            </Col>
                            <Col md={10}>
                                <Form.Item
                                    name="price"
                                    label="Gönderme Seçenekleri"
                                    >
                                   <Checkbox>Mail</Checkbox>
                                   <Checkbox>Sms</Checkbox>
                                   <Checkbox>Pdf</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="price"
                                    label="Fiyat"
                                    rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                    >
                                    <Input placeholder="Fiyat" />
                                </Form.Item>
                                
                                <Form.Item
                                    name="message"
                                    label="Açıklama"
                                    rules={[{ required: true, message: 'Lütfen bu alanı boş bırakmayın' }]}
                                    >
                                    <Input.TextArea placeholder="Açıklama" />
                                </Form.Item>
                            </Col>
                        </Row>


                        <div className="formButton">
                            <Space size={15}>
                                <Button
                                    type="secondary"
                                    htmlType="submit"
                                    style={{marginRight: '10px'}}
                                    >
                                    <PlusOutlined /> Kaydet
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    >
                                    <PlusOutlined /> Gönder
                                </Button>
                            </Space>
                        </div>
                    </Form>
                </Card>


                
            </div>
        );
    }
}

export default offerEdit;