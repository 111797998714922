import React, { useState, useEffect } from "react";

import { Card, Spin, Result } from "antd";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";

const TestsCountGraphic = () => {
  const [testData, setTestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const setData = (data) => {
    let reChartStackedBarData = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        reChartStackedBarData.push({
          name: element.lab,
          test: element.sampleTestCount,
          kayit: element.sampleCount,
        });
      }
    }

    setTestData(reChartStackedBarData);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    DataService.Dashboard.SampleTest()
      .then((result) => {
        console.log("resultsssss", result);
        if (result.status) {
          setData(result.data);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };

  return (
    <Spin spinning={loading}>
      <Card title="Test/Kayıt Sayıları">
        <div style={{ width: "100%", height: 300 }}>
          {!testData ? (
            <Result title="Grafik oluşturulamadı." />
          ) : (
            <ResponsiveContainer>
              <BarChart
                data={testData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="test" stackId="a" fill="#8884d8" />
                <Bar dataKey="kayit" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </Card>
    </Spin>
  );
};

export default TestsCountGraphic;
