import React, { useEffect, useState } from "react";

import { Statistic, Card, Row, Col, Spin } from "antd";
import {
  ExperimentOutlined,
  UserOutlined,
  IdcardOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";

const StatisticComponent = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    DataService.Dashboard.Statistic()
      .then((result) => {
        if (result.status) {
          setData(result.data);
          setLoading(false);
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        Helper.ErrorMessage(error.toString());
      });
  }, []);

  return (
    <Spin spinning={loading}>
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <h2>İşlemlerim</h2>
            <div className="widget-statistic-flex">
              <Statistic
                title={data?.oneBox?.name || ""}
                value={data?.oneBox?.count || 0}
                valueStyle={{ color: "#3f8600" }}
              />
              <UserOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <h2>Numuneler</h2>
            <div className="widget-statistic-flex">
              <Statistic
                title={data?.secondBox?.name || ""}
                value={data?.secondBox?.count || 0}
                valueStyle={{ color: "#3f8600" }}
              />
              <IdcardOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <h2>Raporlar</h2>
            <div className="widget-statistic-flex">
              <Statistic
                title={data?.thirdBox?.name || ""}
                value={data?.thirdBox?.count || 0}
                valueStyle={{ color: "#3f8600" }}
              />
              <FileDoneOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
        <Col className="dash-col" span={24} md={6}>
          <Card>
            <h2>Analizler</h2>
            <div className="widget-statistic-flex">
              <Statistic
                title={data?.fourthBox?.name || ""}
                value={data?.fourthBox?.count || 0}
                valueStyle={{ color: "#3f8600" }}
              />

              <ExperimentOutlined className="widget-icon" />
            </div>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default StatisticComponent;
