import React, { Component } from "react";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import Table from "../../../Components/UI/Table";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from "lodash";

const { Option } = Select;

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      productData: [],
      standartId: null,
      tableColumns: [
        {
          title: "Standart Adı",
          dataIndex: "name",
          key: "name",
          responsive: ["md"],
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Standart Adı"
          ),
          sorter: (a, b) => a.name.localeCompare(b.name), // string sıralama
        },
        {
          title: "Ürün Adı",
          dataIndex: "productName",
          key: "productName",
          responsive: ["md"],
          ...Helper.getColumnSearchProps(
            "productName",
            this.handleSearch,
            this.handleReset,
            "Ürün Adı"
          ),
          sorter: (a, b) => a.productName.localeCompare(b.productName), // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseProduct = await DataService.Product.SelectAll();
      const responseStandart = await DataService.ReferanceStandart.SelectAll();

      if (responseProduct.status && responseStandart.status) {
        const product = responseProduct.response.data;
        const standart = responseStandart.response.data;

        this.setState({
          data: standart,
          productData: product,
          loading: false,
        });
      } else {
        if (!responseProduct.status)
          Helper.ErrorMessage(responseProduct.message);
        if (!responseStandart.status)
          Helper.ErrorMessage(responseStandart.message);
      }
    } catch (error) {
      console.log("Products", JSON.stringify(error));
    }
  };
  _editClick = ({ standard_id, name, name_en, product_id }) => {
    this.setState({ visible: true, standartId: standard_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          standartName: name,
          standartNameEN: name_en,
          productId: product_id,
        });

        console.log("editClick", {
          standard_id,
          name,
          name_en,
          product_id,
        });
      }, 100);
    });
  };
  _deleteClick = ({ standard_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name}  Standartını silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(standard_id);
      }
    });
  };
  deleted = (standartId) => {
    this.setState({ loading: true });
    DataService.ReferanceStandart.Delete(standartId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      standartId: null,
      btnloading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({ productId, standartName, standartNameEN }) => {
    try {
      const { standartId } = this.state;

      this.setState({ btnloading: true });
      let result;
      if (standartId) {
        result = await DataService.ReferanceStandart.Update({
          standard_id: standartId,
          product_id: productId,
          name: standartName,
          name_en: standartNameEN,
        });
      } else {
        result = await DataService.ReferanceStandart.Create({
          product_id: productId,
          name: standartName,
          name_en: standartNameEN,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: true, visible: false, productId: null, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const products = this.state.productData.map((item, index) => (
      <Option key={index} value={item.product_id}>
        {item.name}
      </Option>
    ));
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Ürünler" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"Standart"}
                dates={["tarih"]}
              />

              <Button
                shape="round"
                className="btn-add"
                icon={<PlusOutlined />}
                onClick={this._showModal}
              >
                Standart Ekle
              </Button>
            </Space>
          </div>

          <Modal
            title={this.state.productId ? "Standart Düzenle" : "Standart Ekle"}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="standartName"
                label="Standart Adı"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input placeholder="Standart Adı" />
              </Form.Item>
              <Form.Item
                name="standartNameEN"
                label="Standart Adı EN"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input placeholder="Standart Adı EN" />
              </Form.Item>
              <Form.Item
                name="productId"
                label="Ürün"
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  placeholder="Ürün Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {products}
                </Select>
              </Form.Item>

              <div className="formButton">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.btnloading}
                >
                  {this.state.standartId ? (
                    <>
                      <EditOutlined /> Kaydet
                    </>
                  ) : (
                    <>
                      <PlusOutlined /> Ekle
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.standard_id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
              rowKey="standard_id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra customer id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}
