import React, { Component } from "react";
import "./login.css";
import { NavLink, Redirect } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";
import Logo from "../../Assets/logo/logo.svg";
import DataService from "../../Provider/Service/DataService";
import Helper from "../../Helper";
class Login extends Component {
  state = {
    email: "",
    password: "",
    loginStatus: false,
    btnLoad:false
  };
  componentDidMount() {
    //Helper.removeLocalStorage('Token');
   
  }
  controlData = () => {
    const control = Helper.getlocalStorage("Token");
    if (control) {
      this.setState({ loginStatus: true });
    }
  };

  _onClick = async () => {
    this.setState({btnLoad:true})
    try {
      
      const { email, password } = this.state;
      if (email && password.length >= 3) {
        const response = await DataService.User.Login(email, password);
    
        if (response.status) {
    //      message.success("Giriş Başarılı Yönlendiriliyorsunuz...");
          this.setState({ loginStatus: true,btnLoad:false });
        } else {
          message.error(response.message);
          this.setState({btnLoad:false})
        }
      } else {
        Helper.ErrorMessage("Lütfen email ve şifrenizi giriniz.");
        this.setState({btnLoad:false})
      }
    } catch (error) {
      Helper.ErrorMessage(JSON.stringify(error));
      this.setState({btnLoad:false})
    }
  };
  render() {
    if (this.state.loginStatus) return <Redirect to={"/dashboard"} />;
    return (
      <div className="login-layout ant-layout">
        <div className="loginBrand">
          <img src={Logo} alt="Logo" width="120" />
        </div>
        <div className="loginCard">
          <div className="cardHead">
            <h2>Giriş Yap</h2>
            <span>Kullanıcı bilgileri ile giriş yapın</span>
          </div>

          <div className="cardContent">
            <Form className="formLogin"  >
              <div className="formGroup">
                <label className="">E-Mail</label>
                <Input
                  size="large"
                  placeholder="info@intertek.com"
                  value="test@sharkyazilim.com"
                  prefix={<UserOutlined />}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  type="email"
                  required
                  onPressEnter={()=>this.passwordInput.focus()}
                />
              </div>
              <div className="formGroup">
                <label className="">
                  Parola
                  <NavLink exact to="/login-remember">
                    Parolamı Unuttum
                  </NavLink>
                </label>
                <Input.Password
                ref={refs=>this.passwordInput = refs}
                  size="large"
                  placeholder="Parola"
                  prefix={<KeyOutlined />}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  value={this.state.password}
                  minLength={3}
                  required
                  onPressEnter={!this.state.btnLoad  && this._onClick}
                />
              </div>
              <div className="formGroup">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  icon={<LoginOutlined />}
                  onClick={this._onClick}
                  loading={this.state.btnLoad}
                >
                  Giriş Yap
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
