import React, { Component } from "react";
import { Table, Button } from "antd";
//import "./Style.css";

export default class index extends Component {
  state = {
    pagination: true,
  };

  render() {
    return (
      <Table
        columns={this.props.columns}
        bordered
        // style={{ marginLeft: "30px", marginRight: "30px" }}
        title={this.props.title}
        onHeaderRow={this.props.header}
        showHeader
        onChange={this.props.onChange}
        dataSource={this.props.dataSource}
        // scroll={{ y: "60vh", x: "20vw" }}
        pagination={
          this.props.pagination
            ? {
                pageSize: 10,
              }
            : {
                pageSize: this.props.dataSource.length,
              }
        }
        tableLayout="auto"
        onRow={this.props.onRow}
        rowKey={this.props.rowKey}
        footer={this.props.footer}
        
      />
    );
  }
}
