import React from "react";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class Excel extends React.Component {
  componentDidMount = () => {
    this.props.clear();
  };
  render() {
    return (
      <ExcelFile
        hideElement={true}
        filename={"LIMS_" + this.props.tableName || " "}
      >
        <ExcelSheet data={this.props.data} name="Table">
          {this.props.columns.map((item, index) => {
            if (item.hasOwnProperty("children"))
              return item.children.map((child, index) => (
                <ExcelColumn
                  key={index}
                  label={child.title}
                  value={child.dataIndex}
                />
              ));
            else
              return (
                <ExcelColumn
                  key={index}
                  label={item.title}
                  value={item.dataIndex}
                />
              );
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
