import React from "react";

import { Card, Row, Col } from "antd";
import { Samples, Announcement, Statistic,QrComponent } from "../Components";

const permLabPersonel = () => {
  return (
    <div>
      <Statistic />
      <Row gutter={16}>
        <Col className="dash-col" span={24} md={12}>
          <Samples />
        </Col>
        <Col className="dash-col" span={24} md={12}>
          <QrComponent />
          <Announcement />
        </Col>
      </Row>
    
    </div>
  );
};

export default permLabPersonel;
