import React, { useState, useEffect, useRef } from "react";

import {
  Card,
  Tag,
  Select,
  Button,
  Spin,
  Form,
  Row,
  Col,
  DatePicker,
  Checkbox,
} from "antd";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import PageHeader from "../../../Components/UI/PageHeader";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
import Charts from "./Chart";
import Table from "./Table";
import moment from "moment";
import lodash from "lodash";
const { Option } = Select;
const { RangePicker } = DatePicker;
const DetailedReport = () => {
  const [data, setData] = useState(null);

  const [testData, setTestData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [selectSector, setSelectSector] = useState({
    id: 0,
    name: null,
  });

  const [loading, setLoading] = useState(true);
  const [allSelectedProduct, setAllSelectedProduct] = useState(false);
  const [allSelectedTest, setAllSelectedTest] = useState(false);
  const [allSelectedCustomer, setAllSelectedCustomer] = useState(false);
  const formRef = useRef();
  useEffect(() => {
    getData();
    return () => {};
  }, []);

  const getData = async () => {
    try {
      const getTests = await DataService.Test.SelectAll();

      const getSector = await DataService.Sector.SelectAll();
      const getCustomer = await DataService.Customer.SelectAll();
      const getProduct = await DataService.Product.SelectAll();
      console.log("getss", { getCustomer, getProduct });
      if (
        getTests?.status &&
        getSector?.status &&
        getCustomer?.status &&
        getProduct?.status
      ) {
        setTestData(getTests?.response?.data || []);

        setSectorData(getSector?.response?.data || []);
        setProductData(getProduct.response?.data || []);
        setCustomerData(getCustomer.response?.data || []);
        setLoading(false);
      } else {
        if (!getTests?.status)
          Helper.ErrorMessage(getTests?.message || "Test Listesi Alınamadı");

        if (!getSector?.status)
          Helper.ErrorMessage(getSector?.message || "Sektör Listesi Alınamadı");
        if (!getCustomer?.status)
          Helper.ErrorMessage(
            getCustomer?.message || "Sektör Listesi Alınamadı"
          );
        if (!getProduct?.status)
          Helper.ErrorMessage(
            getProduct?.message || "Sektör Listesi Alınamadı"
          );
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };

  const formFinish = ({ testName, product, customer, date }) => {
    setLoading(true);
    const start_date = date ? date[0].unix() : 0;
    const finish_date = date ? date[1].unix() : 0;
    const product_id = !allSelectedProduct
      ? product
      : productData
          ?.filter((_, i) => _.sector_name === selectSector.name)
          .map((item, index) => item.product_id);
    const test_id = !allSelectedTest
      ? testName
      : testData
          ?.filter((_, i) => _.sector_id === selectSector.id)
          .map((item, index) => item.test_id);
    const customers = !allSelectedTest
      ? customer
      : customerData?.map((item, index) => item.customer_id);
    DataService.Dashboard.detailReportsStatistic({
      test_id: test_id,
      product_id: product_id,
      start_date,
      finish_date,
      customer_id: customers,
    })
      .then((result) => {
        setLoading(false);
        setData(result?.response?.data);
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };
  const onChangeSector = (value) => {
    const onFind = lodash.find(sectorData, { sector_id: value });
    setSelectSector({
      id: onFind?.sector_id ?? 0,
      name: onFind?.name ?? null,
    });
    // eslint-disable-next-line no-unused-expressions
    formRef?.current.setFieldsValue({
      testName: [],
      product: [],
    });
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={formFinish}
        style={{
          marginBottom: "16px",
        }}
        ref={formRef}
      >
        <Row gutter={15}>
          <Col span={12} md={3}>
            <Form.Item
              name="sector"
              label="Sektör"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <Select
                style={{ width: "100%", marginRight: 16 }}
                showSearch
                placeholder="Seçim yap"
                optionFilterProp="children"
                onChange={onChangeSector}
              >
                {sectorData?.map((item, index) => {
                  return (
                    <Option key={index} value={item.sector_id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} md={4}>
            <Form.Item
              name="customer"
              label="Müşteri"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <Select
                style={{ width: "100%", marginRight: 16 }}
                showSearch
                placeholder={allSelectedCustomer ? "Tümünü Seç" : "Seçim yap"}
                optionFilterProp="children"
                mode="tags"
                disabled={allSelectedCustomer}
              >
                {customerData?.map((item, index) => {
                  return (
                    <Option key={index} value={item.customer_id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={12}
            md={3}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Checkbox
              value={allSelectedCustomer}
              onClick={() => setAllSelectedCustomer((prevState) => !prevState)}
              disabled={selectSector.id === 0}
              style={{ marginTop: 12 }}
            >
              Tüm Müşterileri Seç
            </Checkbox>
          </Col>
          <Col span={12} md={7}>
            <Form.Item
              name="date"
              label="Tarih Aralığı Seçiniz"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <RangePicker
                format="YYYY-MM-DD"
                style={{
                  display: "flex",
                  flex: 1,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} md={3}>
            <Button
              type="primary"
              style={{ marginTop: 30 }}
              htmlType="submit"
              loading={loading}
            >
              Getir
            </Button>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12} md={7}>
            <Form.Item
              name="product"
              label="Ürün"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <Select
                style={{ width: "100%", marginRight: 16 }}
                showSearch
                placeholder={allSelectedProduct ? "Tümünü Seç" : "Seçim yap"}
                optionFilterProp="children"
                mode="tags"
                disabled={allSelectedProduct}
              >
                {productData
                  ?.filter((_, i) => _.sector_name === selectSector.name)
                  .map((item, index) => {
                    return (
                      <Option key={index} value={item.product_id}>
                        {item.name} - {item.product_nameEn}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={12}
            md={3}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Checkbox
              value={allSelectedProduct}
              onClick={() => setAllSelectedProduct((prevState) => !prevState)}
              disabled={selectSector.id === 0}
            >
              Tüm Ürünleri Seç
            </Checkbox>
          </Col>
          <Col span={12} md={7}>
            <Form.Item
              name="testName"
              label="Test (Analiz)"
              rules={[
                {
                  required: !allSelectedTest,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <Select
                style={{ width: "100%", marginRight: 16 }}
                showSearch
                placeholder={allSelectedTest ? "Tümünü Seç" : "Seçim yap"}
                optionFilterProp="children"
                mode="tags"
                disabled={allSelectedTest}
              >
                {testData
                  ?.filter((_, i) => _.sector_id === selectSector.id)
                  .map((item, index) => {
                    return (
                      <Option key={index} value={item.test_id}>
                        {item.name} / {item.unit_name} / {item.method_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={12}
            md={3}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Checkbox
              value={allSelectedTest}
              onClick={() => setAllSelectedTest((prevState) => !prevState)}
              disabled={selectSector.id === 0}
            >
              Tüm Testleri Seç
            </Checkbox>
          </Col>
        </Row>
      </Form>
      {!!data && <Table data={data} title="Testlere Göre Raporlama" />}
    </Spin>
  );
};

export default DetailedReport;

/*

 <Row gutter={15}>
          <Col span={12} md={3}></Col>
          <Col span={12} md={4}>
            <Checkbox
              value={allSelectedProduct}
              onClick={() => setAllSelectedProduct((prevState) => !prevState)}
              disabled={selectSector.id === 0}
            >
              Tüm Ürünleri Seç
            </Checkbox>
          </Col>
          <Col span={12} md={4}>
            <Checkbox
              value={allSelectedCustomer}
              onClick={() => setAllSelectedCustomer((prevState) => !prevState)}
              disabled={selectSector.id === 0}
            >
              Tüm Müşterileri Seç
            </Checkbox>
          </Col>
          <Col span={12} md={4}>
            <Checkbox
              value={allSelectedTest}
              onClick={() => setAllSelectedTest((prevState) => !prevState)}
              disabled={selectSector.id === 0}
            >
              Tüm Testleri Seç
            </Checkbox>
          </Col>
          <Col span={12} md={3}></Col>
        </Row>

*/
