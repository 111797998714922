import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import Export from "../../../Components/Export/Export";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Space,
  Select,
  Radio,
  Spin,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import _ from "lodash";
const { Option } = Select;
export default class index extends Component {
  constructor(props){
    super(props)
    this. state = {
      visible: false,
      loading: true,
      btnloading: false,
      data: [],
      InventoryCategoryId: null,
  
      tableColumns: [
        {
          title: "Üst Kategori",
          dataIndex: "top_cat_name",
          key: "top_cat_name",
          ...Helper.getColumnSearchProps(
            "top_cat_name",
            this.handleSearch,
            this.handleReset,
            "Üst Kategori"
          ),
          sorter:(a,b) => a.top_cat_name.localeCompare(b.top_cat_name) // string sıralama
        },
        {
          title: "Kategori Adı",
          dataIndex: "name",
          key: "name",
          ...Helper.getColumnSearchProps(
            "name",
            this.handleSearch,
            this.handleReset,
            "Kategori Adı"
          ),
          sorter:(a,b) => a.name.localeCompare(b.name) // string sıralama
        },
        // {
        //   title: "Kategori Adı (EN)",
        //   dataIndex: "name_en",
        //   key: "name_en",
        //   ...Helper.getColumnSearchProps(
        //     "name_en",
        //     this.handleSearch,
        //     this.handleReset,
        //     "Kategori Adı (EN)"
        //   ),
        //   sorter:(a,b) => a.name_en.localeCompare(b.name_en) // string sıralama
        // },
        {
          title: "Laboratuvar Cihazı",
          dataIndex: "lab_device_name",
          key: "lab_device_name",
          sorter:(a,b) => a.lab_device_name.localeCompare(b.lab_device_name) // string sıralama
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    DataService.InventoryCategory.SelectAll()
      .then((result) => {
        if (result.status) {
          const { data } = result.response;
          let inventoryCategory = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              const find = _.filter(data, { cat_id: element.top_cat_id });
              let top_cat_name = "Ana Kategori";
              if (find.length > 0) {
                top_cat_name = find[0].name;
              }
              inventoryCategory.push({
                ...element,
                id: key,
                top_cat_name,
                lab_device_name: element.lab_device == 0 ? "Hayır" : "Evet",
                top_cat_id:
                  element.top_cat_id == null || element.top_cat_id == "0"
                    ? 0
                    : element.top_cat_id,
              });
            }
          }

          this.setState({
            data: inventoryCategory,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Inventory Brand", error);
      });
  };
  _editClick = ({ cat_id, name, name_en, lab_device, top_cat_id }) => {
    this.setState({ visible: true, InventoryCategoryId: cat_id }, () => {
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          InventoryCategoryNameTr: name,
          InventoryCategoryNameEn: name_en,
          InventoryCategoryLabDevice: lab_device,
          InventoryCategoryTopCategoryId: top_cat_id,
        });
      }, 100);
    });
  };
  _deleteClick = ({ cat_id, name }) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      text: `${name} Envanter Kategorisini silmek üzeresiniz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.value) {
        this.deleted(cat_id);
      }
    });
  };
  deleted = (InventoryCategoryId) => {
    this.setState({ loading: true });
    DataService.InventoryCategory.Delete(InventoryCategoryId)
      .then((result) => {
        if (result.status) {
          Helper.SuccessMessage(result.message);
          this.getData();
        } else {
          Helper.ErrorMessage(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        Helper.ErrorMessage("Bir hata meydana geldi");
      });
  };
  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={() => this._deleteClick(record)}
        ></Button>
      </Space>
    );
  };
  _showModal = () => {
    this.setState({
      visible: true,
    });
  };
  _closeModal = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      InventoryCategoryId: null,
      loading: false,
    });
  };
  _tableHandleChange = (pagintions, filters, sorter) => {
    this.setState({ filteredInfo: filters });
  };
  _formFinish = async ({
    InventoryCategoryNameTr,
    InventoryCategoryNameEn,
    InventoryCategoryLabDevice,
    InventoryCategoryTopCategoryId,
  }) => {
    try {
      const { InventoryCategoryId } = this.state;
      this.setState({ btnloading: true });
      let result,
        labDevice = 0;
      if (parseInt(InventoryCategoryLabDevice) != "NaN") {
        labDevice = InventoryCategoryLabDevice;
      }
      if (InventoryCategoryId) {
        result = await DataService.InventoryCategory.Update({
          InventoryCategoryId,
          InventoryCategoryNameTr,
          InventoryCategoryNameEn,
          InventoryCategoryLabDevice: labDevice,
          InventoryCategoryTopCategoryId,
        });
      } else {
        result = await DataService.InventoryCategory.Create({
          InventoryCategoryNameTr,
          InventoryCategoryNameEn,
          InventoryCategoryLabDevice: labDevice,
          InventoryCategoryTopCategoryId,
        });
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          {
            loading: true,
            visible: false,
            InventoryCategoryId: null,
            btnloading: false,
          },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const topCategory = this.state.data.map((item, index) => {
      return (
        <Option key={index} value={item.cat_id}>
          {item.name}
        </Option>
      );
    });
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Envanter Kategorisi" subTitle="" />

          <div className="pageHeaderOptions">
            <Space>
              <Export
                data={this.state.data}
                columns={[...this.state.tableColumns].slice(0, 6)}
                tableName={"InventoryCategory"}
                dates={["tarih"]}
              />

                <Button
                  shape="round"
                  className="btn-add"
                  icon={<PlusOutlined />}
                  onClick={this._showModal}
                >Yeni Ekle</Button>
            </Space>
          </div>

          <Modal
            title={this.state.InventoryCategoryId ? (
              "Envanter Kategorisi Düzenle"
            ) : (
              "Envanter Kategorisi Ekle"
            )}
            visible={this.state.visible}
            onCancel={this._closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={this._formFinish}
              ref={this.formRef}
            >
              <Form.Item
                name="InventoryCategoryTopCategoryId"
                label="Üst Kategori"
              >
                <Select
                  showSearch
                  placeholder="Seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0}>Ana Kategori</Option>
                  {topCategory}
                </Select>
              </Form.Item>
              <Form.Item
                name="InventoryCategoryNameTr"
                label="Kategori Adı TR"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input  />
              </Form.Item>
              <Form.Item
                name="InventoryCategoryNameEn"
                label="Kategori Adı EN"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="InventoryCategoryLabDevice"
                label="Laboratuvar Aygıtı mı?"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Evet</Radio>
                  <Radio value={0}>Hayır</Radio>
                </Radio.Group>
              </Form.Item>

              <div className="formButton">
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.btnloading}
              >
                {this.state.InventoryCategoryId ? (
                  <>
                    {" "}
                    <PlusOutlined /> Kaydet{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <EditOutlined /> Ekle{" "}
                  </>
                )}
              </Button>
              </div>
            </Form>
          </Modal>
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Tables
              columns={this.state.tableColumns}
              onChange={this._tableHandleChange}
              dataSource={this.state.data}
              pagination={{
                defaultPageSize: 1,
              }}
              onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
              rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
            />
          </Card>
        </Spin>
      </div>
    );
  }
}
