import React, { Component } from "react";

import Table from "../../../Components/Table";

import PageHeader from "../../../Components/UI/PageHeader";
import ContainerItem from "./ContainerItem";

import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  InputNumber,
  Spin,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import Swal from "sweetalert2";
import _ from "lodash";
const { Option } = Select;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      btnloading: false,
      storeId: null,
      containerValue: [],
      containerShelf: [],
      data: [],
      regionData: [],
      labData: [],
      tableColumns: [
        {
          title: "Depo Adı",
          dataIndex: "storeName",
          key: "storeName",
          ...Helper.getColumnSearchProps(
            "storeName",
            this.handleSearch,
            this.handleReset,
            "Depo Adı Tr"
          ),
          sorter: (a, b) => a.storeName.localeCompare(b.storeName), // string sıralama
        },
        // {
        //   title: "Depo Adı En",
        //   dataIndex: "storeNameEn",
        //   key: "storeNameEn",
        //   ...Helper.getColumnSearchProps(
        //     "storeNameEn",
        //     this.handleSearch,
        //     this.handleReset,
        //     "Depo Adı En"
        //   ),
        //   sorter:(a,b) => a.storeNameEn.localeCompare(b.storeNameEn) // string sıralama
        // },
        {
          title: "Kodu",
          dataIndex: "code",
          key: "code",
          ...Helper.getColumnSearchProps(
            "code",
            this.handleSearch,
            this.handleReset,
            "Kodu"
          ),
          sorter: (a, b) => a.code.localeCompare(b.code), // string sıralama
        },
        {
          title: "Bölge",
          dataIndex: "regionName",
          key: "regionName",
        },
        {
          title: "Laboratuvar",
          dataIndex: "labName",
          key: "labName",
        },
        {
          title: "Seçenekler",
          fixed: "right",
          dataIndex: "options",
          key: "options",
          render: (text, record) => this.Actions(text, record),
        },
      ],
    };
  }
  formRef = React.createRef();
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const responseStore = await DataService.Store.SelectAll();
      const responseRegion = await DataService.Region.SelectAll();
      const responseLabs = await DataService.Laboratory.SelectAll();
      if (
        responseStore.status &&
        responseRegion.status &&
        responseLabs.status
      ) {
        this.setState({
          data: responseStore.response.data,
          regionData: responseRegion.response.data,
          labData: responseLabs.response.data,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        Helper.ErrorMessage(responseStore.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
      storeId: null,
    });
  };
  closeModal = (e) => {
    this.setState({
      visible: false,
      storeId: null,
      btnloading: false,
    });
  };
  _editClick = async ({ store_id }) => {
    try {
      const result = await DataService.Store.Select(store_id);
      let containerShelf = [],
        containerValue = [];
      if (result.status) {
        const {
          store_id,
          storeName,
          storeNameEn,
          code,
          lab_id,
          region_id,
          containerTotal,
          container,
        } = result.data;
        for (const key in container) {
          if (container.hasOwnProperty(key)) {
            const element = container[key];
            containerShelf.push({
              id: `shelfNumber-${Number(key) + 1}`,
              value: element.shelf ? element.shelf : 0,
            });
            containerValue.push({
              containerNumber: `containerNumber-${Number(key) + 1}`,
              shelfNumber: `shelfNumber-${Number(key) + 1}`,
            });
          }
        }
        this.setState(
          { visible: true, storeId: store_id, containerValue, containerShelf },
          () => {
            setTimeout(() => {
              this.formRef.current.setFieldsValue({
                storeName: storeName,
                storeNameEn: storeNameEn,
                storeCode: code,
                storeLabId: lab_id,
                storeRegionId: region_id,
                storeContainer: containerTotal,
              });
            }, 100);
          }
        );
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };

  Actions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          shape="round"
          type="primary"
          onClick={() => this._editClick(record)}
        >
          Düzenle
        </Button>
      </Space>
    );
  };
  _formFinish = async ({
    storeName,
    storeNameEn,
    storeCode,
    storeRegionId,
    storeLabId,
    storeContainer,
  }) => {
    try {
      this.setState({ btnloading: true });
      let storeContainerShelf = [];
      const { containerShelf, storeId } = this.state;
      for (const key in containerShelf) {
        if (containerShelf.hasOwnProperty(key)) {
          const element = containerShelf[key];
          storeContainerShelf.push({
            shelf: element.value,
          });
        }
      }
      let formData = {
        storeName,
        storeNameEn,
        storeCode,
        storeRegionId,
        storeLabId,
        storeContainer,
        storeContainerShelf,
      };

      let result;
      if (storeId) {
        result = await DataService.Store.Update({ storeId, ...formData });
      } else {
        result = await DataService.Store.Create(formData);
      }

      if (result.status) {
        Helper.SuccessMessage(
          "İşleminiz başarılı bir şekilde gerçekleştirildi."
        );
        this.formRef.current.resetFields();
        this.setState(
          { loading: false, visible: false, storeId: null, btnloading: false },
          this.getData
        );
      } else {
        this.setState({ btnloading: false });
        Helper.ErrorMessage(result.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  containerValueChange = (value) => {
    let containerValue = [],
      containerShelf = [];
    for (let key = 0; key < value; key++) {
      containerValue.push({
        containerNumber: `containerNumber-${key + 1}`,
        shelfNumber: `shelfNumber-${key + 1}`,
      });
      const data = _.find(this.state.containerShelf, {
        id: `shelfNumber-${key + 1}`,
      });
      containerShelf.push({
        id: `shelfNumber-${key + 1}`,
        value: data ? data.value : 0,
      });
    }
    this.setState({
      containerValue,
      containerShelf,
    });
  };

  render() {
    const ContainerItems = this.state.containerValue.map((item, index) => {
      return (
        <ContainerItem
          key={index}
          value={index}
          containerNumber={item.containerNumber}
          shelfNumber={item.shelfNumber}
          shelfData={this.state.containerShelf}
          onChange={(containerShelf) => this.setState({ containerShelf })}
        />
      );
    });

    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Depo" subTitle="" />

          <Tooltip title="Yeni Depo Ekle" placement="left">
            <Button
              shape="round"
              className="btn-add"
              icon={<PlusOutlined />}
              onClick={this.showModal}
            >
              Yeni Depo Ekle{" "}
            </Button>
          </Tooltip>

          <Modal
            title="Yeni Depo Ekle"
            visible={this.state.visible}
            onCancel={this.closeModal}
            footer=""
          >
            <Form
              layout="vertical"
              hideRequiredMark
              ref={this.formRef}
              onFinish={this._formFinish}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="storeName"
                    label="Depo Adı TR"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="storeNameEn"
                    label="Depo Adı EN"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen bu alanı boş bırakmayın",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="storeCode"
                label="Depo Kodu"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Input placeholder="Örneğin; DPO" />
              </Form.Item>

              <Form.Item
                name="storeRegionId"
                label="Bölge"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select showSearch placeholder="Seçiniz">
                  {this.state.regionData.map((item, index) => {
                    return (
                      <Option key={index} value={item.region_id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="storeLabId"
                label="Laboratuvar"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <Select showSearch placeholder="Seçiniz">
                  {this.state.labData.map((item, index) => {
                    return (
                      <Option key={index} value={item.laboratory_id}>
                        {item.LabName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="storeContainer"
                label="Konteyner Sayısı"
                rules={[
                  { required: true, message: "Lütfen bu alanı boş bırakmayın" },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // max={10}
                  defaultValue={0}
                  onChange={this.containerValueChange}
                />
              </Form.Item>

              {ContainerItems}

              <div className="formButton">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.btnloading}
                >
                  <PlusOutlined /> Ekle
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Table
              columns={this.state.tableColumns}
              dataSource={this.state.data}
            />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
