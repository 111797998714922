import React, { useState, useEffect, useRef } from "react";

import {
  Card,
  Tag,
  Select,
  Button,
  Spin,
  Form,
  Row,
  Col,
  DatePicker,
} from "antd";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";

import Table from "./Table";

const { Option } = Select;
const { RangePicker } = DatePicker;
const DetailedReport = () => {
  const [data, setData] = useState(null);
  const [customerData, setCustomerData] = useState([]);

  const [loading, setLoading] = useState(true);

  const formRef = useRef();
  useEffect(() => {
    getData();
    return () => {};
  }, []);
  const getData = async () => {
    try {
      const getCustomers = await DataService.Customer.SelectAll();

      if (getCustomers?.status) {
        setCustomerData(getCustomers?.response?.data || []);
        setLoading(false);
      } else {
        Helper.ErrorMessage(
          getCustomers?.message || "Müşteri Listesi Alınamadı"
        );
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };

  const formFinish = ({ customerId, date }) => {
    setLoading(true);
    const start_date = date ? date[0].unix() : 0;
    const finish_date = date ? date[1].unix() : 0;

    DataService.Dashboard.detailReportsStatisticCustomer({
      customerId,

      start_date,
      finish_date,
    })
      .then((result) => {
        setLoading(false);
        setData(result?.response?.data);
      })
      .catch((error) => Helper.ErrorMessage(error.toString()));
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={formFinish}
        style={{
          marginBottom: "16px",
        }}
        ref={formRef}
      >
        <Row gutter={15}>
          <Col span={12} md={6}>
            <Form.Item
              name="customerId"
              label="Müşteri"
              rules={[
                {
                  required: true,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <Select
                style={{ width: "100%", marginRight: 16 }}
                showSearch
                placeholder="Seçim yap"
                optionFilterProp="children"
                mode="tags"
              >
                {customerData?.map((item, index) => {
                  return (
                    <Option key={index} value={item.customer_id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} md={6}>
            <Form.Item
              name="date"
              label="Tarih Aralığı Seçiniz"
              rules={[
                {
                  required: false,
                  message: "Lütfen bu alanı boş bırakmayın",
                },
              ]}
            >
              <RangePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12} md={6}>
            <Button
              type="primary"
              style={{ marginTop: 30 }}
              htmlType="submit"
              loading={loading}
            >
              Getir
            </Button>
          </Col>
        </Row>
      </Form>
      {!!data && <Table data={data} title='Müşteriye Göre Raporlama' />}
    </Spin>
  );
};

export default DetailedReport;
