import React, { Component, PureComponent } from "react";

import Table from "../../Components/UI/Table";
import PageHeader from "../../Components/UI/PageHeader";
import { Spin } from "antd";
import Roles from "./Roles";
import Helper from "../../Helper";
import DataService from "../../Provider/Service/DataService";
class index extends Component {
  state = {
    permId: undefined,
    loading: true,
    fullName:''
  };
  componentDidMount() {
    // setTimeout(() => {
    //   this.setState({
    //     loading: false,
    //     permId: "234e82361ec29a9f1ef7cf9bd37782fc",
    //   });
    // });
     // Bu kısmı aktif ettiğinde userPermID gelecek ve ona göre component update olacak.
    // Settimeout kısmını da silmelisin :)
    this.getData();
  }
  getData = async () => {
    try {
      const responseUser = await DataService.User.Profile();
      if (responseUser.status) {
        console.log('responseUser',responseUser);
        const fullName = responseUser?.data ? `${responseUser?.data?.name}  ${responseUser?.data?.lastname}` : 'İsimsiz Kullanıcı';
        this.setState({ permId: responseUser?.data?.perm_id,fullName, loading: false });
      } else {
        Helper.ErrorMessage(responseUser.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error.toString());
    }
  };
  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title={`Hoş geldin, ${this.state.fullName}`} />
        </div>
        <Spin spinning={this.state.loading}>
          <div className="site-statistic-demo-card">
            {this.state.permId && <Roles permId={this.state.permId} />}
          </div>
        </Spin>
      </div>
    );
  }
}

export default index;
