import React, {Component} from "react";
import "../index.css";
import {NavLink} from "react-router-dom";

import {PlusOutlined} from "@ant-design/icons";
import _ from "lodash";
import PageHeader from "../../../../Components/UI/PageHeader";
import ListItem from "../ListItem";
import ListItemsInspection from "../ListItemsInspection";
import Helper from "../../../../Helper";
import DataService from "../../../../Provider/Service/DataService";
import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Spin,
} from "antd";

import dateTimeMoment from "moment";
import CustomerForm from "../../../Customers/Form";

const dateTimeFormat = "HH:mm";

const {Option} = Select;

const test_list = [
    "Ürün Adı",
    "Ürün Adı 2",
    "Ürün Adı 3",
    "Ürün Adı 4",
    "Ürün Adı 5",
    "Ürün Adı 6",
    "Ürün Adı 7",
    "Ürün Adı 8",
    "Ürün Adı 9",
    "Ürün Adı 10",
    "Ürün Adı 11",
];

class index extends Component {
    state = {
        visible: false,
        infoVisible: false,
        customerAddVisible: false,
        sampleStorageValue: 0,
        loading: true,
        ListItemsData: [
            {
                productName: "productName-1",
                labName: "labName-1",
                sampleName: "sampleName-1",
                mainLabChecked: `mainLabChecked-1`,
                repeat_report: 1,
                sample_test_count: 0,
            },
        ],
        ListItemsInspectionData: [
            {
                productNameInspection: "productNameInspection-1",
                location: "location-1",
            },
        ],
        typeValue: 1,
        TestData: [],
        TestPackData: [],
        ProductsData: [],
        RegionsData: [],
        CustomersData: [],
        SectorData: [],
        LabData: [],
        OperationData: [],
        checked: true,
        locationData: [],
        workOrderData: [],
        productId: null,
        sectorId: null,
        permTypeId: null,
        regionId: null,
        userLabId: null,
        btnLoading: false,
    };
    formRef = React.createRef();

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        try {
            const responseTest = await DataService.Test.SelectAll();
            const responseTestPack = await DataService.TestPack.SelectAll();

            const responseRegions = await DataService.Region.SelectAll();
            const responseCustomers = await DataService.Customer.SelectAll();
            const responseSector = await DataService.Sector.SelectAll();
            const responseLab = await DataService.Laboratory.SelectAll();
            const responseOperation = await DataService.Operation.SelectAll();
            const responseGetLocation = await DataService.Sample.getLocation();
            //const responseGetPart = await DataService.Sample.getPart();
            const responseUser = await DataService.User.Profile();
            if (
                responseTest.status &&
                responseTestPack.status &&
                responseRegions.status &&
                responseCustomers.status &&
                responseSector.status &&
                responseLab.status &&
                responseOperation.status &&
                responseUser.status
                //responseGetPart.status
            ) {
                const TestData = responseTest.response.data;
                const TestPackData = responseTestPack.response.data;
                const RegionsData = responseRegions.response.data;
                const CustomersData = responseCustomers.response.data;
                const SectorData = responseSector.response.data;
                const LabData = responseLab.response.data;
                const OperationData = responseOperation.response.data;

                const permTypeId = responseUser?.data?.perm_type_id || null;
                const regionId = responseUser?.data?.region_id || null;
                const userLabId = responseUser?.data?.lab_id || null;

                const typeValue =
                    permTypeId == "ddd2a4ede26ec3f71c602974ae7175c4" ? 2 : 1;

                //console.log('responseGetPart',responseGetPart?.data);
                this.setState({
                    TestData,
                    TestPackData,
                    RegionsData,
                    CustomersData,
                    SectorData,
                    LabData,
                    OperationData,
                    loading: false,
                    permTypeId,
                    typeValue,
                    regionId,
                    userLabId,
                    locationData: responseGetLocation?.data || [],
                    //workOrderData: responseGetPart?.data || [],
                    workOrderUrgency: "Standart – (Max 24 saat)"
                });
                this.formRef.current.setFieldsValue({
                    WorkOrderTypeId: typeValue,
                });
            } else {
                if (!responseTest.status) Helper.ErrorMessage(responseTest.message);
                if (!responseCustomers.status)
                    Helper.ErrorMessage(responseCustomers.message);
                if (!responseSector.status) Helper.ErrorMessage(responseSector.message);
                if (!responseLab.status) Helper.ErrorMessage(responseLab.message);
                if (!responseOperation.status)
                    Helper.ErrorMessage(responseOperation.message);
                if (!responseTestPack.status)
                    Helper.ErrorMessage(responseTestPack.message);

                if (!responseRegions.status)
                    Helper.ErrorMessage(responseRegions.message);
            }
        } catch (error) {
            console.log("error", error);
            Helper.ErrorMessage("Bir Hata Meydana Geldi");
        }
    };
    _addListItem = () => {
        let {ListItemsData} = this.state;
        ListItemsData.push({
            productName: `productName-${ListItemsData.length + 1}`,
            labName: `labName-${ListItemsData.length + 1}`,
            sampleName: `sampleName-${ListItemsData.length + 1}`,
            mainLabChecked: `mainLabChecked-${ListItemsData.length + 1}`,
            repeat_report: 1,
            sample_test_count: 0,
        });
        this.setState({
            ListItemsData,
        });
    };

    _addListItemInspection = () => {
        let {ListItemsInspectionData} = this.state;
        ListItemsInspectionData.push({
            productNameInspection: `productNameInspection-${
                ListItemsInspectionData.length + 1
            }`,
            location: `location-${ListItemsInspectionData.length + 1}`,
        });
        this.setState({
            ListItemsInspectionData,
        });
    };

    _deleteClick = (index) => {
        let {ListItemsData} = this.state;
        if (ListItemsData.length > 1) {
            delete ListItemsData[index];
            this.setState({
                ListItemsData,
            });
        } else {
            Helper.ErrorMessage("Silinemez!");
        }
    };

    _deleteClickInspection = (index) => {
        let {ListItemsInspectionData} = this.state;
        if (ListItemsInspectionData.length > 1) {
            delete ListItemsInspectionData[index];
            this.setState({
                ListItemsInspectionData,
            });
        } else {
            Helper.ErrorMessage("Silinemez2!");
        }
    };

    _showCustomerModal = () => {
        this.setState({
            customerAddVisible: true,
        });
    };
    _showInfoModal = () => {
        this.setState({
            infoVisible: true,
        });
    };
    _showModal = () => {
        this.setState({
            visible: true,
        });
    };
    _closeModal = (e) => {
        this.setState({
            visible: false,
            infoVisible: false,
            customerAddVisible: false,
        });
    };
    _closeModals = (e) => {
        this.setState({
            customerAddVisible: false,

            loading: true,
        });
        DataService.Customer.SelectAll()
            .then((result) => {
                if (result.status) {
                    let customerData = [
                        {
                            customer_id: 0,
                            name: "Tümü",
                        },
                    ];

                    this.setState({
                        CustomersData: customerData.concat(result.response.data),
                        loading: false,
                    });
                } else {
                    Helper.ErrorMessage(result.message);
                    this.setState({loading: false});
                }
            })
            .catch((error) => {
                Helper.ErrorMessage(error.toString());
                this.setState({loading: false});
            });
    };
    onChangeRadio = (e) => {
        this.setState({
            sampleStorageValue: e.target.value,
        });
    };

    _onChangeType = (e) => {
        this.setState({
            typeValue: e.target.value,
        });
    };
    _formFinish = (props) => {
        this.setState({btnLoading: true});
        let WorkOrderLaboratories = [],
            WorkOrderRegions = [],
            WorkOrderCustomers = [],
            testsDatas = [],
            WorkOrderSample = {
                get_location: props.WorkOrderPickupLocation,
                get_part: props.WorkOrderName,
            };
        for (const key in props.WorkOrderCustomers) {
            if (props.WorkOrderCustomers.hasOwnProperty(key)) {
                const element = props.WorkOrderCustomers[key];
                WorkOrderCustomers.push({customer_id: element});
            }
        }

        for (const key in props) {
            if (props.hasOwnProperty(key)) {
                const element = props[key];
                if (key.indexOf("labName") != -1) {
                    const value = key.split("-");
                    let laboratories = {
                        lab_code: props[`labName-${value[1]}`][2],
                        lab_id: props[`labName-${value[1]}`][1],
                        products: [],
                        sample_tests: [],
                        main_lab: props[`mainLabChecked-${value[1]}`] == true ? 1 : 0,
                        sample_test_count: 0,
                        sample_multiple: false,
                    };
                    const prod = props[`productName`];
                    const sample = props[`sampleName-${value[1]}`];
                    const sampleTestMultiple = props[`sampleName-${value[1]}-multiple`];
                    const sampleTestCount = props[`sampleName-${value[1]}-samplecount`];
                    laboratories.products.push({
                        product_id: prod,
                    });
                    laboratories.sample_test_count = sampleTestCount || 0;
                    laboratories.sample_multiple = sampleTestMultiple;
                    /*
                    for (const key in prod) {
                      if (prod.hasOwnProperty(key)) {
                        const element = prod[key];

                        laboratories.products.push({
                          product_id: element,
                        });
                      }
                    }
                    */

                    for (const key in sample) {
                        if (sample.hasOwnProperty(key)) {
                            const element = sample[key];

                            const type = _.find(this.state.TestData, {test_id: element});

                            laboratories.sample_tests.push({
                                type: type ? "test" : "test_pack",
                                sample_test_id: element,
                                parent_test_id: type ? type.parent_test_id : "0",
                            });
                        }
                    }
                    /*
                    if (sampleTestMultiple == 1) {
                        for (const key in sample) {
                            if (sample.hasOwnProperty(key)) {
                                const element = sample[key];

                                const type = _.find(this.state.TestData, {test_id: element});

                                laboratories.sample_tests.push({
                                    type: type ? "test" : "test_pack",
                                    sample_test_id: element,
                                    parent_test_id: type ? type.parent_test_id : "0",
                                });
                            }
                        }
                    } else {
                        console.log('sampleTestMultipleElse',this.state.TestData);
                        const type = _.find(this.state.TestData, {test_id: sample});

                        laboratories.sample_tests.push({
                            type: type ? "test" : "test_pack",
                            sample_test_id: sample,
                            parent_test_id: type ? type.parent_test_id : "0",
                        });
                    }*/

                    WorkOrderLaboratories.push(laboratories);
                }
                if (key.indexOf("location") != -1) {
                    const value = key.split("-");
                    const prod = props[`productNameInspection-${value[1]}`];

                    let product = [
                        {
                            product_id: prod,
                        },
                    ];
                    /*
                    for (const key in prod) {
                      if (prod.hasOwnProperty(key)) {
                        const element = prod[key];
                        product.push({
                          product_id: element,
                        });
                      }
                    }
                    */
                    WorkOrderRegions.push({
                        region_id: props[`location-${value[1]}`],
                        products: product,
                    });
                }
            }
        }
        const apiRequest = {
            WorkOrderTypeId: props.WorkOrderTypeId,
            workOrderUrgency: this.state.workOrderUrgency,
            WorkOrderCustomers: WorkOrderCustomers,
            WorkOrderOperationId: props.WorkOrderOperationId,
            WorkOrderDate: dateTimeMoment(props.WorkOrderDate).unix(),
            WorkOrderSample: WorkOrderSample,
            WorkOrderLaboratories: WorkOrderLaboratories,
            WorkOrderRegions: WorkOrderRegions,
            WorkOrderSectorId: props.WorkOrderSectorId,
            WorkOrderPermID: props.WorkOrderPermID,
            WorkOrderDraftReport: this.state.checked,
        };

        DataService.WorkOrder.Create(apiRequest)
            .then((result) => {
                this.setState({btnLoading: false});
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    this.props.history.goBack();
                } else {
                    Helper.ErrorMessage(result.message);
                }
            })
            .catch((error) => {
                this.setState({btnLoading: false});
                Helper.ErrorMessage(error);
            });
    };
    selectProduct = async (sectorId) => {
        const responseGetPart = await DataService.Sample.getPart(sectorId);
        this.setState({workOrderData: responseGetPart?.data || []});
        try {
            const responseProducts = await DataService.Product.SelectAll(
                0,
                0,
                sectorId
            );
            if (responseProducts.status) {
                const ProductsData = responseProducts.response.data;
                this.setState({ProductsData, sectorId});
            } else {
                Helper.ErrorMessage(responseProducts.message);
            }
        } catch (error) {
            Helper.ErrorMessage(error);
        }
    };
    addItem = ({type, value}) => {
        switch (type) {
            case "SampleLocation":
                let {locationData} = this.state;
                locationData.push({
                    name: value,
                });
                this.setState({
                    locationData,
                });

                break;
            case "WorkOrderName":
                let {workOrderData} = this.state;
                workOrderData.push({
                    name: value,
                });
                this.setState({
                    workOrderData,
                });
            default:
                break;
        }
    };

    render() {
        const options = [
            {
                label: "Lab Müdürü Onaylasın",
                value: "6bd9ecfeb76640f23685c713a99d69dc",
            },
            {
                label: "Operasyon Birimi Onaylasın",
                value: "bba0328e0b5726131ff55fe94c1edff4",
            },
        ];
        const {
            typeValue,
            RegionsData,
            SectorData,
            CustomersData,
            LabData,
            ProductsData,
            TestPackData,
            TestData,
            OperationData,
            sectorId,
            permTypeId,
        } = this.state;

        const ListItems = this.state?.ListItemsData?.map((item, index) => {
            let productId = this.formRef?.current?.getFieldValue("productName");
            return (
                <ListItem
                    key={index}
                    mainLab={index == 0 ? true : false}
                    mainLabChecked={item.mainLabChecked}
                    productName={"productName"}
                    labName={item.labName}
                    sampleName={item.sampleName}
                    sectorId={sectorId}
                    labData={LabData}
                    productData={ProductsData}
                    testPackData={TestPackData}
                    testData={TestData}
                    productId={productId}
                    onClick={() => this._deleteClick(index)}
                    userLabId={this.state.userLabId}
                    labDisabled={permTypeId != "21f9558e6799109befefcd0e7895e6dd"}
                    changed={(changedValue) => {
                        this.formRef.current.setFieldsValue(changedValue);
                    }}
                    multiple={Boolean(item.repeat_report)}
                    sampleCount={item.sample_test_count}
                    resetTest={() => {
                        this.formRef.current.setFieldsValue({
                            [item.sampleName]: [],
                        });
                    }}
                    updateTest={(props) => {
                        this.formRef.current.setFieldsValue(props);
                    }}
                />
            );
        });
        const typeDisable =
            this.state.permTypeId == "ddd2a4ede26ec3f71c602974ae7175c4" ||
            this.state.permTypeId == "9184ecf509b24f750304823dede09c68";

        const ListItemsInspections = this.state.ListItemsInspectionData.map(
            (item, index) => {
                return (
                    <ListItemsInspection
                        key={index}
                        productNameInspection={item.productNameInspection}
                        location={item.location}
                        productData={ProductsData}
                        regionData={RegionsData}
                        onClick={() => this._deleteClickInspection(index)}
                        regionId={this.state.regionId}
                        regionDisabled={typeDisable}
                    />
                );
            }
        );

        const regions = RegionsData.map((item, index) => {
            return (
                <Option key={index} value={item.region_id}>
                    {item.name}
                </Option>
            );
        });
        const sectors = SectorData.map((item, index) => {
            return (
                <Option key={index} value={item.sector_id}>
                    {item.name}
                </Option>
            );
        });
        const customers = CustomersData.map((item, index) => {
            return (
                <Option key={index} value={item.customer_id}>
                    {item.name}
                </Option>
            );
        });
        const revised = _.filter(OperationData, {type: this.state.typeValue});
        const revisedOperations = revised.length > 0 ? revised : OperationData;
        const operations = revisedOperations.map((item, index) => {
            return (
                <Option key={index} value={item.operation_id}>
                    {item.name}
                </Option>
            );
        });
        const selectAfter = (
            <Form.Item name="WorkOrderQuantityType" initialValue={"kg"}>
                <Select className="select-after">
                    <Option value="kg">Kg</Option>
                    <Option value="lt">Lt</Option>
                    <Option value="adet">Adet</Option>
                </Select>
            </Form.Item>
        );

        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="İş Emri Oluştur"/>

                    <div className="pageHeaderOptions">
                        <Space>
                            {/* <Button
                type="primary"
                primary
                icon={<PlusOutlined />}
                onClick={this._showInfoModal}
              >
                Bilgi
              </Button> */}

                            <Modal
                                title="Bilgi"
                                visible={this.state.infoVisible}
                                onCancel={this._closeModal}
                                footer=""
                            >
                                Bilgi modalı
                            </Modal>

                            <NavLink
                                activeClassName="nav-active"
                                exact
                                to="/sample/work-orders"
                            >
                                <Button className="btn-list" shape="round">
                                    İş Listesi
                                </Button>
                            </NavLink>
                        </Space>
                    </div>
                </div>
                <Spin spinning={this.state.loading}>
                    <Card bordered={false} className="cardTable">
                        <Form
                            layout="vertical"
                            hideRequiredMark
                            ref={this.formRef}
                            onFinish={this._formFinish}
                            onValuesChange={(e) => {
                                if (e.productName) {
                                    this.setState({productId: e.productName});
                                }
                            }}
                        >
                            <Row gutter={16}>
                                <Col md={8} span={24}>
                                    <Form.Item
                                        name="WorkOrderSectorId"
                                        label="İş Kolu"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{width: "100%"}}
                                            placeholder="İş Kolu Seçiniz"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(sectorId) => this.selectProduct(sectorId)}
                                        >
                                            {sectors}
                                        </Select>
                                        {/* İş koluna göre ürünler ve testler filtrelenecek disabled edebiliriz. */}
                                    </Form.Item>
                                </Col>
                                <Col md={16} span={24}>
                                    <Row gutter={16}>
                                        <Col md={14} span={24}>
                                            <Form.Item
                                                name="WorkOrderCustomers"
                                                label="Müşteri"
                                                extra="Müşteri listenizde yok ise Müşteri Ekle butonu ile ekleyebilirsiniz"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "En az 1 En fazla 4 müşteri seçebilirsiniz.",
                                                        max: 4,
                                                        min: 1,
                                                        type: "array",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    placeholder="Seçiniz"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {customers}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col
                                            md={6}
                                            span={24}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                primary
                                                onClick={this._showCustomerModal}
                                                style={{width: "100%"}}
                                            >
                                                <PlusOutlined/> Müşteri Ekle
                                            </Button>
                                            <Modal
                                                title="Yeni Müşteri Ekle"
                                                visible={this.state.customerAddVisible}
                                                onCancel={this._closeModal}
                                                footer=""
                                            >
                                                {this.state.customerAddVisible && (
                                                    <CustomerForm onChange={this._closeModals}/>
                                                )}
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Divider/>

                            <Row gutter={16}>
                                <Col md={12} span={24}>
                                    <Form.Item
                                        name="WorkOrderTypeId"
                                        label="Tip Seç"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                            },
                                        ]}
                                        initialValue={typeValue}
                                    >
                                        <Radio.Group
                                            name="radiogroup"
                                            onChange={this._onChangeType}
                                            value={typeValue}
                                            style={{marginBottom: "15px"}}
                                            disabled={typeDisable}
                                        >
                                            <Radio value={1}>Analiz</Radio>
                                            <Radio value={2}>Gözetim</Radio>
                                            {/* <Radio value={3}>Analiz ve Gözetim</Radio> */}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {typeValue === 1 ? (
                                <div>
                                    <div
                                        className="radioSection"
                                        style={{backgroundColor: "#ffd8bf"}}
                                    >
                                        <h3>Analiz</h3>
                                        {ListItems}

                                        <Button
                                            onClick={this._addListItem}
                                            type="success"
                                            disabled={
                                                this.state.permTypeId !=
                                                "21f9558e6799109befefcd0e7895e6dd"
                                            }
                                        >
                                            <PlusOutlined/> Laboratuvar Ekle
                                        </Button>

                                        <Divider/>

                                        <Row gutter={16}>
                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderName"
                                                    label={this.state.sectorId == "31479c2a2efc8bd273259ea67f4f5fdc" ? "Makina" : "Numunenin Alındığı Yer / Sampled At"}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder=""
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        dropdownRender={(menu) => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{margin: "4px 0"}}/>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        padding: 8,
                                                                    }}
                                                                >
                                                                    <Input
                                                                        style={{flex: "auto"}}
                                                                        ref={(refs) => (this.WorkOrderName = refs)}
                                                                    />
                                                                    <a
                                                                        style={{
                                                                            flex: "none",
                                                                            padding: "8px",
                                                                            display: "block",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            this.addItem({
                                                                                type: "WorkOrderName",
                                                                                value: this.WorkOrderName.state.value,
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusOutlined/> Ekle
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                        onChange={(item, options) => {
                                                            this.setState(
                                                                {
                                                                    resultData: {
                                                                        ...this.state.resultData,
                                                                        shipName: options.children,
                                                                    },
                                                                },
                                                                this._shipChange
                                                            );
                                                        }}
                                                    >
                                                        {this.state.workOrderData.map((item, index) => {
                                                            return (
                                                                <Option key={index} value={item.name}>
                                                                    {item.name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            {this.state.sectorId != "31479c2a2efc8bd273259ea67f4f5fdc" &&
                                                <Col md={6} span={24}>
                                                    <Form.Item
                                                        name="WorkOrderPickupLocation"
                                                        label="Terminal / Terminal"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Lütfen bu alanı boş bırakmayın",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder=""
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            dropdownRender={(menu) => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{margin: "4px 0"}}/>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexWrap: "nowrap",
                                                                            padding: 8,
                                                                        }}
                                                                    >
                                                                        <Input
                                                                            style={{flex: "auto"}}
                                                                            ref={(refs) =>
                                                                                (this.SampleLocationText = refs)
                                                                            }
                                                                        />
                                                                        <a
                                                                            style={{
                                                                                flex: "none",
                                                                                padding: "8px",
                                                                                display: "block",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                this.addItem({
                                                                                    type: "SampleLocation",
                                                                                    value:
                                                                                    this.SampleLocationText.state.value,
                                                                                })
                                                                            }
                                                                        >
                                                                            <PlusOutlined/> Ekle
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.locationData.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item.name}>
                                                                        {item.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>}

                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderDate"
                                                    label="Tarih"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen bu alanı boş bırakmayın",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        style={{width: "100%"}}
                                                        placeholder="Tarih Seçiniz"
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderOperationId"
                                                    label="Operasyon"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen bu alanı boş bırakmayın",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        placeholder="Seçiniz"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {operations}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Divider/>
                                        </Row>
                                    </div>
                                    {/* END Analiz Radio */}
                                </div>
                            ) : typeValue === 2 ? (
                                <div
                                    className="radioSection"
                                    style={{backgroundColor: "#ffe7ba"}}
                                >
                                    <h3>Gözetim</h3>
                                    {ListItemsInspections}

                                    <Button onClick={this._addListItemInspection} type="success">
                                        <PlusOutlined/> Konum Ekle
                                    </Button>
                                    {/* END Gozetim Radio */}

                                    <Divider/>

                                    <Row gutter={16}>
                                        <Col md={6} span={24}>
                                            <Form.Item
                                                name="WorkOrderName"
                                                label={this.state.sectorId == "31479c2a2efc8bd273259ea67f4f5fdc" ? "Makina" : "Numunenin Alındığı Yer / Sampled At"}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder=""
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    dropdownRender={(menu) => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{margin: "4px 0"}}/>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexWrap: "nowrap",
                                                                    padding: 8,
                                                                }}
                                                            >
                                                                <Input
                                                                    style={{flex: "auto"}}
                                                                    ref={(refs) => (this.WorkOrderName = refs)}
                                                                />
                                                                <a
                                                                    style={{
                                                                        flex: "none",
                                                                        padding: "8px",
                                                                        display: "block",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.addItem({
                                                                            type: "WorkOrderName",
                                                                            value: this.WorkOrderName.state.value,
                                                                        })
                                                                    }
                                                                >
                                                                    <PlusOutlined/> Ekle
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                    onChange={(item, options) => {
                                                        this.setState(
                                                            {
                                                                resultData: {
                                                                    ...this.state.resultData,
                                                                    shipName: options.children,
                                                                },
                                                            },
                                                            this._shipChange
                                                        );
                                                    }}
                                                >
                                                    {this.state.workOrderData.map((item, index) => {
                                                        return (
                                                            <Option key={item.index} value={item.name}>
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {this.state.sectorId != "31479c2a2efc8bd273259ea67f4f5fdc" &&
                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderPickupLocation"
                                                    label="Terminal / Terminal"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen bu alanı boş bırakmayın",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder=""
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        dropdownRender={(menu) => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{margin: "4px 0"}}/>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexWrap: "nowrap",
                                                                        padding: 8,
                                                                    }}
                                                                >
                                                                    <Input
                                                                        style={{flex: "auto"}}
                                                                        ref={(refs) =>
                                                                            (this.SampleLocationText = refs)
                                                                        }
                                                                    />
                                                                    <a
                                                                        style={{
                                                                            flex: "none",
                                                                            padding: "8px",
                                                                            display: "block",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            this.addItem({
                                                                                type: "SampleLocation",
                                                                                value:
                                                                                this.SampleLocationText.state.value,
                                                                            })
                                                                        }
                                                                    >
                                                                        <PlusOutlined/> Ekle
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.locationData.map((item, index) => {
                                                            return (
                                                                <Option key={index} value={item.name}>
                                                                    {item.name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>}
                                        <Col md={6} span={24}>
                                            <Form.Item
                                                name="WorkOrderDate"
                                                label="Tarih"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Lütfen bu alanı boş bırakmayın",
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{width: "100%"}}
                                                    placeholder="Tarih Seçiniz"
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={6} span={24}>
                                            <Form.Item
                                                name="WorkOrderOperationId"
                                                label="Operasyon"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Lütfen bu alanı boş bırakmayın",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    // mode="multiple"
                                                    showSearch
                                                    placeholder="Seçiniz"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {operations}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div>
                                    <div
                                        className="radioSection"
                                        style={{backgroundColor: "#ffd8bf"}}
                                    >
                                        <h3>Analiz</h3>

                                        {ListItems}

                                        <Button onClick={this._addListItem} type="success">
                                            <PlusOutlined/> Laboratuvar Ekle
                                        </Button>

                                        <Divider/>

                                        <Row gutter={16}>
                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderName"
                                                    label={this.state.sectorId == "31479c2a2efc8bd273259ea67f4f5fdc" ? "Makina" : "Numunenin Alındığı Yer / Sampled At"}
                                                >
                                                    <Input placeholder=""/>
                                                </Form.Item>
                                            </Col>
                                            {this.state.sectorId != "31479c2a2efc8bd273259ea67f4f5fdc" &&
                                                <Col md={6} span={24}>
                                                    <Form.Item
                                                        name="WorkOrderPickupLocation"
                                                        label="Terminal / Terminal"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Lütfen bu alanı boş bırakmayın",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder=""
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            dropdownRender={(menu) => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{margin: "4px 0"}}/>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexWrap: "nowrap",
                                                                            padding: 8,
                                                                        }}
                                                                    >
                                                                        <Input
                                                                            style={{flex: "auto"}}
                                                                            ref={(refs) =>
                                                                                (this.SampleLocationText = refs)
                                                                            }
                                                                        />
                                                                        <a
                                                                            style={{
                                                                                flex: "none",
                                                                                padding: "8px",
                                                                                display: "block",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                this.addItem({
                                                                                    type: "SampleLocation",
                                                                                    value:
                                                                                    this.SampleLocationText.state.value,
                                                                                })
                                                                            }
                                                                        >
                                                                            <PlusOutlined/> Ekle
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.locationData.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item.name}>
                                                                        {item.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>}
                                        </Row>
                                    </div>

                                    <div
                                        className="radioSection"
                                        style={{backgroundColor: "#ffe7ba"}}
                                    >
                                        <h3>Gözetim</h3>
                                        {ListItemsInspections}

                                        <Button
                                            onClick={this._addListItemInspection}
                                            type="success"
                                        >
                                            <PlusOutlined/> Konum Ekle
                                        </Button>

                                        <Divider/>

                                        <Row gutter={16}>
                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderName"
                                                    label={this.state.sectorId == "31479c2a2efc8bd273259ea67f4f5fdc" ? "Makina" : "Numunenin Alındığı Yer / Sampled At"}
                                                >
                                                    <Input placeholder=""/>
                                                </Form.Item>
                                            </Col>

                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderDate"
                                                    label="Tarih"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen bu alanı boş bırakmayın",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        style={{width: "100%"}}
                                                        placeholder="Tarih Seçiniz"
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col md={6} span={24}>
                                                <Form.Item
                                                    name="WorkOrderOperationId"
                                                    label="Operasyon"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen bu alanı boş bırakmayın",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        // mode="multiple"
                                                        showSearch
                                                        placeholder="Seçiniz"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {operations}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Divider/>
                                        </Row>
                                    </div>

                                    {/* END Analiz ve Gozetim Radio */}
                                </div>
                            )}

                            <Divider/>

                            <div className="formButton">
                                {typeValue != 2 && (
                                    <>
                                        <Col md={4} span={24}
                                             style={{ backgroundColor: "#ffd8bf", paddingLeft: 10, paddingRight:10, paddingTop:5, marginRight:10 }}>
                                            <Form.Item
                                                name="workOrderUrgency"
                                                label="Aciliyet"
                                            >
                                                <Select placeholder="" defaultValue="Standart – (Max 24 saat)" value={this.state.workOrderUrgency}
                                                        onChange={(workOrderUrgency) => this.setState({ workOrderUrgency })}
                                                >
                                                    <Option value="Standart – (Max 24 saat)" selected>Standart – (Max 24 saat)</Option>
                                                    <Option value="Acil – (Max 6 saat)">Acil – (Max 6 saat)</Option>
                                                    <Option value="Acil Değil – (Mesaisiz)">Acil Değil – (Mesaisiz)</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Form.Item name="WorkOrderDraftReport" label="Taslak Rapor">
                                            <Checkbox
                                                checked={this.state.checked}
                                                onChange={(e) =>
                                                    this.setState({checked: e.target.checked})
                                                }
                                            >
                                                {" "}
                                                Evet / Hayır{" "}
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="WorkOrderPermID"
                                            label="Onaylayan Birim"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Lütfen bu alanı boş bırakmayın",
                                                },
                                            ]}
                                        >
                                            <Radio.Group options={options} optionType="default"/>
                                        </Form.Item>
                                    </>
                                )}
                                <Space size={15} align="end">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        loading={this.state.btnLoading}
                                    >
                                        <PlusOutlined/> İş Emri Oluştur
                                    </Button>
                                </Space>
                            </div>
                        </Form>
                    </Card>
                </Spin>
            </div>
        );
    }
}

export default index;
