import React, {Component} from "react";
import "../index.css";
import {ArrowLeftOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Descriptions, Divider, Space} from "antd";

class Confirmation extends Component {
    state = {
        loading: false,
        formTextData: {
            customer: "",
            sector: "",
            product: "",
            operation: "",
            WorkOrderName: "",
            SampleLocation: "",
            SamplePart: "",
            tests: "",
        },
        formData: {
            // SampleStock: [],
            date_delivery: new Date(),
        },
    };
    previous = () => {
        let formData = {
            ...this.props.data
        };
        this.props.previous(formData, this.props.data.selectedWorkBranch);
    };
    onFinish = () => this.props.onFinish();

    render() {
        const {data, formTextData} = this.props;

        if (this.state.loading) {
            return <div/>;
        }
        return (
            <div>
                <Descriptions title="Kayıt Bilgileri" bordered size="small">
                    <Descriptions.Item label="Müşteri">
                        {formTextData.customer}
                    </Descriptions.Item>
                    <Descriptions.Item label={(formTextData.sector) === 'LUBOIL (OCM)' ? "Kategori" : "İş Kolu"}>
                        {formTextData.sector}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={(formTextData.sector) === 'LUBOIL (OCM)' ? 'Numunenin Adı ve Tarifi' : 'Ürün'}>
                        {formTextData.product}
                    </Descriptions.Item>
                    <Descriptions.Item label="Operasyon">
                        {formTextData.operation}
                    </Descriptions.Item>
                </Descriptions>

                <Divider/>

                <Descriptions title="Numune Bilgileri" bordered size="small">
                    <Descriptions.Item label="Numune Miktarı">
                        {data?.SampleStock.map((item, key) => {
                            let value = (data.WorkOrderSectorId === '31479c2a2efc8bd273259ea67f4f5fdc' ? item?.bottle + 'X' + item?.amount : item?.amount + ' ' + item?.amount_type);

                            return (
                                <>
                                    <div key={key}>
                                        <span>Mühür Bilgisi : {item.signet_code}</span>
                                        <span className="ml-1">Miktarı : {value}</span>
                                    </div>
                                </>
                            );
                        })}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={(formTextData.sector) === 'LUBOIL (OCM)' ? "Makina" : "Numunenin Alındığı Yer / Sample At"}>
                        {formTextData.WorkOrderName}
                    </Descriptions.Item>
                    {
                        formTextData.SampleLocation && (
                            <Descriptions.Item label="Terminal">
                                {formTextData.SampleLocation}
                            </Descriptions.Item>
                        )
                    }
                    <Descriptions.Item
                        label={(formTextData.sector) === 'LUBOIL (OCM)' ? "Parça" : "Alım Yeri Kategori"}>
                        {formTextData.SamplePart}
                    </Descriptions.Item>
                    <Descriptions.Item label="Alım Tarihi">
                        {formTextData.SampleDateDelivery}
                    </Descriptions.Item>
                    <Descriptions.Item label="Numune Alma Metodu">
                        {formTextData.WorkOrderName}
                    </Descriptions.Item>
                </Descriptions>

                <Divider/>

                <Descriptions title="Analiz Bilgileri" bordered size="small">
                    <Descriptions.Item label="Testler" span="3">
                        {formTextData.SampleTests}
                    </Descriptions.Item>
                </Descriptions>
                <div className="stepActions">
                    <Space align="center">
                        <Button
                            size="large"
                            style={{margin: "0 8px"}}
                            onClick={this.previous}
                            icon={<ArrowLeftOutlined/>}
                        >
                            Önceki
                        </Button>

                        <Button type="primary" size="large" onClick={this.onFinish}>
                            <PlusOutlined/> Kaydet
                        </Button>
                    </Space>
                </div>
            </div>
        );
    }
}

export default Confirmation;
